import { useMemo, useState } from 'react';

import { AdvancedFilterBuilder } from '@/shared/components/filterBuilder/AdvancedFilterItem';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { rebuildFilters } from '@/shared/components/filterBuilder/utils/rebuildFilters';
import { DefaultObject } from '@/shared/types/data';
import { FilterItem, FilterType } from '@/shared/types/filter';
import { Box, Button, Fieldset, Flex, Label, VStack } from '@/shared/ui';

import AudienceQuickFilter from '../editor/components/AudienceQuickFilter';
import { isValidQuickFilter, translateToAdvancedFilter } from '../editor/utils';

export type QuickFilterAlias = {
  label: string;
  filter: FilterItem;
  type: 'included' | 'excluded';
};

type CampaignAudienceProps = {
  channelId: string | null;
  /**
   * There are times where we want to be able to "hide" a filter from the audience builder.
   * This would include things like the system default filters, or filters that are not
   * created through the audience builder like the exclude open conversation filter which
   * is done through a switch component.
   */
  exclude: {
    fullExcludeAudienceFilter: FilterItem[];
    removeFromFilter: (filterItems: FilterItem[]) => FilterItem[];
    appendToFilter: (filterItems: FilterItem[]) => FilterItem[];
  };
  quickFilterAliases?: QuickFilterAlias[];
  includedAudienceFilter: FilterItem[] | null;
  audienceTabValue: 'advanced-filter' | 'quick-filter';
  onAudienceTabValueChanged: (
    audienceTabValue: 'advanced-filter' | 'quick-filter'
  ) => void;
  onIncludedAudienceFilterChanged: (filterItems: FilterItem[]) => void;
  /**
   * This function is called when the excluded audience filter and is valid. Meaning that for the advanced filter
   * you won't get a notification when you're at an intermediate step.
   *
   * NOTE: It's very important that you only pass in the user selected filters here. For example, system default filters
   * should probably not be passed in here since they would be shown to the user.
   *
   */
  onExcludedAudienceFilterChanged: (filterItems: FilterItem[]) => void;
  customDefaultObjects: Array<DefaultObject> | [];
};
const CampaignAudience = ({
  includedAudienceFilter,
  exclude,
  audienceTabValue,
  quickFilterAliases,
  onAudienceTabValueChanged,
  onIncludedAudienceFilterChanged,
  onExcludedAudienceFilterChanged,
  customDefaultObjects,
  channelId,
}: CampaignAudienceProps) => {
  const { fullExcludeAudienceFilter, removeFromFilter, appendToFilter } = exclude;

  const excludedAudienceQuickFilter = useMemo(() => {
    return removeFromFilter(fullExcludeAudienceFilter);
  }, [fullExcludeAudienceFilter]);

  const isExcludedFilterValidQuickFilter = useMemo(() => {
    const cleanedExcludeQuickFilter = removeFromFilter(fullExcludeAudienceFilter);
    return isValidQuickFilter(cleanedExcludeQuickFilter, 'excluded', quickFilterAliases);
  }, [fullExcludeAudienceFilter, removeFromFilter]);

  const [includedFilterType, setIncludedFilterType] = useState<FilterType[]>(() => {
    const advancedFilter = translateToAdvancedFilter(
      includedAudienceFilter ?? []
    ) as FilterType[];
    return rebuildFilters([], advancedFilter, customDefaultObjects);
  });

  const [excludedFilterType, setExcludedFilterType] = useState<FilterType[]>(() => {
    const cleanedExcludeAdvancedFilter = removeFromFilter(fullExcludeAudienceFilter);
    const advancedFilter = translateToAdvancedFilter(
      cleanedExcludeAdvancedFilter ?? []
    ) as FilterType[];
    return rebuildFilters([], advancedFilter, customDefaultObjects);
  });

  const isIncludedFilterValidQuickFilter = useMemo(() => {
    return isValidQuickFilter(includedAudienceFilter, 'included', quickFilterAliases);
  }, [includedAudienceFilter]);

  /*
   * The goal of this function is to
   * 1. Add the system default rules back to the excluded audience filter (the quick filter interacts
   * with the excluded audience filter without the system default rules)
   * 2. Keep the quick filter and the advanced filter in sync
   */
  const updateExcludedAudienceFilters = (
    items: FilterItem[],
    updateFilterType: boolean
  ) => {
    const fullFilter = appendToFilter(items);
    onExcludedAudienceFilterChanged(fullFilter);

    if (!updateFilterType) return;
    // also update the Filter Types for the advanced filter
    const advancedExcludeFilter = translateToAdvancedFilter(
      fullFilter ?? []
    ) as FilterType[];
    setExcludedFilterType(
      rebuildFilters([], advancedExcludeFilter, customDefaultObjects)
    );
  };

  return (
    <VStack gap="2" css={{ gap: '24px' }}>
      <Flex gap="1">
        <Button
          type="button"
          data-testid="quick-filters-btn"
          variant="grayBackground"
          disabled={
            !isIncludedFilterValidQuickFilter || !isExcludedFilterValidQuickFilter
          }
          css={
            audienceTabValue === 'advanced-filter' ? { background: 'transparent' } : {}
          }
          onClick={() => onAudienceTabValueChanged('quick-filter')}
        >
          Quick filters
        </Button>
        <Button
          type="button"
          data-testid="advanced-filters-btn"
          variant="grayBackground"
          css={audienceTabValue === 'quick-filter' ? { background: 'transparent' } : {}}
          onClick={() => onAudienceTabValueChanged('advanced-filter')}
        >
          Advanced filters
        </Button>
      </Flex>
      <Flex direction="column" css={{ gap: '8px', marginTop: '0px' }}>
        <Label>Include Contacts</Label>
        {audienceTabValue == 'quick-filter' ? (
          <Fieldset data-testid="field-include-audience">
            <Box
              css={{
                p: 0,
                m: 0,
                background: 'white',
              }}
            >
              <AudienceQuickFilter
                data-testid="included_audience_filter"
                channelId={channelId}
                aliases={quickFilterAliases}
                onSelectedItemsChanged={(filterItems) => {
                  onIncludedAudienceFilterChanged(filterItems);
                  const advancedIncludeFilter = translateToAdvancedFilter(
                    filterItems ?? []
                  ) as FilterType[];
                  setIncludedFilterType(
                    rebuildFilters([], advancedIncludeFilter, customDefaultObjects)
                  );
                }}
                selectedItems={includedAudienceFilter}
              />
            </Box>
          </Fieldset>
        ) : (
          <Fieldset>
            <AdvancedFilterBuilder
              defaultObjects={customDefaultObjects}
              customObjects={[]}
              filters={includedFilterType}
              setFilters={(filters) => {
                setIncludedFilterType(filters);
                // If we're at an intermediate step, we need to make sure that the filters are valid
                // before we update notify that we have a valid filter
                if (!areFiltersValid(filters)) return;
                const cleanedFilters = cleanFilters(filters);
                onIncludedAudienceFilterChanged(cleanedFilters);
              }}
            />
          </Fieldset>
        )}
      </Flex>
      <Flex direction="column" css={{ gap: '8px', marginTop: '0px' }}>
        <Label>Exclude Contacts</Label>
        {audienceTabValue == 'quick-filter' ? (
          <Fieldset data-testid="field-exclude-audience">
            <Box
              css={{
                p: 0,
                m: 0,
                background: 'white',
              }}
            >
              <AudienceQuickFilter
                data-testid="excluded_audience_filter"
                channelId={channelId}
                onSelectedItemsChanged={async (filterItems) => {
                  if (!channelId) return;
                  // update the filter type as well
                  updateExcludedAudienceFilters(filterItems, true);
                }}
                selectedItems={excludedAudienceQuickFilter}
              />
            </Box>
          </Fieldset>
        ) : (
          <Fieldset>
            <AdvancedFilterBuilder
              defaultObjects={customDefaultObjects}
              customObjects={[]}
              filters={excludedFilterType}
              setFilters={(filters) => {
                setExcludedFilterType(filters);
                // If we're at an intermediate step, we need to make sure that the filters are valid
                // before we update notify that we have a valid filter
                if (!areFiltersValid(filters) || !channelId) return;
                const cleanedFilters = cleanFilters(filters);
                updateExcludedAudienceFilters(cleanedFilters, false);
              }}
            />
          </Fieldset>
        )}
      </Flex>
    </VStack>
  );
};

export default CampaignAudience;
