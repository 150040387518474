/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { getCampaignsOverview } from '@/shared/api/reports';
import { Chart } from '@/shared/components/reports/Chart';
import { DAY } from '@/shared/components/reports/constants';
import { VStack } from '@/shared/ui';

import { useReport } from '../context/ReportsContext';

export type CampaignsOverviewData = {
  /** data to display in chart */
  grouped_result: CampaignsOverviewGroupedResult[];
  /** new campaigns in this time period */
  new_campaigns_count: number;
  /** new campaigns in the previous time period */
  prev_campaigns_count: number;
  /** avg response rate in this time period */
  new_avg_response_rate: number;
  /** avg response rate in the previous time period */
  prev_avg_response_rate: number;
  /** campaign messages in this time period */
  new_delivered_messages_count: number;
  /** campaign messages in the previous time period */
  prev_delivered_messages_count: number;
  /** response key */
  resource: 'campaign';
};

export type CampaignsOverviewGroupedResult = {
  /** average segments per message */
  average_response_rate: number;
  /** period to display -> day, week, month, year */
  period: string;
  /** end of the period */
  period_end: string;
  /** start of the period */
  period_start: string;
};

export const CampaignOverviews = () => {
  const reports = useReport();
  const { dates, initialState } = reports;

  const [data, setData] = useState<CampaignsOverviewData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [dates.startDate, dates.endDate]);

  const getData = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await getCampaignsOverview(
        dates.startDate && dates.endDate
          ? {
              type: 'overview_campaigns',
              start_date: dayjs(dates.startDate as string).format('YYYY-MM-DD'),
              end_date: dayjs(dates.endDate as string).format('YYYY-MM-DD'),
              granularity: DAY,
              location_ids: [],
            }
          : { ...initialState, type: 'overview_campaigns' }
      );
      setData(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const chart_config = [
    {
      dataKey: 'average_response_rate',
      fill: '#3E54CF',
      name: 'Average Response Rate',
    },
  ];

  const headers = [
    { label: 'Average Response Rate', key: 'average_response_rate' },
    { label: 'Period', key: 'period' },
  ];

  // Prepare data for CSV export from grouped result
  const csvData =
    data?.grouped_result.map((item) => ({
      ...item,
      'Average Response Rate': item.average_response_rate,
      Period: item.period,
    })) || [];

  return (
    <VStack gap={2}>
      <Chart
        title="Average Response Rate"
        chartConfig={chart_config}
        data={data || []}
        loading={loading}
        error={error}
        errorCallback={getData}
        headers={headers}
        csvData={csvData}
        filename="average_response_rate_by_day"
      />
    </VStack>
  );
};
