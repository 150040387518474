import { AdvancedFilterItemObject, AdvancedFilterItemProperties } from './types';

export const default_campaign_contact_object: AdvancedFilterItemObject = {
  id: 'unique-id-for-campaign_contact',
  label: 'Campaign Contact',
  key: 'campaign_contact',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'campaign_contact-unsubscribed',
      label: 'Un-subscribed',
      type: 'boolean',
      key: 'unsubscribed',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-campaign_contact_unsubscribed',
    },
    {
      default: '',
      id: 'campaign_contact-link-clicked',
      label: 'Link Clicked',
      type: 'boolean',
      key: 'clicked_link',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-campaign_contact_clicked_link',
    },
  ],
};

export const campaign_contact_campaign_id: AdvancedFilterItemProperties = {
  default: '',
  id: 'campaign_contact-campaign',
  label: 'Campaign',
  type: 'campaign',
  key: 'campaign_id',
  required: true,
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_object_id: 'unique-id-for-campaign_contact_campaign',
};
