import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChevronLeft, HiPlus, HiSearch, HiTrash, HiX } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { DrawerHeaderContainer } from '@/pages/data/contacts/CreateContact';
import { useSequences } from '@/pages/sequences/context/SequenceContext';
import { AddContactToSequence } from '@/pages/sequences/sequence/audience/AddContactToSequence';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { ContactSequence, SequenceContactStatusType } from '@/shared/types/sequences';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerPortal,
  DrawerTrigger,
  Flex,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Text,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';
import { ContactAccordion } from './ContactAccordion';
import { AccordionValue } from './ContactView';

type ContactSequencesProps = {
  contactId: string;
  loading: boolean;
  accordionValue: AccordionValue;
};

const getBadgeColor = (status?: SequenceContactStatusType) => {
  switch (status) {
    case SequenceContactStatusType.SCHEDULED:
      return 'blue';
    case SequenceContactStatusType.COMPLETE:
    case SequenceContactStatusType.ACTIVE:
      return 'green';
    case SequenceContactStatusType.REMOVED:
      return 'orange';
    case SequenceContactStatusType.PENDING:
    case SequenceContactStatusType.INACTIVE:
      return 'yellow';
    default:
      return 'gray';
  }
};

export const renderDate = (date?: string) => {
  dayjs.extend(relativeTime);

  if (!date) return 'Today';

  if (dayjs(new Date()).diff(new Date(date), 'day') < 1) {
    return dayjs(new Date(date)).fromNow(true);
  }

  return dayjs(new Date(date)).format('ddd DD MMM YY');
};

export const ContactSequences = ({
  contactId,
  loading,
  accordionValue,
}: ContactSequencesProps) => {
  // contacts context
  const {
    contactState: { currentContactSequences, loadingContactSequences },
    getContactSequences,
  } = useContacts();

  const { removeContactsFromSequence } = useSequences();

  const handleRemoveFromSequence = useCallback(
    async (sequenceId: string) => {
      await removeContactsFromSequence([contactId] || [], sequenceId || '');
      await getContactSequences(contactId);
    },
    [contactId]
  );

  // render campaign item function
  const renderItem = useCallback(
    (item: ContactSequence, i: number) => (
      <SequenceItem
        data={item}
        isLast={i === Math.min(currentContactSequences?.length - 1, 4)}
        isFirst={i === 0}
        isOnlyOne={currentContactSequences?.length === 1}
        onRemove={() => handleRemoveFromSequence(item?.id)}
      />
    ),
    [currentContactSequences?.length, handleRemoveFromSequence]
  );

  const handleAddToSequence = useCallback(() => {
    getContactSequences(contactId);
  }, [contactId]);

  const renderAddToSequenceButton = useCallback(
    () => (
      <AddContactToSequence
        contact_ids={[contactId || '']}
        onSubmit={handleAddToSequence}
      >
        <Button size={1} css={{ mb: 12 }}>
          <HiPlus />
          <Text css={{ color: 'currentColor' }}>{`Add to Sequence`}</Text>
        </Button>
      </AddContactToSequence>
    ),
    [contactId]
  );

  return (
    <ContactAccordion
      title={'Sequences'}
      accordionValue={accordionValue}
      defaultValue={accordionValue}
    >
      {loadingContactSequences || loading ? (
        <Flex css={{ flexDirection: 'column', position: 'relative' }}>
          <Skeleton
            css={{ height: 125, width: 2, position: 'absolute', top: 4, left: 71 }}
          />
          {Array.from({ length: 5 }, (_: any, k: React.Key | null | undefined) => (
            <Flex align="center" key={k} css={{ width: '100%', flex: 1 }}>
              <Skeleton css={{ height: 20, width: 56, mr: 12, my: 6 }} />
              <Skeleton css={{ height: 8, width: 8, mr: 12, my: 6, borderRadius: 8 }} />
              <Flex css={{ flex: 1 }} justify="between">
                <Skeleton css={{ height: 20, width: 160, mr: 6, my: 6 }} />
                <Skeleton css={{ height: 20, width: 56, my: 6 }} />
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : currentContactSequences?.length > 0 ? (
        <Flex direction="column">
          <Box>{renderAddToSequenceButton()}</Box>
          {currentContactSequences?.slice(0, 5).map(renderItem)}
        </Flex>
      ) : (
        <Flex direction="column" align="center">
          <Box css={{ fontSize: 16, fontWeight: '700', marginBottom: 8 }}>
            No Sequences Yet
          </Box>
          <Box css={{ fontSize: 16, textAlign: 'center', mb: 12 }}>
            Add this user to the sequence using the button below
          </Box>
          {renderAddToSequenceButton()}
        </Flex>
      )}
      {!(loading || loadingContactSequences) && currentContactSequences?.length > 5 && (
        <SequencesDrawer
          contactId={contactId}
          onSubmit={handleAddToSequence}
          onRemove={handleRemoveFromSequence}
        >
          <Button
            ghost
            size={1}
            css={{
              color: '#60646C',
              mt: 8,
              fontSize: 14,
              boxShadow: 'none',
              '&:focus': {
                boxShadow: 'none',
              },
            }}
          >
            {`See All Sequences`}
          </Button>
        </SequencesDrawer>
      )}
    </ContactAccordion>
  );
};

const SequenceItem = ({
  data,
  isFirst,
  isLast,
  isOnlyOne,
  onRemove,
}: {
  data: ContactSequence;
  isFirst: boolean;
  isLast: boolean;
  isOnlyOne: boolean;
  onRemove?: (id: string) => void;
}) => {
  return (
    <StyledSequenceItem align="center">
      <SequenceDate>
        {renderDate(data?.updated_at || data?.inserted_at || '')}
      </SequenceDate>
      <CircleDivider isFirst={isFirst} isLast={isLast} isOnlyOne={isOnlyOne} />
      <Flex css={{ maxWidth: 'calc(100% - 126px)', flex: 1 }} justify="between">
        <SequenceTitle to={`/sequences/${data.id}/contacts`}>{data.title}</SequenceTitle>
        <Flex align="center" justify="end">
          <Badge
            variant={getBadgeColor(data?.contact_status)}
            css={{
              textTransform: 'capitalize',
              mr: data?.contact_status === SequenceContactStatusType.ACTIVE ? 8 : 0,
            }}
          >
            {data?.contact_status || '-'}
          </Badge>
          {data?.contact_status === SequenceContactStatusType.ACTIVE ? (
            <ConfirmationDialog
              title="Remove Contact from Sequence"
              description="Are you sure you want to remove this contact from the sequence?"
              confirmButtonTitle="Yes, Remove Contact from Sequence"
              onConfirm={() => onRemove?.(data?.id)}
            >
              <TooltipIconButton
                sideOffset={10}
                icon={<HiTrash color="#60646C" />}
                text="Remove Contact from Sequence"
                size={0}
              />
            </ConfirmationDialog>
          ) : (
            <Box css={{ width: 20, ml: 8 }} />
          )}
        </Flex>
      </Flex>
    </StyledSequenceItem>
  );
};

type SequencesDrawerProps = {
  children: React.ReactNode;
  contactId: string;
  onSubmit?: () => void;
  onRemove?: (id: string) => void;
};

const SequencesDrawer = ({
  children,
  contactId,
  onSubmit,
  onRemove,
}: SequencesDrawerProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // contact context
  const {
    contactState: { currentContactSequences },
  } = useContacts();

  // local context
  const [search, setSearch] = useState('');

  // handle search campaigns
  const handleSearch = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    }, 500),
    []
  );

  // handle clear search
  const handleClearSearch = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    setSearch('');
  }, []);

  // reset local state when unmount
  useEffect(() => {
    return () => {
      setSearch('');
    };
  }, []);

  const filteredSequences = useMemo(() => {
    return search
      ? currentContactSequences?.filter((item: ContactSequence) => {
          return item?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase());
        })
      : currentContactSequences;
  }, [search, currentContactSequences]);

  return (
    <Drawer>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerPortal>
        <DrawerContent
          aria-describedby={`Contact Sequences`}
          css={{
            maxWidth: '420px',
            minWidth: '360px',
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex>
            <DrawerHeaderContainer css={{ px: 20 }}>
              <HStack>
                <DrawerClose>
                  <HiChevronLeft />
                </DrawerClose>
                <Heading css={{ textTransform: 'capitalize', ml: 20 }}>Sequences</Heading>
              </HStack>
            </DrawerHeaderContainer>
          </Flex>
          <SearchContainer>
            <SearchIconContainer>
              <HiSearch />
            </SearchIconContainer>
            <SearchInput
              ref={inputRef}
              placeholder={`Search Sequences`}
              css={{ pl: 38 }}
              onChange={handleSearch}
            />
            {search.length > 0 && (
              <SearchControlsContainer css={{ right: 5 }}>
                <IconButton onClick={handleClearSearch}>
                  <HiX />
                </IconButton>
              </SearchControlsContainer>
            )}
          </SearchContainer>
          <DrawerContentContainer direction="column">
            <Box css={{ px: 24, pt: 24 }}>
              <AddContactToSequence contact_ids={[contactId || '']} onSubmit={onSubmit}>
                <Button size={1} css={{ mb: 12 }}>
                  <HiPlus />
                  <Text css={{ color: 'currentColor' }}>{`Add to Sequence`}</Text>
                </Button>
              </AddContactToSequence>
            </Box>
            <Flex css={{ flex: 1, width: '100%' }}>
              <Virtuoso
                style={{ width: '100%' }}
                data={filteredSequences}
                itemContent={(i: number, item: ContactSequence) => (
                  <Flex css={{ paddingLeft: 24, paddingRight: 24 }}>
                    <SequenceItem
                      key={item?.id || i}
                      data={item}
                      isLast={filteredSequences?.length - 1 === i}
                      isFirst={i === 0}
                      isOnlyOne={filteredSequences?.length === 1}
                      onRemove={() => onRemove?.(item?.id)}
                    />
                  </Flex>
                )}
                components={{
                  EmptyPlaceholder: () => {
                    if (search) {
                      return (
                        <Box css={{ textAlign: 'center', fontSize: 14 }}>
                          No search results
                        </Box>
                      );
                    }
                    return null;
                  },
                  Header: () => <Box css={{ pb: 4 }} />,
                  Footer: () => <Box css={{ pb: 18 }} />,
                }}
              />
            </Flex>
          </DrawerContentContainer>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

const StyledSequenceItem = styled(Flex, {
  width: '100%',
  flex: 1,
  py: 6,
});

const SequenceTitle = styled(Link, {
  fontSize: 14,
  color: '$primaryButtonColor',
  textDecoration: 'underline',
  marginLeft: 12,
  '&:hover': {
    textDecoration: 'none',
  },
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const SequenceDate = styled(Box, {
  width: 105,
  textAlign: 'right',
  fontSize: 14,
  color: '#1C2024',
  marginRight: 12,
  flex: 'none',
});

const CircleDivider = styled(Box, {
  width: 8,
  height: 8,
  borderRadius: 8,
  backgroundColor: '$slate5',
  position: 'relative',
  '&:after': {
    content: '',
    position: 'absolute',
    top: -12,
    left: 3,
    width: 2,
    height: 33,
    backgroundColor: '$slate5',
  },
  variants: {
    isFirst: {
      true: {
        '&:after': {
          top: 0,
          height: 22,
        },
      },
    },
    isLast: {
      true: {
        '&:after': {
          height: 17,
        },
      },
    },
    isOnlyOne: {
      true: {
        '&:after': {
          height: 0,
        },
      },
    },
  },
});

export const DrawerContentContainer = styled(Flex, {
  position: 'relative',
  width: '100%',
  height: '100%',
  flex: 1,
  flexDirection: 'column',
});

export const Heading = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 17,
  fontWeight: 800,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SearchContainer = styled(Box, {
  position: 'relative',
  width: '100%',
  px: 16,
  py: 12,
  borderBottom: 'thin solid var(--colors-gray4)',
});

const SearchInput = styled(Input, {
  boxShadow: 'none',
  height: 32,
  '&:focus': {
    boxShadow: 'none',
  },
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  left: 20,
});

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  right: 16,
});
