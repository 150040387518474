import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { Agent, AgentVersion } from '@/shared/types/ai/agents';
import { Box, Button, Flex, HStack, Text } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

interface AgentsTableProps {
  data: Agent[] | AgentVersion[];
  loading: boolean;
  totalCount: number;
  onCardClick: (id: string) => void;
  setOffset: (offset: number) => void;
  isVersionTable?: boolean;
}

export const AgentsTable: React.FC<AgentsTableProps> = ({
  data,
  loading,
  totalCount,
  onCardClick,
  setOffset,
  isVersionTable = false,
}) => {
  const columns: Array<TableColumn<Agent | AgentVersion>> = useMemo(
    () => [
      {
        Header: 'Agent',
        colWidth: '100%',
        accessor: 'name',
        Cell: (props: { row: { original: Agent | AgentVersion } }) => (
          <Flex direction="column">
            <Text css={{ fontWeight: 500 }}>
              {isVersionTable
                ? (props.row.original as AgentVersion).name
                : (props.row.original as Agent).current_version.name}
            </Text>
            <Text>
              {isVersionTable
                ? (props.row.original as AgentVersion).description
                : (props.row.original as Agent).current_version.description}
            </Text>
          </Flex>
        ),
      },
      {
        Header: 'Created By',
        colWidth: '100%',
        accessor: 'created_by',
        Cell: (props: { row: { original: Agent | AgentVersion } }) => (
          <Flex direction="column">
            {props.row.original.created_by && (
              <TooltipAvatar
                user={{
                  ...props.row.original.created_by,
                  id: Number(props.row.original.created_by.id),
                }}
              />
            )}
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        colWidth: '100%',
        accessor: 'updated_at',
        Cell: (props: { row: { original: Agent | AgentVersion } }) => (
          <Flex align="center" css={{ minWidth: 100 }}>
            <Box>
              {dayjs(
                (props.row.original as Agent).updated_at ||
                  (props.row.original as AgentVersion).inserted_at
              ).format('MMM D YYYY')}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        colWidth: '100%',
        Cell: (props: { row: { original: Agent | AgentVersion } }) => (
          <Flex direction="column" align="start" css={{ minWidth: 100, mr: 20 }}>
            <HStack>
              <Button variant="gray" onClick={() => onCardClick(props.row.original.id)}>
                {isVersionTable ? 'View Version' : 'Edit Agent'}
              </Button>
            </HStack>
          </Flex>
        ),
      },
    ],
    [isVersionTable]
  );

  return (
    <Table
      data={data}
      columns={columns}
      caption={isVersionTable ? 'Agent Versions Table' : 'Agents Table'}
      emptyTitle={isVersionTable ? 'No Versions Found' : 'No Agents Match Search'}
      totalCount={totalCount}
      pageSize={10}
      isLoading={loading}
      setOffset={setOffset}
    />
  );
};
