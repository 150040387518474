import { Helmet } from 'react-helmet-async';

import { Integration as IntegrationPage } from './Integration';
import { IntegrationProvider } from './IntegrationProvider';
import { Integrations as IntegrationsList } from './Integrations';

export const Integrations = () => {
  return (
    <IntegrationProvider>
      <Helmet>
        <title>Whippy | Integrations</title>
      </Helmet>
      <IntegrationsList />
    </IntegrationProvider>
  );
};

export const Integration = () => {
  return (
    <IntegrationProvider>
      <Helmet>
        <title>Whippy | Integrations</title>
      </Helmet>
      <IntegrationPage />
    </IntegrationProvider>
  );
};
