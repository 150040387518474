import { Dispatch, useEffect, useLayoutEffect, useRef, useState } from 'react';
import React from 'react';

import { searchAgents } from '@/shared/api/ai/agents';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { Agent } from '@/shared/types/ai/agents';
import { FilterItem, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxSelectedItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectTriggerProps } from '@/shared/v2/components/comboboxMultiselect/types';

const ITEMS_COUNT = 50;

export const AnalyticFilterCombobox = ({
  selectedItems,
  setSelectedItems,
}: {
  selectedItems: Array<string>;
  setSelectedItems: Dispatch<React.SetStateAction<Array<string>>>;
}) => {
  const [offset, setOffset] = useState(0);
  const [agents, setAgents] = useState<Array<Agent>>([]);
  const [totalAgents, setTotalAgents] = useState(0);
  const [loading, setLoading] = useState(false);

  // Default filter for agents that are not archived
  const default_status_filter = {
    resource: 'agent',
    column: 'status',
    comparison: '!=',
    value: 'archived',
  };

  useEffect(() => {
    fetchAgents(cleanFilters([default_status_filter]), [], ITEMS_COUNT, offset);
  }, [offset]);

  const fetchAgents = async (
    filter: Array<FilterItem>,
    sort: Array<Sort> = [],
    limit: number,
    offset: number
  ) => {
    setLoading(true);
    const data = await searchAgents(filter, sort, limit, offset);
    setAgents([...agents, ...data.data]);
    setTotalAgents(data.total);
    setLoading(false);
  };

  return (
    <ComboboxMultiselect
      options={agents.map((agent: Agent) => ({
        label: agent.current_version.name ?? '',
        value: agent.id,
      }))}
      width="auto"
      selected={selectedItems}
      onSelect={setSelectedItems}
      searchLabel="Search"
      selectLabel="Agents"
      Trigger={ComboboxMultiselectTrigger}
      Option={ComboboxMultiselectItem}
      currentOffset={offset}
      totalOptions={totalAgents - ITEMS_COUNT}
      loadMore={() => setOffset(offset + ITEMS_COUNT)}
      loading={loading}
      visualized
    />
  );
};

export const ComboboxMultiselectTrigger = ({
  selectedLabels,
  placeholder,
  invalid,
}: ComboboxMultiselectTriggerProps<string>) => {
  const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const cells = ref.current?.children;
    if (cells && cells.length > 0) {
      const startingOffset = (cells[0] as HTMLDivElement).offsetLeft;
      let numItems = 1;
      for (let i = 1; i < cells.length; i++) {
        if ((cells[i] as HTMLDivElement).offsetLeft === startingOffset) {
          break;
        }

        numItems++;
      }
      setNumberOfItemsDisplayed(numItems);
    }
  }, [ref.current?.children, selectedLabels]);

  return (
    <Flex
      align="center"
      css={{
        width: '100%',
        height: 40,
        borderRadius: '4px',
        border: invalid ? '2px solid red' : '1px solid $slate7',
        padding: '$space$1 $space$3',
        backgroundColor: 'white',
      }}
    >
      <div style={{ maxWidth: 'calc(100% - 15px)', width: '100%', textAlign: 'left' }}>
        {selectedLabels.length ? (
          <Flex>
            <Flex
              ref={ref}
              gap={2}
              wrap="wrap"
              css={{
                maxHeight: '20px',
                overflow: 'hidden',
                width: `${
                  numberOfItemsDisplayed < selectedLabels.length
                    ? 'calc(100% - 40px)'
                    : 'calc(100% - 10px)'
                }`,
              }}
            >
              {selectedLabels?.map((item) => (
                <ComboboxSelectedItem
                  data-testid="combobox-selected-item"
                  key={item.value}
                  css={
                    item.color
                      ? {
                          color:
                            item.color === 'white' || item.color === '#fff'
                              ? '$blackA'
                              : '$hiContrast',
                          position: 'relative',
                          '&:before': {
                            content: '',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            backgroundColor: item.color,
                            opacity: 0.08,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            borderRadius: 3,
                          },
                        }
                      : {}
                  }
                >
                  {item.label}
                </ComboboxSelectedItem>
              ))}
            </Flex>
            {numberOfItemsDisplayed < selectedLabels.length && (
              <ComboboxSelectedItem
                data-testid="combobox-selected-items-count"
                css={{ overflowX: 'visible' }}
                style={{ marginLeft: '5px' }}
              >
                + {selectedLabels.length - numberOfItemsDisplayed}
              </ComboboxSelectedItem>
            )}
          </Flex>
        ) : (
          <Box css={{ fontSize: '14px', fontWeight: 400 }}>{placeholder}</Box>
        )}
      </div>
    </Flex>
  );
};
