import { ChannelTypes } from '../channels';
import { Contact, Signature } from '../index';

export type ConversationType = {
  /** The uuid of the conversation */
  id: string;
  /** conversation status - open, automated, closed, spam */
  status: ConversationStatusTypes;
  /** The id of the assigned user */
  assigned_user_id: number | null;
  /** The id of the assigned team */
  assigned_team_id: string | null;
  /** location used in conversation */
  location_id: string;
  /** A partial contact object */
  contact: ConversationContactType;
  /** A partial location object */
  location: LocationContactType;
  /** Messages and events in the conversation */
  conversationItemsPage: {
    conversationItems: ConversationItemType[];
  };
  initialConversationItems?: ConversationItemType[];
  /** Array of objects for users who have read the conversation */
  readByUsers: ConversationReadByUserType[];
  /** An array of scheduled messages */
  scheduled_messages: ConversationScheduledMessageType[] | null;
  /** The array of users typing in the conversation  */
  typing: string[];
  /** The number of unread messages */
  unread_count: number;
  /** the contacts language for this conversation */
  contact_language: string | null;
  /** user's language in the conversation, defaults to en */
  language: string | null;
  /** time the last message was sent or received at */
  last_message_timestamp: string | null;
  /** time the conversation was created at */
  created_at: string | null;
  /** time the conversation was updated at */
  updated_at: string | null;
  /** channel type e.g. a phone number, and email address */
  channel_type: ChannelTypes | null;
  /** time the conversation was inserted at */
  inserted_at?: string | null;
};

export type ConversationStateType = {
  topOffset?: number;
  bottomOffset?: number;
  searchMessageId?: string;
  /** The state of the conversation initialization.
   * Used to scroll to the end of the conversation when it is initialized.
   * When opening a conversation, the initialization state is taken from the
   * conversations list and then the first 10 messages are loaded */
  isInitialConversation?: boolean;
  /** The number of new messages. Used to display the scroll button to the end of the message list */
  newMessagesCount?: number;
};

export type ConversationWithStateType = ConversationType & ConversationStateType;

type ConversationSearchMessageType = {
  /** Array of attachments associated with the message */
  attachments: ConversationAttachmentType[] | null[];
  /** The body content of the message */
  body: string;
  /** The ID of the contact involved in the campaign */
  campaign_contact_id: string | null;
  /** The ID of the campaign associated with the message */
  campaign_id: string | null;
  /** The ID of the contact associated with the message */
  contact_id: string;
  /** The ID of the conversation this message belongs to */
  conversation_id: string;
  /** The delivery status of the message */
  delivery_status: string;
  /** Any error associated with the message */
  error: string | null;
  /** The unique identifier of the message */
  id: string;
  /** The import ID if the message was imported */
  import_id: string | null;
  /** The timestamp when the message was first inserted */
  inserted_at: string;
  /** The language of the translated message */
  language: string | null;
  /** The provider of the message service */
  provider: ConversationItemProviders;
  /** The provider's ID for the message */
  provider_id: string;
  /** The sequence ID if the message is part of a sequence */
  sequence_id: string | null;
  /** The source type of the message (e.g., inbound, outbound) */
  source_type: ConversationItemSourceTypes;
  /** The contact ID for the step in a sequence */
  step_contact_id: string | null;
  /** The step ID if the message is part of a sequence */
  step_id: string | null;
  /** The body of the message translated into another language */
  translated_body: string | null;
  /** The language into which the message has been translated */
  translation_language: string | null;
  /** The type of the message (e.g., automated, manual) */
  type: ConversationItemTypesType;
  /** The timestamp when the message was last updated */
  updated_at: string;
  /** The user ID of the user who sent or interacted with the message */
  user_id: string | number | null;
  /** The visibility status of the message (e.g., visible, hidden, removed) */
  visibility_status: ConversationItemVisibilityStatusTypes | null;
  /** The mode of the message (e.g., manual, automated, campaign, sequence, ai, imported) */
  mode: ConversationItemModeTypes | null;
  /** Call metadata */
  call_metadata: CallMetadataType | null;
};

export enum CallDeliveryStatus {
  CALL_INITIATED = 'call_initiated',
  CALL_RINGING = 'call_ringing',
  CALL_IN_PROGRESS = 'call_in_progress',
  CALL_COMPLETED = 'call_completed',
  CALL_MISSED = 'call_missed',
  CALL_BUSY = 'call_busy',
  CALL_NO_ANSWER = 'call_no_answer',
  CALL_FAILED = 'call_failed',
}

export enum MessageDeliveryStatus {
  QUEUED = 'queued',
  SENDING = 'sending',
  SENT = 'sent',
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SENDING_FAILED = 'sending_failed',
  DELIVERY_FAILED = 'delivery_failed',
  DELIVERY_UNCONFIRMED = 'delivery_unconfirmed',
  DLR_TIMEOUT = 'dlr_timeout',
  PENDING = 'pending',
  RETRYING = 'retrying',
}

export type ConversationContactType = {
  /** The uuid of the contact */
  id: string;
  /** The name of the contact */
  name: string | null;
  /** The email of the contact */
  email: string | null;
  /** The phone of the contact */
  phone: string | null;
  /** The language of the contact */
  language: string | null;
};

export type LocationContactType = {
  /** The uuid of the location */
  id: string;
  /** The name of the location */
  name: string | null;
  /** The language of the location */
  language: string | null;
};

export type ConversationItemType =
  | ConversationEventType
  | ConversationMessageType
  | ContactEvent;

export type ConversationEventType = {
  /** The uuid of the event */
  id: string;
  /** The uuid of the conversation */
  conversation_id: string;
  /** The time the event was inserted at */
  inserted_at: string;
  /** The time the event was updated at */
  updated_at: string;
  /** The event object */
  event: EventType;
};

export type ContactEvent = {
  /** The uuid of the event */
  id: string;
  /** The contact uuid for the event */
  contact_id: string;
  /** The time the event was inserted at */
  inserted_at: string;
  /** The time the event was updated at */
  updated_at: string;
  /** The event object */
  event: EventType;
};

export type EventType = {
  /** The uuid of the event */
  id: string | null;
  /** The type of event */
  type: EventTypes;
  /** The target of the event */
  target?: EventTargetTypes | null;
  /** The time the event was updated at */
  metadata: {
    [key: string]: any;
  };
};

export type ConversationMessageType = {
  /** The uuid of the message */
  id: string;
  /** The translated message body */
  translated_body: string | null;
  /** The message language */
  language: string | null;
  /** The translated message language */
  translation_language: string | null;
  /** The import uuid if the message was imported */
  import_id: string | null;
  /** @deprecated used to store attachments urls on the message */
  attachment_urls?: string[] | null;
  /** @deprecated type of attachment */
  attachment_content_type?: string | null;
  /** The body of the message */
  body: string | null;
  /** the unique id for the message from the third party message provider */
  provider_id: string | null;
  /** The visibility status of the message e.g. hidden, removed (deleted) */
  visibility_status: ConversationItemVisibilityStatusTypes | null;
  /** The sequence id */
  sequence_id: string | null;
  /** Th sequence step id */
  step_id: string | null;
  /** The step contact id for the contact in a sequence */
  step_contact_id: string | null;
  /** The user id of the user who sent the message */
  user_id: string | number | null;
  /** the conversation id the message is associated with */
  conversation_id: string | null;
  /** message error string - you can see the translation for these in the translation file */
  error: string | null;
  /** The uuid of a campaign */
  campaign_id: string | null;
  /** The uuid of a contact in a campaign */
  campaign_contact_id: string | null;
  /** And array of attachments for the message */
  attachments: ConversationAttachmentType[] | null[];
  /** The type of message e.g. was it automated or manually sent? */
  type: ConversationItemTypesType;
  /** The time the message was updated at */
  updated_at: string;
  /** The time the message was inserted at */
  inserted_at: string;
  /** The contact uuid for the message */
  contact_id?: string | null;
  /** The third party provider used to send the message */
  provider: ConversationItemProviders;
  /** The message delivery status - sent, sending, queued, delivered */
  delivery_status: string;
  /** Source Type - the direction of the message */
  source_type: ConversationItemSourceTypes;
  /** email meta data */
  email_metadata: ConversationEmailMetadataType;
  /** The mode of the message (e.g., manual, automated, campaign, sequence, ai, imported) */
  mode: ConversationItemModeTypes | null;
  /** Call metadata */
  call_metadata: CallMetadataType | null;
};

export type ConversationEmailMetadataType = {
  /** The ro address of the email */
  to: string[] | null[];
  /** The subject of the email */
  subject: string | null;
  /** The from address of the email */
  cc: string[] | null[];
  /** The to address of the email */
  bcc: string[] | null[];
  /** html_body */
  html_body: string | null;
  /** reply to */
  reply_to: string | null;
  /** in reply to */
  in_reply_to: string | null;
};

export type ConversationReadByUserType = {
  /** The most recent time the user read the message */
  most_recent_read: string;
  user: {
    /** The id of the user */
    id: string;
    /** The email of the user */
    email: string;
    /** The name of the user */
    name?: string | null;
    /** The user's profile picture */
    attachment?: ConversationAttachmentType | null;
    /** The user's default signature */
    default_signature_id?: string | null;
  };
};

export type ConversationScheduledMessageType = {
  /** The uuid of the scheduled message */
  id: string | null;
  /** The partial contact object */
  contact: ConversationContactType;
  /** The uuid of the conversation */
  conversation_id: string;
  /** The time the scheduled message was send */
  execution_time: string;
  /** The id of the job that will execute the scheduled message */
  job_id: number;
  /** The location uuid of the scheduled message */
  location: string;
  /** The message object */
  message: {
    /** The scheduled message body */
    body: string | null;
    /** The attachments urls for the scheduled message */
    attachment_urls: string[] | null;
  };
  /** The uuid of the organization the message is scheduled in */
  organization_id?: string | null;
  /** The state of the scheduled message */
  state: string | null;
  /** The timezone for the schedule */
  timezone: string;
  /** user who created the schedule message */
  user_id: number | null;
  /** When the scheduled message was updated */
  updated_at: string | null;
};

export type ConversationAttachmentType = {
  /** The type of the attachment - jpg, pdf etc */
  content_type?: string | null;
  /** The attachment url */
  url: string;
  /** The uuid of the attachment */
  id: string;
  /** When the attachment was inserted */
  inserted_at?: string | null;
  /** When the attachment was last updated */
  updated_at?: string | null;
};

export enum ConversationStatusTypes {
  OPEN = 'open',
  CLOSED = 'closed',
  AUTOMATED = 'automated',
  SPAM = 'spam',
  SEARCH_ALL = 'filtered:all',
  SEARCH_CONTACTS = 'filtered:contacts',
  SEARCH_MESSAGES = 'filtered:messages',
}

export enum ConversationItemSourceTypes {
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
  NOTE = 'NOTE',
}

export enum ConversationItemCallStatus {
  CALL_INITIATED = 'call_initiated',
  CALL_RINGING = 'call_ringing',
  CALL_IN_PROGRESS = 'call_in_progress',
  CALL_COMPLETED = 'call_completed',
  CALL_MISSED = 'call_missed',
  CALL_BUSY = 'call_busy',
  CALL_NO_ANSWER = 'call_no_answer',
  CALL_FAILED = 'call_failed',
  CALL_QUEUED = 'call_queued',
}

// The possible types a message can be
export enum ConversationItemTypesType {
  SMS = 'sms',
  MMS = 'mms',
  CALL = 'call',
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
  NOTE = 'note',
}

export enum ConversationItemModeTypes {
  MANUAL = 'manual',
  AUTOMATED = 'automated',
  CAMPAIGN = 'campaign',
  SEQUENCE = 'sequence',
  AI = 'ai',
  IMPORTED = 'imported',
}

// The possible providers a message can be from
export enum ConversationItemProviders {
  TWILIO = 'twilio',
  TELNYX = 'telnyx',
  VONAGE = 'vonage',
  BANDWIDTH = 'bandwidth',
  MAILGUN = 'mailgun',
  META = 'meta',
}

// The possible failed delivery sates a message can be in
export enum ConversationItemFailedStatusType {
  FAILED = 'failed',
  DELIVER_FAILED = 'deliver_failed',
  SENDING_FAILED = 'sending_failed',
}

// The possible visibility sates a message can be in
export enum ConversationItemVisibilityStatusTypes {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  REMOVED = 'removed',
}

// The objects events can be applied to
export enum EventTargetTypes {
  CONVERSATION = 'conversation',
  CONTACT = 'contact',
}

// Sort options for conversations
export enum ConversationSortTypes {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  UNREAD = 'unread',
  RELEVANCE = 'relevance',
}

// The possible event types
export enum EventTypes {
  WEBCHAT_LEAD = 'webchat_lead',
  WEBFLOW_LEAD = 'webflow_lead',
  ZAPIER_LEAD = 'zapier_lead',
  TRANSFER_LOCATION = 'transfer_location',
  CLOSED = 'closed',
  AUTO_CLOSED = 'auto_closed',
  OPEN = 'open',
  REVIEW_REQUEST = 'review_request',
  REVIEW_CLICK = 'review_click',
  LINK_CLICK = 'link_click',
  TAGGING = 'tagging',
  UNTAGGING = 'untagging',
  ASSIGN_USER = 'assign_user',
  REMOVE_FROM_SEQUENCE = 'remove_from_sequence',
  MOVE_TO_STEP = 'move_to_step',
  KEYWORD_TRIGGER = 'keyword_trigger',
  UPDATE_CONVERSATION_LANGUAGES = 'update_conversation_languages',
  CONVERSATION_OPEN = 'open',
  CONVERSATION_CLOSED = 'conversation_closed',
  CONVERSATION_AUTO_CLOSED = 'auto_closed',
  CONVERSATION_TRANSFER_LOCATION = 'transfer_location',
  LINK_CLICKED = 'link_clicked',
  CONVERSATION_TAGGING = 'tagging',
  CONVERSATION_UNTAGGING = 'untagging',
  UPDATE_CONTACT_LANGUAGE = 'update_contact_language',
}

export enum ConversationsActionTypes {
  SET_LOADING = 'SET_LOADING',
  SET_SEARCH_CONTACTS_LOADING = 'SET_SEARCH_CONTACTS_LOADING',
  SET_SEARCH_CONVERSATIONS_LOADING = 'SET_SEARCH_CONVERSATIONS_LOADING',
  SET_CONVERSATION = 'SET_CONVERSATION',
  CLEAR_CONVERSATION = 'CLEAR_CONVERSATION',
  APPEND_CONVERSATIONS = 'APPEND_CONVERSATIONS',
  UPDATE_CONVERSATION = 'UPDATE_CONVERSATION',
  REMOVE_CONVERSATION = 'REMOVE_CONVERSATION',
  UPDATE_CONTACT_LANGUAGE = 'UPDATE_CONTACT_LANGUAGE',
  // Filters
  CHANGE_TAB = 'CHANGE_TAB',
  SET_SORT = 'SET_SORT',
  NEW_CONVERSATION = 'NEW_CONVERSATION',
  ADD_MESSAGE = 'ADD_MESSAGE',
  UPDATE_CONVERSATION_CONTACT = 'UPDATE_CONVERSATION_CONTACT',
  UPSERT_MESSAGES = 'UPSERT_MESSAGES',
  UPSERT_MESSAGES_BEFORE = 'UPSERT_MESSAGES_BEFORE',
  SET_CONVERSATIONS = 'SET_CONVERSATIONS',
  SET_SEARCH_CONVERSATIONS = 'SET_SEARCH_CONVERSATIONS',
  SET_CONTACTS = 'SET_CONTACTS',
  SET_UNREAD_COUNTS = 'SET_UNREAD_COUNTS',
  // Scheduled Messages
  ADD_SCHEDULED_MESSAGE = 'ADD_SCHEDULED_MESSAGE',
  DELETE_SCHEDULED_MESSAGE = 'DELETE_SCHEDULED_MESSAGE',
  SEND_SCHEDULED_MESSAGE_NOW = 'SEND_SCHEDULED_MESSAGE_NOW',
  UPDATE_SCHEDULED_MESSAGE = 'UPDATE_SCHEDULED_MESSAGE',
  // Panel
  SET_PANEL_STATE = 'SET_PANEL_STATE',
  SET_CONVERSATION_PANEL = 'SET_CONVERSATION_PANEL',
  TOGGLE_FORWARD = 'TOGGLE_FORWARD',
  // Bulk Actions
  SELECT_CONVERSATION = 'SELECT_CONVERSATION',
  UNSELECT_CONVERSATION = 'UNSELECT_CONVERSATION',
  CLEAR_SELECTED_CONVERSATIONS = 'CLEAR_SELECTED_CONVERSATIONS',
  BULK_UPDATE_CONVERSATION_STATUS = 'BULK_UPDATE_CONVERSATION_STATUS',
  TOGGLE_EXPAND = 'TOGGLE_EXPAND',
}

export type PartialWithId<T> = { id: string } & Partial<Omit<T, 'id'>>;

export type ConversationActions =
  | {
      type: typeof ConversationsActionTypes.SET_LOADING;
      payload: boolean;
    }
  | {
      type: typeof ConversationsActionTypes.SET_CONVERSATION;
      payload: ConversationWithStateType | null;
    }
  | {
      type: typeof ConversationsActionTypes.CLEAR_CONVERSATION;
    }
  | {
      type: typeof ConversationsActionTypes.APPEND_CONVERSATIONS;
      payload: ConversationType[];
    }
  | {
      type: typeof ConversationsActionTypes.UPDATE_CONVERSATION;
      payload: ConversationType | PartialWithId<ConversationType>;
    }
  | {
      type: typeof ConversationsActionTypes.REMOVE_CONVERSATION;
      payload: {
        id: string;
      };
    }
  | {
      type: typeof ConversationsActionTypes.CHANGE_TAB;
      payload: string;
    }
  | {
      type: typeof ConversationsActionTypes.SET_SORT;
      payload: ConversationSortTypes;
    }
  | {
      type: typeof ConversationsActionTypes.NEW_CONVERSATION;
      payload: PartialWithId<ConversationType>;
    }
  | {
      type: typeof ConversationsActionTypes.ADD_MESSAGE;
      payload: ConversationItemType;
    }
  | {
      type: typeof ConversationsActionTypes.UPDATE_CONVERSATION_CONTACT;
      payload: Partial<Contact>;
    }
  | {
      type: typeof ConversationsActionTypes.UPSERT_MESSAGES;
      payload: ConversationItemType[];
    }
  | {
      type: typeof ConversationsActionTypes.UPSERT_MESSAGES_BEFORE;
      payload: ConversationItemType[];
    }
  | {
      type: typeof ConversationsActionTypes.ADD_SCHEDULED_MESSAGE;
      payload: { params: ConversationScheduledMessageType };
    }
  | {
      type: typeof ConversationsActionTypes.DELETE_SCHEDULED_MESSAGE;
      payload: {
        params: { job_id: number };
      };
    }
  | {
      type: typeof ConversationsActionTypes.SEND_SCHEDULED_MESSAGE_NOW;
      payload: { params: ConversationScheduledMessageType };
    }
  | {
      type: typeof ConversationsActionTypes.UPDATE_SCHEDULED_MESSAGE;
      payload: { params: ConversationScheduledMessageType };
    }
  | {
      type: typeof ConversationsActionTypes.SET_CONVERSATION_PANEL;
      payload: ConversationPanelTypes | null;
    }
  | {
      type: typeof ConversationsActionTypes.SET_CONVERSATIONS;
      payload: ConversationType[];
    }
  | {
      type: typeof ConversationsActionTypes.BULK_UPDATE_CONVERSATION_STATUS;
      payload: {
        conversation_ids: string[];
        status: ConversationStatusTypes;
      };
    }
  | {
      type: typeof ConversationsActionTypes.CLEAR_SELECTED_CONVERSATIONS;
      payload: null;
    }
  | {
      type: typeof ConversationsActionTypes.SELECT_CONVERSATION;
      payload: string;
    }
  | {
      type: typeof ConversationsActionTypes.UNSELECT_CONVERSATION;
      payload: string;
    }
  | {
      type: typeof ConversationsActionTypes.TOGGLE_FORWARD;
      payload: {
        isForwarded: boolean;
        forwardingParams: {
          message: string;
          attachment_urls: string[];
          signature?: Signature | null;
        };
      };
    }
  | {
      type: typeof ConversationsActionTypes.SET_PANEL_STATE;
      payload: boolean;
    }
  | {
      type: typeof ConversationsActionTypes.UPDATE_CONTACT_LANGUAGE;
      payload: string;
    }
  | {
      type: typeof ConversationsActionTypes.TOGGLE_EXPAND;
      payload: boolean;
    }
  | {
      type: typeof ConversationsActionTypes.SET_UNREAD_COUNTS;
      payload: UnreadConversationCountsType;
    };

export enum ConversationWebsocketEventTypes {
  ADD_MESSAGE = 'ADD_MESSAGE',
  ADD_SCHEDULED_MESSAGE = 'ADD_SCHEDULED_MESSAGE',
  UPDATE_SCHEDULED_MESSAGE = 'UPDATE_SCHEDULED_MESSAGE',
  DELETE_SCHEDULED_MESSAGE = 'DELETE_SCHEDULED_MESSAGE',
  SEND_SCHEDULED_MESSAGE_NOW = 'SEND_SCHEDULED_MESSAGE_NOW',
  UPDATE_CONVERSATION = 'UPDATE_CONVERSATION',
  ASSIGN_USER = 'ASSIGN_USER',
  ADD_TYPING_USER = 'ADD_TYPING_USER',
  REMOVE_TYPING_USER = 'REMOVE_TYPING_USER',
  MARK_UNREAD = 'MARK_UNREAD',
  TRANSFER_LOCATION = 'TRANSFER_LOCATION',
  UPDATE_CONVERSATION_LANGUAGES = 'UPDATE_CONVERSATION_LANGUAGES',
  TRANSLATE_MESSAGE = 'TRANSLATE_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  ADD_DRAFT = 'ADD_DRAFT',
  DELETE_DRAFT = 'DELETE_DRAFT',
  ADD_NOTE = 'ADD_NOTE',
  ASSIGN_TEAM = 'ASSIGN_TEAM',
  BULK_UPDATE_CONVERSATION = 'BULK_UPDATE_CONVERSATION',
  NEW_CONVERSATION = 'NEW_CONVERSATION',
  REMOVE_CONVERSATION = 'REMOVE_CONVERSATION',
  UPSERT_MESSAGES = 'UPSERT_MESSAGES',
  NEW_EVENT = 'NEW_EVENT',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export enum ConversationFilterTypes {
  ALL = 'all',
  UNASSIGNED = 'unassigned',
  ME = 'me',
  GHOSTED = 'ghosted',
  UNRESPONSIVE = 'unresponsive',
}

export enum ConversationPanelTypes {
  CONTACT = 'contact',
  SEARCH = 'search',
  ATTACHMENTS = 'attachments',
  CAMPAIGNS = 'campaigns',
  HELP = 'help',
  LANGUAGE = 'language',
  CONVERSATION = 'conversation',
  SCHEDULED = 'scheduled',
}

export type NewConversationContactType = {
  /** The name of the contact */
  name: string;
  /** The phone number of the contact (optional unless email is not provided) */
  phone?: string;
  /** The email address of the contact (optional unless phone is not provided) */
  email?: string;
  /** The optional language of the contact */
  language?: string;
} & ({ phone: string } | { email: string }); // Ensures that either phone or email must be provided

export type NewMessageParams = {
  /** The body of the scheduled message */
  body: string;
  /** URLs of attachments included with the scheduled message */
  attachment_urls: Array<string>;
  /** Optional email metadata if the scheduled message is an email */
  email_metadata?: {
    /** The subject line for the email message */
    subject: string;
  };
};

export type NewConversationParamsType = {
  /** The location ID where the conversation is initiated */
  location_id: string;
  /** Optional message details if the conversation starts with a message */
  message?: NewMessageParams;
  /** Optional note details if the conversation starts with a note */
  note?: {
    /** URLs of attachments included with the note */
    attachment_urls: Array<string>;
    /** The body of the note */
    body: string;
  };
  /** Contact details for the conversation */
  contact: NewConversationContactType;
};

export type NewScheduledConversationParamsType = {
  /** Contact details for the scheduled conversation */
  contact: NewConversationContactType;
  /** The location ID where the scheduled conversation is initiated */
  location_id: string;
  /** Details about the scheduled message */
  scheduled_message: {
    params: {
      /** Message details including body and attachments */
      message: NewMessageParams;
      /** The ID of the organization where the message is scheduled */
      organization_id: string;
      /** The ID of the user who schedules the message */
      user_id: number;
    };
    /** Scheduling options for when the message should be sent */
    schedule_options: ScheduleConversationItemParamsType;
    /** The target type of the scheduled item, e.g., 'message' */
    target: 'message';
  };
};

export type ScheduleConversationItemParamsType = {
  day: string;
  hour: string;
  minute: string;
  month: string;
  year: string;
  timezone: string;
};

type WhippyQueryComparisons = '==' | 'in' | 'ilike';

export type WhippyQueryLanguageBase = {
  resource: string;
  column: string;
  comparison: WhippyQueryComparisons;
  value: string | number;
  or?: WhippyQueryLanguageBase[];
  and?: WhippyQueryLanguageBase[];
};

export type WhippyQueryLanguageFilterSchema = WhippyQueryLanguageBase & {
  and?: WhippyQueryLanguageBase[];
  or?: WhippyQueryLanguageBase[];
};

export type WhippyQueryLanguageSortSchema = {
  column: string;
  order: 'asc' | 'desc';
  resource: string;
};

export type WhippyQueryLanguage = {
  filter: WhippyQueryLanguageFilterSchema[];
  limit: number;
  offset: number;
  sort?: WhippyQueryLanguageSortSchema[];
};

export type SearchV2Type = {
  /* Fuzzy search on user input value */
  inputValue: string;
  filter?: WhippyQueryLanguageFilterSchema;
  sort?: WhippyQueryLanguageSortSchema[];
  offset?: number;
  shouldAppendResults?: boolean;
};

export type ConversationsSearchV2ApiResponse = {
  data: ConversationSearchTypeV2[];
  total: number;
};

export type MessageSearchV2ApiResponse = {
  data: Array<ConversationItemType>;
  offset: number;
  total: number;
};

// Conversation Type used in the new conversation search V2 (OpenSearch)
export type ConversationSearchTypeV2 = ConversationType & {
  /** The contacts returned from the search V2 */
  contact: ConversationSearchV2ContactsType;
  /** Optional score for search conversations */
  score?: number;
  /** Messages and events in the conversation */
  conversationItemsPage: {
    conversationItems: ConversationItemTypeV2[];
  };
};

export type ConversationItemTypeV2 = ConversationItemType & {
  /** The offset is the message's depth in the nested structure */
  offset: number;
  body: string;
};

type ConversationSearchV2ContactsType = {
  blocked: boolean;
  communication_preferences: {
    id: string;
    inserted_at: string;
    last_campaign_date: string | null;
    location_id: string;
    opt_in: boolean;
    opt_in_date: string | null;
    opt_out_date: string | null;
    paused_until: string | null;
    updated_at: string;
  }[];
  email: string | null;
  id: string;
  inserted_at: string;
  name: string;
  opt_in_email: boolean;
  opt_in_sms: boolean;
  opt_out_of_all: boolean;
  organization_id: string;
  phone: string;
  source: string | null;
  state: string;
  tags: string[];
  updated_at: string;
  conversation_id: string;
};

export type ConversationSearchV2ParamsType = {
  organization_id: string;
  conversation_id: string;
  score: number;
  message: ConversationSearchMessageType;
  contacts: ConversationSearchV2ContactsType[];
};

export type ConversationSearchV2Response = {
  data: ConversationSearchV2ParamsType[];
  total: number;
};

export type SearchType = 'messages' | 'contacts' | 'none';

export enum SearchTypes {
  messages = 'messages',
  contacts = 'contacts',
  none = 'none',
}

export type CallMetadataType = {
  type: 'phone' | 'web';
  duration: number;
  placeholders_data: Record<string, string>;
  analysis: {
    agent_task_completion_rating: string;
    call_completion_rating: string;
    call_successful: boolean;
    call_summary: string;
    custom_analysis_data: Record<string, string>;
    in_voicemail: boolean;
    user_sentiment: string;
  };
  external_resource_id: string;
  agent_id: string;
  agent_version_id: string;
  llm_id: string;
  llm_version_id: string;
};

export type LinkClickEventType = {
  id: string;
  type: 'link_click';
  metadata: {
    campaign_id: string | null;
    contact_id: string;
    contact_name: string;
    conversation_id: string;
    link_id: string;
    link_tracking: {
      domain: string;
      geolocation: {
        as: string;
        asname: string;
        city: string;
        continent: string;
        continent_code: string;
        country: string;
        country_code: string;
        currency: string;
        district: string;
        hosting: boolean;
        isp: string;
        lat: number;
        lon: number;
        message: string | null;
        mobile: boolean;
        offset: number;
        org: string;
        proxy: boolean;
        query: string;
        region: string;
        region_name: string;
        reverse: string;
        status: string;
        timezone: string;
        zip: string;
      };
      referrer: string | null;
      referrer_url: string | null;
      user_agent: {
        browser_family: string;
        client: {
          engine: string;
          engine_version: string;
          name: string;
          type: string;
          version: string;
        };
        device: {
          brand: string;
          model: string;
          type: string;
        };
        os: {
          name: string;
          platform: string;
          version: string;
        };
        os_family: string;
        user_agent: string;
      };
    };
    message_id: string;
    organization_id: string;
    sequence_id: string | null;
    step_id: string | null;
  };
  target: 'conversation';
};

export type UnreadConversationCountType = {
  id: string;
  total: number;
};

export type UnreadConversationCountsType = {
  all: number;
  assigned_users: UnreadConversationCountType[];
  channels: UnreadConversationCountType[];
};
