import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  SequenceSubNavigationItem,
  TabsContainer,
} from '@/pages/sequences/sequence/SequenceContainer';
import { usePageView } from '@/shared/hooks';
import { useUserRoles } from '@/shared/hooks/useUserRoles';
import { SettingsLayout } from '@/shared/layouts';
import { UserRoles } from '@/shared/types/users';
import { Box, Flex, HStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { AddUser } from './AddUser';
import { InvitesView } from './InvitesView';
import { UsersView } from './UsersView';

export function Users(): JSX.Element {
  usePageView();
  const isAdmin = useUserRoles(UserRoles.ADMIN);
  const [isUsersTabActive, setIsUsersTabActive] = useState(true);

  return (
    <>
      <Helmet>
        <title>Whippy | Users</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        padding="0"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/users' },
          { title: 'Users', path: `/settings/users` },
        ]}
        actions={isAdmin && <AddUser />}
        width="100%"
      >
        <Box>
          <TabsContainer align="center">
            <HStack>
              <SequenceSubNavigationItem
                selected={isUsersTabActive}
                onClick={() => setIsUsersTabActive(true)}
              >
                Users
              </SequenceSubNavigationItem>
              <SequenceSubNavigationItem
                selected={!isUsersTabActive}
                onClick={() => setIsUsersTabActive(false)}
              >
                Invites
              </SequenceSubNavigationItem>
            </HStack>
          </TabsContainer>
          <StepsContainer
            direction="column"
            css={{
              padding: 24,
              height: 'calc(100vh - 205px)',
            }}
          >
            {isUsersTabActive ? <UsersView /> : <InvitesView />}
          </StepsContainer>
        </Box>
      </SettingsLayout>
    </>
  );
}

const StepsContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});
