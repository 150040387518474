import { isEmpty } from 'lodash';

import { ConversationItemType, ConversationType } from '@/shared/types/conversations';
import { mapOrder, SortableItemType } from '@/shared/utils/mapOrder';

import { PartialWithId } from './ConversationContext';

/*
    Helper functions to format conversation state. 
*/

export const sortConversationItems = (messages: Array<ConversationItemType>) => {
  return messages.sort((a: ConversationItemType, b: ConversationItemType) => {
    return new Date(a.inserted_at).getTime() - new Date(b.inserted_at).getTime();
  });
};

export const formatConversationState = (
  conversations: Array<ConversationType | PartialWithId<ConversationType>>
) => {
  // reduce conversations by their unique id
  const conversationsById = (conversations || [])?.reduce(
    (
      acc: Record<string, ConversationType | PartialWithId<ConversationType>>,
      conv: ConversationType | PartialWithId<ConversationType>
    ) => {
      if (conv && conv.id) {
        return { ...acc, [conv.id]: conv };
      } else {
        return acc;
      }
    },
    {}
  );

  const messagesByConversation = (conversations || [])?.reduce<
    Record<string, Array<ConversationItemType>>
  >(
    (
      acc: Record<string, Array<ConversationItemType>>,
      conv: ConversationType | PartialWithId<ConversationType>
    ) => {
      const { conversationItemsPage } = conv || {};

      if (typeof conversationItemsPage === 'object' && conversationItemsPage !== null) {
        return {
          ...acc,
          [conv?.id]: sortConversationItems(
            conversationItemsPage.conversationItems || []
          ),
        };
      } else {
        return acc;
      }
    },
    {}
  );

  // Sort conversations by last message
  const sortedConversationIds = !isEmpty(conversationsById)
    ? Object.keys(conversationsById)?.sort((a, b) => {
        const messagesA = messagesByConversation[a] || [];
        const messagesB = messagesByConversation[b] || [];
        const x = messagesA[messagesA.length - 1] || {};
        const y = messagesB[messagesB.length - 1] || {};

        return +new Date(y?.inserted_at) - +new Date(x?.inserted_at);
      })
    : [];

  return {
    conversationsById,
    messagesByConversation,
    conversationIds: sortedConversationIds,
  };
};

export const formatState = (
  data: Array<ConversationType | PartialWithId<ConversationType>>
) => {
  const sort_conversations = formatConversationState(data);
  const conversation_ids_in_order = sort_conversations.conversationIds;
  const conversationState = mapOrder(
    data as Array<SortableItemType>,
    conversation_ids_in_order,
    'id'
  );

  return conversationState as Array<ConversationType>;
};
