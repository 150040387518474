/* eslint-disable react-hooks/exhaustive-deps */

import { VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { MessagingOverview } from './MessagingOverview';
import { Overview } from './Overview';
import { UserMessaging } from './UserMessaging';

export const UsageReport = () => {
  return (
    <ReportsContentContainer title="Usage">
      <VStack gap={5}>
        <Overview />
        <MessagingOverview />
        <UserMessaging />
      </VStack>
    </ReportsContentContainer>
  );
};
