import { SearchFilters } from '../contacts';
import { User } from '../users';

export type Channel<P extends ProviderTypes = ProviderTypes> = {
  /* channel uuid */
  id: string;
  /* channel name */
  name?: string;
  /* channel address */
  address?: string;
  /* webchat uuid */
  widget_setting_id?: string | null;
  /* channel phone number */
  phone?: string;
  /* channel state e.g. enabled, disabled */
  state?: ChannelsStates;
  /* google review link */
  review_link?: string;
  /* channel timezone */
  timezone?: string | null;
  /* channel opening hours object */
  opening_hours?: Array<OpeningHour> | null;
  /* channel google place id */
  google_place_id?: string | null;
  /* channel google channel id */
  google_location_id?: string | null;
  /* channel google account id */
  google_account_id?: string | null;
  /* custom open automated response */
  automatic_response_open?: string | null;
  /* custom closed automated response */
  automatic_response_closed?: string | null;
  /* send automated response when? */
  send_automatic_response_when?: SendWhenTypes;
  /* api provider for channel sending */
  provider?: P | null;
  /* agent id */
  agent_id?: string | null;
  /* api provider custom auth params */
  provider_auth?:
    | TelnyxAuthParams
    | TwilioAuthParams
    | VonageAuthParams
    | BandwidthAuthParams
    | MailgunAuthParams
    | SinchAuthParams
    | MetaAuthParams
    | null;
  /* channel types */
  type?: ChannelTypes;
  /* channel settings */
  settings?: ChannelSettings;
  /* channel delivery options for a campaign */
  delivery_options?: DeliveryOptions | null;
  /* user who created the channel */
  created_by?: User;
  /* user who updated the channel */
  updated_by?: User;
  /* channel creation date */
  created_at?: string;
  /* channel update date */
  updated_at?: string;
  /* channel description */
  description?: string | null;
  /* channel color */
  color?: string | null;
  /* channel emoji */
  emoji?: string | null;
  /* channel ten dlc campaign id */
  ten_dlc_campaign_id?: string | null;
  /* provider account id */
  provider_account_id?: string | null;
  /* support AI agent */
  support_ai_agent?: boolean;
  /* is hosted SMS */
  is_hosted_sms?: boolean;
  /* sending email address */
  sending_email_address?: string | null;
  /* meta onboarding params */
  meta_onboarding?: MetaOnboardingParams | null;
};

export type MetaOnboardingParams = {
  code: string;
  phone_pin: string;
  phone_number_id: string;
  waba_id: string;
};

export type MetaOnboardingResponse = {
  phone_number: string;
  provider_auth: MetaAuthParams;
};

export type DeliveryOptions = {
  /* max messages per period */
  messages_per_period: number;
  /* period in seconds */
  period: number;
};

export type OpeningHour = {
  /* opening hour uuid */
  id?: string;
  /* opening time */
  opens_at: string;
  /* closing time */
  closes_at: string;
  /* day of week */
  weekday: string;
  /* opening hour state */
  state: string;
};

export enum ProviderTypes {
  TELNYX = 'telnyx',
  TWILIO = 'twilio',
  VONAGE = 'vonage',
  BANDWIDTH = 'bandwidth',
  MAILGUN = 'mailgun',
  SINCH = 'sinch',
  META = 'meta',
}

export enum ChannelsStates {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  ARCHIVED = 'archived',
}

export enum SendWhenTypes {
  ALWAYS = 'always',
  OPEN = 'open',
  CLOSED = 'closed',
  NEVER = 'never',
}

export enum WeekdayTypes {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export enum ChannelTypes {
  PHONE = 'phone',
  EMAIL = 'email',
  WHATSAPP = 'whatsapp',
}

export type ProviderAuthParams =
  | TelnyxAuthParams
  | TwilioAuthParams
  | VonageAuthParams
  | BandwidthAuthParams
  | MailgunAuthParams
  | SinchAuthParams;

export type SinchAuthParams = {
  /* sinch app id */
  app_id: string;
  /* sinch project id */
  project_id: string;
  /* sinch region */
  region: string;
  /* sinch username */
  username: string;
  /* sinch password */
  password: string;
  /* sinch webhook secret */
  webhook_secret: string;
};

export type TelnyxAuthParams = {
  /* telnyx api key */
  api_key: string;
  /* telnyx api secret */
  public_key: string;
};

export type TwilioAuthParams = {
  /* twilio account sid */
  account_sid: string;
  /* twilio auth token */
  auth_token: string;
};

export type VonageAuthParams = {
  /* vonage api key */
  application_id: string;
  /* vonage api secret */
  private_key: string;
};

export type BandwidthAuthParams = {
  /* bandwidth api key */
  username: string;
  /* bandwidth api secret */
  user_password: string;
  /* bandwidth account id */
  account_id: string;
  /* bandwidth application id */
  application_id: string;
  /* bandwidth sub account number */
  sub_account_number: string;
  /* bandwidth location number */
  location_number: string;
  /* bandwidth callback id */
  callback_id: string;
  /* bandwidth callback password */
  callback_password: string;
};

export type MailgunAuthParams = {
  /* mailgun base domain */
  base_domain: string;
  /* mailgun api key */
  api_key: string;
  /* mailgun webhook signing key */
  webhook_signing_key: string;
};

export type MetaAuthParams = {
  /* phone number id */
  phone_number_id: string;
  /* phone pin */
  phone_pin: string;
  /* waba id */
  waba_id: string;
  /* code */
  code: string;
};

export type ChannelSettings = {
  /* after how long to close a conversation */
  auto_close_conversation_after?: string | null;
  /* how to configure link tracking for messages from this channel */
  link_tracking?: LocationLinkTrackingSettings;
  /* how to configure call settings from this channel */
  call?: CallSettings;
};

export type LocationLinkTrackingSettings = {
  /* whether to enable link tracking */
  disabled: boolean;
  /* what domain to send from */
  default_domain_id: string | null;
};

export type CallSettings = {
  conferences: boolean;
  recordings: boolean;
  transcriptions: boolean;
};

export enum ChannelActionTypes {
  GET_CHANNELS = 'GET_CHANNELS',
  ADD_CHANNEL = 'ADD_CHANNEL',
  ARCHIVE_CHANNEL = 'ARCHIVE_CHANNEL',
  SET_CURRENT = 'SET_CURRENT',
  CLEAR_CURRENT = 'CLEAR_CURRENT',
  UPDATE_CHANNEL = 'UPDATE_CHANNEL',
  CLEAR_CHANNELS = 'CLEAR_CHANNELS',
  GET_ALL_CHANNELS = 'GET_ALL_CHANNELS',
  DISABLE_CHANNEL = 'DISABLE_CHANNEL',
  ENABLE_CHANNEL = 'ENABLE_CHANNEL',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
  SEARCH_CHANNELS = 'SEARCH_CHANNELS',
  META_ONBOARDING = 'META_ONBOARDING',
}

export type ChannelActions =
  | { type: ChannelActionTypes.GET_CHANNELS; payload: Array<Channel> }
  | { type: ChannelActionTypes.ADD_CHANNEL; payload: Partial<Channel> }
  | { type: ChannelActionTypes.ARCHIVE_CHANNEL; payload: Channel }
  | { type: ChannelActionTypes.SET_CURRENT; payload: Channel | null }
  | { type: ChannelActionTypes.CLEAR_CURRENT }
  | { type: ChannelActionTypes.UPDATE_CHANNEL; payload: Channel }
  | { type: ChannelActionTypes.CLEAR_CHANNELS }
  | { type: ChannelActionTypes.GET_ALL_CHANNELS; payload: Array<Channel> }
  | { type: ChannelActionTypes.DISABLE_CHANNEL; payload: Channel }
  | { type: ChannelActionTypes.ENABLE_CHANNEL; payload: Channel }
  | { type: ChannelActionTypes.UPDATE_FILTER_PARAMS; payload: SearchFilters }
  | {
      type: ChannelActionTypes.SEARCH_CHANNELS;
      payload: { data: Array<Channel>; total: number };
    }
  | { type: ChannelActionTypes.META_ONBOARDING; payload: MetaOnboardingParams };
