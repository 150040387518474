export const GET_CONTACTS_IN_GROUP = 'GET_CONTACTS_IN_GROUP';
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const FILTER_CONTACTS = 'FILTER_CONTACTS';
export const FILTER_PART_OF_CONTACTS = 'FILTER_PART_OF_CONTACTS';
export const CLEAR_SEARCHED_CONTACTS = 'CLEAR_SEARCHED_CONTACTS';
export const GET_PORTION_OF_CONTACTS = 'GET_PORTION_OF_CONTACTS';
export const GET_PART_OF_CONTACTS_IN_GROUP = 'GET_PART_OF_CONTACTS_IN_GROUP';
export const SET_LOADING_TAGS = 'SET_LOADING_TAGS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const ARCHIVE_CONTACT = 'ARCHIVE_CONTACT';
export const ARCHIVE_MULTIPLE_CONTACTS = 'ARCHIVE_MULTIPLE_CONTACTS';
export const SET_CURRENT = 'SET_CURRENT';
export const SET_IS_SEARCHED = 'SET_IS_SEARCHED';
export const SET_CONTACTS_CHECKED = 'SET_CONTACTS_CHECKED';
export const SET_SEARCH_RESULT_CHECKED = 'SET_SEARCH_RESULT_CHECKED';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const SET_VIEW = 'SET_VIEW';
export const ADD_NOTE = 'ADD_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const UPDATE_CONTACTS_NOTES = 'UPDATE_CONTACTS_NOTES';
export const CREATE_CONTACT_TAG = 'CREATE_CONTACT_TAG';
export const DELETE_CONTACT_TAG = 'DELETE_CONTACT_TAG';
export const UPDATE_CONTACTS_TAGS = 'UPDATE_CONTACTS_TAGS';
export const UPDATE_CONTACT_LANGUAGE = 'UPDATE_CONTACT_LANGUAGE';
export const NEW_EVENT = 'NEW_EVENT';
export const BLOCK_CONTACT = 'BLOCK_CONTACT';
export const SET_LAST_ADDED_CONTACT = 'SET_LAST_ADDED_CONTACT';
export const SET_LOADING_CONTACT = 'SET_LOADING_CONTACT';
export const GET_CONTACT_CAMPAIGNS = 'GET_CONTACT_CAMPAIGNS';
export const SET_LOADING_CONTACT_CAMPAIGNS = 'SET_LOADING_CONTACT_CAMPAIGNS';
export const SEARCH_CONTACT_CAMPAIGNS = 'SEARCH_CONTACT_CAMPAIGNS';
export const GET_CONTACT_SEQUENCES = 'GET_CONTACT_SEQUENCES';
export const SET_LOADING_CONTACT_SEQUENCES = 'SET_LOADING_CONTACT_SEQUENCES';
export const GET_CONTACT_CONVERSATIONS = 'GET_CONTACT_CONVERSATIONS';
export const SEARCH_CONTACT_CONVERSATIONS = 'SEARCH_CONTACT_CONVERSATIONS';
export const SET_LOADING_CONTACT_CONVERSATIONS = 'SET_LOADING_CONTACT_CONVERSATIONS';
