import React from 'react';

import { VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { ContactsOverview } from './ContactsOverview';

export const ContactsReport = () => {
  return (
    <ReportsContentContainer title="Contacts">
      <VStack gap="2">
        <ContactsOverview />
      </VStack>
    </ReportsContentContainer>
  );
};
