import {
  CallStatusTypes,
  CallType,
  VoIPActions,
  VoIPActionTypes,
  VoIPStateType,
} from '@/shared/types/voip';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

const VoIPReducer = (state: VoIPStateType, action: VoIPActions): VoIPStateType => {
  switch (action.type) {
    case VoIPActionTypes.ADD_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case VoIPActionTypes.DESTROY_DEVICE:
      return {
        ...state,
        device: null,
      };
    case VoIPActionTypes.ADD_INCOMING_CALL:
      return {
        ...state,
        incomingCalls: removeDuplicates(
          [...state.incomingCalls, action.payload],
          'callSid'
        ),
        callStatus: CallStatusTypes.INCOMING,
      };
    case VoIPActionTypes.ACCEPT_INCOMING_CALL:
      return {
        ...state,
        incomingCalls: state.incomingCalls.filter(
          (call: CallType) => call?.callSid !== action.payload?.callSid
        ),
        calls: removeDuplicates([...state.calls, action.payload], 'callSid'),
        current: action.payload,
      };
    case VoIPActionTypes.DESTROY_INCOMING_CALL:
      return {
        ...state,
        incomingCalls: state.incomingCalls.filter(
          (call: CallType) => call?.callSid !== action.payload
        ),
        calls: state.calls.filter((call: CallType) => call?.callSid !== action.payload),
        callStatus: null,
      };
    case VoIPActionTypes.ADD_OUTGOING_CALL:
      return {
        ...state,
        calls: removeDuplicates([...state.calls, action.payload], 'callSid'),
        current: action.payload,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.ACCEPT_OUTGOING_CALL:
      return {
        ...state,
        incomingCalls: state.incomingCalls.filter(
          (call: CallType) => call?.callSid !== action.payload?.callSid
        ),
        calls: [
          ...state.calls.filter(
            (call: CallType) =>
              call?.call?.parameters?.CallSid !== action.payload?.callSid
          ),
          action.payload,
        ],
        current: action.payload,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.DESTROY_OUTGOING_CALL:
      return {
        ...state,
        calls: state.calls.filter(
          (call: CallType) => call?.call?.parameters?.CallSid !== action.payload
        ),
        current: state.current?.callSid === action.payload ? null : state.current,
      };
    case VoIPActionTypes.ADD_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload,
      };
    case VoIPActionTypes.START_INCOMING_CALL_RECORDING:
      return {
        ...state,
        calls: [
          ...state.calls.map((call: CallType) =>
            call?.call?.parameters?.CallSid === action.payload?.callSid
              ? {
                  ...call,
                  recording: true,
                  ...action.payload,
                }
              : call
          ),
        ],
      };
    case VoIPActionTypes.START_OUTGOING_CALL_RECORDING:
      return {
        ...state,
        calls: [
          ...state.calls.map((call: CallType) =>
            call?.call?.parameters?.CallSid === action.payload?.callSid
              ? {
                  ...call,
                  recording: true,
                  ...action.payload,
                }
              : call
          ),
        ],
      };
    case VoIPActionTypes.STOP_INCOMING_CALL_RECORDING:
      return {
        ...state,
        calls: [
          ...state.calls.map((call: CallType) =>
            call?.callSid === action.payload
              ? {
                  ...call,
                  recording: false,
                  recording_id: null,
                }
              : call
          ),
        ],
      };
    case VoIPActionTypes.STOP_OUTGOING_CALL_RECORDING:
      return {
        ...state,
        calls: [
          ...state.calls.map((call: CallType) =>
            call?.callSid === action.payload
              ? {
                  ...call,
                  recording: false,
                  recording_id: null,
                }
              : call
          ),
        ],
      };
    default:
      return { ...state };
  }
};

export default VoIPReducer;
