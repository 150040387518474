import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';

import { AgentsAnalytics } from '@/pages/agents/analytics/AgentsAnalytics';
import {
  AnalyticFilterItem,
  AnalyticFilters,
} from '@/pages/agents/analytics/AnalyticFilters';
import { reportAnalytics, ReportData } from '@/shared/api/reports';
import { DatepickerDates } from '@/shared/components/datepicker/Datepicker';
import { Box, Flex, VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

export const AgentsReport = () => {
  const [data, setData] = useState<Array<ReportData>>([]);
  const [reportBar, setReportBar] = useState<any>([]);
  const [stackedReportBar, setStackedReportBar] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<AnalyticFilterItem>>([]);
  const [dates, setDates] = useState<DatepickerDates>(initialDates);
  const [agents, setAgents] = useState<Array<string>>([]);

  useEffect(() => {
    fetchReports(filters, dates, agents);
  }, [filters, dates, agents]);

  const fetchReports = async (
    filters: Array<AnalyticFilterItem>,
    dates: DatepickerDates,
    agents: Array<string>
  ) => {
    try {
      setIsLoading(true);
      const agentFilters = [...filters];
      if (agents.length) {
        agentFilters.push({
          column: 'agent_id',
          comparison: 'in',
          value: agents,
        });
      }
      const data = await reportAnalytics(agentFilters, dates, 'agents');
      setData(data);
      setReportBar(
        data
          .find((d) => d.chart === 'bar' && d.label === 'Calls by Day')
          ?.values.map((d) => {
            return { key: d.y, count: d.x };
          })
      );

      const positive = data
        .find((d) => d.chart === 'bar' && d.label === 'Stacked Bar Chart Positive')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      const negative = data
        .find((d) => d.chart === 'bar' && d.label === 'Stacked Bar Chart Negative')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      const neutral = data
        .find((d) => d.chart === 'bar' && d.label === 'Stacked Bar Chart Neutral')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      setStackedReportBar(
        positive?.map((d, index) => {
          return {
            key: d.key,
            positive: d.count,
            negative: negative?.[index].count,
            neutral: neutral?.[index].count,
          };
        })
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ReportsContentContainer title="Agents" hideDatepicker>
      <Flex direction="column">
        <VStack gap={5}>
          <Box>
            <AnalyticFilters
              agents={agents}
              setAgents={setAgents}
              filters={filters}
              setFilters={setFilters}
              dates={dates}
              setDates={setDates}
            />
          </Box>
          <AgentsAnalytics
            data={data}
            stackedReportBar={stackedReportBar}
            reportBar={reportBar}
            isLoading={isLoading}
          />
        </VStack>
      </Flex>
    </ReportsContentContainer>
  );
};
