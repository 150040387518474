import { Campaign } from '@/shared/types/campaigns';
import { FilterItem } from '@/shared/types/filter';

import { QuickFilterAlias } from '../create/CampaignAudience';

export function appendFilterItemsToExcludedSystemDefault(
  filterItems: FilterItem[],
  channelId: string
) {
  return [
    // remove blocked contacts
    {
      column: 'blocked',
      resource: 'contact',
      comparison: '==',
      value: true,
      or: [
        // # remove archived contacts
        {
          column: 'state',
          resource: 'contact',
          comparison: 'in',
          value: ['archived', 'blocked'],
          or: [
            {
              column: 'opt_in_sms',
              resource: 'contact',
              comparison: '==',
              value: false,
              or: [
                {
                  column: 'opt_out_of_all',
                  resource: 'contact',
                  comparison: '==',
                  value: 'true',
                  or: [
                    {
                      resource: 'communication_preference',
                      column: 'opt_in',
                      comparison: '==',
                      value: 'false',
                      or: structuredClone(filterItems),
                    },
                    {
                      resource: 'communication_preference',
                      column: 'location_id',
                      comparison: '==',
                      value: channelId,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}

/**
 * This function will return a new copy of the list with the newItem
 * appending to a new or field in the original filterItems.
 */
export function appendOrCondition(list: FilterItem[], newItem: FilterItem) {
  const copyList = structuredClone(list);
  if (list.length == 0) return [newItem];

  function appendCondition(currentItem: FilterItem) {
    if (!currentItem.or) {
      currentItem.or = [
        {
          resource: newItem.resource,
          column: newItem.column,
          comparison: newItem.comparison,
          value: newItem.value,
        },
      ];
      return;
    } else {
      appendCondition(currentItem.or[0]);
      return;
    }
  }
  appendCondition(copyList[0]);
  return copyList;
}

/**
 * This will append a new filter item as an or field to the first item in the list and return a new list.
 * NOTE: This function varies from `appendOrCondition` in that it
 * will use the whole `newItem` including the or and and field, wheras `appendOrCondition` will only
 * append the filteritem resouce, column, comparison, and value.
 */
export function appendFilterItemAsOrField(list: FilterItem[], newItem: FilterItem) {
  const copyList = structuredClone(list);
  if (list.length == 0) return [newItem];

  function appendCondition(currentItem: FilterItem) {
    if (!currentItem.or) {
      currentItem.or = [newItem];
      return;
    } else {
      appendCondition(currentItem.or[0]);
      return;
    }
  }
  appendCondition(copyList[0]);
  return copyList;
}

type FilterItemPredicate = (item: FilterItem) => boolean;
/**
 * Potentially removes a filter item by traversing through the or nodes. This will perform
 * a deep copy of the list that we pass in.
 *
 * NOTE: In the case the item we're looking for is not in an 'or' field, then we will replace
 * that current node with the next filter item in the current level, or else we will
 * try to replace the item with a node from the 'and' field if it exists.
 *
 * @param list - An array of FilterItems
 * @param predicate - A function that takes a FilterItem and returns true or false.
 * @returns A copy of the FilterItem list that was passed in, with a single condition removed.
 */
export function removeOrCondition(list: FilterItem[], predicate: FilterItemPredicate) {
  const copyList = structuredClone(list);
  if (copyList.length == 0) return [];

  function removeCondition(index: number, filters?: FilterItem[]) {
    if (!filters) return;
    if (index >= filters.length) return;
    let currentItem = filters[index];
    // if we have an 'or' field has a child that matches the predicate
    // delete that index from the list
    if (
      currentItem.or &&
      currentItem.or.length == 1 &&
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      predicate(currentItem.or[0])
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      delete currentItem['or'];
      return;
    }
    if (!currentItem.or && predicate(currentItem)) {
      if (filters.length > 1) {
        filters = filters.splice(index + 1);
      } else if (currentItem.and && currentItem.and.length > 0) {
        currentItem = currentItem.and[0];
        currentItem.and = currentItem.and?.splice(index + 1);
      } else {
        filters = filters.splice(index);
      }
      return;
    }
    // handle the case that none of the top level filter items
    // in the list of 'or' filter items matched, search recursively
    // within those filter items
    else if (currentItem.or) {
      removeCondition(0, currentItem.or);
    }
    removeCondition(index + 1, copyList);
  }
  removeCondition(0, copyList);
  return copyList;
}

/**
 * Removes the first FilterItem that matches the predicate while maintaining the other existing logical relationships.
 * This function is handy in a situation where you want to remove a single "rule" from a list of rules while
 * ensuring that the remaining rules behave as expected.
 *
 * @returns A new list of FilterItems with the first matching FilterItem removed.
 *
 * @example
 * const filterItems = [
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     value: '123',
 *     or: [
 *       {
 *         resource: 'tag',
 *         column: 'id',
 *         value: '456',
 *       },
 *     ],
 *   },
 * ];
 *
 * removeFirstFilterItem(filterItems, (filterItem: FilterItem) => filterItem.resource == 'contact');
 *
 * // Resulting `filterItems` where the or relationship is maintained:
 * [
 *   {
 *     resource: 'tag',
 *     column: 'id',
 *     value: '456',
 *   },
 * ]
 */
export function removeFirstFilterItem(
  filters: FilterItem[],
  predicate: (filter: FilterItem) => boolean
): FilterItem[] {
  let found = false;

  return filters.flatMap((filter) => {
    // Don't do any work if we've already found the filter item we're looking for
    if (found) {
      return filter;
    }

    if (predicate(filter)) {
      found = true;

      // Case 1: The FilterItem has an `or` field and an `and` field
      // Since the or list actually represents a list of AND conditions
      // we can merge the and conditions into each or condition
      if (filter.or && filter.or.length > 0 && filter.and && filter.and.length > 0) {
        return filter.or.map((orItem) => ({
          ...orItem,
          and: [...(orItem.and || []), ...filter.and!], // Combine the `and` conditions
        }));
      }

      // Case 2: The FilterItem has only an `or` field
      // In this case then we would want replace the filter item we're removing
      // with the or list
      if (filter.or && filter.or.length > 0) {
        return filter.or;
      }

      // Case 3: The FilterItem has only an `and` field
      // In this case then we would want replace the filter item we're removing
      // with the and list
      if (filter.and && filter.and.length > 0) {
        return filter.and;
      }

      // Case 4: No `or` or `and`, just remove the item
      return [];
    }

    // Process nested relationships recursively
    const updatedFilter: FilterItem = {
      ...filter,
      or: filter.or ? removeFirstFilterItem(filter.or, predicate) : undefined,
      and: filter.and ? removeFirstFilterItem(filter.and, predicate) : undefined,
    };

    // don't include any empty or or and fields
    if (!updatedFilter.or || updatedFilter.or.length === 0) {
      delete updatedFilter.or;
    }
    if (!updatedFilter.and || updatedFilter.and.length === 0) {
      delete updatedFilter.and;
    }

    return updatedFilter;
  });
}

export function replaceFirstFilterItemValue(
  filters: FilterItem[],
  predicate: (filter: FilterItem) => boolean,
  value: string | number | boolean | string[]
): FilterItem[] {
  let found = false;

  return filters.flatMap((filter) => {
    if (found) {
      return filter;
    }

    if (predicate(filter)) {
      found = true;
      return {
        ...filter,
        value,
      };
    }

    // Process nested relationships recursively
    const updatedFilter: FilterItem = {
      ...filter,
      or: filter.or
        ? replaceFirstFilterItemValue(filter.or, predicate, value)
        : undefined,
      and: filter.and
        ? replaceFirstFilterItemValue(filter.and, predicate, value)
        : undefined,
    };

    // Clean up empty `or` and `and` fields
    if (!updatedFilter.or || updatedFilter.or.length === 0) {
      delete updatedFilter.or;
    }
    if (!updatedFilter.and || updatedFilter.and.length === 0) {
      delete updatedFilter.and;
    }

    return updatedFilter;
  });
}

export function removeExcludedSystemDefault(
  filterItems: FilterItem[],
  defaultItems: FilterItem[]
): FilterItem[] {
  if (filterItems.length == 0) return [];
  // There are 4 ors in the defaults
  // If there's an additional filters then it's an additional or
  return filterItems[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or ?? defaultItems;
}

export function hasMoreThanSystemExcludedFilter(filterItems: FilterItem[]): boolean {
  if (filterItems[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or?.[0]?.or) {
    return true;
  } else {
    return false;
  }
}

export function deepCompare(one?: FilterItem[], two?: FilterItem[]): boolean {
  if (!one && two) return false;
  if (one && !two) return false;
  if (!one && !two) return true;
  // handles both null and undefined
  if (one == null && two == null) return true;
  if (one == null || two == null || one.length != two.length) return false;
  if (one.length != two.length) return false;
  if (one.length == 0 && two.length == 0) return false;
  const itemOne = one[0];
  const itemTwo = two[0];
  if (
    itemOne.resource != itemTwo.resource ||
    itemOne.column != itemTwo.column ||
    itemOne.comparison != itemTwo.comparison ||
    itemOne.value != itemTwo.value
  )
    return false;
  return deepCompare(itemOne.and, itemTwo.and) && deepCompare(itemOne.or, itemTwo.or);
}

export function findFilterItem(
  filterItems: FilterItem[],
  predicate: FilterItemPredicate
): FilterItem | null {
  if (filterItems.length === 0) return null;
  for (const filterItem of filterItems) {
    if (predicate(filterItem)) {
      return filterItem;
    }
  }

  for (const filterItem of filterItems) {
    const filterItemInOr = findFilterItem(filterItem.or ?? [], predicate);
    if (filterItemInOr) return filterItemInOr;
    const filterItemInAnd = findFilterItem(filterItem.and ?? [], predicate);
    if (filterItemInAnd) return filterItemInAnd;
  }
  return null;
}
export function translateToAdvancedFilter(filterItems: FilterItem[]): FilterItem[] {
  const validQuickFilterResource = ['contact', 'tag', 'list'];
  const copyFilterItems = structuredClone(filterItems);
  if (filterItems.length === 0) return [];
  function helper(filterItems: FilterItem[]) {
    for (const filterItem of filterItems) {
      if (
        filterItem.comparison == 'in' &&
        Array.isArray(filterItem.value) &&
        validQuickFilterResource.includes(filterItem.resource)
      ) {
        // then it's a list of IDs
        const values = filterItem.value as string[];
        filterItem.comparison = '==';
        filterItem.value = values[0];
        let current = filterItem;
        const childNode = current.or;
        values.slice(1).forEach((value) => {
          current.or = [
            {
              resource: filterItem.resource,
              column: filterItem.column,
              comparison: '==',
              value: value,
            },
          ];
          current = current.or[0];
        });
        if (childNode && childNode.length > 0) current.or = childNode;
      }
    }

    for (const filterItem of filterItems) {
      if (filterItem.or) helper(filterItem.or);
    }
  }
  helper(copyFilterItems);
  return copyFilterItems;
}

/**
 * Flattens the filterItems array of logical ors into a single array of FilterItem objects which can be useful when working
 * with the V2 Audience.
 *
 * @example
 * const filterItems = [
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     value: '123',
 *     or: [
 *       {
 *         resource: 'contact',
 *         column: 'id',
 *         value: '456',
 *       },
 *     ],
 *   },
 * ];
 * getFlattenedFilterItems('contact', filterItems)
 * // in this case since we have a list of contact ids that can be flatterned into one list of ors
 * // returns
 * [
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     value: '123',
 *   },
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     value: '456',
 *   },
 * ]
 */
function getFlattenedFilterItems(resource: string, filterItems: FilterItem[]) {
  const filters = [] as FilterItem[];
  function helper(filterItem: FilterItem) {
    if (filterItem.resource === resource) {
      filters.push({
        resource: filterItem.resource,
        column: filterItem.column,
        comparison: filterItem.comparison,
        value: filterItem.value,
      });
    }
    if (filterItem.or) {
      for (const subFilterItem of filterItem.or) {
        helper(subFilterItem);
      }
    }
  }

  for (const filterItem of filterItems) {
    // try to find matches down this path
    helper(filterItem);
    if (filters.length > 0) return filters;
    // if we haven't found any matches, try to find matches down this path
    if (filterItem.or) helper(filterItem);
  }
  return filters;
}

function getIdsFromFilterItems(filterItems: FilterItem[]) {
  return filterItems.reduce((acc, item) => {
    if (Array.isArray(item.value)) {
      return acc.concat(item.value);
    }
    return acc.concat(item.value as string);
  }, [] as string[]);
}

/**
 * This will take the filter items and the the type of audience and return a new list.
 * The new list represents a Quick Filter approved format.
 * @example
 * const filterItems = [
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     value: '123',
 *     or: [
 *       {
 *         resource: 'contact',
 *         column: 'id',
 *         value: '456',
 *       },
 *     ],
 *   },
 * ];
 * translateToQuickFilter(filterItems, 'included')
 * // returns
 * [
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     comparison: 'in',
 *     value: ['123', '456'],
 *   },
 * ]
 * NOTE: If the filterItems is not an acceptable Quick Filter, then we return null.
 */
export function translateToQuickFilter(
  filterItems: FilterItem[],
  audienceType: 'included' | 'excluded',
  aliases?: QuickFilterAlias[]
): FilterItem[] | null {
  if (!isValidQuickFilter(filterItems, audienceType, aliases)) return null;
  if (filterItems.length === 0) return [];
  const getContacts = getFlattenedFilterItems('contact', filterItems);
  const getTags = getFlattenedFilterItems('tag', filterItems);
  const getLists = getFlattenedFilterItems('list', filterItems);
  const contactIds = getIdsFromFilterItems(getContacts);
  const tagIds = getIdsFromFilterItems(getTags);
  const listIds = getIdsFromFilterItems(getLists);
  let newFilter = [] as FilterItem[];
  if (contactIds.length == 1) {
    newFilter.push({
      resource: 'contact',
      column: 'id',
      comparison: '==',
      value: contactIds[0],
    });
  } else if (contactIds.length > 1) {
    newFilter.push({
      resource: 'contact',
      column: 'id',
      comparison: 'in',
      value: contactIds,
    });
  }
  if (tagIds.length == 1) {
    newFilter = appendOrCondition(newFilter, {
      resource: 'tag',
      column: 'id',
      comparison: '==',
      value: tagIds[0],
    });
  } else if (tagIds.length > 1) {
    newFilter = appendOrCondition(newFilter, {
      resource: 'tag',
      column: 'id',
      comparison: 'in',
      value: tagIds,
    });
  }
  if (listIds.length == 1) {
    newFilter = appendOrCondition(newFilter, {
      resource: 'list',
      column: 'id',
      comparison: '==',
      value: listIds[0],
    });
  } else if (listIds.length > 1) {
    newFilter = appendOrCondition(newFilter, {
      resource: 'list',
      column: 'id',
      comparison: 'in',
      value: listIds,
    });
  }
  return newFilter;
}

/**
 * This determines whether a list of filter items can be properly displayed by the AudienceQuickFilter.
 *
 * A Included QuickFilter Audience is valid if it meets the following criteria
 *   - resource type can only be 'contact', 'tag', and/or 'list'
 *   - only supported column is 'id'
 *   - only supported comparison operations are 'in' and '=='
 *
 * A Excluded QuickFilter Audience is valid if it meets the following criteria
 *   - we can support the rules for the Included Audience Filter and the ones below
 *   - we can support type 'communication_preference', column: 'last_campaign_date', and comparison being '>'
 *       - This is needed to support the exclude rule for excluding contacts that have been in a recent campaign.
 *   - we can support type 'conversation', column: 'status', and comparison being 'in', and value being ['open', 'automated']
 *       - This is needed to support the exclude rule for excluding contacts that have open conversations.
 * @param filterItems - The list of filter items that represent the audience
 * @type audienceType - Whether the audience represents the list of people to include or exclude.
 *
 * NOTE: For excluded filter we should remove the system defaults rules before passing into this function.
 */
export function isValidQuickFilter(
  filterItems: FilterItem[] | null,
  audienceType: 'included' | 'excluded',
  quickFilterAlias?: QuickFilterAlias[]
): boolean {
  if (!filterItems) return true;
  if (filterItems.length == 0) return true;
  for (const filterItem of filterItems) {
    const isValid =
      audienceType == 'included'
        ? isValidEntity(filterItem)
        : isValidEntity(filterItem) ||
          isValidOpenConversation(filterItem) ||
          isValidExcludeTimeSince(filterItem);
    if (
      !isValid &&
      !isValidQuickFilterAlias(filterItem, quickFilterAlias, audienceType)
    ) {
      return false;
    }
  }
  for (const filterItem of filterItems) {
    const isValidOrField = isValidQuickFilter(
      filterItem.or ?? [],
      audienceType,
      quickFilterAlias
    );
    if (!isValidOrField) return false;
    // quick filters for audiences contains only 'or' conditions
    if (
      filterItem.and &&
      !isValidQuickFilterAlias(filterItem, quickFilterAlias, audienceType)
    ) {
      return false;
    }
  }
  return true;
}

function isValidQuickFilterAlias(
  filterItem: FilterItem,
  quickFilterAliases?: QuickFilterAlias[],
  audienceType?: 'included' | 'excluded'
) {
  if (quickFilterAliases === undefined) return false;
  const filterAliasesByType = quickFilterAliases.filter(
    (alias) => alias.type == audienceType
  );
  return filterAliasesByType.some((alias) => {
    const result = compareFilters(filterItem, alias.filter);
    return result;
  });
}

function isValidEntity(filterItem: FilterItem) {
  const validResources = ['contact', 'tag', 'list'];
  const validComparison = ['in', '=='];
  const isValidResource = validResources.includes(filterItem.resource);
  const isValidComparison = validComparison.includes(filterItem.comparison ?? '');
  const isValidColumn = filterItem.column == 'id';
  return isValidColumn && isValidComparison && isValidResource;
}

function isValidExcludeTimeSince(filterItem: FilterItem) {
  const isValidResource = filterItem.resource == 'communication_preference';
  const isValidComparison = filterItem.comparison == '>';
  const isValidColumn = filterItem.column == 'last_campaign_date';
  return isValidColumn && isValidComparison && isValidResource;
}

function isValidOpenConversation(filterItem: FilterItem) {
  const isValidResource = filterItem.resource == 'conversation';
  const isValidComparison = filterItem.comparison == 'in';
  const isValidColumn = filterItem.column == 'status';
  const isValidValue =
    Array.isArray(filterItem.value) &&
    filterItem.value.length == 2 &&
    filterItem.value[0] == 'open' &&
    filterItem.value[1] == 'automated';
  return isValidColumn && isValidComparison && isValidResource && isValidValue;
}

/**
 * This function checks if the audience is valid for the campaign. This will only be true if all of the
 * following conditions are met:
 * 1. The campaign is valid
 * 2. The campaign has a channel_id
 * 3. The included audience filter is not empty
 *
 * @example
 * isAudienceValid(campaign, includedAudienceFilter, advancedFilterTypes)
 */
export function isAudienceValid(
  campaign: Campaign | null,
  includedAudienceFilter: FilterItem[] | null
) {
  // if campaign is valid and we have a channel
  const isCampaignValid =
    campaign != null && campaign.channel_id != null && campaign.channel_id != undefined;
  // if we have an included audience with a length greater than 0
  const isIncludedAudienceValid =
    includedAudienceFilter != null && includedAudienceFilter.length > 0;
  //
  return isCampaignValid && isIncludedAudienceValid;
}

export function isRespondedContactsFromCampaign(
  includedAudienceFilter: FilterItem[] | null
) {
  if (!includedAudienceFilter || includedAudienceFilter.length != 1) return false;
  const maybeCampaignContactFilter = includedAudienceFilter[0];
  if (
    !maybeCampaignContactFilter.and ||
    maybeCampaignContactFilter.and.length != 1 ||
    maybeCampaignContactFilter.column != 'campaign_id' ||
    maybeCampaignContactFilter.comparison != '==' ||
    maybeCampaignContactFilter.resource != 'campaign_contact'
  )
    return false;
  const maybeContactMessageFilter = maybeCampaignContactFilter.and[0];
  if (
    maybeContactMessageFilter.column != 'id' ||
    maybeContactMessageFilter.comparison != '!=' ||
    maybeContactMessageFilter.value != null ||
    maybeContactMessageFilter.resource != 'contact_message' ||
    maybeContactMessageFilter.or ||
    maybeContactMessageFilter.and
  )
    return false;
  return true;
}

export function compareFilters(filter1: FilterItem, filter2: FilterItem) {
  if (filter1.column != filter2.column) return false;
  if (filter1.comparison != filter2.comparison) return false;
  // we'd need to recursively check the values of the filters since we support arrays
  if (!areValuesEqual(filter1.value, filter2.value)) return false;
  if (filter1.resource != filter2.resource) return false;
  // if we one of the filters or and and fiels is undefined, the other must be undefined
  if ((filter1.or === undefined) !== (filter2.or === undefined)) return false;
  if ((filter1.and === undefined) !== (filter2.and === undefined)) return false;
  // the lengths of the or and and fields must be the same
  if (filter1.or?.length !== filter2.or?.length) return false;
  if (filter1.and?.length !== filter2.and?.length) return false;

  if (filter1.or && filter2.or) {
    for (let i = 0; i < filter1.or.length; i++) {
      if (!compareFilters(filter1.or[i], filter2.or[i])) return false;
    }
  }

  if (filter1.and && filter2.and) {
    for (let i = 0; i < filter1.and.length; i++) {
      if (!compareFilters(filter1.and[i], filter2.and[i])) return false;
    }
  }
  return true;
}

// Utility function to compare `value`
function areValuesEqual(
  value1: string[] | string | boolean | number | null | undefined,
  value2: string[] | string | boolean | number | null | undefined
): boolean {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    if (value1.length !== value2.length) return false;
    return value1.every((v, i) => v === value2[i]); // Compare array elements deeply
  }
  return value1 === value2; // Primitive or other types
}
