import * as SelectPrimitive from '@radix-ui/react-select';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import {
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  VStack,
} from '@/shared/ui';

import { WhatsAppSignup } from './WhatsAppSignup';

export const channel_providers = [
  {
    id: 'whippy-phone',
    provider: ProviderTypes.TWILIO,
    supports_phone: true,
    support_phone_number_purchase: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: false,
    label: 'Whippy Phone (Voice, SMS, MMS)',
    provider_auth: {},
  },
  {
    id: 'whippy-email',
    provider: ProviderTypes.MAILGUN,
    supports_phone: false,
    support_phone_number_purchase: false,
    supports_email: true,
    supports_whatsapp: false,
    supports_custom_auth: false,
    label: 'Whippy Email (Default)',
    provider_auth: {},
  },
  {
    id: 'twilio-sms',
    provider: ProviderTypes.TWILIO,
    supports_phone: true,
    support_phone_number_purchase: true,
    supports_email: false,
    supports_whatsapp: true,
    supports_custom_auth: true,
    label: 'Twilio',
    provider_auth: {
      account_sid: '',
      auth_token: '',
    },
  },
  {
    id: 'meta-whatsapp',
    provider: ProviderTypes.META,
    supports_phone: false,
    support_phone_number_purchase: false,
    supports_email: false,
    supports_whatsapp: true,
    supports_custom_auth: false,
    label: 'Meta Whatsapp',
    provider_auth: {},
  },
  {
    id: 'vonage-sms',
    provider: ProviderTypes.VONAGE,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Vonage',
    provider_auth: {
      application_id: '',
      private_key: '',
    },
  },
  {
    id: 'telnyx-sms',
    provider: ProviderTypes.TELNYX,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Telnyx',
    provider_auth: {
      api_key: '',
      public_key: '',
    },
  },
  {
    id: 'sinch-sms',
    provider: ProviderTypes.SINCH,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Sinch',
    provider_auth: {
      app_id: '',
      project_id: '',
      region: '',
      username: '',
      password: '',
    },
  },
  {
    id: 'bandwidth-sms',
    provider: ProviderTypes.BANDWIDTH,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Bandwidth',
    provider_auth: {
      username: '',
      user_password: '',
      account_id: '',
      application_id: '',
      sub_account_number: '',
      location_number: '',
      callback_id: '',
      callback_password: '',
    },
  },
  {
    id: 'mailgun-email',
    provider: ProviderTypes.MAILGUN,
    support_phone_number_purchase: false,
    supports_phone: false,
    supports_email: true,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Mailgun',
    provider_auth: {
      base_domain: '',
      api_key: '',
      webhook_signing_key: '',
    },
  },
];

export const SelectChannelProvider = ({
  channel,
  setChannel,
}: {
  channel: Partial<Channel>;
  setChannel: Dispatch<SetStateAction<Partial<Channel>>>;
}) => {
  const [channelProviderId, setChannelProviderId] = useState<string>('whippy-phone');

  // set default provider if channel type is email
  useEffect(() => {
    // if type is email, set default provider to whippy-email
    if (channel.type === ChannelTypes.EMAIL) {
      setChannelProviderId('whippy-email');
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider: ProviderTypes.MAILGUN,
      }));
    }
    // if type is phone, set default provider to whippy-phone
    else if (channel.type === ChannelTypes.PHONE) {
      setChannelProviderId('whippy-phone');
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider: ProviderTypes.TWILIO,
      }));
    }
    // if type is whatsapp, set default provider to whippy-whatsapp
    else if (channel.type === ChannelTypes.WHATSAPP) {
      setChannelProviderId('twilio-sms');
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider: ProviderTypes.TWILIO,
      }));
    }
  }, []);

  // filter providers based on channel type, only show providers that support the channel type
  const filteredProviders = channel_providers.filter((provider) => {
    if (channel.type === ChannelTypes.PHONE) {
      return provider.supports_phone;
    } else if (channel.type === ChannelTypes.EMAIL) {
      return provider.supports_email;
    } else if (channel.type === ChannelTypes.WHATSAPP) {
      return provider.supports_whatsapp;
    }
    return false;
  });

  // auth type set state default or custom provider auth
  const [providerAuthType, setProviderAuthType] = useState('default');

  // handle provider auth change for custom providers
  const handleProviderAuthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setChannel((prevChannel) => ({
      ...prevChannel,
      provider_auth: {
        ...prevChannel.provider_auth,
        [name]: value,
      } as typeof prevChannel.provider_auth,
    }));
  };

  const handleMetaAuth = (metaParams: {
    code: string;
    phone_pin: string;
    phone_number_id: string;
    waba_id: string;
  }) => {
    setChannel((prevChannel) => ({
      ...prevChannel,
      meta_onboarding: metaParams,
    }));
  };

  const selectedProvider = channel_providers.find(
    (provider) => provider.id === channelProviderId
  );

  return (
    <Flex direction="column" css={{ width: '100%' }}>
      <VStack gap="2">
        <Fieldset>
          <Label>Communication Provider</Label>
          <Select
            defaultValue={'whippy-phone'}
            value={channelProviderId || ''}
            onValueChange={(id: string) => {
              const selectedProvider = channel_providers.find(
                (provider) => provider.id === id
              );
              setChannelProviderId(id);
              if (selectedProvider) {
                setChannel((prevChannel) => ({
                  ...prevChannel,
                  provider: selectedProvider.provider,
                }));
              }
            }}
          >
            <SelectTrigger aria-label="action-select-trigger">
              <SelectValue />
              <SelectIcon>
                <HiChevronDown />
              </SelectIcon>
            </SelectTrigger>
            <SelectPrimitive.Portal>
              <SelectContent css={{ zIndex: '9999' }}>
                <SelectScrollUpButton>
                  <HiChevronUp />
                </SelectScrollUpButton>
                <SelectViewport>
                  <SelectGroup>
                    <SelectLabel>Communication Providers</SelectLabel>
                    {filteredProviders.map((provider, index) => (
                      <SelectItem key={index} value={provider.id}>
                        <SelectItemIndicator />
                        <SelectItemText>{provider.label}</SelectItemText>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectViewport>
              </SelectContent>
            </SelectPrimitive.Portal>
          </Select>
        </Fieldset>

        {selectedProvider?.provider === ProviderTypes.META ? (
          <>
            <WhatsAppSignup onMetaAuth={handleMetaAuth} />
          </>
        ) : (
          <>
            {selectedProvider && selectedProvider.supports_custom_auth && (
              <Fieldset>
                <Label>Select Authentication Type</Label>
                <RadioGroup value={providerAuthType} onValueChange={setProviderAuthType}>
                  <Flex align="center" css={{ mt: 10 }}>
                    <Flex justify="center" align="center" css={{ mr: 10 }}>
                      <RadioGroupRadio value="default">
                        <RadioGroupIndicator />
                      </RadioGroupRadio>
                      <Label css={{ ml: 5, mb: 0 }}>Managed by Whippy</Label>
                    </Flex>
                    <HStack align="center">
                      <RadioGroupRadio value="custom">
                        <RadioGroupIndicator />
                      </RadioGroupRadio>
                      <Label css={{ ml: 5, mb: 0 }}>Custom</Label>
                    </HStack>
                  </Flex>
                </RadioGroup>
              </Fieldset>
            )}
            {providerAuthType === 'custom' && selectedProvider && (
              <Fieldset>
                <Label css={{ mb: 20 }}>Custom Provider Authentication</Label>
                {Object.entries(selectedProvider.provider_auth).map(([key]) => (
                  <Fieldset key={key}>
                    <Label>
                      {key
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Label>
                    <Input
                      name={key}
                      placeholder={key
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                      value={
                        (channel.provider_auth as Record<string, string>)?.[key] || ''
                      }
                      onChange={handleProviderAuthChange}
                    />
                  </Fieldset>
                ))}
              </Fieldset>
            )}
          </>
        )}
      </VStack>
    </Flex>
  );
};
