import { Contact } from '@/shared/types/contacts';
import { FilterItem, Sort } from '@/shared/types/filter';

import { api } from '../../api';

export type ContactParams = {
  id: string;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  status?: string | null;
  source?: string | null;
};

export type NewContactParams = {
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  status?: string | null;
  source?: string | null;
};

export type DynamicFilterParams = {
  params: any;
};

export type getPortionOfContactsParams = {
  offset: number;
};

export type BulkAddTagsParams = {
  tags: Array<string>;
  selection: {
    group_id?: string;
    upload_id?: string;
    contacts?: Array<string> | string;
    campaign_id?: string;
    campaign_contacts_type?: string;
  };
};

export type unpauseLocationsParams = {
  audience: {
    contacts: Array<string>;
  };
  location_ids: Array<string>;
};

export type pauseLocationsParams = {
  audience: {
    contacts: Array<string>;
  };
  date: string;
  location_ids: Array<string>;
};

export type ContactCampaignsParams = {
  filter: Array<FilterItem>;
  sort: Array<Sort>;
  offset: number;
  limit: number;
};

export type getContactSequencesParams = {
  offset: number;
  limit: number;
};

/*
  GET - get one contact by id
*/

export const getOneContact = (id: string) =>
  api.get<{ data: Contact }>(`/contacts/${id}`).then((res) => {
    return res.data.data;
  });

/*
  GET - get one contact by id
*/

export const getContactsByGroup = (groupId: string, offset = 0) =>
  api.get(`/contacts/group/${groupId}?offset=${offset}`).then((res) => {
    return res.data.data;
  });

export const getContactsByDynamicGroup = (groupId: string) =>
  api.get(`/contacts/dynamic/${groupId}`).then((res) => {
    return res.data.data;
  });

/*
  GET - get one contact by id
*/

export const searchContacts = async (
  name: string,
  phone: string,
  uploadId = '',
  searchParamsList = []
) => {
  const { data } = await api.post(`/contacts/search`, {
    name: name,
    phone: phone,
    tag_id: uploadId,
    group_params: searchParamsList,
    offset: 0,
  });
  return data.data;
};

/*
  GET - get portion of organization contacts
*/

export const getPortionOfContacts = (params: getPortionOfContactsParams) =>
  api.get(`/contacts/batch_index?offset=${params.offset.toString()}`).then((res) => {
    return res.data.data;
  });
/*

  GET - get organization contacts with offset
*/

export const getContacts = (params: getPortionOfContactsParams) =>
  api.get(`/contacts?offset=${params.offset.toString()}`).then((res) => {
    return res.data.data;
  });
/*
  POST - add a new contact to an organization
*/

export const createContact = (contactParams: NewContactParams): Promise<Contact> =>
  api.post('/contacts', { contact: contactParams }).then((res) => {
    return res.data.data;
  });

/*
  PUT - update a contact in an organization
*/

export const updateContact = (contactParams: ContactParams) =>
  api.put(`/contacts/${contactParams.id}`, { contact: contactParams }).then((res) => {
    return res.data.data;
  });

/*
  POST - add a new contact to an organization
*/

export const createNote = (noteParams: any) =>
  api
    .post(`/contacts/${noteParams.contact_id}/notes`, { note: noteParams })
    .then((res) => {
      return res.data.data;
    });

/*
  DELETE - remove a contact from an organization
*/

export const deleteNote = (id: string, contact_id: string) =>
  api.delete(`/contacts/${contact_id}/notes/${id}`).then((res) => {
    return res.data.data;
  });

// get notes of a given user

export const getNotes = (contact_id: string | undefined) =>
  api.get(`/contacts/${contact_id}/notes`).then((res) => {
    return res.data.data;
  });

// bulk delete contacts

export const deleteMultipleContacts = (contactIds: Array<string>) => {
  api.post('/contacts/bulk_delete', { contacts: contactIds }).then((res) => {
    return res.data.data;
  });
};

// delete contact

export const deleteContact = (contactId: string) =>
  api.delete(`/contacts/${contactId}`).then((res) => {
    return res.data.data;
  });

// contacts dynamic filtering
export const dynamicFilterContacts = (params: any) =>
  api
    .post('/contacts/dynamic_search', {
      search: params,
    })
    .then((res) => {
      return res.data.data;
    });

export const dynamicFilterPartOfContacts = (params: any, offset: number) =>
  api
    .post('/contacts/dynamic_search', {
      search: params,
      offset: offset,
    })
    .then((res) => {
      return res.data.data;
    });

export const bulkAddTagsToContacts = (params: BulkAddTagsParams) =>
  api.post('/tags/assign', params).then((res) => {
    return res.data.data;
  });

type UpdatePreferenceParams = {
  opt_in: boolean;
  location_ids: Array<string>;
  paused_until?: string | null;
};

export const getContactCommunicationPreference = (contactId: string) =>
  api.get(`/contacts/${contactId}/communication_preferences`).then((res) => {
    return res.data.data;
  });

export const updateContactCommunicationPreference = (
  contactId: string,
  params: UpdatePreferenceParams
) =>
  api
    .put(`/contacts/${contactId}/communication_preferences`, {
      communication_preference: params,
    })
    .then((res) => {
      return res.data.data;
    });

export const blockContact = (id: string, isBlocked: boolean) => {
  return api
    .put(`/contacts/block`, {
      block: isBlocked,
      id: id,
    })
    .then((res) => {
      return res.data.data;
    });
};

export const unpauseLocations = async (params: unpauseLocationsParams) => {
  const res = await api.post('contacts/communication_preferences/unpause', params);
  return res.data.data;
};

export const pauseLocations = async (params: pauseLocationsParams) => {
  const res = await api.post('contacts/communication_preferences/pause', params);
  return res.data.data;
};

/*
  GET - get lists the contact tags of the current contact
*/
export const getContactTags = async (id: string) => {
  const { data } = await api.get(`/contacts/${id}/contact_tags`);
  return data;
};

/*
  GET - get lists the contact campaigns of the current contact
*/
export const getContactCampaigns = async (params: ContactCampaignsParams) => {
  const data = await api.post(`/v2/campaigns/search`, params);
  return data;
};

/*
  GET - get the contact sequences of the current contact
*/

export const getContactSequences = async (contactId: string) => {
  const { data } = await api.get(`/contacts/${contactId}/sequences`);
  return data;
};
