import { Dispatch } from 'react';
import React from 'react';
import { HiX } from 'react-icons/hi';

import { DatepickerDates } from '@/shared/components/datepicker/Datepicker';
import {
  ComboboxOption,
  ValueCombobox,
} from '@/shared/components/filterBuilder/values/Combobox';
import { ConversationItemSourceTypes } from '@/shared/types/conversations';
import { Box, Flex, IconButton } from '@/shared/ui';

import { AnalyticDateSelector } from './AnalyticDateSelector';
import { AnalyticFilterCombobox } from './AnalyticFilterCombobox';

const filterAll = { label: 'All', value: 'All' };
const filterOptions = [
  {
    label: 'Call Completion Rating',
    key: 'call_completion_rating',
    options: [
      filterAll,
      { label: 'Complete', value: 'Complete' },
      { label: 'Incomplete', value: 'Incomplete' },
    ],
  },
  {
    label: 'Direction',
    key: 'source_type',
    options: [
      filterAll,
      { label: 'Inbound', value: ConversationItemSourceTypes.INBOUND },
      { label: 'Outbound', value: ConversationItemSourceTypes.OUTBOUND },
    ],
  },
  {
    label: 'Sentiment',
    key: 'user_sentiment',
    options: [
      filterAll,
      { label: 'Positive', value: 'Positive' },
      { label: 'Negative', value: 'Negative' },
      { label: 'Neutral', value: 'Neutral' },
    ],
  },
];

const defaultFilter = {
  column: '',
  comparison: 'in',
  value: [],
};
export type AnalyticFilterItem = {
  column: string;
  comparison: string | null;
  value: Array<string | number>;
};

export const AnalyticFilters = ({
  filters,
  setFilters,
  dates,
  setDates,
  agents,
  setAgents,
}: {
  filters: Array<AnalyticFilterItem>;
  setFilters: Dispatch<React.SetStateAction<Array<AnalyticFilterItem>>>;
  dates: DatepickerDates;
  setDates: Dispatch<React.SetStateAction<DatepickerDates>>;
  agents?: Array<string>;
  setAgents?: Dispatch<React.SetStateAction<Array<string>>>;
}) => {
  const selectFilter = (key: string, value: string | number) => {
    if (filters.length) {
      const filterIndex = filters.findIndex((f) => f.column === key);
      const newFilters = [...filters];
      const newFilter = { ...defaultFilter, column: key, value: [value] };
      if (filterIndex >= 0) {
        if (value === filterAll.value) {
          newFilters.splice(filterIndex, 1);
        } else {
          newFilters[filterIndex] = newFilter;
        }
      } else {
        if (value !== filterAll.value) {
          newFilters.push(newFilter);
        }
      }
      setFilters(newFilters);
    } else {
      if (value !== filterAll.value) {
        setFilters([{ ...defaultFilter, column: key, value: [value] }]);
      }
    }
  };

  const getFilter = (key: string, options: Array<ComboboxOption>) => {
    const filter = filters.find((f) => f.column === key);
    return filter && options.find((o) => o.value == filter?.value[0]);
  };

  const cleanFilters = () => {
    setFilters([]);
    if (setAgents) {
      setAgents([]);
    }
  };

  return (
    <Flex gap={2} data-testid="analytic-filters">
      <AnalyticDateSelector dates={dates} setDates={setDates} />
      {!!agents && !!setAgents && (
        <Box css={{ maxWidth: 200 }}>
          <AnalyticFilterCombobox selectedItems={agents} setSelectedItems={setAgents} />
        </Box>
      )}
      {filterOptions.map((filter) => {
        return (
          <ValueCombobox
            key={filter.key}
            hideChevron
            selectLabel={filter.label}
            options={filter.options}
            onSelect={(option) => {
              selectFilter(filter.key, option.value);
            }}
            selected={getFilter(filter.key, filter.options) as ComboboxOption}
            withSearch={false}
            css={{ minWidth: 'auto', height: 40 }}
            renderOptionsIfOpen
          />
        );
      })}
      {(!!filters.length || !!agents?.length) && (
        <IconButton
          onClick={() => cleanFilters()}
          css={{
            border: '1px solid $slate7',
            height: 40,
            width: 40,
            background: 'white',
          }}
        >
          <HiX size="15px" />
        </IconButton>
      )}
    </Flex>
  );
};
