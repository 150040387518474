/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiChat, HiExclamationCircle, HiPencilAlt } from 'react-icons/hi';
import { Column as ReactTableColumn } from 'react-table';
import { VirtuosoHandle } from 'react-virtuoso';
import { toast } from 'sonner';

import {
  initialCampaignsState,
  ITEMS_COUNT,
  useCampaignsContext,
} from '@/campaigns/context/CampaignsContext';
import { QuickCampaignDialog } from '@/campaigns/v2/quick';
import { ConversationDrawer } from '@/inbox/drawer';
import { ContactPanel } from '@/pages/data/contacts/ContactPanel';
import { useData } from '@/pages/data/context/DataContext';
import { ContactActionsMenu } from '@/pages/data/utils/ContactsTable';
import {
  handleFilterChange,
  handleQuickSearch,
  handleSortUpdate,
} from '@/pages/data/utils/filterActions';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { OptOutOptions } from '@/pages/settings/organization/general/context/types';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { campaignBulkActions } from '@/shared/api/campaigns';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_campaign_contact_object } from '@/shared/components/filterBuilder/objects/campaign_contact';
import { default_campaign_message_object } from '@/shared/components/filterBuilder/objects/campaign_message';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { default_campaign_contact_message_object } from '@/shared/components/filterBuilder/objects/contact_messages';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { RemoveFromSequenceModal } from '@/shared/components/filters/bulkAction/modals/RemoveFromSequenceModal';
import { UnsubscribeContactsFromCampaignModal } from '@/shared/components/filters/bulkAction/modals/UnsubscribeContactsFromCampaignModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { FilterTabs } from '@/shared/components/filters/FilterTabs';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Campaign } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { SearchFilters } from '@/shared/types/contacts';
import {
  FilterItem,
  FilterParams,
  FilterType,
  Sort,
  TabFilter,
  TabValueMapping,
} from '@/shared/types/filter';
import { SequenceBulkActionFilter } from '@/shared/types/sequences';
import { TagBulkActionType } from '@/shared/types/tags';
import { Box, Flex, HStack, IconButton } from '@/shared/ui';
import { IndeterminateCheckbox } from '@/shared/ui/IndeterminateCheckbox';
import i18next from '@/shared/utils/translation';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { createQuickCampaign } from '../quick/api';
import { Campaign as CampaignHeader } from './Campaign';
import { campaignContactsSortConfig } from './filterConfig';

export const defaultSort: Array<Sort> = [
  {
    label: 'Responded At',
    column: 'inserted_at',
    order: 'asc',
    resource: 'contact_message',
    id: null,
  },
];

const noContactsFound = (
  <Box css={{ textAlign: 'center' }}>
    <Box>No responses found</Box>
  </Box>
);

// gets the location, if any, either through the campaign or defaults
const getCurrentLocationId = (
  campaign: Campaign | null,
  locations: Channel[],
  preferredLocation?: string
): string | null => {
  const locationId =
    campaign?.audience?.location ||
    campaign?.channel_id ||
    preferredLocation ||
    locations[0]?.id;
  return locationId ?? null;
};

const getCurrentLocation = (
  campaign: Campaign | null,
  locations: Channel[],
  preferredLocation?: string
): Channel | null => {
  const id = getCurrentLocationId(campaign, locations, preferredLocation);
  if (!id) return null;
  return locations.find((location) => location.id === id) ?? null;
};

const deliveredFilter = [
  {
    resource: 'campaign_message',
    column: 'delivery_status',
    comparison: 'in',
    value: ['opened', 'delivered', 'queued', 'dlr_timeout', 'delivery_unconfirmed'],
  },
];

const notDeliveredFilter = [
  {
    resource: 'campaign_message',
    column: 'delivery_status',
    comparison: 'in',
    value: ['failed', 'delivery_failed', 'sending_failed'],
  },
];

const respondedFilter = [
  {
    column: 'id',
    comparison: '!=',
    resource: 'contact_message',
    value: null,
  },
  {
    column: 'unsubscribed',
    comparison: '!=',
    resource: 'campaign_contact',
    value: true,
  },
];

const unrespondedFilter = [
  {
    resource: 'campaign_message',
    column: 'delivery_status',
    comparison: 'in',
    value: ['opened', 'delivered', 'queued', 'dlr_timeout', 'delivery_unconfirmed'],
  },
  {
    column: 'id',
    comparison: '==',
    resource: 'contact_message',
    value: null,
  },
];
const linkClickedFilter = [
  {
    column: 'clicked_link',
    comparison: '==',
    resource: 'campaign_contact',
    value: true,
  },
];

const notClickedFilter = [
  {
    column: 'clicked_link',
    comparison: '==',
    resource: 'campaign_contact',
    value: false,
  },
];

const unsubscribedFilter = [
  {
    column: 'unsubscribed',
    comparison: '==',
    resource: 'campaign_contact',
    value: true,
  },
];

const campaignContactsTabFilters = [
  {
    key: 'all',
    label: 'All Contacts',
    value: {
      filter: [],
    },
  },
  {
    label: 'Delivered',
    key: 'delivered',
    value: {
      filter: deliveredFilter,
    },
  },
  {
    label: 'Not Delivered',
    key: 'not_delivered',
    value: {
      filter: notDeliveredFilter,
    },
  },
  {
    label: 'Responded',
    key: 'responded',
    value: {
      filter: respondedFilter,
    },
  },
  {
    label: 'Un-responded',
    key: 'unresponded',
    value: {
      filter: unrespondedFilter,
    },
  },
  {
    label: 'Link Clicked',
    key: 'link_clicked',
    value: {
      filter: linkClickedFilter,
    },
  },
  {
    label: 'Link Not Clicked',
    key: 'link_not_clicked',
    value: {
      filter: notClickedFilter,
    },
  },
  {
    label: 'Un-subscribed',
    key: 'unsubscribed',
    value: {
      filter: unsubscribedFilter,
    },
  },
];

const formatDate = (value: string | undefined) => {
  return value ? dayjs(value).format('ddd D MMM hh:mm A') : '-';
};

export const CampaignContacts = () => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([] as string[]);
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [isQuickCampaignOpen, setQuickCampaignOpen] = useState(false);
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [isRemoveFromSequenceModalOpen, setRemoveFromSequenceModalOpen] = useState(false);
  const [tagModalAction, setTagModalAction] = useState<TagBulkActionType>('unassign');
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [selectedTabFilter, setSelectedTabFilter] = useState<TabFilter>(
    campaignContactsTabFilters[0]
  );
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const { settingsState, getOrganizationInfo } = useSettings();
  const { settings } = settingsState;
  const [
    isUnsubscribeContactsFromCampaignDialogOpen,
    setUnsubscribeContactsFromCampaignDialogOpen,
  ] = useState(false);

  /* if the organization opt out setting is location based, then we need
  the user to specify which locations we should opt these contacts from.

  If the organization opt out setting is channel type or all, then we don't need
  to provide additional information so we don't need the modal to show. We can
  just make the request to unsubscribe.
  */
  const maybeOpenDialog = async (optOutOption: OptOutOptions) => {
    if (!current || !current.id) return;
    if (optOutOption == OptOutOptions.LOCATION) {
      setUnsubscribeContactsFromCampaignDialogOpen(true);
    } else {
      const filter =
        combineFilter(selectedContactIds, campaignContactsParams, isAllSelected).filter ??
        [];
      toast.promise(
        campaignBulkActions({
          action: 'campaigns.unsubscribe.contacts',
          campaignId: current.id,
          filter: filter as FilterItem[],
          filterSource: ['campaign', current.id],
        }),
        {
          loading: 'Attempting to Un-subscribe contacts',
          success: 'Un-subscribing contacts',
          error: 'Failed to un-subscribe contacts',
        }
      );
    }
  };

  const bulkActions: BulkAction[] = [
    {
      action: 'createQuickCampaignV2',
      label: 'Create Campaign',
      title: 'Create Quick Campaign',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to a Quick Campaign?`,
      onManualSelectedSubmit: (selectedCampaignContactIds: string[]) => {
        if (current && current.id) {
          setSelectedContactIds(selectedCampaignContactIds);
          setQuickCampaignOpen(true);
          setIsAllSelected(false);
          setTotalContactsSelected(selectedCampaignContactIds.length);
        }
      },
      onAllSelectedSubmit: () => {
        if (current && current.id) {
          setQuickCampaignOpen(true);
          setIsAllSelected(true);
          setTotalContactsSelected(totalCampaignContacts);
        }
      },
    },
    {
      action: 'unsubscribeFromCampaignV2',
      label: 'Un-subscribe',
      title: 'Un-subscribe Contacts from Campaign',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unsubscribe **${selected} contact(s)** from an existing Campaign?`,
      onAllSelectedSubmit: () => {
        maybeOpenDialog(settings.contact_opt_out_default);
        setIsAllSelected(true);
        setTotalContactsSelected(totalCampaignContacts);
      },
      onManualSelectedSubmit(selectedContactIds: string[]) {
        maybeOpenDialog(settings.contact_opt_out_default);
        setIsAllSelected(false);
        setSelectedContactIds(selectedContactIds);
        setTotalContactsSelected(selectedContactIds.length);
      },
    },
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to an existing sequence?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(campaignContacts.length);
      },
    },
    {
      label: 'Assign Tags',
      action: 'assignTagsV2',
      title: 'Assign Tags',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(campaignContacts.length);
      },
    },
    {
      label: 'Overwrite Tags',
      action: 'overwriteTagsV2',
      title: 'Overwrite Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign.overwrite');
        return `Are you sure you want to overwrite tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(campaignContacts.length);
      },
    },
    {
      label: 'Un-assign Tags',
      action: 'unassignTagV2',
      title: 'Un-assign Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('unassign');
        return `Are you sure you want to unassign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(campaignContacts.length);
      },
    },
    {
      label: 'Remove from Sequence',
      action: 'removeFromSequenceV2',
      title: 'Remove from Sequence',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to remove **${selected} contact(s)** from an existing sequence`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setRemoveFromSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setRemoveFromSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(campaignContacts.length);
      },
    },
  ];

  const campaigns = useCampaignsContext();
  const { campaignsState, updateCampaignContactsParams } = campaigns;

  const {
    current,
    loadingContacts,
    campaignContacts,
    totalCampaignContacts,
    campaignContactsParams,
  } = campaignsState;

  const location = useChannels();
  const { channelsState } = location;
  const { channels } = channelsState;
  const { preferences } = useUserPreferences();

  const [currentLocation, setCurrentLocation] = useState<Channel | null>(
    getCurrentLocation(current, channels, preferences?.inbox?.preferred_location_id)
  );

  const contacts = useData();
  const { blockContact, deleteContact } = contacts;

  const tableRef = useRef<VirtuosoHandle>(null);

  const analytics = current?.analytics || {
    delivery_rate: 0,
    failed_deliveries: 0,
    link_clicks: 0,
    link_clicks_rate: 0,
    response_rate: 0,
    responses: 0,
    sent_messages: 0,
    slated_messages: 0,
    unfulfilled_rate: 0,
    unsubscribe_rate: 0,
    unsubscribes: 0,
  };

  // this object represents the mapping between tab and
  // number of contacts within the tab
  const tabValueMappings: TabValueMapping = {
    all: () => analytics.sent_messages,
    delivered: () => analytics.sent_messages - analytics.failed_deliveries,
    not_delivered: () => analytics.failed_deliveries,
    responded: () => analytics.responses,
    unresponded: () =>
      analytics.sent_messages - analytics.failed_deliveries - analytics.responses,
    link_clicked: () => analytics.link_clicks,
    link_not_clicked: () => analytics.sent_messages - analytics.link_clicks,
    unsubscribed: () => analytics.unsubscribes,
    delivered_rate: () => analytics.delivery_rate,
    not_delivered_rate: () => 100 - analytics.delivery_rate,
    responded_rate: () => {
      if (analytics.responses === 0) {
        return 0;
      }
      return Math.round((analytics.responses / analytics.sent_messages) * 100);
    },
    unresponded_rate: () => {
      if (analytics.responses === 0) {
        return 100;
      }
      return Math.round(
        ((analytics.sent_messages - analytics.responses) / analytics.sent_messages) * 100
      );
    },
    link_clicked_rate: () => analytics.link_clicks_rate,
    link_not_clicked_rate: () => 100 - analytics.link_clicks_rate,
    unsubscribed_rate: () => analytics.unsubscribe_rate,
  };

  /**
   * This page is relying on the filter being applied to the campaign_contact search endpoint.
   * However, this varies slightly from the filter we need to send to the V2 Campaign Audience.
   * Therefore we do a translation here to get the correct filter.
   */
  function getContactsFilterItems(
    selectedContactIds: string[],
    currentTabFilter: string,
    isAllSelected: boolean,
    campaignId: string
  ): FilterItem[] {
    let filter: FilterItem[] = [];
    switch (currentTabFilter) {
      case 'all':
        filter = [
          {
            resource: 'campaign',
            column: 'id',
            comparison: '==',
            value: campaignId,
          },
        ];
        break;
      case 'delivered':
        filter = [
          {
            resource: 'campaign_message',
            column: 'delivery_status',
            comparison: 'in',
            value: ['delivery_unconfirmed', 'dlr_timeout', 'queued', 'delivered'],
            and: [
              {
                resource: 'campaign_message',
                column: 'campaign_id',
                comparison: '==',
                value: campaignId,
              },
            ],
          },
        ];
        break;
      case 'not_delivered':
        filter = [
          {
            resource: 'campaign_message',
            column: 'delivery_status',
            comparison: 'in',
            value: ['delivery_failed', 'sending_failed', 'failed'],
            and: [
              {
                resource: 'campaign_message',
                column: 'campaign_id',
                comparison: '==',
                value: campaignId,
              },
            ],
          },
        ];
        break;
      case 'responded':
        filter = [
          {
            and: [
              {
                column: 'id',
                comparison: '!=',
                resource: 'contact_message',
                value: '',
              },
            ],
            column: 'campaign_id',
            comparison: '==',
            resource: 'campaign_contact',
            value: campaignId,
          },
        ];
        break;
      case 'unresponded':
        filter = [
          {
            column: 'campaign_id',
            comparison: '==',
            resource: 'campaign_message',
            value: campaignId,
            and: [
              {
                column: 'delivery_status',
                comparison: 'in',
                resource: 'campaign_message',
                value: ['delivery_unconfirmed', 'dlr_timeout', 'queued', 'delivered'],
                and: [
                  {
                    resource: 'contact_message',
                    column: 'id',
                    comparison: '==',
                    value: null,
                  },
                ],
              },
            ],
          },
        ];
        break;
      case 'link_clicked':
        filter = linkClickedFilter;
        break;
      case 'link_not_clicked':
        filter = notClickedFilter;
        break;
      case 'unsubscribed':
        filter = unsubscribedFilter;
        break;
    }

    const scopedToCampaignFilter: FilterItem = {
      resource: 'campaign_contact',
      column: 'campaign_id',
      comparison: '==',
      value: campaignId,
    };

    const campaignContactsFilter = {
      resource: 'campaign_contact',
      column: 'id',
      comparison: 'in',
      value: selectedContactIds,
      and: [scopedToCampaignFilter],
    };

    if (isAllSelected) {
      if (
        currentTabFilter == 'all' ||
        currentTabFilter == 'delivered' ||
        currentTabFilter == 'not_delivered' ||
        currentTabFilter == 'responded' ||
        currentTabFilter == 'unresponded'
      )
        return filter;
      return [scopedToCampaignFilter];
    }
    return [campaignContactsFilter];
  }

  function combineFilter(
    campaignContactIds: string[],
    searchParam: SearchFilters,
    isAllSelected: boolean
  ): FilterParams {
    const filter = prepareFilter(
      searchParam,
      campaignContactIds,
      isAllSelected,
      'campaign_contact'
    );
    return { filter: filter };
  }

  function handleConfirm(
    message: string,
    attachments: Attachments,
    _selectedLocation: Channel,
    scheduleOptions: ScheduleOptions | null,
    includedAudienceFilter?: FilterItem[],
    excludedAudienceFilter?: FilterItem[]
  ) {
    if (!current || !current.id) return;
    createQuickCampaign({
      message,
      attachments,
      location: currentLocation,
      scheduleOptions,
      includedAudienceFilter,
      excludedAudienceFilter,
    });
    setQuickCampaignOpen(false);
  }

  const data = useMemo(() => campaignContacts, [campaignContacts]);

  const handleOnEndReached = () => {
    if (
      data.length < totalCampaignContacts &&
      !loadingContacts &&
      current &&
      current.id
    ) {
      updateCampaignContactsParams({
        ...campaignContactsParams,
        offset: campaignContactsParams.offset + ITEMS_COUNT,
      });
    }
  };

  const responseColumn =
    selectedTabFilter.key !== 'not_delivered'
      ? {
          Header: 'Response',
          accessor: 'contact_messages[0].body',
          colWidth: 350,
          Cell: (row: { value: string }) => (
            <MessagePreview>{row.value || '-'}</MessagePreview>
          ),
        }
      : {
          Header: 'Reason',
          accessor: 'campaign_message.error',
          colWidth: 350,
          Cell: (row: { value: string }) => (
            <MessagePreview css={{ color: 'red' }}>
              <HStack>
                <Box css={{ width: 14, mt: 1 }}>
                  <HiExclamationCircle />
                </Box>
                <Box css={ellipsis}>
                  {(i18next.t(row.value) as string) || 'Message failed to send'}
                </Box>
              </HStack>
            </MessagePreview>
          ),
        };

  const columns: ReactTableColumn[] = useMemo(
    () => [
      {
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <Flex align="center" justify="start">
            {/* Checkbox for selecting all rows */}
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            <span style={{ marginLeft: '8px' }}>Name</span>
          </Flex>
        ),
        accessor: 'contact.name',
        colWidth: 320,
        Cell: (props: any) => {
          const contact = props.row.original.contact;
          const locationId = getCurrentLocationId(current, channels) ?? '';
          return (
            <Flex gap={3} align="center" justify="between">
              <Flex align="center">
                <IndeterminateCheckbox {...props.row.getToggleRowSelectedProps()} />
                <Box css={{ ...ellipsis, width: 150, ml: 8 }}>{props.value || '-'}</Box>
              </Flex>
              <HStack gap="2">
                {locationId && (
                  <Box>
                    <ConversationDrawer
                      contact_id={contact.id}
                      contact_name={contact.name || '-'}
                      contact_phone={contact.phone || ''}
                      contact_email={contact.email || ''}
                      location_id={locationId || ''}
                    >
                      <IconButton>
                        <HiChat />
                      </IconButton>
                    </ConversationDrawer>
                  </Box>
                )}
                <ContactPanel
                  contact={props.row.original.contact}
                  deleteContact={deleteContact}
                >
                  <ToolTipIconButton
                    description="Edit Contact"
                    icon={<HiPencilAlt />}
                    size={1}
                  />
                </ContactPanel>
                <Box>
                  <ContactActionsMenu
                    contact={contact}
                    isBlocked={contact.blocked}
                    blockContact={blockContact}
                    deleteContact={deleteContact}
                  />
                </Box>
              </HStack>
            </Flex>
          );
        },
      },
      {
        Header: 'Phone',
        accessor: 'contact.phone',
        colWidth: 175,
        Cell: (row: { value: string }) => <Cell>{formatPhoneNumber(row.value)}</Cell>,
      },
      responseColumn,
      {
        Header: 'Responded At',
        accessor: 'contact_messages[0].inserted_at',
        colWidth: 300,
        Cell: (row: { value: string }) => <Cell>{formatDate(row.value)}</Cell>,
      },
    ],
    [data]
  );

  useEffect(() => {
    const currentChannel = getCurrentLocation(
      current,
      channels,
      preferences?.inbox?.preferred_location_id
    );
    setCurrentLocation(currentChannel);
    // load organization info to load the organization opt out setting
    getOrganizationInfo();
    updateCampaignContactsParams({
      ...initialCampaignsState.campaignContactsParams,
      sort: defaultSort,
    });
  }, [current?.id]);

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateCampaignContactsParams(props);
    }, 1000),
    []
  );

  return (
    <>
      <CampaignHeader />
      <Box css={{ backgroundColor: 'white' }}>
        <FilterTabs
          tabs={campaignContactsTabFilters}
          activeTab={selectedTabFilter}
          onTabChange={(value) => {
            setSelectedTabFilter(value);
            updateCampaignContactsParams({
              ...campaignContactsParams,
              defaultFilters: value.value.filter,
              offset: 0,
            });
          }}
          tabValueMappings={tabValueMappings}
        />
        <Box css={{ padding: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Contacts"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={(value: string) => {
              setQuickSearchValue(value);
              handleQuickSearch(debouncedUpdate, campaignContactsParams, value);
            }}
            defaultObjects={[
              default_contact_object,
              default_campaign_contact_object,
              default_campaign_message_object,
            ]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={(value: Array<FilterType>) => {
              setActiveFilters(value);
              handleFilterChange(debouncedUpdate, campaignContactsParams, value);
            }}
            sortConfig={campaignContactsSortConfig}
            activeSort={campaignContactsParams.sort}
            onSortUpdate={(value: Array<Sort>) =>
              handleSortUpdate(debouncedUpdate, campaignContactsParams, value)
            }
          />
        </Box>
        <FilteredTable
          isLoading={loadingContacts}
          columns={columns}
          bulkActions={bulkActions}
          data={data}
          totalCount={totalCampaignContacts}
          onEndReached={() => handleOnEndReached()}
          emptyStateElement={noContactsFound}
          tableRef={tableRef}
          heightOffset={325}
        />
        {isQuickCampaignOpen && current && current.id && (
          <QuickCampaignDialog
            campaignId={current.id}
            type={current.type ?? 'sms'}
            currentLocation={currentLocation}
            setLocation={setCurrentLocation}
            locations={channels}
            isOpen={isQuickCampaignOpen}
            showTimeZonePicker={false}
            setIsOpen={setQuickCampaignOpen}
            customDefaultObjects={[
              default_contact_object,
              {
                ...default_campaign_contact_object,
                custom_properties: [
                  ...default_campaign_contact_object.custom_properties,
                  {
                    id: 'campaign_contact-campaign-id',
                    label: 'Campaign ID',
                    type: 'campaign',
                    key: 'campaign_id',
                    required: true,
                    inserted_at: 'current-timestamp',
                    updated_at: 'current-timestamp',
                    custom_object_id: 'unique-id-for-campaign-contact-campaign-id',
                  },
                  {
                    id: 'campaign_contact-id',
                    label: 'ID',
                    type: 'id',
                    key: 'id',
                    required: true,
                    inserted_at: 'current-timestamp',
                    updated_at: 'current-timestamp',
                    custom_object_id: 'unique-id-for-campaign-contact-id',
                  },
                ],
              },
              default_campaign_message_object,
              {
                ...default_campaign_contact_message_object,
                custom_properties: [
                  ...default_campaign_contact_message_object.custom_properties,
                  {
                    default: '',
                    id: 'contact_message-message',
                    label: 'Message',
                    type: 'text',
                    key: 'id',
                    required: true,
                    inserted_at: 'current-timestamp',
                    updated_at: 'current-timestamp',
                    custom_object_id: 'unique-id-for-contact-message',
                  },
                ],
              },
            ]}
            // we need to convert this WQL from one compatible with Campaign Contacts
            // to one compatible with Contacts
            defaultIncludedAudienceFilter={getContactsFilterItems(
              selectedContactIds,
              selectedTabFilter.key,
              isAllSelected,
              current.id
            )}
            handleConfirm={handleConfirm}
            totalContacts={totalContactsSelected}
          />
        )}
        {current && current.id && isUnsubscribeContactsFromCampaignDialogOpen && (
          <UnsubscribeContactsFromCampaignModal
            onOpenChange={setUnsubscribeContactsFromCampaignDialogOpen}
            open={isUnsubscribeContactsFromCampaignDialogOpen}
            campaign={campaigns.campaignsState.current as Campaign}
            totalContacts={totalContactsSelected}
            filter={
              combineFilter(selectedContactIds, campaignContactsParams, isAllSelected)
                .filter ?? []
            }
            filterSource={['campaign', current.id]}
          />
        )}
        {current && current.id && (
          <>
            <AddToSequenceDialog
              isOpen={isAddToSequenceModalOpen}
              showTimeZonePicker={false}
              locations={channels}
              totalContacts={totalContactsSelected}
              setIsOpen={setAddToSequenceModalOpen}
              filterSource={['campaign', current.id]}
              filter={
                combineFilter(
                  selectedContactIds,
                  campaignContactsParams,
                  isAllSelected
                ) as SequenceBulkActionFilter
              }
            />
            <BulkTagModal
              action={tagModalAction}
              filterSource={['campaign', current.id]}
              open={isBulkTagModalOpen}
              totalContacts={totalContactsSelected}
              onOpenChange={setBulkTagModalOpen}
              filter={
                combineFilter(selectedContactIds, campaignContactsParams, isAllSelected)
                  .filter ?? []
              }
            />
            <RemoveFromSequenceModal
              open={isRemoveFromSequenceModalOpen}
              totalContacts={totalContactsSelected}
              onOpenChange={setRemoveFromSequenceModalOpen}
              filter={
                combineFilter(selectedContactIds, campaignContactsParams, isAllSelected)
                  .filter ?? []
              }
              filterSource={['campaign', current.id]}
            />
          </>
        )}
      </Box>
    </>
  );
};

const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Cell = styled(Flex, {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  borderBottom: '1px solid $gray300',
});

const MessagePreview = styled(Flex, {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});
