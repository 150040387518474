import { Call } from '@twilio/voice-sdk';
import React from 'react';

import { CallStatusTypes, CallType } from '@/shared/types/voip';

import { useVoIP } from './context/VoIPContext';
import { VoIPCall } from './VoIPCall';
import { VoIPIncomingCall } from './VoIPIncomingCall';
import { VoIPOutgoingCall } from './VoIPOutgoingCall';

export const VoIP = () => {
  const voip = useVoIP();
  const { incomingCalls, calls, callStatus } = voip.voipState;

  return (
    <>
      {incomingCalls?.map((call: CallType) => (
        <VoIPIncomingCall call={call?.call as Call} key={call?.callSid} />
      ))}
      {calls?.map((call: CallType) => <VoIPCall data={call} key={call?.callSid} />)}
      {callStatus === CallStatusTypes.NEW_OUTGOING && <VoIPOutgoingCall />}
    </>
  );
};
