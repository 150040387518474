import { Formik } from 'formik';
import React from 'react';
import { HiPencil, HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { EstimatedSavingsData } from '@/shared/types';
import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  IconButton,
} from '@/shared/ui';

export const EstimatedSavingsDialog = ({
  estimatedSavingsData,
  setEstimatedSavingsData,
}: {
  estimatedSavingsData: EstimatedSavingsData;
  setEstimatedSavingsData: (data: EstimatedSavingsData) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const shouldDisableSubmitBtn = false;

  // handle create team
  const handleSubmit = (
    values: {
      timePerCompleteCall: number | null;
      timePerIncompleteCall: number | null;
      rate: number | null;
    },
    actions: any
  ) => {
    try {
      setEstimatedSavingsData(values);
      actions.resetForm({
        values: {
          timePerCompleteCall: null,
          timePerIncompleteCall: null,
          rate: null,
        },
      });
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => !isOpen}>
      <DialogTrigger asChild>
        <IconButton onClick={onOpen}>
          <HiPencil />{' '}
        </IconButton>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <DialogContent onEscapeKeyDown={onClose} onPointerDownOutside={onClose}>
            <DialogTitle variant="bold" css={{ marginBottom: 8 }}>
              Estimated Savings
            </DialogTitle>
            <Formik
              initialValues={estimatedSavingsData}
              validationSchema={Yup.object({
                timePerCompleteCall: Yup.number().required('Required'),
                timePerIncompleteCall: Yup.number().required('Required'),
                rate: Yup.number().required('Required'),
              })}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} data-testid="create-team-form">
                  <FormFieldWrapper
                    label="Time Per Complete Call"
                    name="timePerCompleteCall"
                  >
                    <TextInput type="number" />
                  </FormFieldWrapper>
                  <FormFieldWrapper
                    label="Time Per Incomplete Call"
                    name="timePerIncompleteCall"
                  >
                    <TextInput type="number" />
                  </FormFieldWrapper>
                  <FormFieldWrapper label="Hourly Rate" name="rate">
                    <TextInput type="number" />
                  </FormFieldWrapper>
                  <DialogFooter justify="end" css={{ mt: 15 }}>
                    <DialogClose asChild>
                      <Button variant="grayBackground" css={{ mr: 8 }} onClick={onClose}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        disabled={shouldDisableSubmitBtn || !formik.isValid}
                      >
                        Save
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </form>
              )}
            </Formik>
            <DialogClose asChild>
              <DialogCloseIcon onClick={onClose} size="2">
                <HiX />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
