import { TooltipArrow, TooltipTrigger } from '@radix-ui/react-tooltip';
import EmojiPicker from 'emoji-picker-react';
import i18next from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';
import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import {
  HiLightningBolt,
  HiOutlineEmojiHappy,
  HiPaperAirplane,
  HiPencil,
  HiStar,
  HiTemplate,
  HiVariable,
} from 'react-icons/hi';
import { PiSignature } from 'react-icons/pi';
import { useDrop } from 'react-use';
import { toast } from 'sonner';

import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { useSignatures } from '@/pages/settings/organization/signatures/context/SignaturesContext';
import { AttachmentButton, AttachmentIconButton } from '@/shared/components/attachments';
import { EditorPopover } from '@/shared/components/editor/EditorPopover';
import { EditorToolbarButton } from '@/shared/components/editor/EditorToolbar';
import { PipeCombobox } from '@/shared/components/editor/Pipes';
import { ReviewTemplates } from '@/shared/components/editor/ReviewTemplates';
import { SignatureCombobox } from '@/shared/components/editor/Signatures';
import { StyledSeparator } from '@/shared/components/editor/Styles';
import { TemplateCombobox } from '@/shared/components/editor/Templates';
import {
  ICON_SIZE,
  ScheduleParams,
  USE_ICON_BUTTONS_AT,
} from '@/shared/components/editor/v2';
import { useDisclosure } from '@/shared/hooks';
import { Signature } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { NewMessageParams } from '@/shared/types/conversations';
import { Template, TemplateContentType } from '@/shared/types/templates';
import { Box, Button, Flex, IconButton, Tooltip, TooltipContent } from '@/shared/ui';

import {
  directUpload,
  readFileHeader,
  returnFileNameBasedOnFileType,
  returnFileType,
} from '../../attachments/utils';
import { MessageEditorToolbarContainer } from '../Styles';
import { AIBox } from './AIBox';
import { Attachments, EmojiData, FileUpload, PipeData } from './constants';
import { useFocusedContext } from './context/FocusedContext';
import { ToolbarErrorBoundary } from './ErrorBoundaries';
import { MessageSegmentPopover } from './MessageSegmentVisualizer';
import { ScheduleMessageV2 } from './ScheduleMessage';
import TranslateMessageV2 from './TranslateMessage';
import {
  EDITOR_MAX_WIDTH_OVERFLOW,
  handleTransformDataTransferIntoBlob,
  SuggestedItem,
} from './utils';

export type MessageToolbarProps = {
  // Editor State Props
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  scheduleParams: ScheduleParams | undefined;
  setScheduleParams?: Dispatch<SetStateAction<ScheduleParams>>;
  attachments?: Attachments;
  setAttachments?: Dispatch<SetStateAction<Attachments>>;
  attachmentLoading: boolean | undefined;
  setAttachmentLoading?: Dispatch<SetStateAction<boolean>>;

  // UI Feature Toggles (default to false if optional)
  showAddAttachment: boolean | undefined;
  showAddTemplate: boolean | undefined;
  showAddReview: boolean | undefined;
  showAddVariable: boolean | undefined;
  showAddEmoji: boolean | undefined;
  showAddTranslate: boolean | undefined;
  showAddSchedule: boolean | undefined;
  showAddSignature: boolean | undefined;
  showCharacterCount: boolean | undefined;
  showSendButton: boolean | undefined;
  showSentenceGenerator?: boolean;

  channel_type?: 'phone' | 'email' | 'whatsapp';

  // Contextual Information and Other Props
  editorWidth: number | undefined;
  anchorDimensions: {
    width: number;
    height: number;
  };
  updateAnchorDimensions: () => void;
  onTemplateClick: (template: Template | undefined | null) => void;
  onReviewInviteClick: () => void;
  onPipeClick: (selectedPipe: PipeData) => void;
  onEmojiClick: (selectedEmoji: EmojiData) => void;
  onSignatureClick: (signature: Signature) => void;
  onAIOptionClick: (option: SuggestedItem) => void;
  handleScheduledSend: () => void;
  setSignature?: Dispatch<SetStateAction<Signature | null>>;
  signature: Signature | null | undefined;
  location?: Channel;
  isNote?: boolean;
  source: string | undefined; // source of the editor eg mainEditor, updateTemplate etc. Used for putting ID on upload files. This prevents rendering issues with React mounting as the Upload component has the same ID
  emojiButton: RefObject<HTMLButtonElement>;
  templateButton: RefObject<HTMLDivElement>;
  reviewButton: RefObject<HTMLDivElement>;
  aiBoxButton: RefObject<HTMLButtonElement>;
  textareaRef: RefObject<HTMLTextAreaElement>;
  characterCount: number;

  // Action Override related props
  sendButtonCopy: string | undefined;
  sendMessageAction?: () => void; // overwrite default for sending message
  scheduleSendAction?: (message: NewMessageParams, params: ScheduleParams) => void; // overwrite default for sending scheduled message
  translateMessageAction?: (
    originalMessage: string,
    translatedMessage: string,
    attachmentUrls: string[]
  ) => void; // this is an overwrite function, if exists, it will be called instead of local implementation of translate
  sendMessageFromTranslationDialog?: boolean;
  isReviewResponse?: boolean;
  isInbox?: boolean;
};
// MessageToolbar | SignatureCombobox
export const MessageToolbarV2 = (props: MessageToolbarProps) => {
  const {
    showAddAttachment,
    showAddTemplate,
    showAddReview,
    showAddVariable,
    showAddEmoji,
    showAddTranslate,
    editorWidth,
    anchorDimensions,
    showCharacterCount,
    signature,
    message,
    setMessage,
    setSignature,
    updateAnchorDimensions,
    onTemplateClick,
    onPipeClick,
    onEmojiClick,
    onSignatureClick,
    showSentenceGenerator,
    showAddSchedule,
    showAddSignature,
    scheduleParams,
    setScheduleParams,
    handleScheduledSend,
    showSendButton,
    attachmentLoading,
    attachments,
    setAttachments,
    setAttachmentLoading,
    emojiButton,
    templateButton,
    reviewButton,
    aiBoxButton,
    textareaRef,
    sendMessageAction,
    translateMessageAction,
    location,
    source,
    channel_type = 'phone',
    isReviewResponse,
    isInbox,
  } = props;

  const { onClose, onOpen } = useDisclosure();
  const {
    isNote,
    setTemplatesModalActive,
    setAiModalActive,
    setEmojiModalActive,
    setReviewRequestTemplatesModalActive,
  } = useFocusedContext();

  const { aiSentenceGenerator } = useFlags();

  const signaturesContext = useSignatures();
  const { signaturesState, getSignature, getDefaultSignature } = signaturesContext;
  const { defaultUserSignatureId } = signaturesState;

  // add a delay in milliseconds
  const sleep = (ms: number | undefined) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onAttachmentClick = async (files: Array<File>) => {
    // check if attachments meet max allowed count
    if (files.length > 10) {
      return toast.error(i18next.t('max_attachments') as string);
    }

    if (!setAttachmentLoading) return;
    setAttachmentLoading(true);

    // for each file read the file header to determine the file type and create a file with the correct extension and mime type
    files = await Promise.all(
      files.map(async (file: File) => {
        const header = await readFileHeader(file);

        const newFile = new File(
          [file as BlobPart],
          returnFileNameBasedOnFileType(returnFileType(header, file), file.name) ||
            'pasted-image.jpeg',
          {
            type: returnFileType(header, file),
          }
        );
        return newFile;
      })
    );

    // check if attachments meet maximum allowed size
    const combinedSize = files.reduce((acc, file: File) => (acc += file.size), 0);

    if (combinedSize >= 5000000) {
      return toast.error(i18next.t('file_size_limit') as string);
    }

    try {
      const uploadResponses: FileUpload[] = await directUpload(files);

      const handleUploads = uploadResponses.map(async (uploadResponse: FileUpload) => {
        // There is a lambda function that resizes JPEG and JPG images once they are uploaded
        // to S3, so this timeout is necessary to wait for the lambda function to finish
        if (['jpeg', 'jpg'].includes(uploadResponse.file_extension)) await sleep(2000);

        if (!setAttachments) return;

        setAttachments((prevAttachments: Attachments) => {
          return {
            ...{
              attachment_urls: [
                ...(prevAttachments?.attachment_urls || []),
                uploadResponse.public_url,
              ],
            },
          };
        });
      });

      // UX improved by waiting for all image uploads to complete (awaiting all upload promises)
      // before updating the state and showing the success message
      toast.promise(Promise.all(handleUploads), {
        loading: i18next.t('upload_loading') as string,
        success() {
          setAttachmentLoading(false);
          return i18next.t('upload_success') as string;
        },
        error: i18next.t('upload_error') as string,
      });
    } catch (error) {
      toast.error(i18next.t('upload_error') as string);
      console.error('Some uploads failed:', error);
    }
  };

  useEffect(() => {
    // On mount, if there is no default signature, to fetch it
    if (!defaultUserSignatureId) {
      getDefaultSignature();
    }
  }, []);

  useEffect(() => {
    // When defaultUserSignatureId changes, fetch the signature and set it
    getAndSetUserDefaultSignature();
  }, [defaultUserSignatureId]);

  const getAndSetUserDefaultSignature = () => {
    if (defaultUserSignatureId) {
      // Get the default signature from the context
      getSignature(defaultUserSignatureId).then(
        (defaultSignature: Signature | null | undefined) => {
          // Set it to be rendered
          if (setSignature) setSignature(defaultSignature as Signature);
        }
      );
    }
  };

  // lets the user drop files onto the screen to upload attachments
  useDrop({
    onFiles: (files) => onAttachmentClick(files),
  });

  // let users paste files
  useEffect(() => {
    // add event listener for pasting an image
    const handlePasteOnDocument = async (e: ClipboardEvent) => {
      if (e.clipboardData) {
        const blob = handleTransformDataTransferIntoBlob(e.clipboardData);

        if (blob) {
          const file = new File([blob as BlobPart], blob.name);

          onAttachmentClick([file]);

          // prevent the name of the file from being pasted into the editor
          e.preventDefault();
        }
      }
    };

    document.addEventListener('paste', handlePasteOnDocument);

    return () => {
      document.removeEventListener('paste', handlePasteOnDocument);
    };
  });

  const {
    settingsState: { settings },
  } = useSettings();

  return (
    <>
      <MessageEditorToolbarContainer>
        <Flex align="center">
          {showAddAttachment && (
            <Box>
              <ToolbarErrorBoundary feature="attachment">
                {editorWidth && editorWidth < USE_ICON_BUTTONS_AT ? (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <AttachmentIconButton
                        source={source || 'source'}
                        iconsize={ICON_SIZE}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onAttachmentClick(Object.values(e.target.files || ''))
                        }
                      />
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      {'Attachments'}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                ) : (
                  <AttachmentButton
                    iconsize={ICON_SIZE}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onAttachmentClick(Object.values(e.target.files || ''))
                    }
                  />
                )}
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showAddTemplate && (
            <ToolbarErrorBoundary feature="template">
              <EditorPopover
                anchorDimensions={anchorDimensions}
                updateAnchorDimensions={updateAnchorDimensions}
                intercomId={'template-popover-button'}
              >
                <EditorToolbarButton
                  width={editorWidth || 0}
                  buttonIcon={<HiTemplate size={ICON_SIZE} />}
                  buttonCopy="Templates"
                  ref={templateButton}
                  onClick={() => {
                    setTemplatesModalActive(true);
                  }}
                />
                {isReviewResponse ? (
                  <ReviewTemplates
                    anchorDimensions={anchorDimensions}
                    onClose={onClose}
                    updateMessageFromTemplate={debounce(onTemplateClick, 10)}
                    type={TemplateContentType.REVIEW_RESPONSE}
                    isWhatsAppTemplate={channel_type === 'whatsapp'}
                  />
                ) : (
                  <TemplateCombobox
                    anchorDimensions={anchorDimensions}
                    onClose={onClose}
                    updateMessageFromTemplate={debounce(onTemplateClick, 10)}
                    isInbox={isInbox}
                    isWhatsAppTemplate={channel_type === 'whatsapp'}
                  />
                )}
              </EditorPopover>
            </ToolbarErrorBoundary>
          )}

          {showCharacterCount && channel_type === 'phone' && (
            <ToolbarErrorBoundary feature="character_count">
              <StyledSeparator
                decorative
                orientation="vertical"
                css={{ mx: '10px', height: 10 }}
              />
              <MessageSegmentPopover
                message={message}
                setMessage={setMessage}
                isNote={isNote}
              />
            </ToolbarErrorBoundary>
          )}
        </Flex>

        <Flex align="center" justify="end" wrap="wrap" css={{ flex: 1 }}>
          {aiSentenceGenerator && showSentenceGenerator && (
            <Box css={{ ml: 5 }}>
              <ToolbarErrorBoundary feature={'aiBox'}>
                <EditorPopover
                  anchorDimensions={anchorDimensions}
                  updateAnchorDimensions={updateAnchorDimensions}
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <IconButton
                        size={2}
                        type="button"
                        onClick={() => {
                          setAiModalActive(true);
                        }}
                        ref={aiBoxButton}
                      >
                        <HiLightningBolt
                          className="animated-text"
                          type="button"
                          size={ICON_SIZE}
                        />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      {'AI Generator'}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                  <AIBox
                    anchorDimensions={anchorDimensions}
                    onOpen={onOpen}
                    onClose={onClose}
                    message={message}
                    setMessage={setMessage}
                    onAIOptionClick={props.onAIOptionClick}
                  />
                </EditorPopover>
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showAddReview && location?.google_place_id && (
            <ToolbarErrorBoundary feature="review_link">
              <EditorPopover
                anchorDimensions={anchorDimensions}
                updateAnchorDimensions={updateAnchorDimensions}
                intercomId={'review-request-template-popover-button'}
              >
                <EditorToolbarButton
                  width={0}
                  buttonIcon={<HiStar size={ICON_SIZE} />}
                  buttonCopy="Review Requests"
                  ref={reviewButton}
                  onClick={() => {
                    setReviewRequestTemplatesModalActive(true);
                  }}
                  dataTestId={'review-request-template-button'}
                />
                <ReviewTemplates
                  anchorDimensions={anchorDimensions}
                  onClose={onClose}
                  updateMessageFromTemplate={debounce(onTemplateClick, 10)}
                  isInbox={isInbox}
                />
              </EditorPopover>
            </ToolbarErrorBoundary>
          )}

          {showAddVariable && (
            <Box css={{ ml: 5 }}>
              <ToolbarErrorBoundary feature={'variable'}>
                <EditorPopover
                  anchorDimensions={anchorDimensions}
                  updateAnchorDimensions={updateAnchorDimensions}
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <IconButton
                        size={2}
                        type="button"
                        aria-label="message-variable-button"
                      >
                        <HiVariable title="Pipe Picker" size={ICON_SIZE} />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      {'Add Message Variable'}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                  <PipeCombobox
                    anchorDimensions={anchorDimensions}
                    addPipeField={debounce(onPipeClick, 10)}
                    onClose={onClose}
                  />
                </EditorPopover>
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showAddEmoji && settings?.sms_emoji_enabled && (
            <Box css={{ ml: 5 }}>
              <ToolbarErrorBoundary feature={'emoji'}>
                <EditorPopover
                  anchorDimensions={anchorDimensions}
                  updateAnchorDimensions={updateAnchorDimensions}
                  textareaRef={textareaRef}
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <IconButton
                        size={2}
                        type="button"
                        ref={emojiButton}
                        onClick={() => setEmojiModalActive(true)}
                      >
                        <HiOutlineEmojiHappy
                          title="Emoji Picker"
                          type="button"
                          size={ICON_SIZE}
                        />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent side="top">
                      {'Add Emoji'}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                  <EmojiPicker
                    onEmojiClick={onEmojiClick}
                    width={editorWidth || 0}
                    height={300}
                    previewConfig={{
                      showPreview: false,
                    }}
                    autoFocusSearch={true}
                  />
                </EditorPopover>
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showAddTranslate && (
            <Box css={{ ml: 5 }}>
              <ToolbarErrorBoundary feature={'translate'}>
                <TranslateMessageV2
                  message={message}
                  setMessage={setMessage}
                  sendMessageFromTranslationDialog={
                    props.sendMessageFromTranslationDialog ?? true
                  }
                  translateMessageAction={translateMessageAction}
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showAddSchedule && scheduleParams && setScheduleParams && (
            <Box css={{ ml: 5 }}>
              <ToolbarErrorBoundary feature={'schedule'}>
                <ScheduleMessageV2
                  attachments={attachments}
                  setAttachments={setAttachments}
                  scheduleParams={scheduleParams}
                  setScheduleParams={setScheduleParams}
                  sendScheduledMessage={
                    props.scheduleSendAction
                      ? props.scheduleSendAction
                      : handleScheduledSend
                  }
                  disableSendScheduledMessage={false}
                  message={message}
                  setMessage={setMessage}
                />
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showAddSignature && (
            <Box css={{ ml: 5 }}>
              <ToolbarErrorBoundary feature={'signature'}>
                {signature ? (
                  <>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <IconButton
                          name="signature"
                          size={2}
                          type="button"
                          data-testid={'signature-button'}
                          css={{
                            backgroundColor: '$slate4',
                          }}
                          onClick={() => {
                            if (signature) {
                              onSignatureClick(signature);
                            }
                          }}
                        >
                          <PiSignature size={ICON_SIZE} />
                        </IconButton>
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        {`${signature.name} Signature Enabled`}
                        <TooltipArrow />
                      </TooltipContent>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <EditorPopover
                      anchorDimensions={anchorDimensions}
                      updateAnchorDimensions={updateAnchorDimensions}
                      intercomId={'signature-popover-button'}
                      data-testid={'editor-popover'}
                    >
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <IconButton
                            size={2}
                            type="button"
                            data-testid={'enable-signature-button'}
                            css={{
                              backgroundColor: 'transparent',
                            }}
                          >
                            <PiSignature type="button" size={ICON_SIZE} />
                          </IconButton>
                        </TooltipTrigger>
                        <TooltipContent side="top">
                          Click to Enable
                          <TooltipArrow />
                        </TooltipContent>
                      </Tooltip>
                      <SignatureCombobox
                        anchorDimensions={anchorDimensions}
                        onClose={onClose}
                        updateMessageFromSignature={debounce(onSignatureClick, 10)}
                        data-testid={'signature-combobox'}
                      />
                    </EditorPopover>
                  </>
                )}
              </ToolbarErrorBoundary>
            </Box>
          )}

          {showSendButton && (
            <Box css={{ ml: 9 }}>
              <ToolbarErrorBoundary feature={'send_message'}>
                <Button
                  data-testid="send-button"
                  aria-label="send-button"
                  variant="send"
                  disabled={attachmentLoading ? true : false}
                  onClick={() => {
                    if (sendMessageAction) sendMessageAction();
                  }}
                  style={{
                    backgroundColor: '$primaryButtonColor',
                    padding:
                      window.innerWidth < EDITOR_MAX_WIDTH_OVERFLOW
                        ? '0 9px 0 0'
                        : undefined,
                  }}
                  data-intercom-target="conversation-send-button"
                >
                  {/* If button is at the edge of Toolbar, remove the text and just show the icon */}
                  {window.innerWidth > EDITOR_MAX_WIDTH_OVERFLOW &&
                    (props?.sendButtonCopy || 'Send')}
                  {isNote && window.innerWidth <= EDITOR_MAX_WIDTH_OVERFLOW && (
                    <HiPencil size={ICON_SIZE} />
                  )}
                  {!isNote && (
                    <HiPaperAirplane
                      style={{ transform: 'rotate(90deg)' }}
                      size={ICON_SIZE}
                    />
                  )}
                </Button>
              </ToolbarErrorBoundary>
            </Box>
          )}
        </Flex>
      </MessageEditorToolbarContainer>
    </>
  );
};
