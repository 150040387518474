import { useEffect, useRef, useState } from 'react';

import type { Message } from '@/shared/api/ai';
import { createChatCompletion } from '@/shared/api/ai';

export const useAgentChat = (prompt: string) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [userInput, setUserInput] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (prompt) {
      setMessages([{ role: 'system', content: prompt }]);
    }
  }, [prompt]);

  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    const newMessage = { role: 'user', content: userInput };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setUserInput('');

    try {
      const response = await createChatCompletion({
        model: 'gpt-4o-mini',
        options: {
          frequency_penalty: 0,
          max_tokens: 150,
          n: 1,
          presence_penalty: 0,
          temperature: 0.5,
          top_p: 1,
        },
        messages: updatedMessages,
      });
      setMessages([
        ...updatedMessages,
        { role: 'assistant', content: response.response },
      ]);
    } catch (error) {
      console.error('Failed to get chat completion:', error);
      setMessages((prev) => [
        ...prev,
        { role: 'assistant', content: 'Error: Failed to get response from agent' },
      ]);
    }
  };

  const handleReset = () => {
    setMessages(prompt ? [{ role: 'system', content: prompt }] : []);
    setUserInput('');
  };

  const scrollToBottom = () => {
    if (typeof window !== 'undefined' && messagesEndRef.current?.scrollIntoView) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return {
    messages,
    userInput,
    setUserInput,
    handleSendMessage,
    handleReset,
    messagesEndRef,
  };
};
