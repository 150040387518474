import { useCallback } from 'react';
import { toast } from 'sonner';

import { CallSettings as CallSettingsType } from '@/shared/types/channels';
import {
  Box,
  Fieldset,
  Flex,
  Label,
  Switch,
  SwitchThumb,
  Text,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { useSettings } from './context/SettingsContext';

export const CallSettings = ({ callSettings }: { callSettings: CallSettingsType }) => {
  const { updateSettings } = useSettings();

  const handleCallSettingsUpdate = useCallback(
    (field: keyof CallSettingsType) => async (value: boolean) => {
      try {
        await updateSettings({
          settings: {
            call: {
              ...callSettings,
              [field]: value,
            },
          },
        });
        toast.success(i18next.t('organization_call_settings_update_success') as string);
      } catch (err) {
        toast.error(i18next.t('organization_call_settings_update_failure') as string);
      }
    },
    [callSettings]
  );

  return (
    <VStack
      css={{
        mt: '$3',
      }}
    >
      <Fieldset css={{ mb: 24 }}>
        <VStack gap={3}>
          <Box>
            <Label>Organization Call Settings</Label>
            <Text>Update call settings for your entire organization.</Text>
          </Box>
          <Flex
            align="center"
            justify="between"
            data-testid="organization-call-conference-toggle"
          >
            <Label>Conferences</Label>
            <Switch
              checked={callSettings?.conferences}
              onCheckedChange={handleCallSettingsUpdate('conferences')}
            >
              <SwitchThumb />
            </Switch>
          </Flex>
          <Flex
            align="center"
            justify="between"
            data-testid="organization-call-recording-toggle"
          >
            <Label>Recordings</Label>
            <Switch
              checked={callSettings?.recordings}
              onCheckedChange={handleCallSettingsUpdate('recordings')}
            >
              <SwitchThumb />
            </Switch>
          </Flex>
          <Flex
            align="center"
            justify="between"
            data-testid="organization-call-transcription-toggle"
          >
            <Label>Transcriptions</Label>
            <Switch
              checked={callSettings?.transcriptions}
              onCheckedChange={handleCallSettingsUpdate('transcriptions')}
            >
              <SwitchThumb />
            </Switch>
          </Flex>
        </VStack>
      </Fieldset>
    </VStack>
  );
};
