import React from 'react';
import { HiPaperAirplane, HiRefresh } from 'react-icons/hi';
import { HiArrowDown } from 'react-icons/hi2';

import { StyledMessage } from '@/pages/inbox/conversation/items';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Box, Flex, HStack, IconButton, Input, VStack } from '@/shared/ui';

import { useAgentChat } from '../hooks/useAgentChat';

interface ChatComponentProps {
  prompt: string;
}

export const TestAgentPanel: React.FC<ChatComponentProps> = ({ prompt }) => {
  const {
    messages,
    userInput,
    setUserInput,
    handleSendMessage,
    handleReset,
    messagesEndRef,
  } = useAgentChat(prompt);

  return (
    <Flex
      direction="column"
      css={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        backgroundColor: 'white',
        borderColor: 'rgba(232, 232, 232, 1)',
        border: 1,
        flexGrow: 1,
      }}
      data-testid="test-agent-panel"
    >
      <VStack
        css={{
          overflowY: 'auto',
          width: '100%',
          px: 30,
          flexGrow: 1,
        }}
        data-testid="messages-container"
      >
        {messages.filter((msg) => msg.role !== 'system').length === 0 && (
          <Flex
            css={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-testid="empty-state"
          >
            <VStack align="center">
              <Box>Enter a message to test your agent</Box>
              <Box>
                <HiArrowDown />
              </Box>
            </VStack>
          </Flex>
        )}
        {messages.map(
          (msg, index) =>
            msg.role !== 'system' && (
              <Flex
                key={index}
                align="center"
                justify={msg.role === 'user' ? 'end' : 'start'}
                css={{
                  margin: '20px 0',
                }}
                data-testid={`message-${msg.role}-${index}`}
              >
                <StyledMessage
                  direction={msg.role === 'user' ? 'outbound_sms' : 'inbound_sms'}
                >
                  {renderText(msg.content)}
                </StyledMessage>
              </Flex>
            )
        )}
        <div ref={messagesEndRef} />
      </VStack>
      <HStack
        css={{
          padding: '10px',
          borderTop: '1px solid rgba(232, 232, 232, 1)',
        }}
        data-testid="input-container"
      >
        <IconButton
          onClick={handleReset}
          variant="outline"
          size="2"
          data-testid="reset-button"
        >
          <HiRefresh />
        </IconButton>
        <Input
          placeholder="Type your message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          data-testid="message-input"
        />
        <IconButton onClick={handleSendMessage} variant="send" data-testid="send-button">
          <HiPaperAirplane style={{ transform: 'rotate(90deg)' }} />
        </IconButton>
      </HStack>
    </Flex>
  );
};
