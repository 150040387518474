import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

import { reportAnalytics, ReportData } from '@/shared/api/reports';
import { DatepickerDates } from '@/shared/components/datepicker/Datepicker';
import { Box, Flex, VStack } from '@/shared/ui';

import { AgentsAnalytics } from './AgentsAnalytics';
import { AnalyticFilterItem, AnalyticFilters } from './AnalyticFilters';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

export const AgentAnalytics = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Array<ReportData>>([]);
  const [reportBar, setReportBar] = useState<any>([]);
  const [stackedReportBar, setStackedReportBar] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<AnalyticFilterItem>>([]);
  const [dates, setDates] = useState<DatepickerDates>(initialDates);

  useEffect(() => {
    fetchReports(id, filters, dates);
  }, [id, filters, dates]);

  const fetchReports = async (
    id: string,
    filters: Array<AnalyticFilterItem>,
    dates: DatepickerDates
  ) => {
    try {
      setIsLoading(true);
      const data = await reportAnalytics(
        [
          {
            column: 'agent_id',
            comparison: 'in',
            value: [id],
          },
          ...filters,
        ],
        dates,
        'agents'
      );
      setData(data);
      setReportBar(
        data
          .find((d) => d.chart === 'bar' && d.label === 'Calls by Day')
          ?.values.map((d) => {
            return { key: d.y, count: d.x };
          })
      );

      const positive = data
        .find((d) => d.chart === 'bar' && d.label === 'Stacked Bar Chart Positive')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      const negative = data
        .find((d) => d.chart === 'bar' && d.label === 'Stacked Bar Chart Negative')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      const neutral = data
        .find((d) => d.chart === 'bar' && d.label === 'Stacked Bar Chart Neutral')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      setStackedReportBar(
        positive?.map((d, index) => {
          return {
            key: d.key,
            positive: d.count,
            negative: negative?.[index].count,
            neutral: neutral?.[index].count,
          };
        })
      );
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Flex
      data-testid="agent-analytics"
      direction="column"
      css={{ p: 24, minWidth: 'auto' }}
    >
      <VStack gap={4}>
        <Box>
          <AnalyticFilters
            filters={filters}
            setFilters={setFilters}
            dates={dates}
            setDates={setDates}
          />
        </Box>
        <AgentsAnalytics
          id={id}
          data={data}
          stackedReportBar={stackedReportBar}
          reportBar={reportBar}
          isLoading={isLoading}
        />
      </VStack>
    </Flex>
  );
};
