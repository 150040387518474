import { useCallback } from 'react';

import {
  CallSettings as CallSettingsType,
  ChannelSettings,
} from '@/shared/types/channels';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Divider,
  Flex,
  Label,
  Switch,
  SwitchThumb,
  Text,
  VStack,
} from '@/shared/ui';

import { defaultChannelSettings, useChannels } from '../context/ChannelContext';

export const CallSettings = () => {
  const {
    channelsState: { current },
    updateChannel,
  } = useChannels();

  const handleCallSettingsUpdate = useCallback(
    (field: keyof CallSettingsType) => async (value: boolean) => {
      // If no current channel or its id, exit the function early
      if (!current || !current.id) {
        return;
      }
      await updateChannel({
        ...current,
        settings: {
          ...(current?.settings || defaultChannelSettings),
          call: {
            ...(current?.settings?.call || defaultChannelSettings.call),
            [field]: value,
          },
        } as ChannelSettings,
      });
    },
    [current]
  );

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="channel-call-settings"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Call Settings</Text>
            <Text>Update call settings that apply to this channel only.</Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <VStack gap={2}>
            <Flex
              align="center"
              justify="between"
              data-testid="channel-call-conference-toggle"
            >
              <Label>Conferences</Label>
              <Switch
                checked={current?.settings?.call?.conferences}
                onCheckedChange={handleCallSettingsUpdate('conferences')}
              >
                <SwitchThumb />
              </Switch>
            </Flex>
            <Flex
              align="center"
              justify="between"
              data-testid="channel-call-recording-toggle"
            >
              <Label>Recordings</Label>
              <Switch
                checked={current?.settings?.call?.recordings}
                onCheckedChange={handleCallSettingsUpdate('recordings')}
              >
                <SwitchThumb />
              </Switch>
            </Flex>
            <Flex
              align="center"
              justify="between"
              data-testid="channel-call-transcription-toggle"
            >
              <Label>Transcriptions</Label>
              <Switch
                checked={current?.settings?.call?.transcriptions}
                onCheckedChange={handleCallSettingsUpdate('transcriptions')}
              >
                <SwitchThumb />
              </Switch>
            </Flex>
          </VStack>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
