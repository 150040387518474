import * as SelectPrimitive from '@radix-ui/react-select';
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { useParams } from 'react-router-dom';

import { CampaignAccordion } from '@/pages/campaigns/create/CampaignAccordion';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import {
  Agent,
  AgentAccordionValue,
  AgentLanguage,
  agentLanguageOptions,
  AgentStatus,
  RetellProviderSettings,
} from '@/shared/types/ai/agents';
import { LLM, Model, Provider } from '@/shared/types/ai/llms';
import { AccordionValue } from '@/shared/types/campaigns';
import {
  Accordion,
  Box,
  Button,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Slider,
  SliderRange,
  SliderThumb,
  SliderTrack,
  Switch,
  SwitchThumb,
  Text,
  TextArea,
  VStack,
} from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

import { useLLMAgent } from '../context/AgentsContext';

export const EditAgent = () => {
  const agents = useLLMAgent();
  const { getAgent, getLLM, updateLLM, updateAgent, setCurrentAgent, setCurrentLLM } =
    agents;
  const { currentAgent, currentLLM } = agents.state;

  const params = useParams<{ id: string }>();
  const { id } = params;

  const [agentName, setAgentName] = useState('');
  const [agentDescription, setAgentDescription] = useState('');
  const [agentSettings, setAgentSettings] = useState<RetellProviderSettings>();

  const [prompt, setPrompt] = useState('');
  const [beginMessage, setBeginMessage] = useState('');

  useEffect(() => {
    fetchAndSetAgent(id);
  }, [id]);

  useEffect(() => {
    if (currentAgent?.current_version?.llm_id) {
      fetchAndSetLLM(currentAgent?.current_version?.llm_id);
    }
  }, [currentAgent?.current_version?.llm_id]);

  useEffect(() => {
    if (currentLLM?.current_version?.provider_settings?.begin_message) {
      setBeginMessage(currentLLM.current_version.provider_settings.begin_message);
    }
    if (currentLLM?.current_version?.prompt) {
      setPrompt(currentLLM.current_version.prompt);
    }
  }, [currentLLM]);

  const fetchAndSetAgent = async (id: string) => {
    const agent: Agent = await getAgent(id);
    setCurrentAgent(agent);
    setAgentName(agent.current_version.name || '');
    setAgentDescription(agent.current_version.description || '');
    setAgentSettings(agent.current_version.provider_settings);
  };

  const fetchAndSetLLM = async (id: string) => {
    const llm: LLM = await getLLM(id);
    setCurrentLLM(llm);
  };

  const locationsContext = useChannels();
  const { channelsState, updateChannel } = locationsContext;
  const { channels } = channelsState;

  // when the user selects a channel we need to update the channel withe the agent_id
  const value = channels.find((channel) => channel?.agent_id === currentAgent?.id);

  useEffect(() => {
    if (value) {
      setLocation(value);
    }
  }, [value]);

  const [location, setLocation] = useState(value);

  // Handle location selection change
  const handleLocationChange = async (selectedLocationId: string) => {
    const selectedLocation = channels.find(
      (location) => location.id === selectedLocationId
    );
    if (selectedLocation && currentAgent) {
      await updateChannel({ ...selectedLocation, agent_id: currentAgent.id });
      setLocation(selectedLocation); // Update local state
    }
  };

  const saveLLM = async () => {
    if (!currentAgent) return;

    await updateLLM(currentAgent.current_version.llm_id, {
      llm: {
        description: 'LLM Description',
        model: Model.Gpt4oMini,
        provider: Provider.Retell,
        prompt: prompt,
        provider_settings: { begin_message: beginMessage },
      },
    });
  };

  const saveAgent = async () => {
    if (!currentAgent) return;

    await updateAgent(currentAgent.id, {
      agent: {
        status: AgentStatus.Active,
        name: agentName,
        description: agentDescription,
        llm_id: currentAgent.current_version.llm_id,
        provider: currentAgent.current_version.provider,
        provider_settings: agentSettings || {},
      },
    });
  };

  const [accordion, setAccordion] = useState<
    | AgentAccordionValue.SELECT_BASIC_INFO
    | AgentAccordionValue.SELECT_CALL_INSTRUCTIONS
    | AgentAccordionValue.SELECT_CALL_SETTINGS
    | AgentAccordionValue.SELECT_CALL_CONTROL
    | AccordionValue.DEFAULT_VALUE
  >(AgentAccordionValue.SELECT_BASIC_INFO);

  return (
    <Flex direction="column" css={{ height: '100%', overflow: 'scroll', p: 30 }}>
      <Accordion type="single" defaultValue={'details'} value={accordion}>
        <VStack gap="2">
          <CampaignAccordion
            index={1}
            hideIndex={false}
            title="Basic Info"
            description="The name and description of the agent"
            currentAccordionValue={accordion}
            itemValue={AgentAccordionValue.SELECT_BASIC_INFO}
            setItemValue={setAccordion}
            isValid={false}
            buttonCopy={'Expand'}
          >
            <Fieldset>
              <Label>Agent Name</Label>
              <Input
                placeholder="A name for your agent"
                value={agentName}
                onChange={(e) => setAgentName(e.target.value)}
              />
            </Fieldset>
            <Fieldset>
              <Label>Description</Label>
              <Input
                placeholder="What does this agent do?"
                value={agentDescription}
                onChange={(e) => setAgentDescription(e.target.value)}
              />
            </Fieldset>
            <Button
              onClick={() => {
                saveAgent();
                setAccordion(AgentAccordionValue.SELECT_CALL_INSTRUCTIONS);
              }}
            >
              Save
            </Button>
          </CampaignAccordion>
          <CampaignAccordion
            index={2}
            hideIndex={false}
            title="Agent Instructions"
            description="The instructions on how the agent will handle the conversation"
            currentAccordionValue={accordion}
            itemValue={AgentAccordionValue.SELECT_CALL_INSTRUCTIONS}
            setItemValue={setAccordion}
            isValid={false}
            buttonCopy={'Expand'}
          >
            <Fieldset>
              <Label>Begin Message</Label>
              <Input
                placeholder="Agent will open by saying..."
                value={beginMessage}
                onChange={(e) => setBeginMessage(e.target.value)}
              />
            </Fieldset>
            <Fieldset>
              <Label>Agent Call Instructions</Label>
              <TextArea
                css={{ lineHeight: '1.5' }}
                placeholder="Add instructions for the agent to call you..."
                value={prompt}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setPrompt(e.target.value)
                }
              />
            </Fieldset>
            <Button
              onClick={() => {
                saveLLM();
                setAccordion(AgentAccordionValue.SELECT_CALL_SETTINGS);
              }}
            >
              Save
            </Button>
          </CampaignAccordion>
          <CampaignAccordion
            index={3}
            hideIndex={false}
            title="Agent Settings"
            description="The settings for the agent"
            currentAccordionValue={accordion}
            itemValue={AgentAccordionValue.SELECT_CALL_SETTINGS}
            setItemValue={setAccordion}
            isValid={false}
            buttonCopy={'Expand'}
          >
            <Fieldset>
              <Label>Select Language</Label>
              <Box css={{ margin: '10px 0', width: '100%' }}>
                <Select
                  value={agentSettings?.language}
                  onValueChange={(e) =>
                    setAgentSettings({ ...agentSettings, language: e as AgentLanguage })
                  }
                >
                  <SelectTrigger
                    aria-label="option-select-trigger"
                    css={{ width: '100%' }}
                  >
                    <SelectValue />
                    <SelectIcon>
                      <HiChevronDown />
                    </SelectIcon>
                  </SelectTrigger>
                  <SelectPrimitive.Portal>
                    <SelectContent>
                      <SelectScrollUpButton>
                        <HiChevronUp />
                      </SelectScrollUpButton>
                      <SelectViewport>
                        <SelectGroup>
                          <SelectLabel>Language</SelectLabel>
                          {agentLanguageOptions.map((option) => (
                            <SelectItem key={option.id} value={option.id}>
                              <SelectItemIndicator />
                              <SelectItemText>{option.name}</SelectItemText>
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectViewport>
                    </SelectContent>
                  </SelectPrimitive.Portal>
                </Select>
              </Box>
            </Fieldset>
            <Fieldset>
              <Label>Voice Temperature</Label>
              <Text>
                Controls how stable the voice sounds. Value ranging from [0,2]. Lower
                values mean more stability.
              </Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Slider
                    value={[agentSettings?.voice_temperature ?? 0.4]}
                    defaultValue={[
                      currentAgent?.current_version.provider_settings
                        ?.voice_temperature ?? 0.4,
                    ]}
                    min={0}
                    max={2}
                    step={0.1}
                    onValueChange={(value: number[]) =>
                      setAgentSettings({ ...agentSettings, voice_temperature: value[0] })
                    }
                  >
                    <SliderTrack>
                      <SliderRange />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Flex>
                <Flex css={{ width: '100px' }} align="center" justify="center">
                  {agentSettings?.voice_temperature}
                </Flex>
              </HStack>
            </Fieldset>
            <Fieldset>
              <Label>Voice Speed</Label>
              <Text>
                Controls speed of voice. Value ranging from [0.5,2]. Lower value means
                slower speech.
              </Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Slider
                    value={[agentSettings?.voice_speed ?? 1]}
                    defaultValue={[
                      currentAgent?.current_version.provider_settings?.voice_speed ?? 1,
                    ]}
                    min={0.5}
                    max={2}
                    step={0.1}
                    onValueChange={(value: number[]) =>
                      setAgentSettings({ ...agentSettings, voice_speed: value[0] })
                    }
                  >
                    <SliderTrack>
                      <SliderRange />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Flex>
                <Flex css={{ width: '100px' }} align="center" justify="center">
                  {agentSettings?.voice_speed}
                </Flex>
              </HStack>
            </Fieldset>
            <Fieldset>
              <Label>Responsiveness</Label>
              <Text>
                Controls how responsive is the agent. Value ranging from [0,1]. Lower
                value means less responsive agent.
              </Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Slider
                    value={[agentSettings?.responsiveness ?? 0.5]}
                    defaultValue={[
                      currentAgent?.current_version.provider_settings?.responsiveness ??
                        0.5,
                    ]}
                    min={0}
                    max={1}
                    step={0.1}
                    onValueChange={(value: number[]) =>
                      setAgentSettings({ ...agentSettings, responsiveness: value[0] })
                    }
                  >
                    <SliderTrack>
                      <SliderRange />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Flex>
                <Flex css={{ width: '100px' }} align="center" justify="center">
                  {agentSettings?.responsiveness}
                </Flex>
              </HStack>
            </Fieldset>
            {/* Interruption Sensitivity controls how many words/how long a user needs to
                speak before the agent considers it an interruption. A lower value means
                the user needs to speak more/longer to trigger an interruption. */}
            <Fieldset>
              <Label>Interruption Sensitivity</Label>
              <Text>
                Controls how sensitive the agent is to interruptions. Value ranging from
                [0,1]. Lower value means it will take longer / more words for user to
                interrupt agent.
              </Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Slider
                    value={[agentSettings?.interruption_sensitivity ?? 0.5]}
                    defaultValue={[
                      currentAgent?.current_version.provider_settings
                        ?.interruption_sensitivity ?? 0.5,
                    ]}
                    min={0}
                    max={1}
                    step={0.1}
                    onValueChange={(value: number[]) =>
                      setAgentSettings({
                        ...agentSettings,
                        interruption_sensitivity: value[0],
                      })
                    }
                  >
                    <SliderTrack>
                      <SliderRange />
                    </SliderTrack>
                    <SliderThumb />
                  </Slider>
                </Flex>
                <Flex css={{ width: '100px' }} align="center" justify="center">
                  {agentSettings?.interruption_sensitivity}
                </Flex>
              </HStack>
            </Fieldset>
            <Fieldset>
              <Label>Enable Backchannel</Label>
              <Text>
                Controls whether the agent would backchannel (agent interjects the speaker
                with phrases like &apos;yeah&apos;, &apos;uh-huh&apos; to signify interest
                and engagement).
              </Text>
              <Flex align="center" css={{ margin: '10px 0' }}>
                <Switch
                  checked={agentSettings?.enable_backchannel}
                  onCheckedChange={(e) =>
                    setAgentSettings({ ...agentSettings, enable_backchannel: e })
                  }
                >
                  <SwitchThumb />
                </Switch>
              </Flex>
            </Fieldset>
            {agentSettings?.enable_backchannel && (
              <Fieldset>
                <Label>Backchannel Frequency</Label>
                <Text>
                  Controls how often the agent would backchannel when a backchannel is
                  possible. Value ranging from [0,1]. Lower value means less frequent
                  backchannel.
                </Text>
                <HStack>
                  <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                    <Slider
                      value={[agentSettings?.backchannel_frequency ?? 0.5]}
                      defaultValue={[
                        currentAgent?.current_version.provider_settings
                          ?.backchannel_frequency ?? 0.5,
                      ]}
                      min={0}
                      max={1}
                      step={0.1}
                      onValueChange={(value: number[]) =>
                        setAgentSettings({
                          ...agentSettings,
                          backchannel_frequency: value[0],
                        })
                      }
                    >
                      <SliderTrack>
                        <SliderRange />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Flex>
                  <Flex css={{ width: '100px' }} align="center" justify="center">
                    {agentSettings?.backchannel_frequency}
                  </Flex>
                </HStack>
              </Fieldset>
            )}
            <Fieldset>
              <Label>Reminder Trigger Frequency</Label>
              <Text>
                Reminder trigger frequency in milliseconds. How often the agent would
                remind the user to respond.
              </Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Input
                    type="number"
                    value={agentSettings?.reminder_trigger_ms}
                    onChange={(e) =>
                      setAgentSettings({
                        ...agentSettings,
                        reminder_trigger_ms: parseInt(e.target.value),
                      })
                    }
                  />
                </Flex>
              </HStack>
            </Fieldset>
            <Fieldset>
              <Label>Reminder Max Count</Label>
              <Text>
                Reminder max count. How many times the agent would remind the user to
                respond.
              </Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Input
                    type="number"
                    value={agentSettings?.reminder_max_count}
                    onChange={(e) =>
                      setAgentSettings({
                        ...agentSettings,
                        reminder_max_count: parseInt(e.target.value),
                      })
                    }
                  />
                </Flex>
              </HStack>
            </Fieldset>
            {/* TODO: Add begin message delay */}
            <Fieldset>
              <Label>Begin Message Delay</Label>
              <Text>Delay before beginning message in milliseconds.</Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Input
                    type="number"
                    value={agentSettings?.begin_message_delay_ms}
                    onChange={(e) =>
                      setAgentSettings({
                        ...agentSettings,
                        begin_message_delay_ms: parseInt(e.target.value),
                      })
                    }
                  />
                </Flex>
              </HStack>
            </Fieldset>
            {/* TODO: Add max call duration */}
            <Fieldset>
              <Label>Max Call Duration</Label>
              <Text>Maximum call duration in milliseconds.</Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Input
                    type="number"
                    value={agentSettings?.max_call_duration_ms}
                    onChange={(e) =>
                      setAgentSettings({
                        ...agentSettings,
                        max_call_duration_ms: parseInt(e.target.value),
                      })
                    }
                  />
                </Flex>
              </HStack>
            </Fieldset>
            {/* TODO: Add voicemail detection timeout */}
            <Fieldset>
              <Label>Voicemail Detection Timeout</Label>
              <Text>Timeout before voicemail detection in milliseconds.</Text>
              <HStack>
                <Flex align="center" css={{ margin: '10px 0', width: '100%' }}>
                  <Input
                    type="number"
                    value={agentSettings?.voicemail_detection_timeout_ms}
                    onChange={(e) =>
                      setAgentSettings({
                        ...agentSettings,
                        voicemail_detection_timeout_ms: parseInt(e.target.value),
                      })
                    }
                  />
                </Flex>
              </HStack>
            </Fieldset>
            {/* TODO: Add ambient sound */}
            <Button
              onClick={() => {
                saveAgent();
                setAccordion(AgentAccordionValue.SELECT_CALL_CONTROL);
              }}
            >
              Save
            </Button>
          </CampaignAccordion>
          <CampaignAccordion
            index={4}
            hideIndex={false}
            title="Inbound Call Control"
            description="The settings for the agent"
            currentAccordionValue={accordion}
            itemValue={AgentAccordionValue.SELECT_CALL_CONTROL}
            setItemValue={setAccordion}
            isValid={false}
            buttonCopy={'Expand'}
          >
            <Fieldset>
              <Label>Use this agent you get an inbound call to?</Label>
              <Select value={location?.id} onValueChange={(e) => handleLocationChange(e)}>
                <SelectTrigger aria-label="option-select-trigger">
                  <SelectValue />
                  <SelectIcon>
                    <HiChevronDown />
                  </SelectIcon>
                </SelectTrigger>
                <SelectPrimitive.Portal>
                  <SelectContent>
                    <SelectScrollUpButton>
                      <HiChevronUp />
                    </SelectScrollUpButton>
                    <SelectViewport>
                      <SelectGroup>
                        <SelectLabel>Channels</SelectLabel>
                        {channels
                          .filter((option) => option.phone) // Filter out no phone channel types
                          .map((option) => (
                            <SelectItem key={option.id} value={option.id}>
                              <SelectItemIndicator />
                              <SelectItemText>
                                {`${option.name} - ${formatPhoneNumber(option.phone)}${option.agent_id && option.agent_id !== currentAgent?.id ? ' - 🚨 Used by Another Agent 🚨' : ''}`}
                              </SelectItemText>
                            </SelectItem>
                          ))}
                      </SelectGroup>
                    </SelectViewport>
                  </SelectContent>
                </SelectPrimitive.Portal>
              </Select>
            </Fieldset>
            <Button
              onClick={() => {
                saveAgent();
                setAccordion(AgentAccordionValue.SELECT_CALL_CONTROL);
              }}
            >
              Save
            </Button>
          </CampaignAccordion>
        </VStack>
      </Accordion>
    </Flex>
  );
};
