import moment from 'moment';
import { toast } from 'sonner';

import { AnalyticFilterItem } from '@/pages/agents/analytics/AnalyticFilters';

import { DatepickerDates } from '../components/datepicker/Datepicker';
import { api } from './api';

type ReportParams = {
  type: string;
  start_date: string;
  end_date: string;
  granularity: string;
  location_ids?: string[];
};

export type ReportData = {
  chart: 'total' | 'bar' | 'heatmap';
  label: string;
  type: string;
  values: Array<ReportDataValue>;
};

export type ReportDataValue = {
  x: string | number;
  y: string | number;
  z: number;
};

const conversationsFilters = [
  {
    type: 'new_conversations',
    label: 'New Conversations',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'open_conversations',
    label: 'Opened Conversations',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'closed_conversations',
    label: 'Closed Conversations',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'new_conversations',
    label: 'New Conversations per Day',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'open_conversations',
    label: 'Opened Conversations per Day',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'closed_conversations',
    label: 'Closed Conversations per Day',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'new_conversations',
    label: 'New Conversations Heatmap',
    chart: 'heatmap',
    filter: [],
    timeframe: {},
  },
];

const agentsFilters = [
  {
    type: 'ai_calls',
    label: 'Total AI Calls',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Complete AI Calls',
    chart: 'total',
    filter: [
      {
        column: 'call_completion_rating',
        comparison: 'in',
        value: ['Complete'],
      },
    ],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Incomplete AI Calls',
    chart: 'total',
    filter: [
      {
        column: 'call_completion_rating',
        comparison: 'in',
        value: ['Incomplete'],
      },
    ],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Positive Sentiment AI Calls',
    chart: 'total',
    filter: [
      {
        column: 'user_sentiment',
        comparison: 'in',
        value: ['Positive'],
      },
    ],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Negative Sentiment AI Calls',
    chart: 'total',
    filter: [
      {
        column: 'user_sentiment',
        comparison: 'in',
        value: ['Negative'],
      },
    ],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Neutral Sentiment AI Calls',
    chart: 'total',
    filter: [
      {
        column: 'user_sentiment',
        comparison: 'in',
        value: ['Neutral'],
      },
    ],
    timeframe: {},
  },
  {
    type: 'ai_calls_average_duration',
    label: 'Average Completed AI Call Duration',
    chart: 'total',
    filter: [
      {
        column: 'call_completion_rating',
        comparison: 'in',
        value: ['Complete'],
      },
    ],
    timeframe: {},
  },
  {
    type: 'ai_calls_hours_automated',
    label: 'Hours Automated',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Calls by Day',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'ai_calls',
    label: 'Calls by Agent Heatmap',
    chart: 'heatmap',
    filter: [],
    timeframe: {},
  },
  {
    type: 'ai_calls',
    label: 'Stacked Bar Chart Neutral',
    chart: 'bar',
    filter: [
      {
        column: 'user_sentiment',
        comparison: 'in',
        value: ['Neutral'],
      },
    ],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'ai_calls',
    label: 'Stacked Bar Chart Negative',
    chart: 'bar',
    filter: [
      {
        column: 'user_sentiment',
        comparison: 'in',
        value: ['Negative'],
      },
    ],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'ai_calls',
    label: 'Stacked Bar Chart Positive',
    chart: 'bar',
    filter: [
      {
        column: 'user_sentiment',
        comparison: 'in',
        value: ['Positive'],
      },
    ],
    timeframe: {
      time_bucket: 'day',
    },
  },
];
const campaignFilters = [
  {
    type: 'total_campaign_messages',
    label: 'Sent Messages',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'delivered_campaign_messages',
    label: 'Delivered Messages',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'failed_campaign_messages',
    label: 'Failed Messages',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'delivered_campaign_messages',
    label: 'Delivered Messages Bar',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'failed_campaign_messages',
    label: 'Failed Messages Bar',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'campaign_responses',
    label: 'Responses',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'campaign_unsubscribes',
    label: 'Unsubscribes',
    chart: 'total',
    filter: [],
    timeframe: {},
  },
  {
    type: 'campaign_responses',
    label: 'Responses',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
  {
    type: 'campaign_unsubscribes',
    label: 'Unsubscribes',
    chart: 'bar',
    filter: [],
    timeframe: {
      time_bucket: 'day',
    },
  },
];

export const getMessagesOverview = (params: ReportParams) =>
  api.post('/reports/messages', { messages: params }).then((res) => {
    return res.data.data;
  });

export const getContactsOverview = (params: ReportParams) =>
  api.post('/reports/contacts', { contacts: params }).then((res) => {
    return res.data.data;
  });

export const getCampaignsOverview = (params: ReportParams) =>
  api.post('/reports/campaigns', { campaigns: params }).then((res) => {
    return res.data.data;
  });

export const reportAnalytics = async (
  analyticFilters: Array<AnalyticFilterItem>,
  dates: DatepickerDates,
  type: 'agents' | 'conversations' | 'campaign'
): Promise<Array<ReportData>> => {
  try {
    const filters = { reports: getFilters(analyticFilters, dates, type) };
    const res = await api.post(`/v2/reports`, { ...filters });
    return res.data.data;
  } catch (error) {
    console.error(error);
    toast.error('Failed to fetch reports');
    return Promise.reject(error);
  }
};

const getFilters = (
  analyticFilters: Array<AnalyticFilterItem>,
  dates: DatepickerDates,
  type: 'agents' | 'conversations' | 'campaign'
) => {
  const formattedDates = {
    startDate: moment(dates.startDate).utc().startOf('day'),
    endDate: moment(dates.endDate ?? dates.startDate)
      .utc()
      .endOf('day'),
  };
  switch (type) {
    case 'agents':
      return agentsFilters.map((f) => {
        return {
          ...f,
          filter: [...f.filter, ...analyticFilters],
          timeframe: {
            ...f.timeframe,
            start: formattedDates.startDate,
            end: formattedDates.endDate,
          },
        };
      });
    case 'conversations':
      return conversationsFilters.map((f) => {
        return {
          ...f,
          filter: [...f.filter, ...analyticFilters],
          timeframe: {
            ...f.timeframe,
            start: formattedDates.startDate,
            end: formattedDates.endDate,
          },
        };
      });
    case 'campaign':
      return campaignFilters.map((f) => {
        return {
          ...f,
          filter: [...f.filter, ...analyticFilters],
          timeframe: {
            ...f.timeframe,
            start: formattedDates.startDate,
            end: formattedDates.endDate,
            time_bucket: dates.time_bucket ?? f.timeframe.time_bucket,
          },
        };
      });
  }
};
