/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ChangeEvent, FormEvent } from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  ControlGroup,
  Divider,
  Fieldset,
  Flex,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';

import { defaultChannelSettings, useChannels } from '../context/ChannelContext';

export const ChannelSettings = () => {
  const { channelsState, updateChannel, setCurrent } = useChannels();
  const { current } = channelsState;

  // Define the onChange event handler for input changes
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Get the current settings or an empty object if it doesn't exist
    const newSettings = current?.settings || defaultChannelSettings;

    // If the input value is empty, set the corresponding field in newSettings to an empty string
    if (e.target.value === '') {
      newSettings.auto_close_conversation_after = '';
    } else {
      // Otherwise, set the corresponding field in newSettings to the input value
      newSettings.auto_close_conversation_after = e.target.value;
    }

    // If a current location and its id exist
    if (current && current.id) {
      // Update the current location with the new settings
      setCurrent({
        ...current,
        settings: newSettings,
      });
    } else {
      // If no current location or id, exit the function
      return;
    }
  };

  // Define the onSubmit event handler for form submission
  const onSubmit = (e: FormEvent<HTMLFormElement>, locationUpdates: any) => {
    // Prevent the default form submission behavior
    e.preventDefault();

    // Get the current settings or an empty object if it doesn't exist
    let newSettings = current?.settings || defaultChannelSettings;

    // If there are updates to the settings, merge them with the current settings
    if (locationUpdates.settings) {
      newSettings = { ...newSettings, ...locationUpdates.settings };
    }

    // If no current location or its id, exit the function early
    if (!current || !current.id) {
      return;
    }

    // Call the updateChannel function with the current location and the new settings
    updateChannel({ ...current, settings: newSettings });
  };

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="location-settings"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Channel Settings</Text>
            <Text>{'Update settings that apply to this channel only'}</Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <form
            onSubmit={(e) =>
              onSubmit(e, {
                settings: {
                  auto_close_conversation_after:
                    current?.settings?.auto_close_conversation_after,
                },
              })
            }
            data-testid="location-settings-form"
          >
            <VStack gap={2}>
              <Fieldset>
                <Label htmlFor="auto_close_conversation_after">
                  Automatically Close Conversations After
                </Label>
                <ControlGroup>
                  <Input
                    id="auto_close_conversation_after"
                    placeholder="10"
                    name="settings.auto_close_conversation_after"
                    value={current?.settings?.auto_close_conversation_after || ''}
                    onChange={onChange}
                  />
                  <Flex
                    align="center"
                    css={{
                      fontSize: 13,
                    }}
                  >
                    Days
                  </Flex>
                </ControlGroup>
              </Fieldset>
              <Box>
                <Button type="submit">Update Settings</Button>
              </Box>
            </VStack>
          </form>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
