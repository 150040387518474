/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { usePageView } from '@/shared/hooks';
import { CampaignStatus } from '@/shared/types/campaigns';

import { useCampaignsContext } from './context/CampaignsContext';
import { CampaignsList } from './list';

export const DraftCampaigns = (): JSX.Element => {
  // Change analytics page
  usePageView();

  const {
    campaignsState: { loading, draftCampaigns },
  } = useCampaignsContext();

  return (
    <CampaignsList
      title="Drafts"
      url="drafts"
      campaigns={draftCampaigns}
      loading={loading}
      status={CampaignStatus.DRAFT}
    />
  );
};
