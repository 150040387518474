/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { startsWith } from 'lodash';
import React, { useMemo, useState } from 'react';
import { HiPhone } from 'react-icons/hi';
import { FlatIndexLocationWithAlign } from 'react-virtuoso';

import { CompletedCallIcon, MissedCallIcon } from '@/shared/components/Icons';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import {
  ConversationItemCallStatus,
  ConversationItemModeTypes,
  ConversationItemSourceTypes,
  ConversationMessageType,
} from '@/shared/types/conversations';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';
import { TIME_STAMP } from '@/shared/utils/timestamps';
import { styled } from '@/stitches.config';

import { ContactIcon } from '../inbox/conversation/items/ContactIcon';
import { SenderIcon } from '../inbox/conversation/items/SenderIcon';

type ConversationCallProps = {
  message: ConversationMessageType;
  /* Date of message */
  date?: string | undefined | null;
  /* Name of contact */
  contact_name: string;
  /* Email of user */
  user_email: string;
  scrollToIndex: (location: number | FlatIndexLocationWithAlign) => void;
  index: number;
};

export const SHORT_BODY_LENGTH = 300;

export const ConversationCall = (props: ConversationCallProps) => {
  const { message, date, contact_name, user_email, scrollToIndex, index } = props;
  const { id, attachments, source_type, delivery_status, mode } = message;

  // Filter the attachments objects for content_type audio/x-wav
  const audioAttachments = attachments.filter(
    (attachment) => attachment?.content_type === 'audio/x-wav'
  );

  // Should we show the full text transcript?
  const [showFullBody, setShowFullBody] = useState(false);
  const [showFullBodyTranslation, setShowFullBodyTranslation] = useState(false);

  // Is the call in progress?
  const isCallInProgress =
    startsWith(delivery_status, ConversationItemCallStatus.CALL_INITIATED) ||
    startsWith(delivery_status, ConversationItemCallStatus.CALL_RINGING) ||
    startsWith(delivery_status, ConversationItemCallStatus.CALL_IN_PROGRESS) ||
    delivery_status == ConversationItemCallStatus.CALL_QUEUED;

  // Is the call missed?
  const isCallMissed =
    startsWith(delivery_status, ConversationItemCallStatus.CALL_NO_ANSWER) ||
    startsWith(delivery_status, ConversationItemCallStatus.CALL_BUSY) ||
    startsWith(delivery_status, ConversationItemCallStatus.CALL_FAILED);

  // Get the call status
  const callStatus = useMemo(() => {
    return getCallStatus(delivery_status, mode);
  }, [id, delivery_status, mode]);

  return (
    <Box data-testid="conversation-call">
      <Flex
        justify={source_type === ConversationItemSourceTypes.INBOUND ? 'start' : 'end'}
        align="end"
      >
        {source_type === ConversationItemSourceTypes.INBOUND && (
          <ContactIcon contact_name={contact_name} />
        )}
        <CallContainer
          variant={
            source_type === ConversationItemSourceTypes.INBOUND ? 'inbound' : 'outbound'
          }
        >
          <VStack gap="2">
            <HStack gap="2">
              <IconContainer
                justify="center"
                align="center"
                variant={isCallMissed ? 'missed' : 'call'}
              >
                {startsWith(
                  delivery_status,
                  ConversationItemCallStatus.CALL_COMPLETED
                ) && (
                  <span data-testid="completed-call-icon">
                    <CompletedCallIcon />
                  </span>
                )}
                {(startsWith(
                  delivery_status,
                  ConversationItemCallStatus.CALL_NO_ANSWER
                ) ||
                  startsWith(
                    delivery_status,
                    ConversationItemCallStatus.CALL_FAILED
                  )) && (
                  <span data-testid="missed-call-icon">
                    <MissedCallIcon />
                  </span>
                )}
                {startsWith(delivery_status, ConversationItemCallStatus.CALL_BUSY) && (
                  <span data-testid="declined-call-icon">
                    <HiPhone style={{ transform: 'rotate(135deg)', margin: 0 }} />
                  </span>
                )}
                {isCallInProgress && (
                  <span data-testid="call-icon">
                    <HiPhone />
                  </span>
                )}
              </IconContainer>
              <Box>
                <Box
                  data-testid="call-status"
                  css={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: isCallMissed ? '#1C2024' : '#3E63DD',
                  }}
                >
                  {callStatus}
                </Box>
                <Box css={{ fontSize: 12, fontWeight: 500, color: '#60646C' }}>
                  {startsWith(
                    delivery_status,
                    ConversationItemCallStatus.CALL_NO_ANSWER
                  ) && 'No one answered'}
                  {!startsWith(
                    delivery_status,
                    ConversationItemCallStatus.CALL_NO_ANSWER
                  ) && (
                    <Flex align="center">
                      <Box css={{ fontWeight: 500 }}>
                        {source_type === ConversationItemSourceTypes.INBOUND
                          ? contact_name
                          : user_email}
                      </Box>
                      <Box css={{ ml: 5 }}>{dayjs(date).format(TIME_STAMP)}</Box>
                    </Flex>
                  )}
                </Box>
              </Box>
            </HStack>
            {startsWith(delivery_status, ConversationItemCallStatus.CALL_COMPLETED) &&
              (message?.body || audioAttachments[0]?.url) && (
                <VStack gap="2" css={{ width: 400 }}>
                  {message?.call_metadata?.analysis?.call_summary && (
                    <>
                      <Box css={{ fontSize: '14px', fontWeight: 600 }}>Call Summary</Box>
                      <Box>
                        {renderText(message?.call_metadata?.analysis?.call_summary || '')}
                      </Box>
                    </>
                  )}
                  {message.body && (
                    <>
                      {showFullBody && (
                        <>
                          <Box css={{ fontSize: '14px', fontWeight: 600 }}>
                            Call Transcript
                          </Box>
                          <Box>
                            {renderText(
                              transcriptFormatter(
                                message?.body || '',
                                contact_name,
                                message.mode
                              )
                            )}
                          </Box>
                        </>
                      )}
                      <Button
                        size="1"
                        variant="gray"
                        data-testid="transcript-btn"
                        ghost
                        onClick={() => {
                          showFullBody &&
                            scrollToIndex({
                              index: index,
                            });
                          setShowFullBody(!showFullBody);
                        }}
                      >
                        {showFullBody ? 'Hide Transcript' : 'Show Full Transcript'}
                      </Button>
                      {message.translated_body &&
                        message.translation_language &&
                        showFullBodyTranslation && (
                          <>
                            <Box css={{ fontSize: '14px', fontWeight: 600 }}>
                              Call Transcript Translation
                            </Box>
                            <Box>
                              {renderText(
                                translatedTextFormatter(message.translated_body)
                              )}
                            </Box>
                          </>
                        )}
                      {message.translated_body && message.translation_language && (
                        <Button
                          size="1"
                          variant="gray"
                          ghost
                          onClick={() =>
                            setShowFullBodyTranslation(!showFullBodyTranslation)
                          }
                        >
                          {showFullBodyTranslation
                            ? 'Hide Transcript Translation'
                            : 'Show Full Transcript Translation'}
                        </Button>
                      )}
                    </>
                  )}
                  <AudioControl controls src={audioAttachments[0]?.url}>
                    <track kind="captions" />
                    Your browser does not support the audio element.
                  </AudioControl>
                </VStack>
              )}
          </VStack>
        </CallContainer>
        {source_type === ConversationItemSourceTypes.OUTBOUND && (
          <SenderIcon message={message} />
        )}
      </Flex>
    </Box>
  );
};

// Take a script and replace the \n skip line with two skip lines
// Then replace the Agent: and User: with **Agent:** and **User:**
const transcriptFormatter = (
  transcript: string,
  contact_name: string,
  mode: ConversationItemModeTypes | null
) => {
  // If the mode is AI, then replace the User with **Contact:**
  if (mode === ConversationItemModeTypes.AI) {
    return transcript
      .replace(/\n/g, '\n\n')
      .replace(/Agent:/g, '*Agent:*')
      .replace(/User:/g, `*${contact_name || 'Contact'}:*`);
  } else {
    return transcript
      .replace(/\n/g, '\n\n')
      .replace(/Contact:/g, `*${contact_name || 'Contact'}:*`);
  }
};

const translatedTextFormatter = (translated_text: string) => {
  return translated_text.replace(/\n/g, '\n\n');
};

const IconContainer = styled(Flex, {
  width: 32,
  height: 32,
  borderRadius: '50%',
  color: 'white',
  variants: {
    variant: {
      missed: {
        backgroundColor: '#E5484D',
      },
      call: {
        backgroundColor: '#3E63DD',
      },
    },
  },
});

const CallContainer = styled(Box, {
  maxWidth: '500px',
  borderRadius: '12px',
  overflow: 'hidden',
  padding: 12,
  textAlign: 'left',
  lineHeight: 1.4,
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
  fontSize: 14,
  variants: {
    variant: {
      outbound: {
        backgroundColor: '#0144FF0F!important',
      },
      inbound: {
        backgroundColor: '#00003B0D!important',
      },
    },
  },
});

const AudioControl = styled('audio', {
  width: '100%',
  outline: 'none',
});

export const getCallStatus = (
  delivery_status: string | undefined,
  mode: ConversationItemModeTypes | null | undefined
) => {
  switch (true) {
    case delivery_status === ConversationItemCallStatus.CALL_COMPLETED:
      return 'Call Ended';
    case delivery_status === ConversationItemCallStatus.CALL_IN_PROGRESS:
    case delivery_status === ConversationItemCallStatus.CALL_QUEUED:
      return 'Call in Progress...';
    case delivery_status === ConversationItemCallStatus.CALL_INITIATED:
    case delivery_status === ConversationItemCallStatus.CALL_RINGING:
      return 'Calling';
    case delivery_status === ConversationItemCallStatus.CALL_NO_ANSWER:
      return 'Missed Call';
    case delivery_status === ConversationItemCallStatus.CALL_BUSY:
      return 'Call Declined';
    case delivery_status === ConversationItemCallStatus.CALL_FAILED:
      return 'Call Failed';
    case mode === ConversationItemModeTypes.AI &&
      startsWith(delivery_status, ConversationItemCallStatus.CALL_COMPLETED):
      return 'Agent Call Ended';
    case mode === ConversationItemModeTypes.AI &&
      startsWith(delivery_status, ConversationItemCallStatus.CALL_IN_PROGRESS):
      return 'Call In Progress...';
    case mode === ConversationItemModeTypes.AI &&
      startsWith(delivery_status, ConversationItemCallStatus.CALL_INITIATED):
      return 'Calling';
    case mode === ConversationItemModeTypes.AI &&
      startsWith(delivery_status, ConversationItemCallStatus.CALL_NO_ANSWER):
      return 'Missed Call';
    case mode === ConversationItemModeTypes.AI &&
      startsWith(delivery_status, ConversationItemCallStatus.CALL_BUSY):
      return 'Call Declined';
  }
};
