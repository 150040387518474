import dayjs from 'dayjs';
import React, { Dispatch, useCallback, useState } from 'react';
import {
  HiBan,
  HiOutlineCake,
  HiOutlineCalendar,
  HiOutlineHome,
  HiOutlineSpeakerphone,
  HiOutlineUser,
  HiPencil,
  HiPhone,
  HiX,
} from 'react-icons/hi';
import { HiOutlineEnvelope, HiOutlineLanguage } from 'react-icons/hi2';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { languages } from '@/shared/components/editor/v2/utils';
import { IdCardIcon } from '@/shared/components/Icons';
import { Contact } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { ContactAccordion } from './ContactAccordion';
import { ContactForm, transformDate } from './ContactForm';
import { AccordionValue } from './ContactView';

type Field = {
  label: string;
  key: string;
  icon: JSX.Element;
};

const contactFieldsList = [
  {
    label: 'Name',
    key: 'name',
    icon: <HiOutlineUser />,
  },
  {
    label: 'Email',
    key: 'email',
    icon: <HiOutlineEnvelope />,
  },
  {
    label: 'Phone',
    key: 'phone',
    icon: <HiPhone />,
  },
  {
    label: 'External ID',
    key: 'external_id',
    icon: <IdCardIcon />,
  },
  {
    label: 'Address',
    key: 'address',
    icon: <HiOutlineHome />,
  },
  {
    label: 'Birth Date',
    key: 'birth_date',
    icon: <HiOutlineCake />,
  },
  {
    label: 'Default Channel',
    key: 'default_channel_id',
    icon: <HiOutlineSpeakerphone />,
  },
  {
    label: 'Language',
    key: 'language',
    icon: <HiOutlineLanguage />,
  },
  {
    label: 'Blocked',
    key: 'blocked',
    icon: <HiBan />,
  },
  {
    label: 'Created At',
    key: 'created_at',
    icon: <HiOutlineCalendar />,
  },
  {
    label: 'Updated At',
    key: 'updated_at',
    icon: <HiOutlineCalendar />,
  },
];

type ViewCampaignBasicInfoProps = {
  /* contact object */
  contact?: Contact | null;
  /* is contact data loading */
  loading?: boolean;
  /* current accordion value */
  accordion: AccordionValue;
  /* set accordion value */
  setAccordion: Dispatch<React.SetStateAction<AccordionValue>>;
};

export const ContactDetails = ({
  contact,
  loading,
}: ViewCampaignBasicInfoProps): JSX.Element => {
  const {
    channelsState: { channels },
  } = useChannels();

  const [isEdit, setIsEdit] = useState(false);

  const renderValue = (key: string, value?: any) => {
    if (value === null) {
      return null;
    }
    switch (key) {
      case 'name':
      case 'email':
      case 'external_id':
        return value;
      case 'phone':
        return formatPhoneNumber(value as string);
      case 'address': {
        const fields = value ? Object.values(value).filter((item) => !!item) : [];
        return fields.join(', ');
      }
      case 'birth_date': {
        return value ? dayjs(transformDate(value)).format('Do MMMM YYYY') : '';
      }
      case 'default_channel_id':
        return (
          <Tag
            css={{
              backgroundColor: '#01CCFF1D',
              color: '#005681DA',
            }}
          >
            {channels.find((channel: Channel) => channel.id === value)?.name}
          </Tag>
        );
      case 'language':
        return (
          <Tag
            css={{
              backgroundColor: '#FFBF012F',
              color: '#783200CF',
            }}
          >
            {languages.find((lang) => lang.value === value)?.label}
          </Tag>
        );
      case 'blocked':
        return (
          <Tag
            css={{
              backgroundColor: '#FF010110',
              color: '#BB0007D5',
              textTransform: 'capitalize',
            }}
          >
            {value?.toString()}
          </Tag>
        );
      case 'created_at':
      case 'updated_at':
        return dayjs(value as string).format('D MMM YYYY');
      default:
        return value;
    }
  };

  const renderContactItem = useCallback(
    (item: Field) => (
      <ContactItem icon={item.icon} title={item.label} key={item.key}>
        {loading ? (
          <Skeleton key={item.key} variant="tag" css={{ height: 20, width: 140 }} />
        ) : (
          renderValue(item.key, contact?.[item.key as keyof Contact])
        )}
      </ContactItem>
    ),
    [loading, contact]
  );

  const handleEditContact = () => {
    setIsEdit(true);
  };

  const handleViewContact = () => {
    setIsEdit(false);
  };

  return (
    <ContactAccordion
      title="Details"
      defaultValue={AccordionValue.CONTACT_DETAILS}
      accordionValue={AccordionValue.CONTACT_DETAILS}
      actionButton={
        !isEdit ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <IconButton
                onClick={handleEditContact}
                size={2}
                variant="ghost"
                css={{ width: 32, height: 32, color: '#60646C' }}
                data-testid={`contact-button-edit`}
              >
                <HiPencil size={16} color="#1C2024" />
              </IconButton>
            </TooltipTrigger>
            <TooltipContent>Edit Contact</TooltipContent>
          </Tooltip>
        ) : (
          <IconButton size={2} onClick={handleViewContact}>
            <HiX size={16} color="#1C2024" />
          </IconButton>
        )
      }
    >
      <VStack gap="1">
        {isEdit ? (
          <ContactForm isEdit onClose={handleViewContact} />
        ) : (
          contactFieldsList?.map(renderContactItem)
        )}
      </VStack>
    </ContactAccordion>
  );
};

type ContactItemProps = {
  icon?: React.ReactElement;
  title?: string;
  direction?: 'row' | 'column';
  children?: React.ReactNode;
};

export const ContactItem = ({
  icon,
  title,
  direction = 'row',
  children,
}: ContactItemProps): JSX.Element => {
  return (
    <Flex
      wrap="wrap"
      css={{ fontSize: 14, p: '2px 0', minHeight: 35 }}
      align={direction === 'row' ? 'center' : 'start'}
      direction={direction}
    >
      <Flex css={{ maxWidth: 126, width: 126 }} align="center">
        <HStack
          gap={1}
          css={{ py: direction === 'column' ? '4px' : '0', color: '#60646C' }}
        >
          <Box css={{ width: 14 }}>{icon}</Box>
          <Box css={{ fontWeight: 500, whiteSpace: 'nowrap' }}>{title}</Box>
        </HStack>
      </Flex>
      <Flex
        css={{
          ml: 6,
          color: '#1C2024',
          fontSize: 14,
          px: 8,
          maxWidth: 'calc(100% - 135px)',
          whiteSpace: 'nowrap',
          display: 'block',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const Tag = styled(Box, {
  backgroundColor: '#01CCFF1D',
  color: '#005681DA',
  fontSize: '12px',
  fontWeight: '500',
  padding: '1px 8px',
  borderRadius: 3,
});
