import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import * as API from '@/shared/api/sequences';
import { Sequence, SequenceStatus } from '@/shared/types/sequences';
import { Box } from '@/shared/ui';

import { ValueCombobox } from './Combobox';

type SequenceValuesComboboxProps = {
  selected: { label?: string; value: string | string[] | number } | null | undefined;
  onSelect: (option: { label: string; value: string | string[] | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
  disabled?: boolean;
};

type CampaignOption = { label: string; value: string };

export const SequenceValuesCombobox = (
  props: SequenceValuesComboboxProps
): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');
  const [sequenceOptions, setSequenceOptions] = useState<CampaignOption[]>([]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    debounceSearchTags(value);
  };

  const searchSequences = async (value: string) => {
    if (value) {
      const data = await API.getSequences({
        title: value,
        status: SequenceStatus.ACTIVE,
        offset: 0,
        limit: 10,
      });
      const options = data.data
        .filter((sequence): sequence is Sequence => sequence !== null)
        .map((sequence: Sequence) => ({
          label: sequence.title || '',
          value: sequence.id || '',
        }));
      setSequenceOptions((prevOptions) => {
        const newOptions = options.filter(
          (option: { value: string }) =>
            !prevOptions.some((prevOption) => prevOption.value === option.value)
        );
        return [...prevOptions, ...newOptions];
      });
    }
  };

  const debounceSearchTags = debounce(searchSequences, 500);

  const getSequences = async () => {
    const data = await API.getSequences({
      status: SequenceStatus.ACTIVE,
      offset: 0,
      limit: 10,
    });
    const options = data.data
      .filter((sequence): sequence is Sequence => sequence !== null)
      .map((sequence: Sequence) => ({
        label: sequence.title || '',
        value: sequence.id || '',
      }));
    setSequenceOptions(options);
  };

  useEffect(() => {
    getSequences();
  }, []);

  return (
    <Box>
      <ValueCombobox
        disabled={props.disabled}
        isOpen={props.isOpen}
        selectorStyles={props.selectorStyles}
        options={sequenceOptions}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
      />
    </Box>
  );
};
