/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { VirtuosoGrid } from 'react-virtuoso';

import { useResponsiveColumns } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import {
  Campaign,
  CampaignPermissionTypes,
  CampaignStatus,
} from '@/shared/types/campaigns';
import { Box, Button, Flex, Grid, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useCampaignsContext } from '../../context/CampaignsContext';
import { useFilterCampaignTemplates } from '../../hooks/useFilterCampaignTemplates';
import { CampaignGlobalTemplatesErrorBoundary } from '../boundaries/CampaignGlobalTemplatesErrorBoundaries';
import TemplateLoadingCard from '../TemplateLoadingCard';
import { TemplateSearch } from '../TemplateSearch';
import { TemplatePreview } from './TemplatePreview';

type CampaignTemplatesProps = {
  /* is the campaign template global */
  isGlobal?: boolean;
};

export const CampaignTemplates = ({ isGlobal }: CampaignTemplatesProps) => {
  const history = useHistory();
  const columns = useResponsiveColumns();
  const {
    campaignsState: { templates, totalCampaignTemplates, loadingTemplates },
    setLoadingCampaignTemplates,
  } = useCampaignsContext();

  const {
    filters,
    changeFilters,
    increaseOffsetCoefficient,
    isLoadingNext,
    isAllTemplatesFetched,
    getNumberOfBottomLoadingCards,
  } = useFilterCampaignTemplates(isGlobal);

  // if filters changed just now, we don't want to
  // display SearchedCampaignEmptyState or CampaignEmptyState immediately
  const [didFiltersChange, setDidFiltersChange] = useState(false);

  const enabledLoadingNext =
    // Is campaign templates fetching next list
    !isLoadingNext &&
    // Is campaign templates still loading
    !loadingTemplates &&
    // All campaign templates are loaded
    !isAllTemplatesFetched;

  useEffect(() => {
    return () => {
      setLoadingCampaignTemplates(true);
    };
  }, []);

  // this is an event hook to prevent the UI "blinking" of the following sequence
  // CampaignEmptyState -> SearchedCampaignEmptyState -> loading -> SearchedCampaignEmptyState
  // when user search something inside an empty campaign list
  useEffect(() => {
    // if loading changed from true to false
    // it means the fetch campaigns api call has finished
    // we can now set didFiltersChange back to false
    // to allow SearchedCampaignEmptyState or CampaignEmptyState to be rendered
    if (!loadingTemplates) {
      setDidFiltersChange(false);
    }
  }, [loadingTemplates]);

  const templatesToDisplay = useMemo(
    () =>
      loadingTemplates
        ? []
        : templates
            ?.filter(
              (template) =>
                template &&
                template?.status === CampaignStatus.TEMPLATE &&
                (isGlobal
                  ? template?.access_level === CampaignPermissionTypes.GLOBAL
                  : template?.access_level !== CampaignPermissionTypes.GLOBAL)
            )
            ?.filter(
              (template) =>
                template &&
                template.title &&
                template.title.toLowerCase().includes(filters.title)
            ),
    [isGlobal, templates, loadingTemplates, filters.title]
  );

  return (
    <CampaignGlobalTemplatesErrorBoundary
      feature={isGlobal ? 'CampaignGlobalTemplates' : 'CampaignTemplates'}
    >
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns/sms' },
          { title: 'SMS', path: '/campaigns/sms' },
          isGlobal
            ? { title: 'Browse Templates', path: '/campaigns/sms/templates/browse' }
            : { title: 'My Templates', path: '/campaigns/sms/templates' },
        ]}
        actions={
          !isGlobal ? (
            <Button onClick={() => history.push('/campaigns/sms/templates/create')}>
              Create Template
            </Button>
          ) : null
        }
      >
        <ListContainer gap={1}>
          <TemplateSearch
            changeFilters={changeFilters}
            filters={filters}
            setDidFiltersChange={setDidFiltersChange}
            isGlobal={isGlobal}
          />

          {/* Loading state */}
          {loadingTemplates && templatesToDisplay?.length === 0 && (
            <Grid columns={columns as any} gap={2} css={{ px: 30 }}>
              {Array.from({ length: 20 }, (_, k) => (
                <TemplateLoadingCard key={k} />
              ))}
            </Grid>
          )}

          {/* User doesn't have any Campaign Template */}
          {(!didFiltersChange || filters?.title === '') &&
            !loadingTemplates &&
            templatesToDisplay?.length === 0 && (
              <CampaignTemplateEmptyState isGlobal={isGlobal} />
            )}

          {/* No Campaign Template matches user's search*/}
          {filters?.title !== '' &&
            !loadingTemplates &&
            templatesToDisplay?.filter(
              (template) =>
                template &&
                template.title &&
                template.title.toLowerCase().includes(filters.title)
            ).length === 0 && <SearchedCampaignTemplateEmptyState isGlobal={isGlobal} />}

          {templatesToDisplay?.length > 0 && (
            <VirtuosoGrid
              data={templatesToDisplay as Campaign[]}
              atBottomStateChange={(isAtBottom) => {
                if (isAtBottom && enabledLoadingNext) {
                  increaseOffsetCoefficient();
                }
              }}
              totalCount={totalCampaignTemplates}
              itemContent={(index: number, template: Campaign) => (
                <TemplatePreview
                  key={template.id}
                  template={template}
                  isGlobal={isGlobal}
                />
              )}
              components={{
                Footer: () =>
                  enabledLoadingNext ? (
                    <Grid
                      columns={getNumberOfBottomLoadingCards as any}
                      gap={2}
                      css={{ px: 30 }}
                    >
                      {Array.from(
                        { length: getNumberOfBottomLoadingCards },
                        (_: any, k: React.Key | null | undefined) => (
                          <Box css={{ pb: 10 }} key={k}>
                            <TemplateLoadingCard key={k} />
                          </Box>
                        )
                      )}
                    </Grid>
                  ) : (
                    <Box css={{ pb: 20 }} />
                  ),
                List: forwardRef(({ style, children, ...props }, ref) => (
                  <div
                    ref={ref}
                    {...props}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      paddingLeft: 25,
                      paddingRight: 25,
                      ...style,
                    }}
                  >
                    {children}
                  </div>
                )),
                Item: ({ children, ...props }: any) => (
                  <div
                    {...props}
                    style={{
                      padding: '0 5px 10px',
                      width: `calc(100%/${columns})`,
                      display: 'flex',
                      flex: 'none',
                      alignContent: 'stretch',
                      boxSizing: 'border-box',
                    }}
                  >
                    {children}
                  </div>
                ),
              }}
            />
          )}
        </ListContainer>
      </PageLayout>
    </CampaignGlobalTemplatesErrorBoundary>
  );
};

export const SearchedCampaignTemplateEmptyState = ({
  isGlobal,
}: {
  isGlobal?: boolean;
}) => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>
        {isGlobal
          ? 'No Global Campaign Templates Matches Your Search'
          : 'No Campaign Template Matches Your Search'}
      </Text>
      <Text css={{ mt: 10 }}>Please try a different filter</Text>
    </EmptyStateContainer>
  );
};

export const CampaignTemplateEmptyState = ({ isGlobal }: { isGlobal?: boolean }) => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>
        {isGlobal ? 'No Global Campaign Templates Found' : 'No Campaign Template'}
      </Text>
      <Text css={{ mt: 10 }}>
        {isGlobal
          ? 'You need admins to grant you a global template to get started'
          : `Click "Create Template" to get started`}
      </Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: 'calc(100% - 78px)',
  width: '100%',
});

export const ListContainer = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  pt: 30,
  pb: 0,
});
