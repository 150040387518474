import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  HiLightningBolt,
  HiPhone,
  // HiSearch
} from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import AgentProvider from '../context/AgentsContext';

export const AgentsNavigation = () => {
  const location = useLocation();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const isAgents =
    location.pathname === '/agents' ||
    (location.pathname.startsWith('/agents') && !location.pathname.includes('calls'));
  const isCalls = location.pathname.includes('calls');
  // const isTemplates = location.pathname.includes('/ai/templates');

  const automationsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'Agents',
          image: <HiLightningBolt size={18} />,
          isActive: isAgents,
          link: '/agents',
        },
        {
          title: 'Calls',
          image: <HiPhone size={18} />,
          isActive: isCalls,
          link: '/agents/calls',
        },
      ],
    },
    // {
    //   sideNavigationGroup: [
    //     {
    //       title: 'Browse Templates',
    //       image: <HiSearch size={18} />,
    //       isActive: isTemplates,
    //       link: '/ai/templates',
    //     },
    //   ],
    // },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Agents"
          sideNavigationTabs={automationsSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
};

export const AgentsContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <MainLayout>
      <Helmet>
        <title>Whippy | Agents</title>
      </Helmet>
      {isDesktop && <AgentsNavigation />}
      <AgentProvider>
        <LayoutContent css={{ width: isDesktop ? 'calc(100% - 180px)' : '100%' }}>
          {children}
        </LayoutContent>
      </AgentProvider>
    </MainLayout>
  );
};
