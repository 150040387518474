/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, isObject } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VirtuosoHandle } from 'react-virtuoso';

import { useCustomData } from '@/pages/settings/organization/data/context/CustomDataContext';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { FilteredTable } from '@/shared/components/filters/FiltersTable';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { CustomObject, RowValueType } from '@/shared/types/data';
import {
  ColumnType,
  FilterType,
  Resource,
  Sort,
  SortConfig,
} from '@/shared/types/filter';
import { Box } from '@/shared/ui';

import { useData } from '../context/DataContext';
import { Cell } from '../utils/ContactsTable';
import {
  handleFilterChange,
  handleInfiniteScroll,
  handleSortChange,
} from '../utils/filterActions';
import { RecordContentDialog } from './RecordContentDialog';

export const Records = (): JSX.Element => {
  const params = useParams<{ id: string }>();

  const records = useData();
  const { getObjectRecords, updateObjectRecordFilters, dataState } = records;
  const {
    customObjectRecords,
    loadingCustomObjects,
    loadingMore,
    customObjectsFilters,
    totalCount,
  } = dataState;

  const custom = useCustomData();
  const { getCustomObject } = custom;

  const [currentObject, setCurrentObject] = useState<CustomObject | null>(null);
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const getCustomObjectAsync = async (id: string) => {
    const data = await getCustomObject(id);
    setCurrentObject(data);
  };

  useEffect(() => {
    setActiveFilters([]);
    if (params.id) {
      getCustomObjectAsync(params.id);
    }

    // on unmount set the current upload to null
    return () => {
      debouncedUpdateObjectRecordFilters({
        filter: [],
        searchFilter: [],
        sort: [],
        limit: 100,
        offset: 0,
      });
    };
  }, [params.id]);

  useEffect(() => {
    if (currentObject && currentObject.id) {
      getObjectRecords(currentObject.id, {
        filter: [],
        searchFilter: [],
        sort: [],
        limit: 100,
        offset: 0,
      });
    }
  }, [currentObject?.id]);

  useEffect(() => {
    if (currentObject && currentObject.id) {
      getObjectRecords(currentObject.id, customObjectsFilters);
    }
  }, [customObjectsFilters]);

  const debouncedUpdateObjectRecordFilters = useCallback(
    debounce(updateObjectRecordFilters, 500),
    []
  );

  const title = currentObject?.label || '';
  const properties = currentObject?.custom_properties || [];

  type RowType = {
    properties: {
      [key: string]: string | number | null;
    };
  };

  const columns = useMemo(
    () =>
      properties.map((property) => ({
        Header: property.label,
        // Accessing nested properties in the object
        accessor: (row: RowType) => row.properties[property.key],
        colWidth: 200,
        Cell: ({
          value,
        }: {
          value: string | number | null | Array<RowValueType> | RowValueType;
        }) => {
          if (isObject(value)) {
            return <RecordContentDialog value={value} />;
          } else {
            return <Cell value={value as string | number} />;
          }
        },
      })),
    [properties, params.id]
  );

  // map over the properties and create the columns
  const data = customObjectRecords.map((record) => {
    const properties = record.properties;
    const row = { ...record, properties };
    return row;
  });

  const tableRef = useRef<VirtuosoHandle>(null);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Data', path: '/data/contacts' },
        { title: 'Objects', path: '/data/contacts' },
        { title: `${title}`, path: `/data/lists/${params.id}` },
      ]}
    >
      <Box css={{ backgroundColor: 'white', height: '100%', overflow: 'hidden' }}>
        <Box css={{ padding: 24 }}>
          <CombinedFilters
            defaultObjects={currentObject ? [currentObject] : []}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={(value: Array<FilterType>) => {
              setActiveFilters(value);
              handleFilterChange(
                debouncedUpdateObjectRecordFilters,
                customObjectsFilters,
                value
              );
            }}
            sortConfig={sortConfig}
            activeSort={customObjectsFilters.sort}
            onSortUpdate={(value: Array<Sort>) =>
              handleSortChange(updateObjectRecordFilters, customObjectsFilters, value)
            }
          />
        </Box>
        <Box>
          <FilteredTable
            columns={columns}
            data={data}
            isLoading={loadingCustomObjects}
            showTotalCount
            totalCount={totalCount}
            onEndReached={() =>
              handleInfiniteScroll(
                updateObjectRecordFilters,
                customObjectsFilters,
                data,
                totalCount,
                loadingMore
              )
            }
            emptyStateElement={<Box>No records found</Box>}
            tableRef={tableRef}
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

const sortConfig: SortConfig<
  Resource.CustomObjectRecord,
  ColumnType.UpdatedAt | ColumnType.InsertedAt
> = {
  label: 'Sort',
  columnOptions: [
    {
      label: 'Updated At',
      column: ColumnType.UpdatedAt,
      resource: Resource.CustomObjectRecord,
      order: 'desc',
    },
    {
      label: 'Inserted At',
      column: ColumnType.InsertedAt,
      resource: Resource.CustomObjectRecord,
      order: 'desc',
    },
  ],
};
