import { Link } from 'react-router-dom';

import { styled } from '@/stitches.config';

import { Flex, Text, Tooltip, TooltipContent, TooltipTrigger } from '../../ui';
import { sideNavigationTabType } from './SideNavigation';

export const SideNavigationItem = styled('button', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  padding: '0px 15px',
  position: 'relative',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '#EFF2FF',
        color: '$primaryColor !important',
        fontWeight: 600,
        '&:hover': {
          backgroundColor: '#EFF2FF',
          color: '$primaryColor !important',
        },
      },
    },
    size: {
      '1': {
        height: '28px',
      },
      '2': { height: '45px' },
      '3': {
        height: '35px',
        width: '35px',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          width: '18px',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'inherit',
        },
      },
    },
  },
  '& svg': {
    width: '13.75px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'inherit',
  },
  [`& ${Text}`]: {
    ml: '10px',
    flex: '1',
    minWidth: '0px',
    textAlign: 'left',
    fontWeight: 500,
    color: 'inherit',
    py: 3,
  },
  defaultVariants: {
    size: 1,
  },
});

export const SideNavigationHeading = styled(Flex, {
  flex: 'initial 0 initial',
  paddingLeft: 15,
  color: '$slate12',
  variants: {
    size: {
      '1': {
        fontSize: 14,
        fontWeight: 600,
        height: '36px',
        letterSpacing: '0.5px',
      },
      '2': {
        fontSize: 18,
        fontWeight: 800,
        height: '56px',
        letterSpacing: '0.5px',
      },
    },
  },
  defaultVariants: {
    size: 2,
  },
});

export const SideNavigationCount = styled('span', {
  padding: '2px 8px',
  color: '#00259ECB',
  backgroundColor: '#0144FF0F',
  borderRadius: 3,
  fontSize: 12,
  fontWeight: '500',
});

export const SideNavigationTooltipButton = ({
  navigation,
  expanded,
}: {
  navigation: sideNavigationTabType;
  expanded: boolean;
}) => {
  return expanded ? (
    <SideNavigationButton expanded={expanded} navigation={navigation} />
  ) : (
    <Tooltip>
      <TooltipTrigger>
        <SideNavigationButton expanded={expanded} navigation={navigation} />
      </TooltipTrigger>
      <TooltipContent side="left">{navigation.title}</TooltipContent>
    </Tooltip>
  );
};

export const SideNavigationButton = ({
  navigation,
  expanded,
}: {
  navigation: sideNavigationTabType;
  expanded: boolean;
}) => {
  if (navigation.link) {
    return (
      <Link
        data-testid="side-navigation-link"
        key={navigation.title}
        to={navigation.link}
      >
        <SideNavigationItem active={navigation.isActive} size={expanded ? 1 : 3}>
          <div>{navigation.image}</div>
          {expanded && (
            <Flex align="center" justify="between" css={{ flex: 1 }}>
              <Text
                data-testid="side-navigation-button-title"
                variant="sidenavigation"
                css={{ width: 'calc(100% - 30px)' }}
              >
                {navigation.title}
              </Text>
              {navigation?.count ? (
                <SideNavigationCount
                  data-testid="side-navigation-button-count"
                  css={{ flex: 'none' }}
                >
                  {navigation?.count}
                </SideNavigationCount>
              ) : null}
            </Flex>
          )}
        </SideNavigationItem>
      </Link>
    );
  } else if (navigation.action) {
    return (
      <SideNavigationItem
        data-testid="side-navigation-button"
        key={navigation.title}
        active={navigation.isActive}
        onClick={navigation.action}
        size={expanded ? 1 : 3}
      >
        <div>{navigation.image}</div>
        {expanded && (
          <Text data-testid="side-navigation-button-title">{navigation.title}</Text>
        )}
      </SideNavigationItem>
    );
  } else {
    return (
      <a href={navigation.href} target={navigation.target}>
        <SideNavigationItem
          key={navigation.title}
          active={navigation.isActive}
          size={expanded ? 1 : 3}
        >
          <div>{navigation.image}</div>
          {expanded && (
            <Text data-testid="side-navigation-button-title">{navigation.title}</Text>
          )}
        </SideNavigationItem>
      </a>
    );
  }
};
