import React from 'react';

import { Card } from '@/pages/links/analytics/Card';
import { Card as LinkCard } from '@/pages/links/analytics/Card';
import { Chart } from '@/pages/links/analytics/Chart';
import { DevicesSection } from '@/pages/links/analytics/DevicesSection';
import { LocationsSection } from '@/pages/links/analytics/LocationsSection';
import { ReferrerSection } from '@/pages/links/analytics/ReferrerSection';
import { useLinks } from '@/pages/links/context/LinksContext';
import { ReportData } from '@/shared/api/reports';
import { Grid } from '@/shared/ui';

const chart_config = [
  {
    dataKey: 'count',
    fill: '#8DA4EF',
    name: 'Total Clicks',
  },
];

const responses_config = [
  {
    dataKey: 'count',
    fill: '#8DA4EF',
    name: 'Responses',
  },
];

const unsubscribes_config = [
  {
    dataKey: 'count',
    fill: '#F76808',
    name: 'Unsubscribes',
  },
];

const stacked_chart_config = [
  {
    dataKey: 'delivered',
    fill: '#3DB9CF',
    name: 'Delivered',
    stackId: 'bar',
  },
  {
    dataKey: 'failed',
    fill: '#ED8A5C',
    name: 'Failed',
    stackId: 'bar',
  },
];

const reportCards = [
  {
    label: 'Sent Messages',
    type: 'total',
    description: 'The total number of sent messages during the selected time period.',
    cardType: 'card',
  },
  {
    label: 'Delivered Messages',
    type: 'total',
    description:
      'The total number of delivered messages during the selected time period.',
    cardType: 'card',
  },
  {
    label: 'Failed Messages',
    type: 'total',
    description: 'The total number of failed messages during the selected time period.',
    cardType: 'card',
  },
  {
    label: 'Responses',
    type: 'total',
    description: 'The total number of responses during the selected time period.',
    cardType: 'card',
  },
  {
    label: 'Link Clicks',
    type: 'total',
    description: 'The total number of links created during the selected time period.',
    value: 'selected_days',
  },
  {
    label: 'Unsubscribes',
    type: 'total',
    description: 'The total number of unsubscribes during the selected time period.',
    cardType: 'card',
  },
];

export type EstimatedSavingsData = {
  timePerCompleteCall: number | null;
  timePerIncompleteCall: number | null;
  rate: number | null;
};

export const CampaignAnalyticsView = ({
  data,
  campaignResponses,
  campaignUnsubscribes,
  stackedReportBar,
  isLoading,
  time_bucket,
  error,
}: {
  data: Array<ReportData>;
  campaignResponses: any;
  campaignUnsubscribes: any;
  stackedReportBar: any;
  isLoading: boolean;
  time_bucket: string;
  error: boolean;
}) => {
  const {
    linksState: { currentLinkAnalytics },
  } = useLinks();

  return (
    <>
      <Grid columns="3" gap="4">
        {reportCards.map((call) => (
          <>
            {call.cardType ? (
              <Card
                key={call.label}
                title={call.label}
                value={
                  data.find((d) => d.chart === call.type && d.label == call.label)
                    ?.values[0]?.x as number
                }
                tooltipContent={call.description}
                loading={isLoading}
                hidePercent
              />
            ) : (
              <LinkCard
                key={call.label}
                title={call.label}
                value={currentLinkAnalytics?.[call.value ?? '']?.count || 0}
                tooltipContent={call.description}
                loading={isLoading}
                hidePercent
              />
            )}
          </>
        ))}
      </Grid>
      <Chart
        time_bucket={time_bucket}
        title="Messages Over Time"
        chartConfig={stacked_chart_config}
        data={stackedReportBar || []}
        loading={isLoading}
        barSize={56}
      />
      <Chart
        time_bucket={time_bucket}
        title="Responses Over Time"
        chartConfig={responses_config}
        data={campaignResponses || []}
        loading={isLoading}
        barSize={56}
      />
      <Chart
        time_bucket={time_bucket}
        title="Unsubscribes Over Time"
        chartConfig={unsubscribes_config}
        data={campaignUnsubscribes || []}
        loading={isLoading}
        barSize={56}
      />
      <Chart
        title="Link Clicks Over Time"
        chartConfig={chart_config}
        data={currentLinkAnalytics?.selected_days?.per_day || []}
        loading={isLoading}
        barSize={56}
      />
      <LocationsSection data={currentLinkAnalytics} loading={isLoading} error={error} />
      <DevicesSection data={currentLinkAnalytics} loading={isLoading} error={error} />
      <ReferrerSection
        title="Total Clicks by Referrer"
        chartConfig={[{ ...chart_config[0], fill: '#16AF8A' }]}
        data={currentLinkAnalytics?.by_referrer || []}
        loading={isLoading}
      />
    </>
  );
};
