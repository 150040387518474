/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';

import {
  DATE_MONTH_DAY_FORMAT,
  DATE_MONTH_DAY_YEAR_FORMAT,
  DAY,
  HOUR,
} from '@/shared/components/reports/constants';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { Box, Flex, VStack } from '@/shared/ui';
import { keyframes, styled } from '@/stitches.config';

import { EmptyStateAnalytics } from './EmptyStateAnalytics';
import { ErrorStateAnalytics } from './ErrorStateAnalytics';

type BarChartConfig = {
  /** data to display in chart */
  dataKey: string;
  /** color of the bar */
  fill: string;
  /** name of the bar */
  name: string;
  stackId?: string;
};

type ChartProps = {
  /** title of the chart */
  title?: string;
  /** data to be displayed in the chart */
  chartConfig: Array<BarChartConfig>;
  /** the purpose of the chart */
  tooltipContent?: string;
  /** bar chart data */
  data: any;
  /** currently fetching data */
  loading?: boolean;
  /** fetching data failed */
  error?: boolean;
  /** retry request */
  errorCallback?: () => void;
  /** default bar size */
  barSize?: number;
  showLegend?: boolean;
  time_bucket?: string;
};

const CustomChartTooltip = ({ active, payload, label }: any) => {
  // write a function that formats a value with a comma separator if it's a number in the thousands
  const formatToolTipValue = (tickItem: number) => {
    if (tickItem >= 1000) {
      return tickItem?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return tickItem;
  };

  if (active && payload) {
    return (
      <CustomTooltipContentWrapper direction="column" justify="between">
        {payload.map((item: any, index: number) => (
          <>
            <Box
              css={{
                pb: 5,
              }}
            >
              {index === 0 && moment(label).format(DATE_MONTH_DAY_YEAR_FORMAT)}
            </Box>
            <Flex key={item.dataKey} direction="row" align="center">
              <Box>{item.name}</Box>
              <ChartTooltipValue>
                {formatToolTipValue(Math.round(item.value))}
              </ChartTooltipValue>
            </Flex>
          </>
        ))}
      </CustomTooltipContentWrapper>
    );
  }
  return null;
};

export const Chart = (props: ChartProps) => {
  const {
    title,
    chartConfig,
    tooltipContent,
    data,
    loading,
    error,
    errorCallback,
    barSize,
    time_bucket = DAY,
  } = props;
  // get tooltip coordinates to display it on top of the bar
  const [barGraphCoordinates, setBarGraphCoordinates] = useState<any>(null);

  const chartId = uuidv4();

  // is last element on the chart
  const lastIndex = data?.length - 1 === barGraphCoordinates?.index;

  // is first element of the chart
  const firstIndex = barGraphCoordinates?.index === 0;

  // position tooltip on top of the bar
  const positionChartTooltip = (tooltipWidth: number) => {
    const tooltipCenter = tooltipWidth / 2;

    // if last bar
    if (lastIndex) {
      return `left: -${
        tooltipCenter - barGraphCoordinates?.data.width + tooltipCenter
      }px;`;
      // first bar
    } else if (firstIndex) {
      return `left: -${
        tooltipCenter - barGraphCoordinates?.data.width - tooltipCenter
      }px;`;
    } else {
      return `left: -${tooltipCenter - barGraphCoordinates?.data.width / 2}px;`;
    }
  };

  // Rewrite tooltip styles
  const rewriteTooltipDefaultStyle = (tooltip: any) => {
    // Init tooltip values
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const spaceForLittleTriangle = 10;

    return (tooltip.style = `
      transform: translate(${barGraphCoordinates?.data.x}px, ${
        barGraphCoordinates?.data.y
      }px);
      pointer-events: none;
      position: absolute;
      top: -${tooltipHeight + spaceForLittleTriangle}px;
      ${positionChartTooltip(tooltipWidth)}
      opacity: ${barGraphCoordinates?.show ? '1' : 0};
    `);
  };

  useEffect(() => {
    const tooltip = document
      .getElementById(chartId)
      ?.querySelector('.recharts-tooltip-wrapper') as HTMLElement | null;
    if (!tooltip) return;

    rewriteTooltipDefaultStyle(tooltip);
  }, [barGraphCoordinates]);

  // format dates displayed on x axes
  const formatXAxis = (tickItem: string) => {
    switch (time_bucket) {
      case DAY:
        return moment(tickItem).format(DATE_MONTH_DAY_FORMAT);
      case HOUR:
        return moment(tickItem).format('MMM D, h:mm a');
      default:
        break;
    }
  };

  // loading state
  if (loading) {
    return <LoadingChart title={title} tooltipContent={tooltipContent} />;
  }

  // empty state
  if (!data?.length && !loading && !error) {
    return (
      <EmptyStateAnalytics title={title || ''} tooltipContent={tooltipContent || ''} />
    );
  }

  // error state
  if (error && !loading) {
    return (
      <ErrorStateAnalytics
        title={title || ''}
        tooltipContent={tooltipContent || ''}
        error={error}
        errorCallback={errorCallback}
      />
    );
  }

  return (
    <ReportsCollapsible title={title || ''} description={tooltipContent || ''}>
      <VStack data-testid="bar-chart" id={chartId} gap={2} css={{ mt: 24 }}>
        <ResponsiveContainer height={400}>
          <BarChart
            data={data}
            barSize={barSize ?? 20}
            margin={{
              top: 5,
              right: 5,
              left: -25,
              bottom: time_bucket === HOUR ? 50 : data.length > 20 ? 10 : 5,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" vertical={false} />
            <XAxis
              dataKey="key"
              padding={{ left: 5, right: time_bucket === HOUR ? 15 : 5 }}
              width={0}
              fontSize={12}
              fontWeight={500}
              tick={{ fontSize: 12, fill: '#60646C' }}
              angle={data.length > 20 ? -45 : 0}
              tickMargin={time_bucket === HOUR ? 30 : data.length > 20 ? 15 : 5}
              dx={data.length > 20 ? -10 : 0}
              tickFormatter={formatXAxis as any}
            />
            <YAxis axisLine={false} fontSize={9} fontWeight={500} />
            <RechartsTooltip
              // pass empty element to content to show empty tooltip if cursor is outside of the bar
              // or default recharts tooltip will be shown initially
              content={barGraphCoordinates?.show ? <CustomChartTooltip /> : <div></div>}
              position={{
                // Static position
                x: barGraphCoordinates?.data.x ?? 'auto',
                y: barGraphCoordinates?.data.y ?? 'auto',
              }}
              cursor={false}
            />
            {chartConfig.map((config, index: number) => (
              <Bar
                key={index}
                dataKey={config.dataKey}
                fill={config.fill}
                name={config.name}
                stackId={config.stackId ?? config.dataKey}
                background={{
                  fill: 'transparent',
                }}
                onMouseMove={(data, index) =>
                  setBarGraphCoordinates({ data: data, show: true, index: index })
                }
                onMouseLeave={(data) =>
                  setBarGraphCoordinates({ data: data, show: false, index: -1 })
                }
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </VStack>
    </ReportsCollapsible>
  );
};

type LoadingChartProps = {
  /** Title of the chart */
  title?: string;
  /** Tooltip content */
  tooltipContent?: string;
};

export const LoadingChart = (props: LoadingChartProps) => {
  const { title, tooltipContent } = props;

  const granularity = DAY;
  // format dates displayed on x axes
  const formatXAxis = (tickItem: string) => {
    switch (granularity) {
      case DAY:
        return moment(tickItem).format(DATE_MONTH_DAY_FORMAT);
      default:
        break;
    }
  };

  /* 
    create an array of fake data object with the shape 

      {
        count: 66
        key: "2022-08-23"
        period_end: "2022-08-23T23:59:59.999999"
        period_start: "2022-08-23T00:00:00.000000"
      }

    make the period, period_start and period_end dynamic using moment(), going back 30 days

    make the messages count a random number between 0 and 50000
  */

  const fakeData = Array.from({ length: 20 }, (_, i) => {
    const date = moment().subtract(i, 'days');
    return {
      key: date.format('YYYY-MM-DD'),
      // period_start: date.startOf('day').format(),
      // period_end: date.endOf('day').format(),
      count: Math.floor(Math.random() * 5000),
    };
  });

  // reverse the array so that the data is in ascending order
  fakeData.reverse();

  // create a function that changes the fake every 2 seconds in a wave like pattern
  const [fakeChartData, setFakeChartData] = useState(fakeData);

  // change the fake data every 0.75 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setFakeChartData((prev) => {
        const newFakeData = prev.map((item) => {
          return {
            ...item,
            count: Math.floor(Math.random() * 5000),
          };
        });
        return newFakeData;
      });
    }, 750);
    return () => clearInterval(interval);
  }, []);

  return (
    <ReportsCollapsible title={title || ''} description={tooltipContent || ''}>
      <ResponsiveContainer height={400}>
        <BarChart
          data={fakeChartData}
          barSize={20}
          margin={{
            top: 5,
            right: 5,
            left: -25,
            bottom: 5,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" vertical={false} />
          <XAxis
            dataKey="key"
            padding={{ left: 10, right: 10 }}
            width={0}
            fontSize={12}
            fontWeight={500}
            tickFormatter={formatXAxis as any}
          />
          <YAxis axisLine={false} fontSize={9} fontWeight={500} />
          <Bar dataKey="count" stackId="stacked" name="Messages" fill="#eceef0" />
        </BarChart>
      </ResponsiveContainer>
    </ReportsCollapsible>
  );
};

export const pulse = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: '100%' },
});

export const CustomTooltipContentWrapper = styled(Flex, {
  width: 'fit-content',
  p: 12,
  backgroundColor: '$gray12',
  borderRadius: 4,
  color: '#FFFFFF',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 14,
  outline: 'none',
});

export const ChartTooltipValue = styled('span', {
  color: '#FFFFFF',
  ml: 15,
});
