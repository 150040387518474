import React from 'react';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { CampaignOverview as Overview } from '@/pages/campaigns/analytics/overview';

import { Campaign } from './Campaign';

export const CampaignOverview = () => {
  const campaigns = useCampaignsContext();
  const { campaignsState, setCurrentCampaign } = campaigns;

  const { current } = campaignsState;

  return (
    <>
      <Campaign />
      <Overview campaign={current} setCurrentCampaign={setCurrentCampaign} />;
    </>
  );
};
