import { AdvancedFilterItemObject } from './types';

export const default_campaign_message_object: AdvancedFilterItemObject = {
  id: 'unique-id-for-campaign_message',
  label: 'Campaign Message',
  // matches the resource in the WQL
  key: 'campaign_message',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'campaign_messaged-delivery-status',
      label: 'Delivery Status',
      type: 'delivery_status',
      // matches the value in the WQL
      key: 'delivery_status',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-campaign_message_delivery_status',
    },
    {
      default: '',
      id: 'campaign_message-id',
      label: 'ID',
      type: 'text',
      key: 'id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-campaign_message_id',
    },
    {
      default: '',
      id: 'campaign_message-campaign-id',
      label: 'Campaign',
      type: 'text',
      key: 'campaign_id',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-campaign_message_campaign_id',
    },
  ],
};
