import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { ResponsiveContainer } from 'recharts';

import { Card, CardContainer, CardData, CardTitle } from '@/pages/links/analytics/Card';
import { Chart } from '@/pages/links/analytics/Chart';
import { ReportData } from '@/shared/api/reports';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { EstimatedSavingsData } from '@/shared/types';
import { Flex, Grid, VStack } from '@/shared/ui';

import { EstimatedSavingsDialog } from './EstimatedSavingsDialog';
import { Heatmap } from './Heatmap';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

const chart_config = [
  {
    dataKey: 'count',
    fill: '#3E54CF',
    name: 'Call',
  },
];

const chart_config2 = [
  {
    dataKey: 'negative',
    fill: 'red',
    name: 'Negative',
    stackId: 'bar',
  },
  {
    dataKey: 'neutral',
    fill: '#FBE32D',
    name: 'Neutral',
    stackId: 'bar',
  },
  {
    dataKey: 'positive',
    fill: 'green',
    name: 'Positive',
    stackId: 'bar',
  },
];

const agentCalls = [
  {
    label: 'Total AI Calls',
    type: 'total',
    description:
      'The total number of agent calls created during the selected time period.',
  },
  {
    label: 'Complete AI Calls',
    type: 'total',
    description:
      'The total number of complete agent calls created during the selected time period.',
  },
  {
    label: 'Incomplete AI Calls',
    type: 'total',
    description:
      'The total number of incomplete agent calls created during the selected time period.',
  },
  {
    label: 'Average Completed AI Call Duration',
    type: 'total',
    description: 'Average Completed AI Call Duration',
    unit: 'min',
  },
  {
    label: 'Hours Automated',
    type: 'total',
    description: 'Hours Automated',
    unit: 'h',
  },
];

const agentCallSentiments = [
  {
    label: 'Positive Sentiment AI Calls',
    type: 'total',
    description:
      'The total number of agent calls with positive sentiment created during the selected time period.',
  },
  {
    label: 'Negative Sentiment AI Calls',
    type: 'total',
    description:
      'The total number of agent calls with negative sentiment created during the selected time period.',
  },
  {
    label: 'Neutral Sentiment AI Calls',
    type: 'total',
    description:
      'The total number of agent calls with neutral sentiment created during the selected time period.',
  },
];

export const AgentsAnalytics = ({
  id,
  data,
  reportBar,
  stackedReportBar,
  isLoading,
}: {
  data: Array<ReportData>;
  reportBar: any;
  stackedReportBar: any;
  isLoading: boolean;
  id?: string;
}) => {
  const [estimatedSavingsData, setEstimatedSavingsData] = useState<EstimatedSavingsData>({
    timePerCompleteCall: null,
    timePerIncompleteCall: null,
    rate: null,
  });

  const estimatedSavings = useMemo(() => {
    const completeCalls = data.find(
      (d) => d.chart === 'total' && d.label == 'Complete AI Calls'
    )?.values[0]?.x as number;
    const incompleteCalls = data.find(
      (d) => d.chart === 'total' && d.label == 'Incomplete AI Calls'
    )?.values[0]?.x as number;
    if (
      completeCalls &&
      incompleteCalls &&
      estimatedSavingsData.rate &&
      estimatedSavingsData.timePerCompleteCall &&
      estimatedSavingsData.timePerIncompleteCall
    ) {
      const result =
        ((completeCalls * estimatedSavingsData.timePerCompleteCall +
          incompleteCalls * estimatedSavingsData.timePerIncompleteCall) /
          60) *
        estimatedSavingsData.rate;
      return `${(Math.round(result * 100) / 100).toFixed(2)} USD`;
    } else {
      return '-';
    }
  }, [data, estimatedSavingsData]);

  useEffect(() => {
    if (id) {
      const data = localStorage.getItem(`estimatedSavingsData-${id}`);
      if (data) {
        setEstimatedSavingsData(JSON.parse(data));
      }
    }
  }, [id]);

  const updateEstimatedSavingsData = (data: EstimatedSavingsData) => {
    localStorage.setItem(`estimatedSavingsData-${id}`, JSON.stringify(data));
    setEstimatedSavingsData(data);
  };

  return (
    <>
      <Grid columns="3" gap="5" data-testid="agents-analytics">
        {agentCalls.map((call) => (
          <Card
            key={call.label}
            title={call.label}
            value={
              data.find((d) => d.chart === call.type && d.label == call.label)?.values[0]
                ?.x as number
            }
            tooltipContent={call.description}
            loading={isLoading}
            unit={call.unit}
            hidePercent
          />
        ))}
        {!!id && (
          <CardContainer direction="column">
            <VStack gap={2}>
              <Flex direction="row" align="center" css={{ color: '#60646C' }}>
                <CardTitle>Estimated Savings</CardTitle>
                <EstimatedSavingsDialog
                  estimatedSavingsData={estimatedSavingsData}
                  setEstimatedSavingsData={updateEstimatedSavingsData}
                />
              </Flex>
              <Flex direction="row" align="center" justify="start" gap={1}>
                <CardData>{estimatedSavings}</CardData>
              </Flex>
            </VStack>
          </CardContainer>
        )}
      </Grid>
      <Chart
        title="Calls by Day"
        chartConfig={chart_config}
        data={reportBar || []}
        loading={isLoading}
        barSize={56}
      />
      <ReportsCollapsible title="Call Heatmap" description="">
        {!!data.length && (
          <ResponsiveContainer>
            <Heatmap data={data} loading={isLoading} />
          </ResponsiveContainer>
        )}
      </ReportsCollapsible>
      <Grid columns="3" gap="5">
        {agentCallSentiments.map((call) => (
          <Card
            key={call.label}
            title={call.label}
            value={
              data.find((d) => d.chart === call.type && d.label == call.label)?.values[0]
                ?.x as number
            }
            tooltipContent={call.description}
            loading={isLoading}
            hidePercent
          />
        ))}
      </Grid>
      <Chart
        title="Sentiment of Calls by Day"
        chartConfig={chart_config2}
        data={stackedReportBar || []}
        loading={isLoading}
        barSize={56}
      />
    </>
  );
};
