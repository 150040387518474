import { useState } from 'react';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { Channel } from '@/shared/types/channels';
import { Box } from '@/shared/ui';

import { ValueCombobox } from './Combobox';

type ChannelValuesComboboxProps = {
  selected: { label?: string; value: string | string[] | number } | null | undefined;
  onSelect: (option: { label: string; value: string | string[] | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
  disabled?: boolean;
};

export const ChannelValuesCombobox = (props: ChannelValuesComboboxProps): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');

  const locationsContext = useChannels();
  const { channelsState } = locationsContext;
  const { channels } = channelsState;

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
  };

  return (
    <Box>
      <ValueCombobox
        disabled={props.disabled}
        isOpen={props.isOpen}
        selectorStyles={props.selectorStyles}
        options={channels.map((channel: Channel) => ({
          label: channel.name || '',
          value: channel.id || '',
        }))}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
      />
    </Box>
  );
};
