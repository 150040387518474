import moment, { Moment } from 'moment';
import { createContext, Dispatch, useContext, useReducer, useState } from 'react';
import { toast } from 'sonner';

import { LinkParams, linksAPI, LinksParams } from '@/shared/api/links';
import { CustomCardOptions, Link } from '@/shared/types/links';
import i18next from '@/shared/utils/translation';

import { LinksReducer } from './LinksReducer';
import { LinksActions, LinksActionTypes } from './types';

export type LinksState = {
  /* all links in state */
  links: Array<Link> | Array<null>;
  /* total amount of links */
  totalCount: number;
  /* loading state */
  loading: boolean;
  /* current link */
  current: Link | null;
  /* current link analytics */
  currentLinkAnalytics: any | null;
  /* error */
  error: string | null;
};

export const initialSocialCard = {
  custom_card_title: null,
  custom_card_description: null,
  custom_card_image: null,
} as CustomCardOptions;

export const initialLinksState: LinksState = {
  links: [],
  totalCount: 0,
  loading: true,
  current: null,
  currentLinkAnalytics: null,
  error: null,
};

export const initialDates = {
  startDate: moment().add(-30, 'day'),
  endDate: moment(),
};

export const LinksContext = createContext<{
  linksState: LinksState;
  getLink: (id: string) => void;
  setCurrentLink: (link: Link | null) => void;
  getLinks: (params: LinksParams) => void;
  createLink: (params: LinkParams) => Promise<Link | void>;
  updateLink: (id: string, params: LinkParams) => Promise<Link | void>;
  deleteLink: (id: string) => void;
  clearError: () => void;
  showPanel: boolean;
  setShowPanel: (value: boolean | ((prevState: boolean) => boolean)) => void;
  ogData: CustomCardOptions;
  setOgData: (value: CustomCardOptions) => void;
  getLinkAnalytics: (
    id: string,
    startDate: string,
    endDate: string,
    column?: string
  ) => Promise<any | void>;
  setCurrentLinkAnalytics: (data: any | null) => void;
  dates: { startDate: Moment; endDate: Moment };
  setDates: Dispatch<any>;
}>({
  linksState: initialLinksState,
  setCurrentLink: () => Promise.resolve(),
  getLink: () => Promise.resolve({} as Link),
  getLinks: () => Promise.resolve([] as Array<Link>),
  createLink: () => Promise.resolve({} as Link),
  updateLink: () => Promise.resolve({} as Link),
  deleteLink: () => Promise.resolve(),
  clearError: () => Promise.resolve(),
  showPanel: false,
  setShowPanel: () => Promise.resolve(),
  ogData: initialSocialCard,
  setOgData: () => Promise.resolve(),
  getLinkAnalytics: () => Promise.resolve(),
  setCurrentLinkAnalytics: () => Promise.resolve(),
  dates: initialDates,
  setDates: () => Promise.resolve(),
});

export const useLinks = () => useContext(LinksContext);

const LinksProvider = ({ children }: { children: React.ReactNode }) => {
  const [linksState, dispatch]: [LinksState, Dispatch<LinksActions>] = useReducer(
    LinksReducer,
    initialLinksState
  );

  const [showPanel, setShowPanel] = useState(false);
  const [ogData, setOgData] = useState<CustomCardOptions>(initialSocialCard);

  const [dates, setDates] = useState(initialDates);

  const getLink = async (id: string): Promise<Link | null> => {
    try {
      const { data } = await linksAPI.getLink(id);

      dispatch({
        type: LinksActionTypes.GET_LINK,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const getLinks = async (params: LinksParams) => {
    try {
      const data: { data: Array<Link> | Array<null>; total: number } =
        await linksAPI.searchLinks(params);

      dispatch({
        type: LinksActionTypes.GET_LINKS,
        payload: { links: data.data, offset: params?.offset || 0, total: data.total },
      });

      return data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const createLink = async (params: LinkParams): Promise<Link | void> => {
    try {
      const { data } = await linksAPI.createLink(params);

      dispatch({
        type: LinksActionTypes.CREATE_LINK,
        payload: data,
      });

      toast.success(i18next.t('link_created_success') as string);

      return data;
    } catch (err) {
      if (err?.response?.data) {
        dispatch({
          type: LinksActionTypes.SET_ERROR,
          payload: err?.response?.data?.errors[0]?.description,
        });
        toast.error(err?.response?.data?.errors[0]?.description);
      } else {
        toast.error(i18next.t('link_created_failure') as string);
      }
      console.error(err);
    }
  };

  const updateLink = async (id: string, params: LinkParams): Promise<Link | void> => {
    try {
      const { data } = await linksAPI.updateLink(id, params);

      dispatch({
        type: LinksActionTypes.UPDATE_LINK,
        payload: data,
      });

      toast.success(i18next.t('link_updated_success') as string);

      return data;
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err?.response?.data?.errors[0]?.description);
      } else {
        toast.error(i18next.t('link_updated_failure') as string);
      }

      console.error(err);
    }
  };

  const deleteLink = async (id: string): Promise<Link> => {
    try {
      const data = await linksAPI.deleteLink(id);

      if (id) {
        dispatch({
          type: LinksActionTypes.DELETE_LINK,
          payload: id,
        });

        toast.success(i18next.t('link_deleted_success') as string);
      }

      return data;
    } catch (err) {
      toast.error(i18next.t('link_deleted_failure') as string);
      console.error(err);
      return Promise.reject(err);
    }
  };

  const setCurrentLink = (link: Link | null) => {
    try {
      dispatch({
        type: LinksActionTypes.SET_CURRENT,
        payload: link,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const clearError = () => {
    dispatch({
      type: LinksActionTypes.SET_ERROR,
      payload: null,
    });
  };

  const getLinkAnalytics = async (
    id: string,
    startDate: string,
    endDate: string,
    column?: string
  ) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const period = end.diff(start, 'days');
    const prevStart = moment(startDate).add(-period, 'day').format('YYYY-MM-DD');
    try {
      const { data } = await linksAPI.linkAnalytics({
        filter: [
          {
            column: column ? column : 'metadata.link_setting_id',
            comparison: '==',
            resource: 'event',
            value: id,
          },
          {
            resource: 'event',
            column: 'inserted_at',
            comparison: '<=',
            value: `${endDate}`,
          },
          {
            resource: 'event',
            column: 'inserted_at',
            comparison: '>=',
            value: `${startDate}`,
          },
        ],
        aggregation: [
          {
            name: 'last_seven_days',
            filter: [
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '<=',
                value: 'now/d',
              },
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '>=',
                value: 'now-7d/d',
              },
            ],
            aggregation: [
              {
                name: 'per_day',
                resource: 'event',
                column: 'inserted_at',
                type: 'date_histogram',
                interval: 'day',
                format: 'yyyy-MM-dd',
              },
            ],
          },
          {
            name: 'prev_last_seven_days',
            filter: [
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '<=',
                value: 'now-7d/d',
              },
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '>=',
                value: 'now-14d/d',
              },
            ],
            aggregation: [
              {
                name: 'per_day',
                resource: 'event',
                column: 'inserted_at',
                type: 'date_histogram',
                interval: 'day',
                format: 'yyyy-MM-dd',
              },
            ],
          },
          {
            name: 'selected_days',
            filter: [
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '<=',
                value: `${endDate}`,
              },
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '>=',
                value: `${startDate}`,
              },
            ],
            aggregation: [
              {
                name: 'per_day',
                resource: 'event',
                column: 'inserted_at',
                type: 'date_histogram',
                interval: 'day',
                format: 'yyyy-MM-dd',
              },
            ],
          },
          {
            name: 'prev_selected_days',
            filter: [
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '<=',
                value: `${startDate}`,
              },
              {
                resource: 'event',
                column: 'inserted_at',
                comparison: '>=',
                value: `${prevStart}`,
              },
            ],
            aggregation: [
              {
                name: 'per_day',
                resource: 'event',
                column: 'inserted_at',
                type: 'date_histogram',
                interval: 'day',
                format: 'yyyy-MM-dd',
              },
            ],
          },
          {
            name: 'by_country',
            resource: 'link_tracking',
            column: 'geolocation.country',
            type: 'terms',
            aggregation: [
              {
                name: 'unique',
                resource: 'link_tracking',
                column: 'geolocation.query',
                type: 'cardinality',
              },
            ],
          },
          {
            name: 'by_city',
            resource: 'link_tracking',
            column: 'geolocation.city',
            type: 'terms',
            aggregation: [
              {
                name: 'unique',
                resource: 'link_tracking',
                column: 'geolocation.query',
                type: 'cardinality',
              },
              {
                name: 'country',
                resource: 'link_tracking',
                column: 'geolocation.country',
                type: 'terms',
              },
            ],
          },
          {
            name: 'by_device',
            resource: 'link_tracking',
            column: 'user_agent.device.type',
            type: 'terms',
            aggregation: [
              {
                name: 'unique',
                resource: 'link_tracking',
                column: 'geolocation.query',
                type: 'cardinality',
              },
              {
                name: 'os',
                resource: 'link_tracking',
                column: 'user_agent.os_family',
                type: 'terms',
                aggregation: [
                  {
                    name: 'unique',
                    resource: 'link_tracking',
                    column: 'geolocation.query',
                    type: 'cardinality',
                  },
                ],
              },
            ],
          },
          {
            name: 'by_browser',
            resource: 'link_tracking',
            column: 'user_agent.browser_family',
            type: 'terms',
            aggregation: [
              {
                name: 'unique',
                resource: 'link_tracking',
                column: 'geolocation.query',
                type: 'cardinality',
              },
            ],
          },
          {
            name: 'by_os',
            resource: 'link_tracking',
            column: 'user_agent.os_family',
            type: 'terms',
            aggregation: [
              {
                name: 'unique',
                resource: 'link_tracking',
                column: 'geolocation.query',
                type: 'cardinality',
              },
            ],
          },
          {
            name: 'by_referrer',
            resource: 'link_tracking',
            column: 'referrer',
            type: 'terms',
          },
          {
            name: 'by_link',
            resource: 'link',
            column: 'id',
            type: 'terms',
          },
        ],
      });

      dispatch({
        type: LinksActionTypes.GET_CURRENT_LINK_ANALYTICS,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const setCurrentLinkAnalytics = (analyticsData: any | null) => {
    try {
      dispatch({
        type: LinksActionTypes.SET_CURRENT_LINK_ANALYTICS,
        payload: analyticsData,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <LinksContext.Provider
      value={{
        linksState,
        getLink,
        getLinks,
        createLink,
        updateLink,
        deleteLink,
        setCurrentLink,
        clearError,
        showPanel,
        setShowPanel,
        ogData,
        setOgData,
        getLinkAnalytics,
        setCurrentLinkAnalytics,
        dates,
        setDates,
      }}
    >
      {children}
    </LinksContext.Provider>
  );
};

export default LinksProvider;
