/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useAuth } from '@/auth/context/AuthProvider';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import * as API from '@/shared/api/users';
import { FormFieldWrapper, PhoneNumberInput, TextInput } from '@/shared/components/forms';
import { Box, Button, Fieldset, Label, VStack } from '@/shared/ui';
import { isValidPhoneNumber, toE164 } from '@/shared/utils/validations/validations';

import AccountProfilePicture from './AccountProfilePicture';

function AccountView(): JSX.Element {
  const { updateUser } = useUsers();
  const auth = useAuth();
  const email = auth?.tokens?.email || '';
  const userId = auth?.tokens?.user_id || 0;
  const [user, setUser] = useState({ name: '', phone: '' });

  useEffect(() => {
    async function getUserProfile() {
      const data = await API.getUser(userId);
      if (data) {
        setUser({ ...user, ...{ name: data.name, phone: data.phone } });
      }
    }
    getUserProfile();
  }, []);

  return (
    <Box>
      <VStack gap={2}>
        <Fieldset>
          <Label>Profile Picture</Label>
          <AccountProfilePicture />
        </Fieldset>
        <Formik
          enableReinitialize
          initialValues={{
            email: email || '',
            name: user?.name || '',
            phone: user?.phone || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            phone: Yup.string()
              .nullable()
              .test(
                'phone',
                'Please provide a valid phone number',
                (value: string | null | undefined): boolean => {
                  if (!value || value.trim() === '') return true;
                  return Boolean(isValidPhoneNumber(value));
                }
              ),
          })}
          onSubmit={async (values) => {
            const params = {
              name: values.name,
              phone: toE164(values.phone) || null,
              id: userId,
            };
            try {
              updateUser(params);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <VStack gap={2}>
                <FormFieldWrapper label="Full Name" name="name">
                  <TextInput />
                </FormFieldWrapper>
                <FormFieldWrapper label="Email" name="email">
                  <TextInput disabled={true} />
                </FormFieldWrapper>
                <FormFieldWrapper label="Phone" name="phone">
                  <PhoneNumberInput />
                </FormFieldWrapper>
                <Box>
                  <Button type="submit">Update Profile</Button>
                </Box>
              </VStack>
            </form>
          )}
        </Formik>
      </VStack>
    </Box>
  );
}

export default AccountView;
