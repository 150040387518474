import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HiChartBar, HiSpeakerphone } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { AgentReportIcon, ConversationsReportIcon } from '@/shared/components/Icons';
import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import ReportsState from '../context/ReportsContext';

export function ReportsNavigation(): JSX.Element {
  const location = useLocation();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const reportsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'Usage',
          image: <HiChartBar size={18} />,
          isActive: location.pathname.includes('/reports/usage'),
          link: '/reports/usage',
        },
        {
          title: 'Campaigns',
          image: <HiSpeakerphone size={18} />,
          isActive: location.pathname.includes('/reports/campaigns'),
          link: '/reports/campaigns',
        },
        {
          title: 'Agents',
          image: <AgentReportIcon />,
          isActive: location.pathname.includes('/reports/agents'),
          link: '/reports/agents',
        },
        {
          title: 'Conversations',
          image: <ConversationsReportIcon />,
          isActive: location.pathname.includes('/reports/conversations'),
          link: '/reports/conversations',
        },
      ],
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Reports"
          sideNavigationTabs={reportsSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const ReportsContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <ReportsState>
      <MainLayout>
        <Helmet>
          <title>Whippy | Reports</title>
        </Helmet>
        {isDesktop && <ReportsNavigation />}
        <LayoutContent>{children}</LayoutContent>
      </MainLayout>
    </ReportsState>
  );
};
