/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorBoundary, Scope } from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce, isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { VirtuosoHandle } from 'react-virtuoso';

import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { usePageView } from '@/shared/hooks';
import {
  ConversationFilterTypes,
  UnreadConversationCountType,
} from '@/shared/types/conversations';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerPortal,
  Flex,
  Text,
  VStack,
} from '@/shared/ui';

import { MessageEditorWrapper } from '../../../shared/components/editor/Styles';
import { useConversation } from '../context/ConversationContext';
import { ConversationEditor } from './editor/ConversationEditor';
import { ConversationHeader } from './header';
import { ConversationItems } from './items';
import { ConversationPanels } from './panels';
import { ConversationToolbar } from './panels/ConversationToolbar';

type ParamTypes = {
  filter: ConversationFilterTypes;
  id: string;
};

export function ConversationContainer(): JSX.Element {
  // track conversation page view
  usePageView();

  // use the useRef hook to keep track the unread counts when open new conversation
  const unreadCountsRef = useRef<number>(0);

  const params = useParams<ParamTypes>();

  // track window size
  const isDesktop = useMedia('(min-width: 912px)');
  const isLargeDesktop = useMedia('(min-width: 1550px)');

  // conversation state
  const conversations = useConversation();
  const {
    conversationState,
    getConversationMessages,
    totalUnread,
    toggleConversationPanel,
    body,
    setUnreadCounts,
  } = conversations;
  const { current, showConversationPanel, unread_counts } = conversationState;

  const { showInboxUnreadCounts } = useFlags();

  // calculate page title
  const showUnreadCount = () => {
    if (totalUnread > 0) {
      return totalUnread;
    } else return 'Whippy';
  };

  // track messages list scroll position
  const virtuoso = useRef<VirtuosoHandle>(null);

  const handlePointerDownOutside = (e: Event) => {
    const addressInput = document.querySelector('.pac-container') as HTMLElement;
    if (!addressInput?.contains(e.currentTarget as Node)) {
      toggleConversationPanel(false);
    }
  };

  const debouncedSetUnreadCounts = debounce(
    (newCounts) => setUnreadCounts(newCounts),
    300
  );

  // update unread counts when open the conversation if current unread_count > 0
  useEffect(() => {
    if (current?.id === params?.id && showInboxUnreadCounts) {
      if (current?.unread_count > 0) {
        unreadCountsRef.current = current?.unread_count;
      } else {
        if (unreadCountsRef.current > 0) {
          // find unread count object for current assigned user
          const assignedCounts = unread_counts.assigned_users?.find(
            (item: UnreadConversationCountType) =>
              current?.assigned_user_id
                ? item.id === `${current?.assigned_user_id}`
                : item.id === null
          );
          // find unread count object for current channel
          const channelsCounts = unread_counts.channels?.find(
            (item: UnreadConversationCountType) => item.id === current?.location_id
          );
          // set new unread counts
          const newUnreadCounts = {
            ...unread_counts,
            all: Math.max(unread_counts.all - 1, 0),
            assigned_users: assignedCounts
              ? [
                  ...unread_counts.assigned_users.filter(
                    (item) => item.id !== assignedCounts?.id
                  ),
                  {
                    ...assignedCounts,
                    total: Math.max(assignedCounts?.total - 1, 0),
                  },
                ]
              : [...unread_counts.assigned_users],
            channels: channelsCounts
              ? [
                  ...unread_counts.channels.filter(
                    (item) => item.id !== channelsCounts?.id
                  ),
                  {
                    ...channelsCounts,
                    total: Math.max(channelsCounts?.total - 1, 0),
                  },
                ]
              : [...unread_counts.channels],
          };
          // Only update if counts have changed
          if (!isEqual(unread_counts, newUnreadCounts)) {
            debouncedSetUnreadCounts(newUnreadCounts);
          }
          // reset unreadCountsRef
          unreadCountsRef.current = 0;
        }
      }
    }
    if (current?.id !== params?.id) {
      // reset unreadCountsRef
      unreadCountsRef.current = 0;
    }
  }, [
    current?.id,
    params?.id,
    current?.unread_count,
    showInboxUnreadCounts,
    unread_counts,
    unreadCountsRef,
  ]);

  useEffect(() => {
    return () => {
      unreadCountsRef.current = 0;
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${showUnreadCount()} | Inbox `}</title>
      </Helmet>
      <ErrorBoundary
        fallback={<ErrorFallback />}
        beforeCapture={(scope: Scope) => {
          scope.setTag('ErrorLocation', 'conversation');
        }}
        showDialog={false}
      >
        <Flex css={{ flex: 1 }}>
          {current && (
            <Flex direction="column" css={{ width: '100%', position: 'relative' }}>
              <ConversationHeader />
              <ConversationItems
                current={conversationState.current}
                getConversationMessages={getConversationMessages}
                virtuoso={virtuoso}
              />
              <ErrorBoundary
                beforeCapture={(scope: Scope) => {
                  scope.setTag('ErrorLocation', 'messageEditorV2');
                }}
                showDialog={false}
              >
                <MessageEditorWrapper>
                  <ConversationEditor />
                </MessageEditorWrapper>
              </ErrorBoundary>
            </Flex>
          )}
        </Flex>
      </ErrorBoundary>
      {isDesktop && (
        <Flex>
          {showConversationPanel && isLargeDesktop && (
            <SidebarNavigationContainer
              defaultWidth={360}
              minWidth={360}
              maxWidth={450}
              name="CONVERSATION"
              dragDirection="right"
              disableCollapse
            >
              <ConversationPanels fullWidth virtusoso={virtuoso} body={body} />
            </SidebarNavigationContainer>
          )}
          <ConversationToolbar />
          {!isLargeDesktop && (
            <Drawer open={showConversationPanel}>
              <DrawerPortal>
                <DrawerContent
                  onEscapeKeyDown={handlePointerDownOutside}
                  onPointerDownOutside={handlePointerDownOutside}
                  side="right"
                  css={{ width: 420, overflowY: 'scroll' }}
                >
                  <ConversationPanels virtusoso={virtuoso} body={body} />
                </DrawerContent>
              </DrawerPortal>
            </Drawer>
          )}
        </Flex>
      )}
    </>
  );
}

const ErrorFallback = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      css={{
        width: '100%',
        height: '100%',
        padding: 30,
      }}
    >
      <VStack gap="3" align="center">
        <Text variant="bold" size="3">
          Something went wrong
        </Text>
        <Text size="2">
          Our team has been notified, click Reload to try again or contact support if the
          issue persists.
        </Text>
        <Button variant="gray" size="1" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </VStack>
    </Flex>
  );
};
