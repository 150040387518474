/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { CSSProperties, useEffect, useState } from 'react';
import { HiPlus, HiX } from 'react-icons/hi';
import { useMedia } from 'react-use';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { useDisclosure } from '@/shared/hooks';
import { Channel } from '@/shared/types/channels';
import { Sequence, SequenceStep } from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  HStack,
  Label,
} from '@/shared/ui';
import { isValidUuid } from '@/shared/utils/validations/validations';

import { ContactButton } from '../../../contacts/editor';
import { useSequences } from '../../context/SequenceContext';
import { SetSchedule } from './SetSchedule';

type AddContactToSequenceProps = {
  contact_ids: Array<string>;
  children?: React.ReactNode;
  onSubmit?: () => void;
  triggerStyle?: CSSProperties;
};

export const AddContactToSequence = (props: AddContactToSequenceProps) => {
  const { contact_ids, children, onSubmit } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Determine if the viewport is wide enough to display a dialog
  const isDesktop = useMedia('(min-width: 600px)');

  const sequenceContext = useSequences();
  const { sequencesState, getSequences, addContactsToSequence, getAndSetSequenceSteps } =
    sequenceContext;
  const { allSequences, currentSequenceSteps } = sequencesState;

  const locationContext = useChannels();
  const { channelsState } = locationContext;
  const channels =
    channelsState.allChannels.length < channelsState.channels.length
      ? channelsState.allChannels
      : channelsState.channels;

  const [selectedSequenceId, setSelectedSequenceId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState('');

  const [date, setDate] = useState<string>(dayjs(new Date()).format('MM/DD/YYYY'));

  const [time, setTime] = useState('');

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone || ''
  );

  const [scheduleParams, setScheduleParams] = useState({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  // get the location name from the id
  const getChannelById = (id: string) =>
    channels.find((channel: Channel) => channel.id === id);

  // set the schedule params in the correct format
  useEffect(() => {
    if (time) {
      const day = date.split('/')[1];
      const month = date.split('/')[0];
      const year = date.split('/')[2];
      let hour = time?.split(':')[0];
      const minute = time?.split(':')[1].split(' ')[0];
      const ampm = time?.split(' ')[1];

      if (ampm === 'PM') {
        if (hour === '12') {
          hour = '12';
        } else {
          hour = (Number(hour) + 12).toString();
        }
      } else {
        if (hour === '12') {
          hour = '00';
        }
      }

      setScheduleParams({
        day,
        month,
        year,
        hour,
        minute,
        timezone: timezone || getChannelById(selectedLocationId)?.timezone || '',
      });
    }
  }, [time, date, timezone]);

  const [dateOpen, setDateOpen] = useState(false);

  const [isScheduled, setIsScheduled] = useState(false);

  const onRadioClick = (e: string) => {
    if (e === 'scheduled') {
      setIsScheduled(true);
    } else {
      setIsScheduled(false);
    }
  };

  const dateSelect = (e: any) => {
    const date = dayjs(e.$d).format('MM/DD/YYYY');
    setDate(date);
  };

  const getSequenceTitle = (id: string) => {
    // make sure the sequence is not a null array or undefined
    if (allSequences && allSequences.length > 0) {
      const sequence = (allSequences as Sequence[]).find(
        (sequence) => sequence.id === id
      );
      return sequence?.title;
    } else {
      return '';
    }
  };

  const getLocationName = (id: string) =>
    channels.find((channel) => channel.id === id)?.name;

  // if sequences are empty, get all sequences
  useEffect(() => {
    if (allSequences.length === 0) {
      getSequences();
    }
  }, []);

  // once a sequence is selected, get the steps of the sequence
  useEffect(() => {
    if (selectedSequenceId) {
      getAndSetSequenceSteps(selectedSequenceId);
    }
  }, [selectedSequenceId]);

  // add contact(s) to sequence step
  const handleAdd = async () => {
    // get the first step of the selected sequence
    const sequenceInitialStepId = (currentSequenceSteps as SequenceStep[]).find(
      (sequenceStep: SequenceStep) => sequenceStep.position === 0
    )?.id;

    if (sequenceInitialStepId && isValidUuid(sequenceInitialStepId)) {
      const data = await addContactsToSequence(
        contact_ids,
        selectedLocationId,
        sequenceInitialStepId,
        selectedSequenceId
      );
      if (data) {
        onSubmit?.();
      }
      handleClose();
    }
  };

  // close modal, reset selected sequence and location state
  const handleClose = () => {
    onClose();
    setSelectedSequenceId('');
    setSelectedLocationId('');
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger onClick={children ? onOpen : undefined} style={props?.triggerStyle}>
        {children ? (
          children
        ) : (
          <ContactButton
            onClick={onOpen}
            icon={<HiPlus size={18} />}
            text={'Add To Sequence'}
          />
        )}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" />
        <DialogContent
          onEscapeKeyDown={handleClose}
          onPointerDownOutside={handleClose}
          css={{ width: isDesktop ? 600 : '100%' }}
        >
          <DialogTitle variant="bold">Add Contact to Sequence</DialogTitle>
          <Fieldset>
            <Label>Select Sequence</Label>
            <SingleSelect
              selectItem={selectedSequenceId}
              setSelectItem={setSelectedSequenceId}
              closeOnClick={true}
              options={
                (allSequences as Sequence[])
                  ?.filter((sequence: Sequence) => sequence.status === 'active')
                  ?.filter((sequence: Sequence) => !!sequence?.steps?.length)
                  ?.map((sequence: Sequence) => ({
                    type: sequence?.title || '',
                    value: sequence?.id || '',
                  })) || []
              }
              defaultPlaceholder={
                getSequenceTitle(selectedSequenceId) || 'Select a Sequence'
              }
              isDropdown={true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Select From Channel</Label>
            <SingleSelect
              selectItem={selectedLocationId}
              setSelectItem={setSelectedLocationId}
              closeOnClick={true}
              options={channels.map((channel: Channel) => ({
                type: channel.name || '',
                value: channel.id,
              }))}
              defaultPlaceholder={
                getLocationName(selectedLocationId) || 'Select a Channel'
              }
              isDropdown={true}
            />
          </Fieldset>
          <Fieldset>
            <Label>Schedule Add to Sequence</Label>
            <Box css={{ p: 0, m: 0 }}>
              <SetSchedule
                show={isScheduled}
                onRadioClick={onRadioClick}
                open={dateOpen}
                setOpen={setDateOpen}
                date={date}
                time={time}
                setTime={setTime}
                dateSelect={dateSelect}
                scheduleParams={scheduleParams}
                location={getChannelById(selectedLocationId) || ({} as Channel)}
                timezone={timezone}
                setTimezone={setTimezone}
              />
            </Box>
          </Fieldset>
          <DialogFooter justify="end" css={{ mt: 20 }}>
            <HStack>
              <Button variant="gray" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleAdd}
                disabled={!selectedSequenceId || !selectedLocationId}
              >
                Add to Sequence
              </Button>
            </HStack>
          </DialogFooter>
          <DialogCloseIcon onClick={handleClose} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
