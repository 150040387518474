import { SettingsLayout } from '@/shared/layouts';

import { AddDomain } from './AddDomain';
import { BuyDomain } from './BuyDomain';
import { DomainsTable } from './DomainsTable';

export const DomainsList = () => {
  return (
    <SettingsLayout
      background="#fafafa"
      breadcrumbs={[
        { title: 'Settings', path: '/settings/domains' },
        { title: 'Domains', path: '/settings/domains' },
      ]}
      width="100%"
      actions={
        <>
          <BuyDomain key="buy" />
          <AddDomain key="add" />
        </>
      }
    >
      <DomainsTable />
    </SettingsLayout>
  );
};
