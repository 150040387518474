import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import * as API from '@/shared/api/tags';
import { Flex } from '@/shared/ui';

import { ValueCombobox } from './Combobox';

type ContactTagValuesComboboxProps = {
  filter?: string;
  selected: { label?: string; value: string | string[] | number } | null | undefined;
  onSelect: (option: { label: string; value: string | string[] | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
  disabled?: boolean;
};

export const ContactTagValuesCombobox = (
  props: ContactTagValuesComboboxProps
): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');
  const [tagOptions, setTagOptions] = useState<{ label: string; value: string }[]>([]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    debounceSearchTags(value);
  };

  const searchTags = async (value: string) => {
    if (value) {
      const tags = await API.searchTag(value);
      const options = tags.map((tag: { name: string; id: string }) => ({
        label: tag.name,
        value: tag.id,
      }));
      setTagOptions(options);
    }
  };

  const debounceSearchTags = debounce(searchTags, 500);

  const getTags = async () => {
    const tags = await API.getPartTags({ offset: 0, limit: 10 });
    const options = tags.tags.map((tag: { name: string; id: string }) => ({
      label: tag.name,
      value: tag.id,
    }));
    setTagOptions((prevOptions) => {
      const newOptions = options.filter(
        (option: { value: string }) =>
          !prevOptions.some((prevOption) => prevOption.value === option.value)
      );
      return [...prevOptions, ...newOptions];
    });
  };

  useEffect(() => {
    getTags();
  }, [props.filter]);

  return (
    <Flex align="center" css={{ minWidth: 127, height: 35 }}>
      <ValueCombobox
        disabled={props.disabled}
        isOpen={props.isOpen}
        selectorStyles={props.selectorStyles}
        options={tagOptions}
        selected={{ label: selected?.label || '', value: selected?.value || '' }}
        onSelect={onSelect}
        searchValue={searchValue}
        handleSearchValue={handleSearchValue}
        selectLabel={selectLabel}
        searchLabel={searchLabel}
      />
    </Flex>
  );
};
