import { CountryCode } from 'libphonenumber-js';
import { useMemo } from 'react';

import {
  ComboboxOption,
  ValueCombobox,
} from '@/shared/components/filterBuilder/values/Combobox';
import { countries } from '@/shared/utils/countries';
import { SelectComboboxItemIndicator } from '@/shared/v2/components/forms/selectCombobox/SelectComboboxItemIndicator';

export type SelectComboboxFieldProps = {
  value: CountryCode;
  onChange: (value: CountryCode) => void;
};

export const CountrySelect = ({ value, onChange }: SelectComboboxFieldProps) => {
  const options = useMemo(
    () =>
      countries.map((item) => ({
        label: `${item.flag} ${item.name} +${item.code}`,
        value: item.cca2,
      })),
    []
  );

  const selectedValue = useMemo(
    () => options?.find((item) => item.value === value),
    [value]
  );

  const onSelect = (selectedValue: ComboboxOption) => {
    onChange(selectedValue.value as CountryCode);
  };

  const transformValue = (value?: string | number | string[]) => {
    const countryValue = countries?.find((item) => item.cca2 === value);
    return countryValue ? `${countryValue.flag} +${countryValue.code}` : '';
  };

  return (
    <div>
      <ValueCombobox
        selectorStyles={{
          padding: '6px 8px',
          backgroundColor: '#EBEBEF',
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          borderRightWidth: 0,
        }}
        css={{
          minWidth: 65,
        }}
        valueStyles={
          value
            ? {
                color: '#1C2024',
                fontSize: '14px',
              }
            : {
                color: '#00041D74',
              }
        }
        options={options}
        selected={selectedValue}
        onSelect={onSelect}
        searchLabel="Search country"
        SelectItemIndicatorComponent={SelectComboboxItemIndicator}
        transformValue={transformValue}
        renderOptionsIfOpen
      />
    </div>
  );
};
