import { User } from '@sentry/react';

import { Organization } from '../..';
import { LLM } from '../llms';

/**
 * Enum for the status of an Agent.
 */
export enum AgentStatus {
  Active = 'active',
  Archived = 'archived',
  Deleted = 'deleted',
}

/**
 * Enum for providers of Agent services.
 */
enum Provider {
  Retell = 'retell',
}

// language options
export enum AgentLanguage {
  EnUs = 'en-US',
  EnIn = 'en-IN',
  EnGb = 'en-GB',
  DeDe = 'de-DE',
  EsEs = 'es-ES',
  FrFr = 'fr-FR',
  Es419 = 'es-419',
  JaJp = 'ja-JP',
  KoKr = 'ko-KR',
  PtPt = 'pt-PT',
  PtBr = 'pt-BR',
  Multi = 'multi',
}

// e.g. [{id: 'en-US', name: 'English (US)'}, {id: 'en-IN', name: 'English (IN)'}]
export const agentLanguageOptions = [
  { id: AgentLanguage.EnUs, name: 'English (US)' },
  { id: AgentLanguage.EnIn, name: 'English (IN)' },
  { id: AgentLanguage.EnGb, name: 'English (GB)' },
  { id: AgentLanguage.DeDe, name: 'German (DE)' },
  { id: AgentLanguage.EsEs, name: 'Spanish (ES)' },
  { id: AgentLanguage.FrFr, name: 'French (FR)' },
  { id: AgentLanguage.Es419, name: 'Spanish (ES-419)' },
  { id: AgentLanguage.JaJp, name: 'Japanese (JP)' },
  { id: AgentLanguage.KoKr, name: 'Korean (KR)' },
  { id: AgentLanguage.PtPt, name: 'Portuguese (PT)' },
  { id: AgentLanguage.PtBr, name: 'Portuguese (BR)' },
  { id: AgentLanguage.Multi, name: 'Multi-Language' },
];

/**
 * Interface for settings specific to the Retell provider.
 */
export interface RetellProviderSettings {
  /** Optional language setting for the provider. */
  language?: AgentLanguage;
  /** Optional voice ID for the provider. */
  voice_id?: string;
  /** Optional voice model */
  voice_model?: string | null;
  /** Optional fallback voice IDs. */
  fallback_voice_ids?: string[];
  /** Optional temperature setting for the voice. */
  voice_temperature?: number;
  /** Optional speed setting for the voice. */
  voice_speed?: number;
  /** Optional volume setting */
  volume?: number;
  /** Optional responsiveness setting. */
  responsiveness?: number;
  /** Optional sensitivity to interruptions. */
  interruption_sensitivity?: number;
  /** Optional flag to enable backchannel communication. */
  enable_backchannel?: boolean;
  /** Optional frequency of backchannel communication. */
  backchannel_frequency?: number;
  /** Optional array of words for backchannel communication. */
  backchannel_words?: string[] | null;
  /** Optional milliseconds to trigger reminders. */
  reminder_trigger_ms?: number;
  /** Optional maximum count of reminders. */
  reminder_max_count?: number;
  /** Optional type of ambient sound. */
  ambient_sound?: string | null;
  /** Optional volume of ambient sound. */
  ambient_sound_volume?: number;
  /** Optional array of keywords to be boosted. */
  boosted_keywords?: string[] | null;
  /** Optional dictionary for pronunciation adjustments. */
  pronunciation_dictionary?:
    | { word: string; alphabet: 'ipa' | 'cmu'; phoneme: string }[]
    | null;
  /** Optional flag to normalize speech. */
  normalize_for_speech?: boolean;
  /** Optional milliseconds after which a call should end following silence. */
  end_call_after_silence_ms?: number;
  /** Enable voicemail detection */
  enable_voicemail_detection?: boolean;
  /** Voicemail message */
  voicemail_message?: string;
  /** Post call analysis data */
  post_call_analysis_data?:
    | {
        type: string;
        name: string;
        description: string;
        examples: string[];
      }[]
    | null;
  /** Enable transcription formatting */
  enable_transcription_formatting?: boolean;
  /** Maximum call duration in milliseconds */
  max_call_duration_ms?: number;
  /** Voicemail detection timeout in milliseconds */
  voicemail_detection_timeout_ms?: number;
  /** Delay before beginning message in milliseconds */
  begin_message_delay_ms?: number;
}

/**
 * Type definition for an Agent.
 */
export type Agent = {
  /** Unique identifier for the Agent. */
  id: string;
  /** Current status of the Agent. */
  status: AgentStatus;
  /** ID of the current version of the Agent. */
  current_version_id: string;
  /** Current active version of the Agent. */
  current_version: AgentVersion;
  /** List of all versions of the Agent. */
  versions: AgentVersion[];
  /** ID of the organization owning the Agent. */
  organization_id: string;
  /** Organization owning the Agent. */
  organization: Organization;
  /** ID of the user who created the Agent. */
  created_by_id: number;
  /** User who created the Agent. */
  created_by: User;
  /** ID of the user who last updated the Agent. */
  updated_by_id: number;
  /** User who last updated the Agent. */
  updated_by: User;
  /** ID of the user who deleted the Agent, if applicable. */
  deleted_by_id: number | null;
  /** User who deleted the Agent, if applicable. */
  deleted_by: User | null;
  /** Timestamp when the Agent was created. */
  inserted_at: Date;
  /** Timestamp when the Agent was last updated. */
  updated_at: Date;
  /** Timestamp when the Agent was deleted, if applicable. */
  deleted_at: Date | null;
};

/**
 * Type definition for a version of an Agent.
 */
export type AgentVersion = {
  /** Unique identifier for the version. */
  id: string;
  /** Optional name of the version. */
  name?: string;
  /** Optional description of the version. */
  description?: string;
  /** Provider of the Agent service. */
  provider: Provider;
  /** Optional specific identifier for the provider. */
  provider_id?: string;
  /** Optional settings specific to the provider. */
  provider_settings: RetellProviderSettings;
  /** ID of the Agent this version belongs to. */
  agent_id: string;
  /** Reference to the Agent this version belongs to. */
  agent: Agent;
  /** ID of the LLM this version is associated with. */
  llm_id: string;
  /** Reference to the LLM this version is associated with. */
  llm: LLM; // Assuming LLM type is defined and imported
  /** ID of the organization owning this version. */
  organization_id: string;
  /** Organization owning this version. */
  organization: Organization;
  /** ID of the user who created this version. */
  created_by_id: number;
  /** User who created this version. */
  created_by: User;
  /** Timestamp when this version was created. */
  inserted_at: Date;
};

export type CreateAgentParams = {
  agent: {
    name: string;
    description: string;
    llm_id: string;
    provider: Provider;
    provider_settings: RetellProviderSettings;
  };
};

export type UpdateAgentParams = {
  agent: {
    name: string;
    description: string;
    llm_id: string;
    provider: Provider;
    provider_settings: RetellProviderSettings;
    status: AgentStatus;
  };
};

// link panel types
export enum AgentEditorPanelType {
  CHAT = 'chat',
  HELP = 'help',
  SUGGESTIONS = 'suggestions',
}

// campaign accordion types
export enum AgentAccordionValue {
  SELECT_BASIC_INFO = 'select_basic_info',
  SELECT_CALL_INSTRUCTIONS = 'select_call_instructions',
  SELECT_CALL_SETTINGS = 'select_call_settings',
  SELECT_CALL_CONTROL = 'select_call_control',
  DEFAULT_VALUE = '',
}

export type CreateAgentWebCallParams = {
  name: string;
  phone: string;
  email?: string;
};

export type CreateAgentWebCallResponse = {
  id: string;
  delivery_status: string;
  contact_id: string;
  access_token: string;
};
