import * as Collapsible from '@radix-ui/react-collapsible';
import React from 'react';
import { CSVLink } from 'react-csv';
import { HiChevronDown, HiChevronRight, HiDotsVertical } from 'react-icons/hi';

import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

type ReportsCollapsibleProps = {
  /* the title of the report */
  title: string;
  /* the description of the report */
  description: string;
  /* the report content */
  children: React.ReactNode;
  /* the data to be exported */
  headers?: { label: string; key: string }[];
  data?: any[];
  /* the filename to be used for the export */
  filename?: string;
};

export const ReportsCollapsible = (props: ReportsCollapsibleProps) => {
  const { title, description, children, headers, data, filename } = props;

  const [open, setOpen] = React.useState(true);

  return (
    <Collapsible.Root defaultOpen={open} onOpenChange={setOpen} open={open}>
      <StyledItem>
        <StyledHeader justify="between" variant={open ? 'open' : 'closed'}>
          <HStack align="center" css={{ justifyContent: 'center' }}>
            <StyledTitle>{title}</StyledTitle>
            {headers && data && filename && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <IconButton>
                    <HiDotsVertical />
                  </IconButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent sideOffset={10}>
                  <CSVLink data={data} headers={headers} filename={filename}>
                    <DropdownMenuItem>Download Data as CSV</DropdownMenuItem>
                  </CSVLink>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
            <Box>{description}</Box>
          </HStack>
          <Collapsible.Trigger>
            <IconButton>{open ? <HiChevronDown /> : <HiChevronRight />}</IconButton>
          </Collapsible.Trigger>
        </StyledHeader>
        <StyledContent>
          <Box>{children}</Box>
        </StyledContent>
      </StyledItem>
    </Collapsible.Root>
  );
};

const StyledHeader = styled(Flex, {
  p: 24,
  width: '100%',
  alignItems: 'center',

  variants: {
    variant: {
      open: {
        borderBottom: '1px solid #E8E8E8',
      },
      closed: {
        borderBottom: 'none',
      },
    },
  },
});

const StyledItem = styled(Box, {
  backgroundColor: 'white',
  border: '1px solid $slate5',
  borderRadius: '$3',
  position: 'relative',
});

const StyledContent = styled(Collapsible.Content, {
  backgroundColor: 'white',
  p: 24,
  borderRadius: '$3',
});

const StyledTitle = styled(Box, {
  fontSize: 18,
  fontWeight: 700,
  mr: 10,
});
