import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { getAudienceV2 } from '@/shared/api/campaigns';
import { Attachments } from '@/shared/components/editor/v2/constants';
import {
  BulkActionDialogDescription,
  BulkActionDialogTitle,
} from '@/shared/components/filters/bulkAction';
import { BulkActionDialogContent } from '@/shared/components/filters/bulkAction/Dialog';
import {
  convertTimeString,
  getHours,
} from '@/shared/components/timepicker/utils/getHours';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { DefaultObject } from '@/shared/types/data';
import { FilterItem } from '@/shared/types/filter';
import {
  Button,
  Dialog,
  DialogClose,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
  Flex,
  HStack,
  IconButton,
  Label,
  VStack,
} from '@/shared/ui';
import { generateCampaignAnalyticsFilterItem } from '@/shared/utils/filterItems';

import CampaignAudience, { QuickFilterAlias } from '../../create/CampaignAudience';
import { isValidQuickFilter } from '../../editor/utils';
import { LocationDropdown } from './LocationDropdown';
import { MessageEditor } from './MessageEditor';
import { RadioGroupOptions, ScheduleRadioGroup } from './ScheduleRadioGroup';

type Props = {
  type: 'sms' | 'email';
  currentLocation: Channel | null;
  locations: Channel[];
  setLocation: (location: Channel) => void;
  showTimeZonePicker: boolean;
  /* the boolean value controlling whether or not the dialog is open*/
  isOpen: boolean;
  /* the function to control whether or not the dialog is open*/
  setIsOpen: (isOpen: boolean) => void;
  /*
   * The total number of contacts that will be messaged. This could be determined by
   * either the bulk actions sending us this information.
   */
  totalContacts: number;
  // when this dialog is used for follow up campaigns, we want the campaign id of the completed campaign
  campaignId?: string;
  /*
    Once the user submits the message we append the input they gave in the location and schedule options
    A selectedOption of null means that it is an immediate message
  */
  handleConfirm: (
    message: string,
    attachments: Attachments,
    selectedLocation: Channel,
    selectedScheduleOption: ScheduleOptions | null,
    includedFilterItems?: FilterItem[],
    excludedFilterItems?: FilterItem[]
  ) => void;
  /***
   * When the defaultIncludedAudienceFilter filter is undefined this means that we
   * won't be displaying the CampaignAudience section of the form.
   */
  defaultIncludedAudienceFilter?: FilterItem[];
  defaultExcludedAudienceFilter?: FilterItem[];
  customDefaultObjects?: Array<DefaultObject>;
  resourceOptions?: Array<DefaultObject>;
};
export const QuickCampaignDialog = ({
  isOpen,
  campaignId,
  setIsOpen,
  currentLocation,
  locations,
  defaultIncludedAudienceFilter,
  defaultExcludedAudienceFilter = [],
  customDefaultObjects = [],
  resourceOptions = [],
  type,
  setLocation,
  handleConfirm,
  totalContacts: totalContactsProp,
}: Props): JSX.Element => {
  const [includedAudienceFilter, setIncludedAudienceFilter] = useState<
    FilterItem[] | undefined
  >(defaultIncludedAudienceFilter);
  const [excludedAudienceFilter, setExcludedAudienceFilter] = useState<FilterItem[]>(
    defaultExcludedAudienceFilter
  );

  let quickFilterAliases: QuickFilterAlias[] = [];
  if (campaignId) {
    quickFilterAliases = [
      {
        label: 'All',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('all', campaignId),
      },
      {
        label: 'Delivered',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('delivered', campaignId),
      },
      {
        label: 'Not Delivered',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('notDelivered', campaignId),
      },
      {
        label: 'Responded',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('responded', campaignId),
      },
      {
        label: 'Unresponded',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('unresponded', campaignId),
      },
      {
        label: 'Link Clicked',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('linkClicked', campaignId),
      },
      {
        label: 'Link Not Clicked',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('linkNotClicked', campaignId),
      },
      {
        label: 'Unsubscribed',
        type: 'included',
        filter: generateCampaignAnalyticsFilterItem('unsubscribed', campaignId),
      },
    ];
  }

  const [audienceTabValue, setAudienceTabValue] = useState<
    'advanced-filter' | 'quick-filter'
  >(() => {
    const isIncludedValidQuickFilter = isValidQuickFilter(
      includedAudienceFilter ?? [],
      'included',
      quickFilterAliases
    );
    const isExcludedValidQuickFilter = isValidQuickFilter(
      excludedAudienceFilter,
      'excluded',
      quickFilterAliases
    );
    return isIncludedValidQuickFilter && isExcludedValidQuickFilter
      ? 'quick-filter'
      : 'advanced-filter';
  });

  const [totalContacts, setTotalContacts] = useState<number>(totalContactsProp);
  // the campaign type we are creating which can be scheduled or default for an immediate message
  const [campaignType, setCampaignType] = useState<RadioGroupOptions>('default');
  // message text
  const [message, setMessage] = useState<string>('');
  // message attachments
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: [],
  });
  const [isAttachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const [scheduleTime, setScheduleTime] = useState<ScheduleOptions>(
    initializeScheduleTime(currentLocation, new Date())
  );

  const debouncedGetAudienceV2 = useCallback(
    debounce(
      (
        channelId: string,
        includedAudienceFilter: FilterItem[],
        excludeWithoutSystemDefault: FilterItem[]
      ) => {
        if (includedAudienceFilter.length == 0) {
          setTotalContacts(0);
          return;
        }
        getAudienceV2(
          channelId,
          includedAudienceFilter,
          excludeWithoutSystemDefault,
          type
        ).then((data) => {
          setTotalContacts(data.data.data.audience_count);
        });
      },
      500
    ),
    []
  );

  const resetModalState = (): void => {
    setIsOpen(false);
    initializeScheduleTime(currentLocation, new Date());
    setCampaignType('default');
  };

  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if the location is not in the users location given to us
    if (!currentLocation || !validLocation(currentLocation, locations)) {
      toast.error('Error in selecting channel.');
      return;
    }
    // if the message is invalid
    if (message.length < 2 && attachments?.attachment_urls?.length == 0) {
      toast.error('Missing message or attachment(s).');
      return;
    }
    if (campaignType == 'scheduled' && isScheduledTimeInPast(scheduleTime, new Date())) {
      toast.error('Cannot schedule campaigns in the past.');
      return;
    }
    let selectedSchedule: ScheduleOptions | null = scheduleTime;
    if (campaignType == 'default') {
      selectedSchedule = null;
    }
    handleConfirm(
      message,
      attachments,
      currentLocation,
      selectedSchedule,
      includedAudienceFilter,
      excludedAudienceFilter
    );
  };

  const handleChangeLocation = (selectedLocation: Channel) => {
    if (validLocation(selectedLocation, locations)) {
      setLocation(selectedLocation);
    } else {
      toast.error('Error in selecting channel.');
    }
  };

  return (
    <Dialog open={isOpen} modal={false}>
      <DialogTrigger asChild onClick={resetModalState}></DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div">
          <BulkActionDialogContent
            onPointerDownOutside={resetModalState}
            onEscapeKeyDown={resetModalState}
          >
            <BulkActionDialogTitle>
              <Flex justify="between" align="center" css={{ width: '100%' }}>
                <h2>Quick Campaign</h2>
                <IconButton onClick={resetModalState}>
                  <HiX size={'20px'} color="gray" />
                </IconButton>
              </Flex>
            </BulkActionDialogTitle>
            <BulkActionDialogDescription>
              {' '}
              Create a new Campaign with the selected contacts.
            </BulkActionDialogDescription>
            <form
              onSubmit={handleSendMessage}
              data-testid="create-follow-up-campaign-form"
            >
              <VStack gap={2}>
                <LocationDropdown
                  selected={currentLocation}
                  showTimeZonePicker={false}
                  handleChangeLocation={handleChangeLocation}
                />
                {defaultIncludedAudienceFilter && includedAudienceFilter && (
                  <CampaignAudience
                    quickFilterAliases={quickFilterAliases}
                    channelId={currentLocation?.id ?? null}
                    exclude={{
                      fullExcludeAudienceFilter: excludedAudienceFilter,
                      removeFromFilter: (filterItems: FilterItem[]) => filterItems,
                      appendToFilter: (filterItems: FilterItem[]) => filterItems,
                    }}
                    includedAudienceFilter={includedAudienceFilter}
                    audienceTabValue={audienceTabValue}
                    onAudienceTabValueChanged={(tabValue) => {
                      setAudienceTabValue(tabValue);
                    }}
                    onIncludedAudienceFilterChanged={async (filterItems) => {
                      setIncludedAudienceFilter(filterItems);
                      debouncedGetAudienceV2(
                        currentLocation?.id ?? '',
                        filterItems,
                        excludedAudienceFilter
                      );
                    }}
                    onExcludedAudienceFilterChanged={async (filterItems) => {
                      setExcludedAudienceFilter(filterItems);
                      debouncedGetAudienceV2(
                        currentLocation?.id ?? '',
                        includedAudienceFilter,
                        filterItems
                      );
                    }}
                    customDefaultObjects={[...customDefaultObjects, ...resourceOptions]}
                  />
                )}

                <Label css={{ marginBottom: '0px' }}>Schedule:</Label>
                <ScheduleRadioGroup
                  initialState={campaignType}
                  onRadioClick={(campaignType) => {
                    setCampaignType(campaignType as RadioGroupOptions);
                  }}
                  scheduleOptions={scheduleTime}
                  onDateTimeUpdated={(dateTime) => {
                    setScheduleTime(dateTime);
                  }}
                />
                <Label css={{ marginBottom: '0px' }}>Message:</Label>
                <MessageEditor
                  message=""
                  attachments={attachments}
                  onInputUpdated={(message, attachments, isAttachmentLoading) => {
                    setMessage(message);
                    setAttachments(attachments);
                    setAttachmentLoading(isAttachmentLoading);
                  }}
                  preSelectedAttachments={[]}
                  isCampaignsEditPage={false}
                  showSchedule={campaignType == 'scheduled'}
                  location={currentLocation}
                  locations={locations}
                />
              </VStack>
              <DialogFooter justify="between" css={{ mb: 8, mt: 8 }}>
                <HStack gap={1} css={{ width: '100%', justifyContent: 'space-between' }}>
                  <Label css={{ mb: 0 }}>{`Total Contacts: ${totalContacts}`}</Label>
                  <HStack>
                    <DialogClose asChild>
                      <Button variant="gray" css={{ mr: '$1' }} onClick={resetModalState}>
                        Cancel
                      </Button>
                    </DialogClose>
                    <DialogClose asChild>
                      <Button
                        type="submit"
                        data-testid="create-follow-up-campaign-submit-button"
                        disabled={
                          !isValidCampaign(
                            message,
                            locations,
                            includedAudienceFilter,
                            isAttachmentLoading,
                            attachments,
                            currentLocation
                          )
                        }
                      >
                        {campaignType == 'scheduled' ? 'Schedule' : 'Confirm'}
                      </Button>
                    </DialogClose>
                  </HStack>
                </HStack>
              </DialogFooter>
            </form>
          </BulkActionDialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

function initializeScheduleTime(location: Channel | null, currentDate: Date) {
  const timezone = location?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startDate = `${
    currentDate.getMonth() + 1
  }/${currentDate.getDate()}/${currentDate.getFullYear()}`;
  const dateTime = dayjs();
  const newDateTime = dateTime.add(15, 'minute');
  let time = {
    hour: newDateTime.hour(),
    minute: newDateTime.minute(),
  };
  const hourIntervals = getHours(startDate, timezone);
  if (hourIntervals.length > 0) {
    const firstTimeInFuture = getHours(startDate, timezone)[0];
    time = convertTimeString(firstTimeInFuture);
  }
  return {
    year: currentDate.getFullYear().toString(),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    month: (currentDate.getMonth() + 1).toString(),
    day: currentDate.getDate().toString(),
    hour: time.hour.toString(),
    minute: time.minute.toString(),
    timezone: timezone,
  };
}
function validLocation(currentLocation: Channel, locations: Channel[]) {
  return locations.some((location) => location.id == currentLocation.id);
}

function isScheduledTimeInPast(scheduleTime: ScheduleOptions, now: Date): boolean {
  const { year, month, day, hour, minute } = scheduleTime;
  const dateToCheck = new Date(
    parseInt(year),
    // date is zero indexed and schedule options is not
    // so we need to modify the date before passing it as a scheduleOption
    parseInt(month) - 1,
    parseInt(day),
    parseInt(hour),
    parseInt(minute)
  );

  return dayjs(dateToCheck).isBefore(dayjs(now));
}

function isValidCampaign(
  message: string,
  channels: Channel[],
  includedAudienceFilter?: FilterItem[],
  isAttachmentLoading?: boolean,
  attachments?: Attachments,
  channel?: Channel | null
) {
  // if the location is not provided
  if (channel === undefined || channel == null) return false;
  // when the included audience filter is undefined, this means that we're not displaying
  // the CampaignAudience
  if (includedAudienceFilter === undefined) return true;
  if (includedAudienceFilter?.length == 0) return false;
  // if the location does not belong to the locations (belonging to the user)
  if (!channels.some((location) => location.id == channel.id)) return false;
  if (attachments === undefined || channel == undefined) return false;
  const isValidInput = message.length >= 2 || attachments?.attachment_urls?.length > 0;
  return !isAttachmentLoading && isValidInput;
}
