import { styled } from '@stitches/react';
import { useLayoutEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { Avatar, Flex } from '@/shared/ui';

import { ComboboxMultiselectTriggerProps, TestDataType } from './types';

export const ComboboxMultiselectTrigger = ({
  selectedLabels,
  placeholder,
  isOpen,
  invalid,
}: ComboboxMultiselectTriggerProps<string>) => {
  const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const cells = ref.current?.children;
    if (cells && cells.length > 0) {
      const startingOffset = (cells[0] as HTMLDivElement).offsetLeft;
      let numItems = 1;
      for (let i = 1; i < cells.length; i++) {
        if ((cells[i] as HTMLDivElement).offsetLeft === startingOffset) {
          break;
        }

        numItems++;
      }
      setNumberOfItemsDisplayed(numItems);
    }
  }, [ref.current?.children, selectedLabels]);

  return (
    <Flex
      align="center"
      css={{
        width: '100%',
        height: '35px',
        borderRadius: '4px',
        border: invalid
          ? '2px solid red'
          : isOpen
            ? '1px solid $primaryColor'
            : '1px solid #01062F2C',
        padding: '6px 8px',
      }}
    >
      <div style={{ maxWidth: 'calc(100% - 15px)', width: '100%', textAlign: 'left' }}>
        {selectedLabels.length ? (
          <Flex>
            <Flex
              ref={ref}
              gap={2}
              wrap="wrap"
              css={{
                maxHeight: '20px',
                overflow: 'hidden',
                width: `${
                  numberOfItemsDisplayed < selectedLabels.length
                    ? 'calc(100% - 40px)'
                    : 'calc(100% - 10px)'
                }`,
              }}
            >
              {selectedLabels?.map((item) => (
                <ComboboxSelectedItem
                  data-testid="combobox-selected-item"
                  key={item.value}
                  css={
                    item.color
                      ? {
                          color:
                            item.color === 'white' || item.color === '#fff'
                              ? '$blackA'
                              : '$hiContrast',
                          position: 'relative',
                          '&:before': {
                            content: '',
                            display: 'block',
                            width: '100%',
                            height: '100%',
                            backgroundColor: item.color,
                            opacity: 0.08,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            borderRadius: 3,
                          },
                        }
                      : {}
                  }
                >
                  {item.label}
                </ComboboxSelectedItem>
              ))}
            </Flex>
            {numberOfItemsDisplayed < selectedLabels.length && (
              <ComboboxSelectedItem
                data-testid="combobox-selected-items-count"
                css={{ overflowX: 'visible' }}
                style={{ marginLeft: '5px' }}
              >
                + {selectedLabels.length - numberOfItemsDisplayed}
              </ComboboxSelectedItem>
            )}
          </Flex>
        ) : (
          <ComboboxPlaceholder data-testid="combobox-placeholder">
            {placeholder}
          </ComboboxPlaceholder>
        )}
      </div>

      {isOpen ? <HiChevronUp /> : <HiChevronDown />}
    </Flex>
  );
};

export const ComboboxPlaceholder = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  color: '#00041D74',
});

export const ComboboxSelectedItem = styled('div', {
  color: '#0007139F',
  fontSize: 12,
  fontWeight: 500,
  background: '#02023414',
  borderRadius: '3px',
  padding: '0 8px',
  height: 20,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ComboboxMultiselectTriggerWithAvatar = ({
  selectedLabels,
  placeholder,
  isOpen,
}: ComboboxMultiselectTriggerProps<TestDataType>) => {
  const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const cells = ref.current?.children;
    if (cells && cells.length > 0) {
      const startingOffset = (cells[0] as HTMLDivElement).offsetLeft;
      let numItems = 1;
      for (let i = 1; i < cells.length; i++) {
        if ((cells[i] as HTMLDivElement).offsetLeft === startingOffset) {
          break;
        }

        numItems++;
      }
      setNumberOfItemsDisplayed(numItems);
    }
  }, [ref.current?.children, selectedLabels]);

  return (
    <Flex
      align="center"
      css={{
        width: '100%',
        height: '52px',
        borderRadius: '4px',
        border: '1px solid #01062F2C',
        padding: '6px 8px',
      }}
    >
      <div style={{ maxWidth: 'calc(100% - 15px)', width: '100%', textAlign: 'left' }}>
        {selectedLabels.length ? (
          <Flex>
            <Flex
              ref={ref}
              gap={2}
              wrap="wrap"
              css={{
                maxHeight: '40px',
                overflow: 'hidden',
                width: `${
                  numberOfItemsDisplayed < selectedLabels.length
                    ? 'calc(100% - 40px)'
                    : 'calc(100% - 10px)'
                }`,
              }}
            >
              {selectedLabels?.map((item) => (
                <ComboboxSelectedItem key={item.value} css={{ height: 40 }}>
                  <Flex align="center" css={{ marginTop: 5 }}>
                    <Avatar
                      css={{ marginRight: 5 }}
                      src={item.label?.image}
                      fallback={item.label.image}
                    />
                    {item.label?.name}
                  </Flex>
                </ComboboxSelectedItem>
              ))}
            </Flex>
            {numberOfItemsDisplayed < selectedLabels.length && (
              <ComboboxSelectedItem
                style={{ marginLeft: '5px', height: 20, marginTop: 10 }}
              >
                + {selectedLabels.length - numberOfItemsDisplayed}
              </ComboboxSelectedItem>
            )}
          </Flex>
        ) : (
          <div>{placeholder}</div>
        )}
      </div>

      {isOpen ? <HiChevronUp /> : <HiChevronDown />}
    </Flex>
  );
};
