/* eslint-disable react-hooks/exhaustive-deps */
import * as TabsPrimitive from '@radix-ui/react-tabs';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import {
  HiAdjustments,
  HiCheck,
  HiChevronRight,
  HiOutlineMailOpen,
  HiPencil,
  HiPhoneOutgoing,
} from 'react-icons/hi';
import { Link, match, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { useVoIP } from '@/pages/voip/context/VoIPContext';
import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { SideNavigationCount } from '@/shared/components/navigation/SideNavigationItem';
import { useDisclosure } from '@/shared/hooks';
import { Channel } from '@/shared/types/channels';
import {
  ConversationFilterTypes,
  ConversationItemType,
  ConversationSortTypes,
  ConversationStatusTypes,
  ConversationType,
  UnreadConversationCountType,
} from '@/shared/types/conversations';
import {
  Box,
  Checkbox,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerTrigger,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { formatPhoneNumber, isValidUuid } from '@/shared/utils/validations/validations';
import { keyframes, styled } from '@/stitches.config';

import { MatchParams, useConversation } from '../context/ConversationContext';
import {
  EmptyConversationPreview,
  getLastMessage,
  LoadingConversationPreview,
} from './ConversationPreview';
import { ConversationsFilter, SideNavigationHeading } from './ConversationsFilter';
import { FilteredAll } from './FilteredAll';
import { FilteredContacts } from './FilteredContacts';
import { FilteredMessages } from './FilteredMessages';
import { returnFilteredConversations } from './utils';
import { VirtualisedConversationsList } from './VirtualisedConversationsList';

type ConversationsListProps = {
  /** the current conversation tab - open, automated, closed */
  tab: ConversationStatusTypes;
  /** the current conversation filter - all, me, unassigned, location_id */
  filter: string;
  /** the object of the params from the inbox url - tab, filter, id */
  match: match<MatchParams> | null;
  /** conversation component */
  children?: React.ReactNode;
};

// conversation search query filter related constants
const DESC = 'desc';
const ASC = 'asc';
const LAST_MESSAGE_TIME = 'last_message_timestamp';
const UNREAD_COUNT = 'unread_count';

// search resource related constants
const CONVERSATION = 'conversation';

export const ConversationsList = (props: ConversationsListProps) => {
  const isDesktop = useMedia('(min-width: 912px)');
  const isLargeDesktop = useMedia('(min-width: 1624px)');
  return (
    <>
      {isDesktop ? (
        <SidebarNavigationContainer
          defaultWidth={isLargeDesktop ? 370 : 320}
          minWidth={310}
          maxWidth={500}
          name="INBOX"
          disableCollapse
        >
          <ConversationsListContainer {...props} />
        </SidebarNavigationContainer>
      ) : (
        <Flex css={{ position: 'relative' }} direction="column">
          <ConversationsListContainer {...props} />
        </Flex>
      )}
      {props.children}
    </>
  );
};

export const ConversationsListContainer = React.memo(
  (props: ConversationsListProps) => {
    const { tab, filter, match } = props;

    const history = useHistory();
    const isDesktop = useMedia('(min-width: 912px)');

    const {
      conversationState,
      changeTabIndex,
      showTabs,
      setShowTabs,
      clearSelectedConversations,
      bulkUpdateConversationsStatus,
    } = useConversation();
    const { tabIndex, selectedConversations } = conversationState;

    // the search value in the conversation search input
    // could be a phone number or a contact name
    const [contactValue, setContactValue] = useState('');
    const { enableVoip } = useFlags();
    const voip = useVoIP();

    useEffect(() => {
      // if the tabIndex is not the same as the tab param, and the search value is less than 2 characters
      // then update the tabIndex to the tab param and show the tabs to the user, instead of the search results
      if (tabIndex != tab && contactValue.length < 2) {
        changeTabIndex(tab);
        setShowTabs(true);
      }

      // if there is not tab param, then push the user to the open tab
      if (!tab) {
        history.push(`/inbox/${filter}/open`);
      }
    }, [match, tab, contactValue]);

    const { channelsState } = useChannels();
    const { channels } = channelsState;

    // get the channels name from the location id
    const getLocationName = (id: string) =>
      channels.find((channel) => channel.id === id)?.name;

    const { userState } = useUsers();
    const { users } = userState;

    // get the conversation title based on the filter id in the url
    // it will either be a channels id or a user id
    const getConversationTitle = (title: string | number) => {
      const titleString = String(title);
      if (isValidUuid(titleString)) {
        return getLocationName(titleString) || '';
      } else {
        const user = users.find((user) => String(user.id) === titleString);
        return user?.name || user?.email || '';
      }
    };

    // set the title of the page based on the filter
    const setTitle = (title: string) => {
      switch (title) {
        case ConversationFilterTypes.ALL:
          return 'All Conversations';
        case ConversationFilterTypes.ME:
          return 'My Conversations';
        case ConversationFilterTypes.UNASSIGNED:
          return 'Unassigned Conversations';
        case ConversationFilterTypes.GHOSTED:
          return '👻 Ghosted Conversations';
        case ConversationFilterTypes.UNRESPONSIVE:
          return 'Unresponsive Conversations';
        default:
          return getConversationTitle(title);
      }
    };

    return (
      <>
        <Flex css={{ position: 'relative', minHeight: '100%' }} direction="column">
          <ConversationsTabs
            defaultValue={tabIndex}
            onValueChange={(value: string) => changeTabIndex(value)}
            value={tabIndex || 'open'}
          >
            <Flex
              css={{
                py: 14,
                px: 24,
                width: '100%',
                borderBottom: 'thin solid $gray4',
              }}
              justify="between"
              align="center"
            >
              <SideNavigationHeading
                css={{
                  fontSize: 18,
                  fontWeight: 700,
                  width: 'calc(100% - 82px)',
                  whiteSpace: 'nowrap',
                  display: 'block',
                }}
              >
                {setTitle(filter)}
              </SideNavigationHeading>
              <HStack gap={3}>
                {!isDesktop && <MobileDrawer locations={channels} />}
                {enableVoip &&
                  channels.filter((channel) => channel.provider === 'twilio').length >
                    0 && (
                    <IconButton
                      size={2}
                      variant="ghost"
                      onClick={() => setTimeout(() => voip.newCall(), 0)}
                    >
                      <HiPhoneOutgoing />
                    </IconButton>
                  )}
                <Link to={`/inbox/${filter}/${tab}/new`}>
                  <IconButton variant="send" size={2}>
                    <HiPencil />
                  </IconButton>
                </Link>
              </HStack>
            </Flex>
            <ConversationsTabsList>
              {showTabs && (
                <Flex>
                  {tabsConfig.map((tabConfig) => (
                    <TabComponent
                      tabConfig={tabConfig}
                      tab={tab}
                      filter={filter}
                      key={tabConfig.key}
                    />
                  ))}
                </Flex>
              )}

              <Flex>
                <>
                  <TabComponent
                    tabConfig={{
                      key: 'filtered',
                      value: ConversationStatusTypes.SEARCH_CONTACTS,
                      label: 'Contacts',
                    }}
                    filter={filter}
                    tab={tab}
                    showTabs={showTabs}
                  />
                  <TabComponent
                    tabConfig={{
                      key: 'filtered',
                      value: ConversationStatusTypes.SEARCH_MESSAGES,
                      label: 'Messages',
                    }}
                    filter={filter}
                    tab={tab}
                    showTabs={showTabs}
                  />
                </>
              </Flex>
            </ConversationsTabsList>
            <ConversationsFilter
              tab={tab}
              filter={filter}
              contactValue={contactValue}
              setContactValue={setContactValue}
            />
            <Switch>
              <Route
                path={`/inbox/${filter}/open/:id?`}
                exact
                render={() => <ConversationsListTab filter={filter} tab={tab} />}
              />
              <Route
                path={`/inbox/${filter}/automated/:id?`}
                exact
                render={() => <ConversationsListTab filter={filter} tab={tab} />}
              />
              <Route
                path={`/inbox/${filter}/closed/:id?`}
                exact
                render={() => <ConversationsListTab filter={filter} tab={tab} />}
              />
            </Switch>

            <ConversationsTabsContent value="filtered:all">
              <FilteredAll filter={filter} tab={tab} />
            </ConversationsTabsContent>
            <ConversationsTabsContent value="filtered:contacts">
              <FilteredContacts filter={filter} tab={tab} />
            </ConversationsTabsContent>
            <ConversationsTabsContent value="filtered:messages">
              <FilteredMessages filter={filter} tab={tab} />
            </ConversationsTabsContent>
          </ConversationsTabs>
          {isDesktop && selectedConversations && selectedConversations.length > 0 && (
            <Flex
              justify="between"
              align="center"
              css={{
                backgroundColor: '$gray12',
                position: 'relative',
                bottom: 70,
                mx: 12,
                p: 12,
                borderRadius: 6,
                boxShadow:
                  '0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1)',
                animation: `${slideIn} 0.3s ease-in-out`,
              }}
            >
              <HStack gap="2" align="center">
                <Tooltip>
                  <TooltipTrigger>
                    <Flex align="center">
                      <Checkbox
                        size="2"
                        checked={selectedConversations.length > 0}
                        indeterminate={selectedConversations.length > 0}
                        onCheckedChange={() => clearSelectedConversations()}
                      />
                    </Flex>
                  </TooltipTrigger>
                  <TooltipContent sideOffset={20}>Un-select Conversations</TooltipContent>
                </Tooltip>
                <Box css={{ color: 'White', fontSize: 14, fontWeight: 500 }}>
                  {selectedConversations.length} selected
                </Box>
              </HStack>
              <HStack gap="2">
                {tab !== ConversationStatusTypes.OPEN && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <IconButton
                        variant="ghost"
                        onClick={() =>
                          bulkUpdateConversationsStatus(
                            selectedConversations,
                            ConversationStatusTypes.OPEN
                          )
                        }
                      >
                        <HiOutlineMailOpen style={{ color: 'white' }} size={18} />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={20}>Open Conversations</TooltipContent>
                  </Tooltip>
                )}
                {tab !== ConversationStatusTypes.CLOSED && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <IconButton
                        variant="ghost"
                        onClick={() =>
                          bulkUpdateConversationsStatus(
                            selectedConversations,
                            ConversationStatusTypes.CLOSED
                          )
                        }
                      >
                        <HiCheck style={{ color: 'white' }} size={18} />
                      </IconButton>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={20}>Close Conversations</TooltipContent>
                  </Tooltip>
                )}
              </HStack>
            </Flex>
          )}
        </Flex>
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.filter === nextProps.filter && prevProps.tab === nextProps.tab;
  }
);

// slide in animation
const slideIn = keyframes({
  '0%': { transform: 'translateY(100%)' },
  '100%': { transform: 'translateY(0%)' },
});

export type TabProps = {
  /* the tab value from the url */
  tab: ConversationStatusTypes;
  /* the filter value from the url */
  filter: string;
};

export function ConversationsListTab(props: TabProps): JSX.Element {
  const { filter, tab } = props;

  const { conversationState, advancedSearchConversations, inbox } = useConversation();
  const { conversations, loading } = conversationState;

  // current sort type applied - newest, oldest, unread
  const sort = conversationState.sort;

  // get the conversation id from the url
  const conversation_id = useParams<{ id?: string }>();

  const [offset, setOffset] = useState(0);

  // when the tab changes, reset the offset
  useEffect(() => {
    if (offset !== 0) {
      setOffset(0);
    }
  }, [tab, filter]);

  // fetch conversations based on the current filter
  // and the current query parameters
  useEffect(() => {
    const searchParams = {
      ...inbox,
      sort: {
        resource: CONVERSATION,
        order: sort === ConversationSortTypes.OLDEST ? ASC : DESC,
        column: sort === ConversationSortTypes.UNREAD ? UNREAD_COUNT : LAST_MESSAGE_TIME,
      },
    };
    advancedSearchConversations({ ...searchParams, status: tab, offset: offset });
  }, [inbox, offset]);

  // apply filter by tab
  const tabConversations = conversations.filter(
    (conversation: ConversationType) =>
      conversation?.status === tab &&
      conversation?.conversationItemsPage?.conversationItems.length > 0
  );

  // apply filter by filter params
  const filteredConversations = returnFilteredConversations(
    tabConversations,
    filter,
    inbox.assigned_users_ids
  );

  // sort conversations by date or unread
  const sortConversations = (conversations: ConversationType[]) => {
    // filter events out of conversations because they are not messages
    // if you filter the conversations array directly, the offset will be wrong
    const filtered_conversations = conversations.map((conversation) => ({
      ...conversation,
      conversationItemsPage: {
        ...conversation.conversationItemsPage,
        conversationItems: conversation.conversationItemsPage.conversationItems.filter(
          (item: ConversationItemType) => !('event' in item)
        ),
      },
    }));
    // sort conversations by date or unread
    switch (sort) {
      case ConversationSortTypes.NEWEST:
        // get the last message in each conversation and sort by inserted_at
        return filtered_conversations.sort((a, b) => {
          return dayjs(getLastMessage(b)?.inserted_at).diff(
            dayjs(getLastMessage(a)?.inserted_at)
          );
        });
      // sort by oldest on last message
      case ConversationSortTypes.OLDEST:
        return filtered_conversations.sort((a: ConversationType, b: ConversationType) =>
          getLastMessage(a)?.updated_at.localeCompare(getLastMessage(b)?.updated_at)
        );
      // sort by unread count
      case ConversationSortTypes.UNREAD:
        return filtered_conversations.sort((a, b) => b?.unread_count - a?.unread_count);
      default:
        return conversations;
    }
  };

  return (
    <ConversationsTabsContent value={tab}>
      {loading && <LoadingConversationPreview />}
      {!loading && filteredConversations.length > 0 && (
        <VirtualisedConversationsList
          conversations={sortConversations(filteredConversations)}
          filter={filter}
          tab={tab}
          length={sortConversations(filteredConversations).length}
          offset={offset}
          setOffset={setOffset}
          slug={conversation_id}
        />
      )}
      {!loading && filteredConversations.length === 0 && <EmptyConversationPreview />}
    </ConversationsTabsContent>
  );
}

export function FilteredConversations(props: TabProps) {
  const { filter, tab } = props;

  const { conversationState, advancedSearchConversations, inbox } = useConversation();
  const { conversations, loading } = conversationState;

  // get the conversation id from the url
  const conversation_id = useParams<{ id?: string }>();

  const [offset, setOffset] = useState(0);

  // fetch conversations based on the current filter
  // and the current query parameters
  useEffect(() => {
    // generate search params
    const searchParams = {
      ...inbox,
      sort: {
        resource: CONVERSATION,
        order: conversationState.sort === ConversationSortTypes.OLDEST ? ASC : DESC,
        column:
          conversationState.sort === ConversationSortTypes.UNREAD
            ? UNREAD_COUNT
            : LAST_MESSAGE_TIME,
      },
    };

    // fetch conversations based on the current search params
    advancedSearchConversations({
      ...searchParams,
      offset: offset,
      contact_value: inbox.contact_value,
    });
  }, [inbox, offset]);

  // filter open conversations passed on current route
  const filteredConversations = returnFilteredConversations(
    conversations,
    filter,
    inbox.assigned_users_ids,
    inbox.contact_value
  );

  return (
    <ConversationsTabsContent value="filtered">
      {loading && <LoadingConversationPreview />}
      {!loading && filteredConversations.length > 0 && (
        <VirtualisedConversationsList
          conversations={filteredConversations}
          length={filteredConversations.length}
          setOffset={setOffset}
          offset={offset}
          slug={conversation_id}
          filter={filter}
          tab={tab}
        />
      )}
      {!loading && filteredConversations.length === 0 && <EmptyConversationPreview />}
    </ConversationsTabsContent>
  );
}

const MobileDrawer = (props: { locations: Channel[] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { tokens } = useAuth();
  const {
    conversationState: { unread_counts },
  } = useConversation();

  return (
    <Drawer open={isOpen}>
      <DrawerTrigger asChild>
        <IconButton onClick={onOpen}>
          <HiAdjustments size={18} />
        </IconButton>
      </DrawerTrigger>
      <DrawerPortal>
        <DrawerOverlay />
        <DrawerContent
          onEscapeKeyDown={onClose}
          onPointerDownOutside={onClose}
          side="bottom"
          css={{ height: '90%', overflowY: 'scroll', p: 20 }}
        >
          <VStack gap={4}>
            <Flex css={{ px: 10, py: 10 }}>
              <SideNavigationHeading>Filter by Channel</SideNavigationHeading>
            </Flex>
            <MobileInboxNavigationItem
              name="All Conversations"
              description="All organization conversations."
              onClick={() => (history.push('/inbox/all/open'), onClose())}
              count={unread_counts?.all}
            />
            <MobileInboxNavigationItem
              name="Assigned to Me"
              description="All conversations assigned to me."
              onClick={() => (history.push('/inbox/me/open'), onClose())}
              count={
                unread_counts?.assigned_users?.find(
                  (item: UnreadConversationCountType) => item?.id === `${tokens?.user_id}`
                )?.total || 0
              }
            />
            <MobileInboxNavigationItem
              name="Ghosted"
              description="All conversations waiting for a response"
              onClick={() => (history.push('/inbox/ghosted/open'), onClose())}
            />
            <MobileInboxNavigationItem
              name="Unassigned Conversations"
              description="Conversation not assigned to a user."
              onClick={() => (history.push('/inbox/unassigned/open'), onClose())}
              count={
                unread_counts?.assigned_users?.find(
                  (item: UnreadConversationCountType) => item?.id === null
                )?.total || 0
              }
            />
            {props.locations.map((location: Channel) => (
              <MobileInboxNavigationItem
                key={location.id}
                name={location?.name || ''}
                description={formatPhoneNumber(location?.phone) || ''}
                onClick={() => (history.push(`/inbox/${location.id}/open`), onClose())}
                count={
                  unread_counts?.channels?.find(
                    (item: UnreadConversationCountType) => item?.id === `${location.id}`
                  )?.total || 0
                }
              />
            ))}
          </VStack>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

type MobileInboxNavigationProps = {
  /** the value to display for the navigation item */
  name: string;
  /** the description to display for the navigation item */
  description: string;
  /** the function to call when the navigation item is clicked */
  onClick?: () => void;
  /** the unread count of conversation */
  count?: number;
};

const MobileInboxNavigationItem = (props: MobileInboxNavigationProps) => {
  return (
    <StyledLocationContainer onClick={props.onClick} direction="column">
      <Flex justify="between" align="center">
        <StyledLocationName>{props.name}</StyledLocationName>
        <Flex align="center" justify="end">
          {props?.count ? (
            <SideNavigationCount css={{ mr: 6 }}>{props?.count}</SideNavigationCount>
          ) : null}
          <IconButton>
            <HiChevronRight />
          </IconButton>
        </Flex>
      </Flex>
      <StyledLocationPhone>{props.description}</StyledLocationPhone>
    </StyledLocationContainer>
  );
};

const ConversationsTabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minHeight: '100%',
});

const ConversationsTabsList = styled(TabsPrimitive.List, {
  backgroundColor: '#F2F2F5',
  padding: '4px 18px 0 18px',
  overflowY: 'hidden',
  fontSize: 13,
  display: 'flex',
  position: 'relative',
  maxHeight: 41,
  minHeight: 41,
});

const ConversationsTabsTrigger = styled(TabsPrimitive.Trigger, {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  padding: '6px 18px 8px',
  whiteSpace: 'nowrap',
  color: '#879096',
  fontSize: 13,
  '&[data-state="active"]': {
    background: 'white',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    color: 'black',
  },
});

export const ConversationsTabsContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  pt: 5,
  height: '83vh',
  overflowY: 'auto',
  '@lg': {
    height: 'inherit',
  },
});

const RoundedCornerLeft = styled(Box, {
  display: 'none',
  height: 10,
  width: 10,
  backgroundColor: 'white',
  position: 'absolute',
  bottom: 0,
  left: -10,

  variants: {
    active: {
      true: {
        display: 'block',
      },
    },
  },

  '&::after': {
    content: '',
    position: 'absolute',
    borderRadius: '100%',
    backgroundColor: '$slate3',
    height: 20,
    width: 20,
    left: -10,
    bottom: -1,
  },
});

const RoundedCornerRight = styled(Box, {
  display: 'none',
  height: 10,
  width: 10,
  backgroundColor: 'white',
  position: 'absolute',
  bottom: 0,
  right: -10,

  variants: {
    active: {
      true: {
        display: 'block',
      },
    },
  },

  '&::after': {
    content: '',
    position: 'absolute',
    borderRadius: '100%',
    backgroundColor: '$slate3',
    height: 20,
    width: 20,
    left: 0,
    bottom: 0,
  },
});

const StyledLocationContainer = styled(Flex, {
  position: 'relative',
  width: '100%',
  px: 10,
  py: 5,
});

const StyledLocationName = styled(Flex, {
  fontSize: 14,
  fontWeight: 600,
  mb: 5,
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
});

const StyledLocationPhone = styled(Flex, {
  fontSize: 14,
  fontWeight: 400,
  color: '$gray10',
  width: '100%',
});

export const SearchHeader = styled(Flex, {
  marginLeft: 15,
  flex: 'initial 0 initial',
  fontSize: 16,
  fontWeight: 700,
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingBottom: 4,
});

type TabComponentProps = {
  tabConfig: {
    key: string;
    value: ConversationStatusTypes | 'filtered';
    label: string;
  };
  tab: ConversationStatusTypes;
  filter: string;
  showTabs?: boolean;
};

const TabComponent: React.FC<TabComponentProps> = ({
  tabConfig,
  tab,
  filter,
  showTabs,
}) => {
  return (
    <Flex css={{ position: 'relative' }} key={tabConfig.key}>
      <RoundedCornerLeft active={tab === tabConfig.value} />
      <ConversationsTabsTrigger
        value={tabConfig.value}
        asChild
        css={{ display: showTabs ? 'none' : 'flex' }}
      >
        {/* Search tabs should not have a URL change */}
        {tabConfig.key.includes('filter') ? (
          <div style={{ cursor: 'pointer' }}>{tabConfig.label}</div>
        ) : (
          <Link to={`/inbox/${filter}/${tabConfig.key}`}>{tabConfig.label}</Link>
        )}
      </ConversationsTabsTrigger>
      <RoundedCornerRight active={tab === tabConfig.value} />
    </Flex>
  );
};

const tabsConfig = [
  { key: 'open', value: ConversationStatusTypes.OPEN, label: 'Open' },
  { key: 'automated', value: ConversationStatusTypes.AUTOMATED, label: 'Automated' },
  { key: 'closed', value: ConversationStatusTypes.CLOSED, label: 'Closed' },
];
