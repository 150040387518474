import { CampaignsParams } from '@/campaigns/context/CampaignsContext';
import { removeExcludedSystemDefault } from '@/pages/campaigns/editor/utils';
import { Audience } from '@/shared/types/audience';
import { Contact } from '@/shared/types/contacts';
import { FilterItem } from '@/shared/types/filter';
import { Trigger } from '@/shared/types/triggers';
import customParamsSerializer from '@/shared/utils/filterParams';

import {
  AudiencePreviewType,
  Campaign,
  CampaignContact,
  CampaignExcludeContacts,
} from '../../types/campaigns';
import { api } from '../api';
import { CampaignSourceTuple, SequenceSourceTuple } from '../sequences';

type ReceivedCampaignsParams = {
  data: {
    campaigns: Campaign[] | [];
    total: number;
  };
};

export const getCampaigns = async (
  params: CampaignsParams,
  controller?: AbortController
) => {
  const options = controller ? { signal: controller.signal } : undefined;
  const { data } = await api.get<ReceivedCampaignsParams>(`/campaigns`, {
    params,
    paramsSerializer: {
      serialize: customParamsSerializer,
    },
    ...options,
  });

  return data;
};

export const getOneCampaign = (id: string): Promise<Campaign> =>
  api.get(`/campaigns/${id}`).then((res) => {
    return res.data.data as Campaign;
  });

export const getOneCampaignV2 = async (id: string): Promise<Campaign> => {
  const results = await api.get<{ data: Campaign }>(`/v2/campaigns/${id}`);
  return results.data.data;
};

export const getCampaignContacts = (
  id: string
): Promise<Array<CampaignContact> | Array<null>> =>
  api.get(`/campaigns/${id}/campaigns_contacts`).then((res) => {
    return res.data.data as Array<CampaignContact> | Array<null>;
  });

export const getCampaignContactsByType = (
  id: string,
  type: string,
  offset: number,
  limit: number | null
) =>
  api
    .post(`/campaigns/${id}/campaigns_contacts`, {
      type,
      offset,
      limit,
      search_value: '',
    })
    .then((res) => {
      return res.data.data;
    });

export const getCampaignContactsV2 = (id: string, params: any, signal: any) =>
  api
    .post(`/v2/campaigns/${id}/contacts/search`, { ...params }, { signal })
    .then((res) => {
      return res.data;
    });

export const searchCampaignContactsByType = (id: string, type: string, name: string) =>
  api
    .post(`/campaigns/${id}/campaigns_contacts`, {
      type,
      search_value: name,
      offset: null,
      limit: null,
    })
    .then((res) => {
      return res.data.data;
    });

export const createCampaign = (campaign: Campaign) =>
  api.post('/campaigns', { campaign }).then((res) => {
    return res.data.data;
  });

export const createCampaignV2 = async (campaign: Campaign) => {
  return api.post('/v2/campaigns', { campaign }).then((res) => {
    return res.data.data;
  });
};

export async function getAudienceV2(
  channelId: string,
  includeAudienceFilter: FilterItem[],
  excludeWithSystemDefaults: FilterItem[] = [],
  type: 'sms' | 'email'
): Promise<{
  data: {
    data: {
      audience_count: number;
      excluded_contacts: Contact[];
      audience: Contact[];
    };
  };
}> {
  const excludedAudienceWithoutSystemDefaults = removeExcludedSystemDefault(
    excludeWithSystemDefaults,
    []
  );
  return await api.post<{
    data: {
      audience_count: number;
      excluded_contacts: Contact[];
      audience: Contact[];
    };
  }>('/v2/campaigns/audience', {
    channel_id: channelId,
    included_audience_filter: {
      filter: includeAudienceFilter,
    },
    excluded_audience_filter: {
      filter: excludedAudienceWithoutSystemDefaults,
    },
    type,
  });
}

export const updateCampaign = (campaignId: string, params: Campaign) =>
  api.put(`/campaigns/${campaignId}`, { campaign: params }).then((res) => {
    return res.data.data;
  });

export const updateCampaignV2 = async (
  campaignId: string,
  params: Campaign
): Promise<Campaign> => {
  const result = await api.put<{ data: Campaign }>(`/v2/campaigns/${campaignId}`, {
    campaign: params,
  });
  return result.data.data;
};

export const deleteCampaign = (id: string) =>
  api.delete(`/campaigns/${id}`).then((res) => {
    return res.data.data;
  });

type SendCampaignPreviewParams = {
  /* to phone number */
  to: string;
  /* from phone number */
  from: string;
  /* message body */
  body: string;
  /* attachment urls */
  attachment_urls: Array<string>;
};

export const sendCampaignPreview = (preview: SendCampaignPreviewParams) =>
  api.post('/campaigns/preview', preview).then((res) => {
    return res.data.data;
  });

export const getCampaignMessageCount = (
  includeAudience: Audience,
  excludeAudience: CampaignExcludeContacts
) =>
  api
    .post(`/campaigns/messages_count`, {
      audience: includeAudience,
      excluded_contacts: excludeAudience,
    })
    .then((res) => {
      return res.data.data;
    });

export const duplicateCampaign = (id: string, include?: boolean): Promise<Campaign> =>
  api
    .post(`campaigns/${id}/duplicate`, { include_audience: include || false })
    .then((res) => {
      return res.data.data as Campaign;
    });

export const selectAllContacts = (id: string) =>
  api.post(`campaigns/${id}/duplicate`).then((res) => {
    return res.data.data;
  });

export const getContactCampaigns = (id: string) =>
  api.post(`/campaigns/contact/${id}`).then((res) => {
    return res.data.data as { campaigns: Array<Campaign>; total: number };
  });

/**
 * Returns an object representing who will be messaged, who would not be messaged, and the total of number who will be messaged.
 *
 * @returns A Promise that resolves a map containing the following fields
 * - audience: A map representing contacts that will be messaged through id => [contactObject]
 * - excluded_contacts: A map representing contacts that will NOT be messaged through id => [contactObject]
 * - audienceCount: total number of people that will be messaged
 *   @example
 *   "data": {
 *     "audience": {
 *       // since this is a tag id, this could have multiple contacts
 *       "2bf2a457-8f6b-40da-99d1-6db17386917d": [
 *         {
 *           "blocked": false,
 *           "id": "94188f83-2448-46d5-bbed-20f1e8222f78",
 *           "name": "John Legend",
 *           "phone": "+18667131953",
 *           "email": null
 *         },
 *         {
 *           "blocked": false,
 *           "id": "84c26703-461f-4f7d-ab95-5392361672a2",
 *           "name": "Rey Collins",
 *           "phone": "+18667773252",
 *           "email": "shaylee2064@hoeger.net"
 *         },
 *         {
 *           "blocked": false,
 *           "id": "25c4fca6-f3c0-455d-a2b3-0f1814eb9f3f",
 *           "name": "Luke Boyle",
 *           "phone": "+15776339268",
 *           "email": null
 *         },
 *         {
 *           "blocked": false,
 *           "id": "bd8768b7-56a0-4d04-a95d-828bed1faeb9",
 *           "name": "Keenan Simon",
 *           "phone": "+16617926831",
 *           "email": null
 *         }
 *       ]
 *     },
 *     "excluded_contacts": {
 *       "30f1db65-1741-4a0b-9cf3-bdfdd24ec6f4": {
 *         "contact": {
 *           "blocked": false,
 *           "id": "30f1db65-1741-4a0b-9cf3-bdfdd24ec6f4",
 *           "name": "Jack Kennedy (IRL)",
 *           "phone": "+353876792525",
 *           "email": "jack@whippy.co"
 *         },
 *         "criteria": [
 *           "opted_out"
 *         ]
 *       },
 *       "cd3717a6-7b3f-434b-af49-ab0115eab6d2": {
 *         "contact": {
 *           "blocked": false,
 *           "id": "cd3717a6-7b3f-434b-af49-ab0115eab6d2",
 *           "name": "Ashlee Lang",
 *           "phone": "+18453550804",
 *           "email": null
 *         },
 *         "criteria": [
 *           "contact_ids"
 *         ]
 *       }
 *     },
 *     "audience_count": 4
 *   }
}
**/
export const getAudienceContacts = (
  includeAudience: Audience,
  excludeAudience: CampaignExcludeContacts
) =>
  api
    .post<{ data: AudiencePreviewType & { audience_count: number } }>(
      `/campaigns/audience`,
      {
        audience: includeAudience,
        excluded_contacts: excludeAudience,
      }
    )
    .then((res) => {
      return res.data.data;
    });

export const createCampaignTrigger = (campaign_id: string, trigger_param: Trigger) =>
  api
    .post(`/campaigns/${campaign_id}/campaign_trigger/trigger`, {
      campaign_id,
      trigger_param,
    })
    .then((res) => {
      return res.data;
    });

export const updateCampaignTrigger = (
  campaign_id: string,
  trigger_id: string,
  trigger_param: Trigger
) =>
  api
    .put(`/campaigns/${campaign_id}/campaign_trigger/trigger/${trigger_id}`, {
      trigger_param,
    })
    .then((res) => {
      return res.data.data;
    });

export const deleteCampaignTrigger = (campaign_id: string, trigger_id: string) =>
  api
    .delete(`/campaigns/${campaign_id}/campaign_trigger/trigger/${trigger_id}`)
    .then((res) => {
      return res.data.data;
    });

type UnsubscribeCampaignBulkActionSelection =
  | { contact_selection: { filter: FilterItem } }
  | { step_contact_selection: { filter: FilterItem } }
  | { campaign_contact_selection: { filter: FilterItem } };

type UnsubscribeCampaignBulkActionParams = {
  bulk_action: 'campaigns.unsubscribe.contacts';
  resource: { ids: string[] };
  selection: UnsubscribeCampaignBulkActionSelection;
  args?: {
    channel_ids?: string[];
    campaign_id?: string;
    sequence_id?: string;
  };
};

export const campaignBulkActions = async ({
  action,
  campaignId,
  filter,
  locationIds,
  filterSource,
}: {
  action: 'campaigns.unsubscribe.contacts';
  campaignId: string;
  filter: FilterItem[];
  locationIds?: string[];
  filterSource?: CampaignSourceTuple | SequenceSourceTuple;
}) => {
  let selection = {};
  let args: { channel_ids?: string[]; campaign_id?: string; sequence_id?: string } = {};

  if (!filterSource) {
    selection = { contact_selection: { filter } };
  } else if (filterSource[0] == 'campaign') {
    selection = { campaign_contact_selection: { filter } };
    args = { campaign_id: filterSource[1] };
  } else if (filterSource[0] == 'sequence') {
    selection = { step_contact_selection: { filter } };
    args = { sequence_id: filterSource[1] };
  }

  if (locationIds && locationIds.length > 0) args.channel_ids = locationIds;

  const params: Partial<UnsubscribeCampaignBulkActionParams> = {
    bulk_action: action,
    resource: { ids: [campaignId] },
    selection: selection as UnsubscribeCampaignBulkActionSelection,
    args,
  };

  const response = await api.post('/v2/bulk_actions', params);

  return response.data.data;
};

type SendCampaignEmailPreviewParams = {
  /* email subject */
  subject: string;
  /* email body */
  body: string;
  /* attachment urls */
  attachment_urls: Array<string>;
  /* channel id */
  channel_id: string;
  /* email address */
  to: string;
};

export const sendCampaignEmailPreview = (preview: SendCampaignEmailPreviewParams) =>
  api.post('v2/campaigns/preview', { ...preview, type: 'email' }).then((res) => {
    return res.data.data;
  });
