// register if the user is typing
export const onUserTyping = (
  /* the body of the message */
  body: string,
  /* the name of the user */
  name: string,
  /* the email of the user */
  email: string,
  current: any,
  /* remove typing indicator */
  remove: () => void,
  /* add typing indicator */
  add: () => void,
  timeout: any,
  setTypingTimeout: (x: NodeJS.Timeout | null) => void
) => {
  // user's name or use email as fallback
  const user = name || email;
  // clearing the current timeout every time the message changes
  clearTimeout(Number(timeout));

  // removing the user from the typing list as soon as the input is empty
  // instead of waiting for the setTimeout function to execute
  if (body === '') {
    return remove();
  }

  if (current) {
    if (!current.typing?.includes(user)) {
      // only adding the user to the typing list
      // if they are not already in it
      add();
    }

    let timeoutID: null | ReturnType<typeof setTimeout> = null;

    // resetting the timeout every time the message changes
    timeoutID = setTimeout(() => {
      remove();
    }, 1000);

    setTypingTimeout(timeoutID);
  }
};

// rendering typing users string
export const renderTypingUsers = (name: string, email: string, typing: Array<any>) => {
  // removing the current user's name from the typing list
  const user = name || email;
  const typingUsers = typing.filter((name: string) => name !== user);

  // composing sentence based on the filtered list length
  switch (typingUsers?.length) {
    case undefined:
      return null;
    case 0:
      return null;
    case 1:
      return `${typingUsers[0]} is typing...`;
    case 2:
    case 3:
      return `${formatTypingUsers(typingUsers)} are typing...`;
    default:
      return 'Several people are typing...';
  }
};

// format the tying users string
export const formatTypingUsers = (names: Array<any>) => {
  return names.reduce(
    (a, b, i, array) => a + (i < array.length - 1 ? ', ' : ' and ') + b
  );
};

export const deliveryStatuses = [
  { label: 'Delivered', value: 'delivered' },
  { label: 'Queued', value: 'queued' },
  { label: 'DLR Timeout', value: 'dlr_timeout' },
  { label: 'Delivery Unconfirmed', value: 'delivery_unconfirmed' },
  { label: 'Failed', value: 'failed' },
  { label: 'Sending Failed', value: 'sending_failed' },
  { label: 'Delivery Failed', value: 'delivery_failed' },
];

// pass dates int MM/DD/YYYY format
export const isDateInThePast = (selectedDate: string, todaysDate: string) => {
  const selectedDateArray = selectedDate.split('/').map((item) => Number(item));
  const todaysDateArray = todaysDate.split('/').map((item) => Number(item));

  const selectedMonth = selectedDateArray[0];
  const selectedDay = selectedDateArray[1];
  const selectedYear = selectedDateArray[2];

  const todaysMonth = todaysDateArray[0];
  const todaysDay = todaysDateArray[1];
  const todaysYear = todaysDateArray[2];

  if (selectedMonth < todaysMonth && selectedYear === todaysYear) {
    return true;
  }

  if (
    selectedDay < todaysDay &&
    selectedMonth === todaysMonth &&
    selectedYear === todaysYear
  ) {
    return true;
  }

  if (selectedYear < todaysYear) {
    return true;
  }

  return false;
};

// pass time in H:mm format (24h)
export const isTimeInThePast = (selectedTime: string, todaysTime: string) => {
  const selectedTimeArray = selectedTime.split(':').map((item) => Number(item));
  const todaysTimeArray = todaysTime.split(':').map((item) => Number(item));

  const selectedHour = selectedTimeArray[0];
  const selectedMinutes = selectedTimeArray[1];

  const todaysHour = todaysTimeArray[0];
  const todaysMinutes = todaysTimeArray[1];

  if (selectedHour < todaysHour) {
    return true;
  }

  if (selectedHour === todaysHour && selectedMinutes < todaysMinutes) {
    return true;
  }

  return false;
};
