import { captureException } from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { HiExternalLink, HiPhoneOutgoing, HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { useVoIP } from '@/pages/voip/context/VoIPContext';
import { MessageEditorWrapper } from '@/shared/components/editor/Styles';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { useFocusedContext } from '@/shared/components/editor/v2/context/FocusedContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TooltipButton } from '@/shared/components/TooltipButton';
import { useDisclosure } from '@/shared/hooks';
import { Signature } from '@/shared/types';
import { Channel, ProviderTypes } from '@/shared/types/channels';
import {
  Box,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerTrigger,
  Flex,
  HStack,
  IconButton,
  Text,
} from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';

import { useChannels } from '../../settings/organization/channels/context/ChannelContext';
import { useConversation } from '../context/ConversationContext';
import { AssignConversationModal } from '../conversation/assign/AssignConversationModal';
import { ConversationEditor } from '../conversation/editor/ConversationEditor';
import { ConversationHeaderContainer } from '../conversation/header';
import { ConversationHeaderContact } from '../conversation/header';
import { ConversationStatus } from '../conversation/header/ConversationStatus';
import { ConversationItems } from '../conversation/items';

type ConversationDrawerProps = {
  /* contact uuid */
  contact_id: string;
  /* contact name */
  contact_name: string;
  /* contact phone */
  contact_phone: string;
  /* contact email */
  contact_email?: string;
  /* location uuid */
  location_id: string;
  /* children button component */
  children: React.ReactNode;
};

export const ConversationDrawer = (props: ConversationDrawerProps) => {
  const {
    contact_id,
    contact_name,
    contact_phone,
    contact_email,
    location_id,
    children,
  } = props;

  // Message Editor V2
  const [message, setMessage] = useState('');

  const { isOpen, onClose, onOpen } = useDisclosure();

  const auth = useAuth();
  const { enableVoip } = useFlags();
  const voip = useVoIP();

  const conversationsContext = useConversation();
  const {
    getConversationByLocationAndContact,
    conversationState,
    getOrCreateConversation,
    getConversationMessages,
    scheduleNewConversationMessage,
  } = conversationsContext;
  const { current } = conversationState;

  const { isNote } = useFocusedContext();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { channelsState, getChannelById } = useChannels();
  const { channels } = channelsState;

  const user_preferences = useUserPreferences();
  const { preferences } = user_preferences;

  const [locationId, setLocationId] = React.useState<string>(location_id);
  const [signature, setSignature] = useState<Signature | null>(null);
  const [channel, setChannel] = useState<Channel>(getChannelById(locationId));

  useEffect(() => {
    setChannel(getChannelById(locationId));
  }, [locationId]);

  const handleOpenDrawer = () => {
    onOpen();

    if (contact_id && location_id) {
      getConversationByLocationAndContact(contact_id, location_id);
    }
  };

  const handleChangeLocation = (new_location_id: string) => {
    setLocationId(new_location_id);
    getConversationByLocationAndContact(contact_id, new_location_id);
  };

  const [attachments, setAttachments] = useState<{
    attachment_urls: string[];
  }>({
    attachment_urls: [],
  });

  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const startNewConversation = () => {
    try {
      if (!locationId) return toast.error('Please select a location');
      if (!message) return toast.error('Please enter a message');

      const newConversation: Promise<void> = getOrCreateConversation({
        contact: {
          name: props.contact_name,
          phone: toE164(props.contact_phone),
        },
        location_id: locationId,
        ...(isNote
          ? {
              note: {
                attachment_urls: attachments.attachment_urls,
                body: signature ? `${message}\n\n${signature.body}` : message,
              },
            }
          : {
              message: {
                attachment_urls: attachments.attachment_urls,
                body: signature ? `${message}\n\n${signature.body}` : message,
              },
            }),
      });
      const conversationComplete: Promise<void> = Promise.any([newConversation]);

      if (!(conversationComplete instanceof Promise))
        throw new Error('Conversation is not a promise');

      // Reset message before sending to prevent user from sending multiple times
      const messageBackup = message;
      setMessage('');

      toast.promise(conversationComplete, {
        loading: `Sending ${isNote ? 'note' : 'message'}...`,
        success: `${isNote ? 'Note' : 'Message'} sent`,
        error: () => {
          // Restore message if sending fails
          setMessage(messageBackup);
          return `Failed to send ${isNote ? 'note' : 'message'}`;
        },
      });
    } catch (error) {
      captureException(error, {
        tags: { action: 'start_conversation_message_drawer', feature: 'message_editor' },
        extra: { messageV2: message, attachments, isNote },
      });
      toast.error('Failed to send message');
    }
  };

  const [scheduleParams, setScheduleParams] = useState({
    day: '',
    month: '',
    year: '',
    hour: '',
    minute: '',
    timezone: '',
  });

  const sendScheduledMessage = () => {
    try {
      if (!message && attachments.attachment_urls.length === 0) return;

      const scheduleMessage: Promise<void> = scheduleNewConversationMessage({
        contact: {
          name: props.contact_name,
          phone: toE164(props.contact_phone),
        },
        location_id: locationId,
        scheduled_message: {
          params: {
            message: {
              body: message.trim(),
              attachment_urls: attachments.attachment_urls || [],
            },
            user_id: Number(auth?.tokens?.user_id),
            organization_id: String(auth?.tokens?.organization_id),
          },
          schedule_options: scheduleParams,
          target: 'message',
        },
      });

      const scheduleMessageComplete: Promise<void> = Promise.any([scheduleMessage]);

      if (!(scheduleMessageComplete instanceof Promise))
        throw new Error('Schedule message is not a promise');

      toast.promise(scheduleMessage, {
        loading: 'Scheduling message...',
        success: 'Message scheduled',
        error: 'Failed to schedule message',
      });

      // Reset state
      setMessage('');
      setAttachments({
        attachment_urls: [],
      });
    } catch (error) {
      captureException(error, {
        tags: { action: 'schedule_message_drawer', feature: 'message_editor' },
        extra: { scheduleParams },
      });
      toast.error('Failed to schedule message');
    }
  };

  // ref to keep track of scroll position
  const virtuoso = useRef();

  return (
    <Drawer open={isOpen}>
      <TooltipButton text="Open Conversation" sideOffset={5}>
        <DrawerTrigger onClick={handleOpenDrawer} asChild>
          {children}
        </DrawerTrigger>
      </TooltipButton>
      <DrawerPortal>
        <DrawerOverlay as="div" css={{ zIndex: 999 }}>
          <DrawerContent
            onEscapeKeyDown={onClose}
            onPointerDownOutside={onClose}
            css={{ width: '50%', zIndex: 999 }}
          >
            <Flex css={{ flex: 1, height: '100%' }}>
              <Flex
                direction="column"
                css={{ width: '100%', position: 'relative', height: '100%' }}
              >
                <ConversationHeaderContainer justify="between" align="center">
                  <Flex>
                    {current && current.id ? (
                      <ConversationHeaderContact
                        conversation={current}
                        channel={channel}
                      />
                    ) : (
                      <ConversationHeaderContact
                        conversation={{
                          contact: {
                            name: contact_name,
                            phone: contact_phone,
                            email: contact_email || '',
                          },
                        }}
                        channel={channel}
                      />
                    )}
                  </Flex>
                  <Flex>
                    <HStack gap={4}>
                      <AssignConversationModal isSmall />
                      {auth &&
                        current &&
                        channel.provider === ProviderTypes.TWILIO &&
                        enableVoip && (
                          <IconButton
                            css={{ ml: 20 }}
                            size="2"
                            variant="ghost"
                            onClick={() =>
                              voip.makeOutgoingCall(
                                channel.phone ?? '',
                                current?.contact.phone ?? '',
                                auth?.tokens?.user_id?.toString() ?? ''
                              )
                            }
                          >
                            <HiPhoneOutgoing />
                          </IconButton>
                        )}
                      {current?.id && (
                        <IconButton
                          size={2}
                          onClick={() =>
                            window.open(`/inbox/all/${current?.status}/${current?.id}`)
                          }
                        >
                          <HiExternalLink size={18} />
                        </IconButton>
                      )}
                      {/* we have to set these props false to prevent navigating to the inbox from the drawer */}
                      {current?.id && (
                        <ConversationStatus
                          closeAndNavigate={false}
                          openAndNavigate={false}
                        />
                      )}
                      <DrawerClose asChild>
                        <IconButton size={2} onClick={onClose}>
                          <HiX size={18} />
                        </IconButton>
                      </DrawerClose>
                    </HStack>
                  </Flex>
                </ConversationHeaderContainer>
                {channels.length > 1 && (
                  <ConversationHeaderContainer align="center">
                    <Box css={{ width: '100%' }}>
                      <SingleSelect
                        defaultPlaceholder={channel?.name || 'Select a channel'}
                        isDropdown={true}
                        selectItem={locationId}
                        setSelectItem={handleChangeLocation}
                        options={channels.map((channel) => ({
                          type: channel?.name || channel?.address || '',
                          value: channel.id,
                        }))}
                      />
                    </Box>
                  </ConversationHeaderContainer>
                )}
                <ConversationItems
                  current={current}
                  getConversationMessages={getConversationMessages}
                  virtuoso={virtuoso}
                />
                <MessageEditorWrapper>
                  {current ? (
                    <ConversationEditor />
                  ) : (
                    <>
                      <MessageEditorV2
                        message={message}
                        setMessage={setMessage}
                        textareaRef={textareaRef}
                        attachments={attachments}
                        setAttachments={setAttachments}
                        showAddTemplate={true}
                        showAddAttachment={true}
                        showAddReview={true}
                        showAddEmoji={true}
                        showAddTranslate={true}
                        showAddSchedule={true}
                        showAddVariable={true}
                        showAddSignature={true}
                        showCharacterCount={true}
                        showSendButton={true}
                        showAddNote={true}
                        showShortcuts={true}
                        setScheduleParams={setScheduleParams}
                        scheduleParams={scheduleParams}
                        signature={signature}
                        setSignature={setSignature}
                        contact={{
                          name: props.contact_name,
                          phone: toE164(props.contact_phone),
                        }}
                        location={channel}
                        enableAttachments={true}
                        showKeyboardSendShortcut={true}
                        scheduleSendAction={sendScheduledMessage}
                        sendMessageAction={startNewConversation}
                        showAutoComplete={true}
                        attachmentLoading={attachmentLoading}
                        setAttachmentLoading={setAttachmentLoading}
                        channel_type={channel?.type || 'phone'}
                        isInbox
                      />
                      <Flex justify="between" align="center" css={{ height: 40 }}>
                        <Box css={{ marginLeft: 'auto' }}>
                          {preferences &&
                          preferences.inbox &&
                          preferences.inbox.enter_to_send === false ? (
                            <Text css={{ fontWeight: 500, color: '$gray11' }}>
                              {'Shift + Enter to Send'}
                            </Text>
                          ) : (
                            <Text css={{ fontWeight: 500, color: '$gray11' }}>
                              {'Shift + Enter to Skip a Line'}
                            </Text>
                          )}
                        </Box>
                      </Flex>
                    </>
                  )}
                </MessageEditorWrapper>
              </Flex>
            </Flex>
          </DrawerContent>
        </DrawerOverlay>
      </DrawerPortal>
    </Drawer>
  );
};
