import React from 'react';
import { HiX } from 'react-icons/hi';

import { Box, Button } from '../../ui';
import { AttachmentsStack } from './AttachmentsStack';
import { FileAttachment, FileType } from './File';
import { ImageAttachment } from './Image';
import { MediaPreviewContainer } from './previewer';
import { AttachmentButton, AttachmentIconButton } from './Upload';
import { deleteAttachment, deleteUnsentAttachments, directUpload } from './utils';

export const fileTypeMappings: { [key in FileType]: string } = {
  [FileType.Image]: 'jpg|jpeg|png|gif|heic',
  [FileType.Pdf]: 'pdf',
  [FileType.Spreadsheet]: 'xlsx|xls',
  [FileType.Doc]: 'doc|docx',
  [FileType.Csv]: 'csv',
  [FileType.Presentation]: 'pot|pptx',
  [FileType.Zip]: 'pub|bin',
  [FileType.Video]:
    'webm|mpg|mp2|mpeg|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|3gp',
  [FileType.Audio]: 'mp3|wav|ogg|aac|m4a|amr|oga',
  [FileType.Text]: 'txt',
  [FileType.VCard]: 'vcf',
  [FileType.Blank]: '',
};

export const getFileType = (url: string): FileType => {
  for (const fileType in fileTypeMappings) {
    if (new RegExp(`.(${fileTypeMappings[fileType as FileType]})$`, 'i').test(url)) {
      return fileType as FileType;
    }
  }
  return FileType.Blank;
};

// Add more cases depending on attachment type e.g. audio/video
const renderAttachmentType = (url: string, preview?: boolean): JSX.Element | null => {
  const fileType = getFileType(url);

  switch (fileType) {
    case FileType.Image:
      return (
        <MediaPreviewContainer url={url}>
          <ImageAttachment src={url} preview={preview || false} />
        </MediaPreviewContainer>
      );
    case FileType.Pdf:
    case FileType.Spreadsheet:
    case FileType.Doc:
    case FileType.Csv:
    case FileType.Presentation:
    case FileType.Zip:
    case FileType.Text:
      return (
        <MediaPreviewContainer url={url}>
          <FileAttachment src={url} type={fileType} />
        </MediaPreviewContainer>
      );
    case FileType.VCard: // Add this case
      return (
        <MediaPreviewContainer url={url}>
          <FileAttachment src={url} type={fileType} />
        </MediaPreviewContainer>
      );
    case FileType.Video:
      return (
        <MediaPreviewContainer url={url}>
          <FileAttachment src={url} type={FileType.Video} />
        </MediaPreviewContainer>
      );
    case FileType.Audio:
      return (
        <MediaPreviewContainer url={url}>
          <FileAttachment src={url} type={fileType} />
        </MediaPreviewContainer>
      );
    default:
      return null;
  }
};

// write a function that takes an attachment url and returns true if it can ber rendered by the renderAttachmentType function
export const isValidAttachment = (url: string): boolean => {
  return getFileType(url) !== FileType.Blank;
};

const renderAttachmentPreview = (url: string, preview?: boolean): JSX.Element | null => {
  const fileType = getFileType(url);

  switch (fileType) {
    case FileType.Image:
      return <ImageAttachment src={url} preview={preview || false} />;
    case FileType.Pdf:
    case FileType.Spreadsheet:
    case FileType.Doc:
    case FileType.Csv:
    case FileType.Presentation:
    case FileType.Zip:
    case FileType.Text:
      return <FileAttachment src={url} type={fileType} />;
    case FileType.Video:
      return <FileAttachment src={url} type={FileType.Video} />;
    case FileType.Audio:
      return <FileAttachment src={url} type={FileType.Audio} />;
    default:
      return null;
  }
};

const renderDeleteButton = (
  deleteHandler: (publicUrl: string) => void,
  publicUrl: string
): JSX.Element => {
  return (
    <Button
      variant="primary"
      type="button"
      onClick={() => deleteHandler(publicUrl)}
      aria-label="delete attachment"
      css={{
        position: 'absolute',
        alignContent: 'center',
        borderRadius: '$round !important',
        top: 0,
        padding: '0px !important',
        right: 0,
        width: 27,
        zIndex: 100000,
        height: 27,
        maxHeight: 27,
        '& svg': {
          marginRight: '0px !important',
        },
      }}
    >
      <HiX />
    </Button>
  );
};

type AttachmentProps = {
  src: string;
  deleteHandler?: (publicUrl: string) => void;
  preview?: boolean;
};

const Attachment = (props: AttachmentProps): JSX.Element => {
  return (
    <Box css={{ mb: 5, borderRadius: 13 }}>
      {props.deleteHandler && renderDeleteButton(props.deleteHandler, props.src)}
      <Box>
        {props.preview && renderAttachmentPreview(props.src, props.preview)}
        {!props.preview && renderAttachmentType(props.src, props.preview)}
      </Box>
    </Box>
  );
};

export {
  Attachment,
  AttachmentButton,
  AttachmentIconButton,
  AttachmentsStack,
  deleteAttachment,
  deleteUnsentAttachments,
  directUpload,
  renderAttachmentPreview,
  renderAttachmentType,
  renderDeleteButton,
};
