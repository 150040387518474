import { Call } from '@twilio/voice-sdk';
import React, { useEffect, useState } from 'react';
import { HiPhone, HiX } from 'react-icons/hi';

import { searchContacts } from '@/shared/api/contacts/v2';
import { Contact } from '@/shared/types';
import { CallStatusTypes } from '@/shared/types/voip';
import { Avatar, Box, Button, Flex } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { getAuthTokens } from '@/shared/utils/storage';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';

import { showContactIcon } from '../inbox/list/ConversationPreview';
import { useVoIP } from './context/VoIPContext';
import { CallHeader, VoIPDialog } from './VoIPDialog';

export const VoIPIncomingCall = ({ call }: { call: Call }) => {
  const {
    voipState: { callStatus },
  } = useVoIP();

  const [contact, setContact] = useState<Contact | null>(null);

  const tokens = getAuthTokens();

  useEffect(() => {
    if (call && call?.parameters?.From) {
      findContact(call?.parameters?.From);
    }
  }, [call]);

  const findContact = async (phone: string) => {
    const contacts = await searchContacts([
      {
        column: 'phone',
        comparison: '==',
        resource: 'contact',
        value: phone,
      },
    ]);
    if (contacts.data.length) {
      setContact(contacts.data[0]);
    }
  };

  return (
    <VoIPDialog
      defaultSize={{ width: 280, height: 275 }}
      header={
        <CallHeader justify="between">
          <Box>{tokens?.name || tokens?.email}</Box>
          <Flex align="center" css={{ fontWeight: 400 }}>
            Incoming call
            <HiX
              data-testid="close-call"
              onClick={() =>
                callStatus === CallStatusTypes.INCOMING_ACCEPTED
                  ? call?.disconnect()
                  : call?.reject()
              }
              size={14}
              style={{ marginLeft: 4, cursor: 'pointer' }}
            />
          </Flex>
        </CallHeader>
      }
    >
      <Flex
        data-testid="incoming-call"
        css={{ width: '100%', padding: 16 }}
        direction="column"
        align="center"
        justify="center"
      >
        <Avatar
          css={{ marginTop: 16, marginBottom: 12 }}
          size="48"
          variant="lightGray"
          src={
            showContactIcon(contact?.name || '')
              ? `${window.location.origin}/outline.svg`
              : ''
          }
          alt={contact?.name || 'No name'}
          fallback={initials(contact?.name || '')}
        />
        <Box css={{ fontSize: 18, color: 'white', fontWeight: 500, marginBottom: 4 }}>
          {formatPhoneNumber(call?.parameters?.From || '') || '-'}
        </Box>
        <Box css={{ marginBottom: 32, fontSize: 14, fontWeight: 500 }}>
          is calling {tokens?.name || tokens?.email}
        </Box>
        <Flex>
          <Button
            data-testid="decline-call"
            css={{ marginRight: 6 }}
            variant="callDecline"
            onClick={() => call?.reject()}
          >
            <HiPhone style={{ transform: 'rotate(135deg)', margin: 0 }} />
            <Box css={{ marginLeft: 12 }}>Decline</Box>
          </Button>
          <Button
            data-testid="accept-call"
            css={{ marginLeft: 6 }}
            variant="callAccept"
            onClick={() => call?.accept()}
          >
            <HiPhone style={{ margin: 0 }} />
            <Box css={{ marginLeft: 12 }}>Accept</Box>
          </Button>
        </Flex>
      </Flex>
    </VoIPDialog>
  );
};
