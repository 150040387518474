import React from 'react';

import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, HStack } from '@/shared/ui';

import { AddQuestion } from './AddQuestion';
import QuestionsState from './context/QuestionsContext';
import { QuestionsTable } from './QuestionsTable';
import { SearchQuestion } from './SearchQuestion';

export const Questions = () => {
  usePageView();

  return (
    <QuestionsState>
      <PageLayout
        breadcrumbs={[
          { title: 'Automations', path: '/automations/keywords' },
          { title: 'Questions', path: '/automations/questions' },
        ]}
        actions={
          <HStack gap="2">
            <SearchQuestion />
            <AddQuestion />
          </HStack>
        }
      >
        <Box css={{ p: 30 }}>
          <QuestionsTable />
        </Box>
      </PageLayout>
    </QuestionsState>
  );
};
