import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';
import { HiX } from 'react-icons/hi';

import { AnalyticDateSelector } from '@/pages/agents/analytics/AnalyticDateSelector';
import { ComboboxMultiselectTrigger } from '@/pages/agents/analytics/AnalyticFilterCombobox';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { reportAnalytics, ReportData } from '@/shared/api/reports';
import { DatepickerDates } from '@/shared/components/datepicker/Datepicker';
import { Channel } from '@/shared/types/channels';
import { User } from '@/shared/types/users';
import { Box, Flex, IconButton, VStack } from '@/shared/ui';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { ConversationsAnalytics } from './ConversationsAnalytics';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

// Add type definition
type BarDataItem = {
  key: string;
  count: number;
};

export const ConversationsReport = () => {
  const [data, setData] = useState<Array<ReportData>>([]);
  const [closedConversationsBar, setClosedConversationsBar] = useState<BarDataItem[]>([]);
  const [openedConversationsBar, setOpenedConversationsBar] = useState<BarDataItem[]>([]);
  const [newConversationsBar, setNewConversationsBar] = useState<BarDataItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<DatepickerDates>(initialDates);
  const [users, setUsers] = useState<Array<string>>([]);
  const [channels, setChannels] = useState<Array<string>>([]);

  const usersContext = useUsers();
  const { userState } = usersContext;
  const locationsContext = useChannels();
  const { channelsState } = locationsContext;

  useEffect(() => {
    fetchReports(dates, users, channels);
  }, [dates, users, channels]);

  const fetchReports = async (
    dates: DatepickerDates,
    users: Array<string>,
    channels: Array<string>
  ) => {
    try {
      setIsLoading(true);
      const filters = [];
      if (users.length) {
        filters.push({
          column: 'assigned_user_id',
          comparison: 'in',
          value: users,
        });
      }
      if (channels.length) {
        filters.push({
          column: 'location_id',
          comparison: 'in',
          value: channels,
        });
      }
      const data = await reportAnalytics(filters, dates, 'conversations');
      setData(data);
      setNewConversationsBar(
        data
          .find((d) => d.chart === 'bar' && d.label === 'New Conversations per Day')
          ?.values.map((d) => {
            return { key: String(d.y), count: Number(d.x) };
          }) ?? []
      );
      setOpenedConversationsBar(
        data
          .find((d) => d.chart === 'bar' && d.label === 'Opened Conversations per Day')
          ?.values.map((d) => {
            return { key: String(d.y), count: Number(d.x) };
          }) ?? []
      );
      setClosedConversationsBar(
        data
          .find((d) => d.chart === 'bar' && d.label === 'Closed Conversations per Day')
          ?.values.map((d) => {
            return { key: String(d.y), count: Number(d.x) };
          }) ?? []
      );

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const cleanFilters = () => {
    setUsers([]);
    setChannels([]);
  };

  return (
    <ReportsContentContainer title="Conversations" hideDatepicker>
      <Flex direction="column">
        <VStack gap={5}>
          <Box>
            <Flex gap={2}>
              <AnalyticDateSelector dates={dates} setDates={setDates} />
              <Box css={{ maxWidth: 300 }}>
                <ComboboxMultiselect
                  options={userState.users.map((user: User) => ({
                    label: user.name || user.email || '',
                    value: user.id.toString() || '',
                  }))}
                  width="auto"
                  selected={users}
                  onSelect={setUsers}
                  searchLabel="Search"
                  selectLabel="Users"
                  Trigger={ComboboxMultiselectTrigger}
                  Option={ComboboxMultiselectItem}
                  visualized
                />
              </Box>
              <Box css={{ maxWidth: 300 }}>
                <ComboboxMultiselect
                  options={channelsState.allChannels.map((channel: Channel) => ({
                    label: channel.name || '',
                    value: channel.id || '',
                  }))}
                  width="auto"
                  selected={channels}
                  onSelect={setChannels}
                  searchLabel="Search"
                  selectLabel="Channels"
                  Trigger={ComboboxMultiselectTrigger}
                  Option={ComboboxMultiselectItem}
                  visualized
                />
              </Box>
              {(!!users.length || !!channels?.length) && (
                <IconButton
                  onClick={() => cleanFilters()}
                  css={{
                    border: '1px solid $slate7',
                    height: 40,
                    width: 40,
                    background: 'white',
                  }}
                >
                  <HiX size="15px" />
                </IconButton>
              )}
            </Flex>
          </Box>
          <ConversationsAnalytics
            data={data}
            newConversationsBar={newConversationsBar}
            openedConversationsBar={openedConversationsBar}
            closedConversationsBar={closedConversationsBar}
            isLoading={isLoading}
          />
        </VStack>
      </Flex>
    </ReportsContentContainer>
  );
};
