import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import { SideNavigation } from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';
import { Flex } from '@/shared/ui';

import { DataSideNavigation } from './DataSideNavigation';

export function DataNavigation(): JSX.Element {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();
  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Data"
          sideNavigationTabs={[]}
          toggleExpandedState={toggleExpandedState}
        >
          <DataSideNavigation />
        </SideNavigation>
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const DataLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      align="stretch"
      css={{
        justifyItems: 'stretch',
        flexGrow: 1,
        flexShrink: 'initial',
        flexBasis: 'initial',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {children}
    </Flex>
  );
};

export const DataContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <MainLayout>
      <Helmet>
        <title>Whippy | Data</title>
      </Helmet>
      {isDesktop && <DataNavigation />}
      <LayoutContent>
        <DataLayout>{children}</DataLayout>
      </LayoutContent>
    </MainLayout>
  );
};
