/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import 'react-dayjs-picker/dist/index.css';

import React, { useEffect } from 'react';
import {
  HiChat,
  HiDocumentSearch,
  HiGlobe,
  HiMail,
  HiPhone,
  HiTag,
} from 'react-icons/hi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { MatchParams, useConversation } from '@/inbox/context/ConversationContext';
import { ConversationDrawer } from '@/inbox/drawer';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { languages } from '@/shared/components/editor/v2/utils';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { useUserRoles } from '@/shared/hooks/useUserRoles';
import {
  ConversationFilterTypes,
  ConversationStatusTypes,
} from '@/shared/types/conversations';
import { UserRoles } from '@/shared/types/users';
import {
  Avatar,
  Box,
  CustomScrollbar,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber, toE164 } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { AddContactToSequence } from '../../sequences/sequence/audience/AddContactToSequence';
import { useContacts } from '../context/ContactContext';
import { formatContactName } from '../utils';
import { ArchiveContact } from './ArchiveContact';
import { OptoutContact } from './ContactCommunicationPreferences';
import { ContactDynamicInput } from './ContactDynamicInput';
import { ContactNotes } from './ContactNotes';
import { ContactSource } from './ContactSource';
import { ContactTags } from './tags/ContactTags';

type ContactEditorProps = {
  contactId?: string;
  isInbox?: boolean;
};

export const ContactEditor = React.memo((props: ContactEditorProps) => {
  const { contactId, isInbox } = props;

  const {
    contactState,
    setCurrent,
    archiveContact,
    clearCurrent,
    updateContactHttp,
    onClose,
    findAndSetCurrent,
    getAndSetCurrent,
  } = useContacts();

  const { current } = contactState;

  const { updateConversationContact, updateConversationContactLanguage } =
    useConversation();

  const isAdmin = useUserRoles(UserRoles.ADMIN);

  const { channelsState } = useChannels();
  const { channels } = channelsState;

  const isDesktop = useMedia('(min-width: 912px)');
  const history = useHistory();
  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');
  const filter = match?.params.filter || ConversationFilterTypes.ALL;
  const tab = match?.params.tab || ConversationStatusTypes.OPEN;

  useEffect(() => {
    if (isInbox && contactId && current?.id !== contactId) {
      setCurrent(null);
      if (!current?.contact_tags) {
        getAndSetCurrent(contactId);
      } else {
        findAndSetCurrent(contactId);
      }
    }
  }, [contactId]);

  // Empty state
  if (current === null || (contactId === null && isInbox)) {
    return <ContactEditorLayout />;
  }

  // Format phone number value
  const normalizePhoneNumber = (phoneNumber: string | null) => {
    if (phoneNumber != null) {
      return toE164(phoneNumber);
    } else {
      return null;
    }
  };

  // Archive contact function
  const onArchive = () => {
    archiveContact(current?.id);
    clearCurrent();
    // next conversation when you delete one
    if (isInbox) {
      history.push(`/inbox/${filter}/${tab}`);
    }
    if (!isDesktop) {
      onClose();
    }
  };

  const handlePhoneChange = (phone: string) => {
    const normalizedPhone = normalizePhoneNumber(phone);
    // @ts-ignore
    updateContactHttp({ id: current.id, phone: normalizedPhone });
    updateConversationContact({ id: current.id, phone: normalizedPhone });
  };

  const handleLanguageChange = (language: string) => {
    // @ts-ignore
    updateContactHttp({ id: current.id, language: language });
    updateConversationContactLanguage(language);
  };

  const handleEmailChange = (email: string) => {
    // @ts-ignore
    updateContactHttp({ id: current.id, email });
  };

  const handleNameChange = (name: string) => {
    // @ts-ignore
    updateContactHttp({ id: current.id, name: formatContactName(name) });
  };

  return (
    <ContactEditorLayout>
      <VStack gap={3}>
        <Flex
          direction="column"
          align="center"
          justify="center"
          css={{ px: '$5', pt: '$5' }}
        >
          <Avatar size="profile" fallback={initials(current.name)} />
          <ContactDynamicInput
            key={`${current.id}-${current.name}`}
            initialValue={current.name || ''}
            onSave={handleNameChange}
            fallbackString="Add a name"
          />
        </Flex>
        {!isInbox && isDesktop ? (
          <HStack gap={1} css={{ jc: 'center' }}>
            {current.phone && (
              <ConversationDrawer
                contact_id={current.id}
                location_id={channels[0].id}
                contact_name={current?.name || ''}
                contact_phone={current?.phone}
                contact_email={current?.email || ''}
              >
                <ContactButton icon={<HiChat size={18} />} text={'Message'} />
              </ConversationDrawer>
            )}
            <HStack gap={1} css={{ jc: 'center' }}>
              <AddContactToSequence contact_ids={[contactId || current.id || '']} />
              {isAdmin && <ArchiveContact alertAction={onArchive} />}
            </HStack>
          </HStack>
        ) : (
          <HStack gap={1} css={{ jc: 'center' }}>
            <AddContactToSequence contact_ids={[contactId || current.id || '']} />
            {isAdmin && <ArchiveContact alertAction={onArchive} />}
          </HStack>
        )}
        <Box css={{ px: 24, pb: 10 }}>
          <VStack gap={2}>
            <ContactItem icon={<HiMail />} title={'Email'}>
              <ContactDynamicInput
                key={`${current.id}-${current.email}`}
                initialValue={current.email || ''}
                onSave={handleEmailChange}
                fallbackString="Empty"
              />
            </ContactItem>
            <ContactItem icon={<HiPhone />} title={'Phone'}>
              <ContactDynamicInput
                key={`${current.id}-${current.phone}`}
                initialValue={formatPhoneNumber(current.phone || '') || ''}
                onSave={handlePhoneChange}
                fallbackString="Empty"
              />
            </ContactItem>
            <ContactItem icon={<HiDocumentSearch />} title={'Source'}>
              <ContactSource key={`${current?.id}-${current.source}`} />
            </ContactItem>
            <ContactItem icon={<HiGlobe />} title={'Language'} direction="column">
              <Box>
                <SingleSelect
                  defaultPlaceholder={
                    languages.find(({ value }) => value === current.language)?.label ||
                    'English'
                  }
                  isDropdown={true}
                  selectItem={current.language || 'en'}
                  setSelectItem={handleLanguageChange}
                  options={languages.map((language) => ({
                    type: language.label,
                    value: language.value,
                  }))}
                />
              </Box>
            </ContactItem>
            <ContactItem direction="column" icon={<HiTag />} title={'Tags'}>
              <ContactTags />
            </ContactItem>
          </VStack>
        </Box>
        <Divider />
        <Box>
          <ContactNotes />
        </Box>
        {/* need to pass setCurrent from this layer down */}
        <OptoutContact contact={current} setCurrent={setCurrent} />
      </VStack>
    </ContactEditorLayout>
  );
});

type ContactItemProps = {
  icon?: React.ReactElement;
  title?: string;
  direction?: 'row' | 'column';
  children?: React.ReactNode;
};

export function ContactItem({
  icon,
  title,
  direction = 'row',
  children,
}: ContactItemProps): JSX.Element {
  return (
    <Flex
      wrap="wrap"
      css={{ fontSize: 13, p: '2px 0', minHeight: 35 }}
      align={direction === 'row' ? 'center' : 'start'}
      direction={direction}
    >
      <Flex css={{ maxWidth: 70, width: 70 }} align="center">
        <HStack gap={2} css={{ py: direction === 'column' ? '8px' : '0' }}>
          <Box></Box>
          <Box css={{ width: 13 }}>{icon}</Box>
          <Box css={{ fontWeight: 500 }}>{title}</Box>
        </HStack>
      </Flex>
      <Flex css={{ flex: 'initial' }}>{children}</Flex>
    </Flex>
  );
}

type ContactButtonProps = {
  icon?: React.ReactElement;
  text?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ContactButton = (props: ContactButtonProps) => {
  const sanitizedText = props?.text?.toLowerCase()?.replace(/\s/g, '');
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          onClick={props.onClick}
          size={3}
          variant="outline"
          css={{ width: 45, height: 45 }}
          data-testid={`contact-button-${sanitizedText}`}
        >
          {props.icon}
        </IconButton>
      </TooltipTrigger>
      <TooltipContent>{props.text}</TooltipContent>
    </Tooltip>
  );
};

export function ContactNameInput(props: any): JSX.Element {
  return (
    <ContactNameInputContainer variant="contact" css={{ fontSize: 24 }} {...props} />
  );
}

export function ContactItemInput(props: any): JSX.Element {
  return (
    <Box css={{ cursor: 'pointer', ml: 12 }}>
      <ContactItemInputContainer variant="contact" {...props} />
    </Box>
  );
}

export const ContactEditorLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <ContactPanelLayout
      data-testid="ContactEditorLayout"
      css={{ width: '100%', minWidth: '100%' }}
    >
      <CustomScrollbar>{children}</CustomScrollbar>
    </ContactPanelLayout>
  );
};

export const ContactPanelLayout = styled(Flex, {
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  '@lg': {
    width: '360px',
    maxWidth: '360px',
    display: 'initial',
  },
});

export const ContactNameInputContainer = styled(Input, {
  textAlign: 'center',
  marginTop: '15px',
  minHeight: '36px',
  width: '100%',
  fontSize: '30px',
  fontWeight: 500,
});

export const ContactName = styled('span', {
  marginTop: '15px',
  minHeight: '36px',
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 500,
});

export const ContactItemInputContainer = styled(Input, {
  p: '4px 10px',
  textAlign: 'left',
  jc: 'flex-start',
  height: '35px',
  minWidth: '232px',
  flexShrink: 1,
  overflow: 'hidden',
  bR: 3,
  backgroundColor: 'inherit',
});
