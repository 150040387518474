import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiDuplicate } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { Datepicker } from '@/shared/components/datepicker/Datepicker';
import { usePageView } from '@/shared/hooks';
import { Flex, Grid, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useLinks } from '../context/LinksContext';
import { CreateLinkContainer } from '../create';
import { Card } from './Card';
import { Chart } from './Chart';
import { DevicesSection } from './DevicesSection';
import { LinkContainer } from './LinkContainer';
import { LocationsSection } from './LocationsSection';
import { ReferrerSection } from './ReferrerSection';

export const LinkAnalytics = (): JSX.Element => {
  // product analytics tracking
  usePageView();
  const params = useParams<{ id: string }>();

  // links state
  const {
    linksState: { current, currentLinkAnalytics },
    getLinkAnalytics,
    dates,
    setDates,
  } = useLinks();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (params?.id && dates.startDate && dates.endDate) {
      getData();
    }
  }, [dates.startDate, dates.endDate, params?.id]);

  const getData = async () => {
    setLoading(true);
    setError(false);
    const start = moment(dates.startDate).format('YYYY-MM-DD');
    const end = moment(dates.endDate).format('YYYY-MM-DD');
    try {
      const data = await getLinkAnalytics(params?.id, start, end);
      return data;
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const chart_config = [
    {
      dataKey: 'count',
      fill: '#3E54CF',
      name: 'Total Clicks',
    },
  ];

  const weeklyValue = useMemo(() => {
    if (
      !currentLinkAnalytics?.last_seven_days?.count &&
      !currentLinkAnalytics?.prev_last_seven_days?.count
    ) {
      return 0;
    }
    return currentLinkAnalytics?.prev_last_seven_days?.count
      ? ((currentLinkAnalytics?.last_seven_days?.count -
          currentLinkAnalytics?.prev_last_seven_days?.count) /
          currentLinkAnalytics?.prev_last_seven_days?.count) *
          100
      : 100;
  }, [currentLinkAnalytics?.prev_last_seven_days, currentLinkAnalytics?.last_seven_days]);

  const handleCopyShortLink = useCallback(() => {
    navigator.clipboard.writeText(current?.shareable_link?.short_url || '');
    toast.success(i18next.t('link_url_copied') as string);
  }, [current?.shareable_link?.short_url]);

  const ContainerComponent = params?.id ? LinkContainer : CreateLinkContainer;

  return (
    <ContainerComponent
      actions={
        <Flex gap={2}>
          <ToolTipIconButton
            variant="outline"
            description="Copy Short Link"
            icon={<HiDuplicate />}
            onClick={handleCopyShortLink}
            dataTestID="copy-short-link-button"
          />
          <Datepicker setParentDates={setDates} initialDates={dates} />
        </Flex>
      }
    >
      <Container>
        <VStack gap={4}>
          <Grid columns="3" gap="5">
            <Card
              title="Total Clicks"
              value={currentLinkAnalytics?.selected_days?.count || 0}
              tooltipContent="The total number of links created during the selected time period."
              previousValue={currentLinkAnalytics?.prev_selected_days?.count || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
            />
            <Card
              title="Last 7 Days"
              value={currentLinkAnalytics?.last_seven_days?.count || 0}
              tooltipContent="The total number of links created during the last 7 days."
              previousValue={currentLinkAnalytics?.prev_last_seven_days?.count || 0}
              loading={loading}
              error={error}
              errorCallback={getData}
            />
            <Card
              title="Weekly Changes"
              value={weeklyValue}
              tooltipContent="Weekly changes during the selected time period."
              loading={loading}
              error={error}
              errorCallback={getData}
              hidePercent
              isPercentValue
            />
          </Grid>
          <Chart
            title="Total Clicks by Day"
            chartConfig={chart_config}
            data={currentLinkAnalytics?.selected_days?.per_day || []}
            loading={loading}
            error={error}
            errorCallback={getData}
          />
          <LocationsSection
            data={currentLinkAnalytics}
            loading={loading}
            error={error}
            errorCallback={getData}
          />
          <DevicesSection
            data={currentLinkAnalytics}
            loading={loading}
            error={error}
            errorCallback={getData}
          />
          <ReferrerSection
            title="Total Clicks by Referrer"
            chartConfig={[{ ...chart_config[0], fill: '#16AF8A' }]}
            data={currentLinkAnalytics?.by_referrer || []}
            loading={loading}
            error={error}
            errorCallback={getData}
          />
        </VStack>
      </Container>
    </ContainerComponent>
  );
};

const Container = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  p: 20,
  pb: 0,
});
