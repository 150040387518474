import {
  ConversationMessageType,
  ConversationsSearchV2ApiResponse,
  MessageSearchV2ApiResponse,
  NewConversationParamsType,
  WhippyQueryLanguage,
} from '@/shared/types/conversations';

import { api } from '../api';

/**
 * GET - /api/conversations/:id
 *
 * Retrieves a conversation object from an API using an HTTP GET request.
 * @param conversation_id The ID of the conversation to be retrieved.
 * @param controller An optional AbortController object that can be used to cancel the HTTP request.
 * @returns A Promise that resolves to the conversation object data.
 * 
 * @example {
    "data":{
        "assigned_user_id":null,
        "contact":{
          "email":null,
          "id":"ff703593-b5aa-4e26-8d23-79475073ba8d",
          "name":null,
          "phone":"+13239974985"
        },
        "conversationItemsPage":{
          "conversationItems":[
              {
                "source_type":"OUTBOUND",
                "translated_body":null,
                "language":null,
                "import_id":null,
                "id":"5c51faf2-8a1e-4e35-993c-1417ed55ce53",
                "attachment_urls":[
                    
                ],
                "body":"Hi,  thanks for messaging Organization 2. A member of our team will contact you shortly at this number.",
                "provider_id":"40318781-b98f-413f-95c0-0601c8bfe494",
                "visibility_status":"visible",
                "step_contact_id":null,
                "user_id":null,
                "conversation_id":"e02c97c4-84bc-4f27-b31a-bd3aa56356ee",
                "attachment_content_type":null,
                "error":null,
                "campaign_contact_id":null,
                "attachments":[
                    
                ],
                "type":"automated",
                "updated_at":"2023-04-14T21:44:01.562018Z",
                "translation_language":null,
                "campaign_id":null,
                "step_id":null,
                "inserted_at":"2023-04-14T21:43:53.779011Z",
                "contact_id":null,
                "provider":"telnyx",
                "delivery_status":"delivered"
              }
          ]
        },
        "created_at":"2023-04-14T21:43:53",
        "id":"e02c97c4-84bc-4f27-b31a-bd3aa56356ee",
        "last_message_timestamp":"2023-04-14T21:43:53.779011Z",
        "location_id":"7c770962-dbf7-4478-a4f5-4b6a234131ef",
        "organization_id":"53904907-dc43-4eaa-864f-0100d331efca",
        "readByUsers":[
          {
              "most_recent_read":"2023-04-25T13:28:14",
              "user":{
                "email":"testuser1@whippy.co",
                "id":2,
                "name":"Judy Morissette",
                "attachment":[
                    
                ],
                "default_signature_id":"11142740-8342-43ec-86d9-5eb97de05151"
              }
          }
        ],
        "scheduled_messages":[
          
        ],
        "status":"open",
        "typing":[
          
        ],
        "unread_count":0,
        "updated_at":"2023-04-14T21:44:06"
    }
  }
 * 
 */
export const getConversation = async (
  conversation_id: string,
  controller?: AbortController
) => {
  // Check if an AbortController was passed in and if it has a signal property
  if (controller && controller.signal) {
    // Send the GET request with an abort signal
    const res = await api.get(`/conversations/${conversation_id}`, {
      signal: controller?.signal,
    });
    return res.data.data;
  } else {
    // Send a normal GET request without an abort signal
    const res = await api.get(`/conversations/${conversation_id}`);
    return res.data.data;
  }
};

/*
  GET - get a conversation by location id and contact id
*/

export const getConversationByLocationAndContact = (
  contact_id: string,
  location_id: string
) =>
  api.get(`/conversations/location/${location_id}/contact/${contact_id}`).then((res) => {
    return res.data.data;
  });

/*
  POST - start a new conversation
*/

export const createConversation = async (
  conversationParams: NewConversationParamsType
) => {
  try {
    // If request takes longer than 10 seconds, abort the request
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000);
    const res = await api.post(
      '/conversations',
      { conversation: conversationParams },
      { signal: controller.signal }
    );
    clearTimeout(timeoutId);
    return res.data.data;
  } catch (err) {
    if (err.name === 'AbortError') {
      throw new Error('Request took longer than 15 seconds and was aborted');
    }
    console.log(err);
    throw err;
  }
};

/*
  POST - search the organization's conversations by contact name or phone
*/

export const advancedSearchConversations = (params: any) =>
  api.post(`/conversations/search`, params).then((res) => {
    return res.data.data;
  });

/* 
  POST - search the organization's conversations by filter (Whippy Query Language) 
  Supports aborting the request using an AbortController.
*/
export const conversationSearch = async (
  params: WhippyQueryLanguage,
  controller?: AbortController
): Promise<ConversationsSearchV2ApiResponse> => {
  const options = controller ? { signal: controller.signal } : undefined;
  const res = await api.post(`/v2/conversations/search`, params, options);
  return res.data as ConversationsSearchV2ApiResponse;
};

/* 
  POST - search the contacts in the organization by filter (Whippy Query Language)
  Supports aborting the request using an AbortController.
*/

export const contactSearch = async (
  params: WhippyQueryLanguage,
  controller?: AbortController
) => {
  const options = controller ? { signal: controller.signal } : undefined;
  const res = await api.post(`/v2/conversations/contacts/search`, params, options);
  return res.data as ConversationsSearchV2ApiResponse;
};

/* 
  POST - search the message by message amd conversation id
*/
export const searchMessageById = (
  conversationId: string,
  messageId: string
): Promise<MessageSearchV2ApiResponse> => {
  const params = {
    filter: [
      {
        resource: 'message',
        column: 'id',
        comparison: '==',
        value: messageId,
      },
      {
        resource: 'message',
        column: 'conversation_id',
        comparison: '==',
        value: conversationId,
      },
    ],
  };
  return api.post(`/v2/conversations/${conversationId}/search`, params).then((res) => {
    return res.data;
  });
};

/*
  GET - get a list of scheduled messages
*/

export const getScheduledMessages = () =>
  api.get(`/scheduled_messages`).then((res) => {
    return res.data.data;
  });

/*
  PUT - send a scheduled message now
*/
export const sendScheduledMessage = (id: number) =>
  api.put(`/scheduled_messages/send/${id}`).then((res) => {
    return res.data.data;
  });

/*
  POST - get a list of filtered scheduled messages
*/

export type GetFilteredScheduledMessagesParams = {
  limit: number;
  offset: number;
  location_ids: Array<string>;
  start_date: string;
  end_date: string;
};

export const getFilteredScheduledMessages = (
  params: GetFilteredScheduledMessagesParams
) =>
  api
    .post(`/scheduled_messages`, {
      start_date: params.start_date,
      end_date: params.end_date,
      location_ids: params.location_ids,
      limit: params.limit,
      offset: params.offset,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  PUT - update a scheduled message
*/

export const editScheduledMessage = (
  id: number,
  scheduledMessage: any,
  conversationId: string
) =>
  api
    .put(`/scheduled_messages/${id}`, {
      conversation_id: conversationId,
      scheduled_message: {
        params: { message: scheduledMessage.message },
        schedule_options: scheduledMessage.scheduleParams,
      },
    })
    .then((res) => {
      return res.data.data;
    });

/*
  DELETE - delete a scheduled message
*/

export const deleteScheduledMessage = (id: number) =>
  api.delete(`/scheduled_messages/${id}`).then((res) => {
    return res.data.data;
  });

/*
  POST - bulk import conversations
*/

export const bulkImportConversations = (messages: Array<any>) => {
  const formData = new FormData();
  const blob = new Blob([JSON.stringify(messages, null, 2)], {
    type: 'application/json',
  });

  formData.append('upload', blob);
  formData.append('location_phone', messages[0]?.location_phone);

  api
    .post(`/conversations/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => {
      return res.data.data;
    });
};

/*
  GET - conversation messages
*/

export const getConversationMessages = (
  conversation_id: string,
  offset: number,
  limit = 50
) =>
  api
    .get(`/conversations/${conversation_id}/messages?offset=${offset}&limit=${limit}`)
    .then((res) => {
      return res.data.data;
    });

/*
  POST - search message body by content
*/

export const searchConversationMessages = (conversation_id: string, query: string) =>
  api.post(`/conversations/${conversation_id}/search`, { query }).then((res) => {
    return res.data.data;
  });

/*
  GET - a list of the conversation attachments

  {
   "data":{
      "messages":[
         {
            "attachments":[
               {
                  "content_type":"JPG",
                  "id":"37e8dfbc-68e8-4236-8275-00a6ea6f5e1c",
                  "inserted_at":"2023-03-02T16:18:22",
                  "updated_at":"2023-03-02T16:18:22",
                  "url":"https://dashboard-api-dev.s3.amazonaws.com/organization_f2ee4276-7f4f-43c6-93fd-b7f8f6c9ccf0/35156d81-35d1-4af4-954f-aa895151fe4e/Contact_Import_Spread_Sheet_Test_Cases.xlsx"
               }
            ],
            "body":"EXCEL",
            "campaign_contact_id":null,
            "campaign_id":null,
            "contact_id":null,
            "conversation_id":"f968b220-d25b-41bb-8e1b-9ca6daa0ec59",
            "delivery_status":"queued",
            "error":null,
            "id":"15907eda-4066-4dd6-9d71-76fb2a96011f",
            "inserted_at":"2023-03-02T16:18:22.837057Z",
            "language":null,
            "provider_id":"403186a3-1e15-4213-82fc-95a260bb1cfa",
            "source_type":"OUTBOUND",
            "translated_body":null,
            "translation_language":null,
            "type":"manual",
            "updated_at":"2023-03-02T16:18:23.347267Z",
            "user_id":2
         }
      ],
      "total":1
   }
}

*/

export const searchConversationAttachments = (conversationId: string) =>
  api.post(`/conversations/${conversationId}/attachments`).then((res) => {
    return res.data.data as { messages: Array<ConversationMessageType>; total: number };
  });

/*
  POST - search attachments across all conversations
*/

export type GetAttachmentParams = {
  /* pagination limit */
  limit?: number;
  /* pagination offset */
  offset?: number;
  /* filter by location ids */
  location_ids?: Array<string>;
  /* filter by start date */
  start_date?: string;
  /* filter by end date */
  end_date?: string;
};

export const getAttachments = (params: GetAttachmentParams) =>
  api
    .post(`/conversations/attachments`, {
      start_date: params.start_date,
      end_date: params.end_date,
      location_ids: params.location_ids,
      limit: params.limit,
      offset: params.offset,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  PUT - bulk open or close conversations
*/

export const bulkUpdateConversationStatus = (
  conversationIds: Array<string>,
  status: 'open' | 'closed'
) =>
  api
    .put(`/conversations/update`, {
      conversation_ids: conversationIds,
      status,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  PUT - bulk assign a user
*/

export const bulkAssignConversation = (
  conversation_ids: Array<string>,
  assigned_user_id: number
) =>
  api
    .put(`/conversations/update`, {
      conversation_ids,
      assigned_user_id,
    })
    .then((res) => {
      return res.data.data;
    });

/*
  GET - get unread conversations
*/
export const getUnreadConversations = async (controller?: AbortController) => {
  const options = controller ? { signal: controller.signal } : undefined;
  const { data } = await api.get(`/conversations/unread_count`, options);
  return data;
};
