/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';

import { getAutomationTemplate } from '@/shared/api/automations/templates';
import { AutomationTemplate } from '@/shared/types/automations';
import { BatchDelayTimeUnit, Campaign } from '@/shared/types/campaigns';
import { AccordionValue, Campaign as CampaignTemplate } from '@/shared/types/campaigns';
import { Box, CustomScrollbar, Flex, VStack } from '@/shared/ui';

import { CampaignAutomations } from '../../automations';
import { getIntervalValue } from '../../create';
import { Accordion, CampaignAccordion } from '../../create/CampaignAccordion';
import { CampaignSchedule } from '../../create/CampaignSchedule';
import { hasV1Audience } from '../../editor/components/utils';
import { ViewCampaignAudience } from './ViewCampaignAudience';
import { ViewCampaignBasicInfo } from './ViewCampaignBasicInfo';
import { ViewCampaignMessage } from './ViewCampaignMessage';
import { ViewCampaignV2Audience } from './ViewCampaignV2Audience';

type CampaignOverviewProps = {
  /* campaign object */
  campaign?: Campaign | null;
  /* update the current campaign */
  setCurrentCampaign?: (campaign: Campaign | CampaignTemplate | null) => void;
};

const defaultCampaignSettings = {
  support_sms_quiet_hours: {
    enabled: false,
    use_contact_timezone: false,
  },
  skip_sending_on_weekend: {
    enabled: false,
    use_contact_timezone: false,
  },
  only_send_during_business_hours: {
    enabled: false,
    use_contact_timezone: false,
  },
  link_tracking: {
    disabled: false,
    domain_id: null,
  },
};

export const CampaignOverview = (props: CampaignOverviewProps) => {
  const { campaign, setCurrentCampaign } = props;

  const [accordion, setAccordion] = React.useState<AccordionValue>(
    AccordionValue.DEFAULT_VALUE
  );

  const setDate = (date: string) => {
    console.log('set date', date);
  };

  const setTime = (time: string) => {
    console.log('set time', time);
  };

  const setTimezone = (timeZone: string) => {
    console.log('set time zone', timeZone);
  };

  const setIsScheduled = (isScheduled: boolean) => {
    console.log('set is scheduled', isScheduled);
  };

  const hasTriggersOrTemplates =
    (campaign && campaign.triggers && campaign.triggers.length > 0) ||
    (campaign &&
      campaign.associated_automation_templates &&
      campaign.associated_automation_templates.length > 0)
      ? true
      : false;

  // all as-is templates under this campaign
  const [existingAllAutomationTemplates, setExistingAllAutomationTemplates] = useState<
    Array<AutomationTemplate>
  >([]);

  // fetch the existing as-is templates under this campaign
  const fetchTemplates = async () => {
    const existingAssociatedAutomationTemplates =
      campaign?.associated_automation_templates || [];
    // extract the automation_template_id from each existingAssociatedAutomationTemplates
    const existingAllAutomationTemplateIds = existingAssociatedAutomationTemplates.map(
      (template: {
        automation_template_id: string;
        campaign_automation_template_id: string;
      }) => template.automation_template_id
    );
    const existingAllAutomationTemplatesPromises: Array<Promise<AutomationTemplate>> =
      existingAllAutomationTemplateIds.map((id: string) => getAutomationTemplate(id));
    try {
      const templates = await Promise.all(existingAllAutomationTemplatesPromises);
      setExistingAllAutomationTemplates(templates);
    } catch (error) {
      console.error('Error fetching automation templates:', error);
    }
  };

  const batchDelayTimeUnit = useMemo(() => {
    if (campaign?.delivery_options?.period) {
      const delayInMinutes = campaign?.delivery_options?.period / 60;
      const hourInMinutes = getIntervalValue(BatchDelayTimeUnit.HOURS);
      const dayInMinutes = getIntervalValue(BatchDelayTimeUnit.DAYS);
      if (delayInMinutes >= hourInMinutes && delayInMinutes % hourInMinutes === 0) {
        return BatchDelayTimeUnit.HOURS;
      }
      if (delayInMinutes >= dayInMinutes && delayInMinutes % dayInMinutes === 0) {
        return BatchDelayTimeUnit.DAYS;
      }
    }
    return BatchDelayTimeUnit.MINUTES;
  }, [campaign?.delivery_options?.period]);

  const batchDelay = useMemo(() => {
    if (campaign?.delivery_options?.period) {
      if (batchDelayTimeUnit === BatchDelayTimeUnit.MINUTES) {
        return campaign?.delivery_options?.period / 60;
      }
      if (batchDelayTimeUnit === BatchDelayTimeUnit.HOURS) {
        return (
          campaign?.delivery_options?.period /
          getIntervalValue(BatchDelayTimeUnit.HOURS) /
          60
        );
      }
      if (batchDelayTimeUnit === BatchDelayTimeUnit.DAYS) {
        return (
          campaign?.delivery_options?.period /
          getIntervalValue(BatchDelayTimeUnit.DAYS) /
          60
        );
      }
    }
    return 0;
  }, [batchDelayTimeUnit, campaign?.delivery_options?.period]);

  useEffect(() => {
    fetchTemplates();
  }, [campaign]);

  return (
    <Flex css={{ height: 'calc(100vh - 225px)' }}>
      <CustomScrollbar css={{ display: 'flex !important' }} asChild>
        <Flex css={{ p: 30, width: '100%' }}>
          <Box css={{ width: '100%' }}>
            <Flex direction="column">
              {campaign && (
                <Accordion type="single" defaultValue="select_audience" value={accordion}>
                  <VStack gap="2">
                    <ViewCampaignBasicInfo
                      campaign={campaign}
                      accordion={accordion}
                      setAccordion={setAccordion}
                    />
                    {hasV1Audience(campaign) ? (
                      <ViewCampaignAudience
                        current={campaign}
                        accordion={accordion}
                        setAccordion={setAccordion}
                      />
                    ) : (
                      <ViewCampaignV2Audience
                        campaign={campaign}
                        accordion={accordion}
                        setAccordion={setAccordion}
                      />
                    )}
                    <ViewCampaignMessage
                      campaign={campaign}
                      accordion={accordion}
                      setAccordion={setAccordion}
                    />
                    {(campaign.schedule_options?.day ||
                      campaign.delivery_options?.messages_per_period) && (
                      <CampaignSchedule
                        accordion={accordion}
                        showSchedulePicker={true}
                        setAccordion={setAccordion}
                        date={`${campaign.schedule_options?.month}/${campaign.schedule_options?.day}/${campaign.schedule_options?.year}`}
                        setDate={setDate}
                        time={`${campaign.schedule_options?.hour}:${campaign.schedule_options?.minute}`}
                        setTime={setTime}
                        setTimezone={setTimezone}
                        setIsScheduled={setIsScheduled}
                        isScheduled={!!campaign?.schedule_options?.day}
                        time_zone={campaign.schedule_options?.timezone}
                        isValidSchedule={true}
                        enableBatchSend={
                          !!(
                            campaign?.delivery_options?.period &&
                            campaign?.delivery_options?.messages_per_period
                          )
                        }
                        batchSize={campaign?.delivery_options?.messages_per_period ?? 0}
                        batchDelay={batchDelay}
                        batchDelayTimeUnit={batchDelayTimeUnit}
                        enableCampaignSettings={
                          !!(
                            campaign?.settings?.support_sms_quiet_hours?.enabled ||
                            campaign?.settings?.skip_sending_on_weekend?.enabled ||
                            campaign?.settings?.only_send_during_business_hours?.enabled
                          )
                        }
                        campaignSettings={campaign.settings ?? defaultCampaignSettings}
                        enableLinkTrackingSettings={
                          !!(
                            campaign?.settings?.link_tracking?.disabled ||
                            campaign?.settings?.link_tracking?.domain_id
                          )
                        }
                        disableEdit={true}
                      />
                    )}
                    {setCurrentCampaign && (
                      <CampaignAccordion
                        index={5}
                        title="Automations"
                        description="Automate campaign follow up tasks."
                        currentAccordionValue={accordion}
                        itemValue={AccordionValue.CREATE_AUTOMATION}
                        setItemValue={setAccordion}
                        isValid={hasTriggersOrTemplates}
                      >
                        <CampaignAutomations
                          current={campaign}
                          setCurrentCampaign={setCurrentCampaign}
                          accordion={accordion}
                          setAccordion={setAccordion}
                          existingAllAutomationTemplates={existingAllAutomationTemplates}
                          setExistingAllAutomationTemplates={
                            setExistingAllAutomationTemplates
                          }
                        />
                      </CampaignAccordion>
                    )}
                  </VStack>
                </Accordion>
              )}
            </Flex>
          </Box>
        </Flex>
      </CustomScrollbar>
    </Flex>
  );
};
