import moment from 'moment';
import { Dispatch, useState } from 'react';
import React from 'react';
import { HiArrowRight, HiCalendar, HiPencil } from 'react-icons/hi';

import { Datepicker, DatepickerDates } from '@/shared/components/datepicker/Datepicker';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

export const dateOption = [
  {
    label: 'Today',
    value: {
      startDate: moment(),
      endDate: moment(),
    },
  },
  {
    label: 'Yesterday',
    value: {
      startDate: moment().add(-1, 'day'),
      endDate: moment().add(-1, 'day'),
    },
  },
  {
    label: 'Past week',
    value: {
      startDate: moment().add(-1, 'week'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 4 weeks',
    value: {
      startDate: moment().add(-4, 'week'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 12 weeks',
    value: {
      startDate: moment().add(-12, 'week'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 6 months',
    value: {
      startDate: moment().add(-6, 'month'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 12 months',
    value: {
      startDate: moment().add(-12, 'month'),
      endDate: moment(),
    },
  },
];

export const AnalyticDateSelector = ({
  dates,
  setDates,
}: {
  dates: DatepickerDates;
  setDates: Dispatch<React.SetStateAction<DatepickerDates>>;
}) => {
  const [isCustomDate, setIsCustomDate] = useState<boolean>(false);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTrigger
          data-testid="date-selector-trigger"
          align="center"
          justify="center"
          gap={2}
        >
          <Box>{dates.startDate?.format('MMM D, YYYY')}</Box>
          <HiArrowRight />
          <Box>{dates.endDate?.format('MMM D, YYYY')}</Box>
        </DropdownTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuContent sideOffset={10}>
        {dateOption.map((option) => {
          return (
            <DropdownMenuItem
              data-testid="date-selector-item"
              css={{
                '&:hover': {
                  backgroundColor: '#3A5CCC',
                  color: '#fff',
                },
                margin: '0 4px',
                height: '2rem',
                borderRadius: 4,
                ...(!isCustomDate &&
                option.value.startDate === dates.startDate &&
                option.value.endDate === dates.endDate
                  ? {
                      background: '#3E63DD',
                      color: 'white',
                    }
                  : {}),
              }}
              key={option.label}
              onSelect={() => {
                setIsCustomDate(false);
                setDates(option.value);
              }}
            >
              <Flex
                gap={2}
                align="center"
                css={{ fontSize: 14, padding: '8px 0px', cursor: 'pointer' }}
              >
                <HiCalendar />
                <Box>{option.label}</Box>
              </Flex>
            </DropdownMenuItem>
          );
        })}
        <hr style={{ color: '#02023414', margin: 8 }} />
        {isCustomDate ? (
          <Box data-testid="datepicker">
            <Datepicker
              anchorDirection="right"
              openDirection="up"
              noBorder={true}
              setParentDates={(value) => {
                setDates(value);
              }}
              initialDates={dates}
            />
          </Box>
        ) : (
          <Flex
            onClick={() => setIsCustomDate(true)}
            data-testid="custom-date-btn"
            gap={2}
            align="center"
            css={{ fontSize: 14, padding: '8px 20px', cursor: 'pointer' }}
          >
            <HiPencil />
            <Box>Custom</Box>
          </Flex>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DropdownTrigger = styled(Flex, {
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  borderRadius: 4,
  color: 'hsl(204 10% 10%)',
  border: '1px solid $slate7',
  padding: '$space$1 $space$3',
  height: 40,
  fontSize: '14px',
  minWidth: 'max-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
