import { useFlags } from 'launchdarkly-react-client-sdk';
import { omit } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIdle } from 'react-use';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as CampaignsAPI from '@/shared/api/campaigns';
import * as CampaignsTemplatesAPI from '@/shared/api/campaigns/templates';
import { GetCampaignTemplateParams } from '@/shared/api/campaigns/templates';
import { Audience } from '@/shared/types/audience';
import {
  Campaign,
  CampaignContact,
  CampaignExcludeContacts,
  Contact,
} from '@/shared/types/campaigns';
import { SearchFilters } from '@/shared/types/contacts';
import i18next from '@/shared/utils/translation';

import CampaignsReducer from './CampaignsReducer';
import { CampaignAnalyticsTab } from './types';
import {
  CHANGE_CAMPAIGN_STATUS,
  CHANGE_TAB_TOTAL_CONTACTS,
  CLEAR_AUDIENCE,
  CREATE_CAMPAIGN,
  CREATE_CAMPAIGN_TEMPLATE,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN,
  GET_CAMPAIGN_TEMPLATE,
  GET_CAMPAIGN_TEMPLATES,
  GET_CAMPAIGNS,
  GET_MESSAGE_COUNT,
  SET_ALL_SELECTED,
  SET_CAMPAIGN_CONTACTS_PARAMS,
  SET_CURRENT,
  SET_CURRENT_TEMPLATE,
  SET_LOADING,
  SET_LOADING_CAMPAIGN_TEMPLATES,
  SET_LOADING_CONTACTS,
  SET_SELECTED_CONTACTS,
  SET_UPDATING,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_TEMPLATE,
  UPDATE_CAMPAIGNS,
} from './types';
import { checkCampaignURL, generateCampaignRequestType } from './utils';

export const ITEMS_COUNT = 50;

export type CampaignsParams = {
  [key: string]: string | string[] | number | number[] | boolean;
  status: string;
};

export type CampaignsState = {
  campaigns: Array<Campaign>;
  templates: Array<Campaign>;
  currentTemplate: Campaign | null;
  draftCampaigns: Array<Campaign>;
  archivedCampaigns: Array<Campaign>;
  completeCampaigns: Array<Campaign>;
  scheduledCampaigns: Array<Campaign>;
  searchedCampaigns: Array<Campaign>;
  filteredCampaigns: Array<Campaign>;
  deliveredContacts: Array<CampaignContact>;
  notDeliveredContacts: Array<CampaignContact>;
  respondedContacts: Array<CampaignContact>;
  unrespondedContacts: Array<CampaignContact>;
  clickedContacts: Array<CampaignContact>;
  notClickedContacts: Array<CampaignContact>;
  unsubscribedContacts: Array<CampaignContact>;
  unfulfilledContacts: Array<CampaignContact>;
  isCampaignsInitialized: boolean;
  isSearched: boolean;
  loading: boolean;
  loadingTemplates: boolean;
  current: Campaign | null;
  error: Error | null;
  currentMessageCount: number;
  selectedContacts: Array<Contact>;
  currentTabTotalContacts: number | null;
  allSelected: boolean;
  totalCount: number;
  isUpdating: boolean;
  campaignContacts: Array<CampaignContact>;
  totalCampaignContacts: number;
  loadingContacts: boolean;
  campaignContactsParams: SearchFilters;
  totalCampaignTemplates: number;
};

export const initialCampaignsState: CampaignsState = {
  isCampaignsInitialized: false,
  campaigns: [],
  templates: [],
  currentTemplate: null,
  draftCampaigns: [],
  archivedCampaigns: [],
  completeCampaigns: [],
  scheduledCampaigns: [],
  searchedCampaigns: [],
  filteredCampaigns: [],
  deliveredContacts: [],
  notDeliveredContacts: [],
  respondedContacts: [],
  unrespondedContacts: [],
  clickedContacts: [],
  notClickedContacts: [],
  unsubscribedContacts: [],
  unfulfilledContacts: [],
  isSearched: false,
  loading: true,
  loadingTemplates: true,
  current: null,
  error: null,
  currentMessageCount: 0,
  selectedContacts: [],
  currentTabTotalContacts: 0,
  totalCount: 0,
  allSelected: false,
  isUpdating: false,
  campaignContacts: [],
  totalCampaignContacts: 0,
  loadingContacts: true,
  campaignContactsParams: {
    offset: 0,
    limit: ITEMS_COUNT,
    sort: [],
    filter: [],
    searchFilter: [],
  },
  totalCampaignTemplates: 0,
};

export interface CampaignsContacts {
  campaignsContacts: Array<CampaignContact>;
  total: number;
}

export const CampaignsContext = createContext<{
  campaignsState: CampaignsState;
  /* fetch campaigns with parameters or not */
  getCampaigns: (params: CampaignsParams) => void;
  updateCampaigns: (params: CampaignsParams) => void;
  /* fetch a single campaign */
  getCampaign: (id: string) => void;
  getCampaignV2: (id: string) => void;
  getCampaignContacts: (
    id: string,
    type: string,
    offset: number,
    limit: number | null
  ) => Promise<CampaignsContacts | null | undefined> | void;
  searchCampaignContacts: (id: string, type: string, name: string) => void;
  createOneCampaign: (campaign: Campaign) => any;
  createV2Campaign: (campaign: Campaign) => any;
  updateOneCampaign: (
    campaignId: string,
    campaign: Campaign,
    setAsCurrent: boolean
  ) => any;
  updateV2Campaign: (
    campaignId: string,
    campaign: Campaign,
    setAsCurrent: boolean
  ) => any;
  deleteOneCampaign: (id: string) => void;
  setCurrentCampaign: (campaign: Campaign | null) => void;
  changeCampaignStatus: (id: string) => void;
  getCampaignTemplates: (params: GetCampaignTemplateParams) => void;
  getCampaignTemplate: (id: string) => void;
  createCampaignTemplate: (template: Campaign) => Promise<Campaign>;
  updateCampaignTemplate: (id: string, template: Campaign) => void;
  deleteCampaignTemplate: (id: string) => void;
  duplicateCampaignTemplate: (id: string) => void;
  setCurrentTemplate: (template: Campaign | Campaign | null) => void;
  sendPreview: (
    body: string,
    attachments: Array<string>,
    from: string,
    to: string
  ) => void;
  setLoading: (loading: boolean) => void;
  clearAudience: () => void;
  getOneCampaignMessageCount: (
    includeAudience: Audience,
    excludeAudience: CampaignExcludeContacts
  ) => void;
  duplicateOneCampaign: (id: string, include?: boolean) => Promise<Campaign>;
  setSelectedContacts: (contacts: Array<Contact>) => void;
  setAllSelected: (param: boolean) => void;
  showConfetti: boolean;
  setShowConfetti: () => void;
  currentTab: CampaignAnalyticsTab;
  calculatingAudience: boolean;
  setCurrentTab: (tab: CampaignAnalyticsTab) => void;
  changeCurrentTabTotalContacts: (param: number | null) => void;
  pauseOneCampaign: (id: string, campaign: Campaign) => void;
  isIdle: boolean;
  getCampaignContactsV2: (
    sequenceId: string,
    params: SearchFilters,
    isNewSearch?: boolean,
    signal?: any
  ) => void;
  setLoadingContacts: (loading: boolean) => void;
  setLoadingCampaignTemplates: (loading: boolean) => void;
  updateCampaignContactsParams: (filters: SearchFilters) => void;
  sendEmailPreview: (
    body: string,
    attachments: Array<string>,
    channel_id: string,
    subject: string,
    to: string
  ) => void;
}>({
  campaignsState: initialCampaignsState,
  showConfetti: false,
  currentTab: CampaignAnalyticsTab.OVERVIEW,
  calculatingAudience: false,
  isIdle: false,
  getCampaigns: () => Promise.resolve(),
  updateCampaigns: () => Promise.resolve(),
  getCampaign: () => Promise.resolve(),
  clearAudience: () => Promise.resolve(),
  getCampaignContacts: () => Promise.resolve(null),
  searchCampaignContacts: () => Promise.resolve(),
  createOneCampaign: () => Promise.resolve(),
  updateOneCampaign: () => Promise.resolve(),
  createV2Campaign: () => Promise.resolve(),
  updateV2Campaign: () => Promise.resolve(),
  getCampaignV2: () => Promise.resolve({} as Campaign),
  deleteOneCampaign: () => Promise.resolve(),
  setCurrentCampaign: () => Promise.resolve(),
  getCampaignTemplates: () => Promise.resolve(),
  getCampaignTemplate: () => Promise.resolve(),
  createCampaignTemplate: () => Promise.resolve({} as Campaign),
  updateCampaignTemplate: () => Promise.resolve({} as Campaign),
  deleteCampaignTemplate: () => Promise.resolve(),
  duplicateCampaignTemplate: () => Promise.resolve(),
  setCurrentTemplate: () => Promise.resolve(),
  changeCampaignStatus: () => Promise.resolve(),
  sendPreview: () => Promise.resolve(),
  setLoading: () => Promise.resolve(),
  getOneCampaignMessageCount: () => Promise.resolve(),
  duplicateOneCampaign: () => Promise.resolve({} as Campaign),
  setSelectedContacts: () => Promise.resolve(),
  setAllSelected: () => Promise.resolve(),
  setShowConfetti: () => Promise.resolve(),
  setCurrentTab: () => Promise.resolve(),
  changeCurrentTabTotalContacts: () => Promise.resolve(),
  pauseOneCampaign: () => Promise.resolve(),
  getCampaignContactsV2: () => Promise.resolve([] as Array<any>),
  setLoadingContacts: () => Promise.resolve(),
  setLoadingCampaignTemplates: () => Promise.resolve(),
  updateCampaignContactsParams: () => Promise.resolve(),
  sendEmailPreview: () => Promise.resolve(),
});

export const useCampaignsContext = () => useContext(CampaignsContext);

const CampaignsState = ({ children }: { children: React.ReactNode }) => {
  const [campaignsState, dispatch] = useReducer(CampaignsReducer, initialCampaignsState);
  const [currentTab, setCurrentTab] = useState<CampaignAnalyticsTab>(
    CampaignAnalyticsTab.OVERVIEW
  );
  const [calculatingAudience, setCalculatingAudience] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { campaignContactsV2 } = useFlags();
  // Use the useRef hook to keep track of the current AbortController instance
  const currentController = useRef<AbortController | null>(null);

  // Set the value of isIdle to true after 1 minute of user inactivity
  const isIdle = useIdle(60e3);

  useEffect(() => {
    if (campaignsState.current && campaignsState.current.id) {
      getCampaignContactsV2(
        campaignsState.current.id,
        campaignsState.campaignContactsParams,
        campaignsState.campaignContactsParams.offset === 0 ? true : false
      );
    }
  }, [campaignsState.campaignContactsParams]);

  useEffect(() => {
    if (campaignsState.current && campaignsState.current.id) {
      checkCampaignURL(history, location, campaignsState.current, campaignContactsV2);
    }
  }, [campaignsState.current]);

  // Fetch list of campaigns
  const getCampaigns = async (params: CampaignsParams) => {
    try {
      if (params?.isLoading) {
        setLoading(true);
      }

      const { data } = await CampaignsAPI.getCampaigns(
        omit(params, 'isLoading') as CampaignsParams
      );

      dispatch({
        type: GET_CAMPAIGNS,
        payload: { ...data, status: params.status },
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Update list of campaigns
  const updateCampaigns = async (params: CampaignsParams) => {
    try {
      dispatch({
        type: SET_UPDATING,
        payload: true,
      });

      const { data } = await CampaignsAPI.getCampaigns(params);

      dispatch({
        type: UPDATE_CAMPAIGNS,
        payload: { ...data, status: params.status },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCampaign = async (id: string) => {
    try {
      const data = await CampaignsAPI.getOneCampaign(id);

      dispatch({
        type: GET_CAMPAIGN,
        payload: data,
      });

      setCurrentCampaign(data);
    } catch (err) {
      console.error(err);
    }
  };

  const getCampaignV2 = async (id: string) => {
    try {
      const data = await CampaignsAPI.getOneCampaignV2(id);

      dispatch({
        type: GET_CAMPAIGN,
        payload: data,
      });

      setCurrentCampaign(data);
    } catch (err) {
      console.error(err);
    }
  };

  const createOneCampaign = async (campaign: Campaign) => {
    try {
      const data = await CampaignsAPI.createCampaign(campaign);

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: data,
      });

      // check if it is a quick campaign
      if (campaign.title?.includes('quick-campaign-')) {
        // check if the quick campaign is scheduled
        campaign.schedule_options?.year
          ? toast.success(i18next.t('quick_campaign_scheduled') as string)
          : toast.success(i18next.t('quick_campaign_sent') as string);
      }
      return data;
    } catch (err) {
      toast.error(i18next.t('campaign_creation_failure') as string);
      console.error(err);
    }
  };

  const createV2Campaign = async (campaign: Campaign) => {
    try {
      const data = await CampaignsAPI.createCampaignV2(campaign);

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: data,
      });

      // check if it is a quick campaign
      if (campaign.title?.includes('quick-campaign-')) {
        // check if the quick campaign is scheduled
        campaign.schedule_options?.year
          ? toast.success(i18next.t('quick_campaign_scheduled') as string)
          : toast.success(i18next.t('quick_campaign_sent') as string);
      }
      return data;
    } catch (err) {
      toast.error(i18next.t('campaign_creation_failure') as string);
      console.error(err);
    }
  };

  const updateOneCampaign = async (
    campaignId: string,
    campaign: Campaign,
    setAsCurrent: boolean
  ) => {
    try {
      const data = await CampaignsAPI.updateCampaign(campaignId, campaign);
      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: data,
      });

      if (setAsCurrent) {
        dispatch({
          type: SET_CURRENT,
          payload: data,
        });
      }

      toast.success(i18next.t('campaign_update_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('campaign_update_failure') as string);
      console.error(err);
    }
  };

  const updateV2Campaign = async (
    campaignId: string,
    campaign: Campaign,
    setAsCurrent: boolean
  ) => {
    try {
      const data = await CampaignsAPI.updateCampaignV2(campaignId, campaign);

      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: data,
      });

      if (setAsCurrent) {
        dispatch({
          type: SET_CURRENT,
          payload: data,
        });
      }

      toast.success(i18next.t('campaign_update_success') as string);

      return data;
    } catch (err) {
      toast.error(i18next.t('campaign_update_failure') as string);
      console.error(err);
    }
  };

  const deleteOneCampaign = async (id: string) => {
    try {
      await CampaignsAPI.deleteCampaign(id);

      dispatch({
        type: DELETE_CAMPAIGN,
        payload: id,
      });

      toast.success(i18next.t('campaign_delete_success') as string);
    } catch (err) {
      toast.error(i18next.t('campaign_delete_failure') as string);
      console.error(err);
    }
  };

  const setCurrentCampaign = (campaign: Campaign | null) => {
    try {
      dispatch({
        type: SET_CURRENT,
        payload: campaign,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const sendPreview = async (
    body: string,
    attachments: Array<string>,
    from: string,
    to: string
  ) => {
    try {
      const data = await CampaignsAPI.sendCampaignPreview({
        body: body,
        attachment_urls: attachments,
        from: from,
        to: to,
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const sendEmailPreview = async (
    body: string,
    attachments: Array<string>,
    channel_id: string,
    subject: string,
    to: string
  ) => {
    try {
      const data = await CampaignsAPI.sendCampaignEmailPreview({
        body: body,
        attachment_urls: attachments,
        channel_id: channel_id,
        to: to,
        subject: subject,
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getCampaignContacts = async (
    id: string,
    type: string,
    offset: number,
    limit: number | null
  ) => {
    try {
      const data = await CampaignsAPI.getCampaignContactsByType(id, type, offset, limit);

      const campaignType = generateCampaignRequestType(type);

      if (!campaignType) return null;

      const total: number = data.total || 0;

      dispatch({
        type: campaignType,
        payload: {
          data: data.campaigns_contacts,
          search: false,
          offset,
          total,
        },
      });

      const response: CampaignsContacts = {
        campaignsContacts: data.campaigns_contacts,
        total,
      };

      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const setLoadingContacts = (loading: boolean) => {
    dispatch({
      type: SET_LOADING_CONTACTS,
      payload: loading,
    });
  };

  const getCampaignContactsV2 = async (
    id: string,
    params: SearchFilters,
    isNewSearch?: boolean
  ): Promise<any> => {
    /**
     * Cancel the previous request by calling the abort method on the current AbortController instance,
     * and then set the current instance to null.
     */
    if (currentController.current) {
      currentController.current.abort();
      currentController.current = null;
    }
    const controller = new AbortController();
    currentController.current = controller;

    try {
      if (params.offset === 0) {
        setLoadingContacts(true);
      }

      const data = await CampaignsAPI.getCampaignContactsV2(
        id,
        prepareFilters(params),
        controller.signal
      ).finally(() => {
        setLoadingContacts(false);
      });

      const dispatchType = isNewSearch
        ? 'GET_CAMPAIGN_CONTACTS_V2_NEW_SEARCH'
        : 'GET_CAMPAIGN_CONTACTS_V2';

      dispatch({
        type: dispatchType,
        payload: data,
      });

      return data;
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  };

  const searchCampaignContacts = async (id: string, type: string, name: string) => {
    try {
      const data = await CampaignsAPI.searchCampaignContactsByType(id, type, name);

      const campaignType = generateCampaignRequestType(type);

      if (!campaignType) return;

      dispatch({
        type: campaignType,
        payload: { data: data.campaigns_contacts, search: true, total: data.total },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getOneCampaignMessageCount = async (
    includeAudience: Audience,
    excludeAudience: CampaignExcludeContacts
  ) => {
    try {
      setCalculatingAudience(true);

      const data = await CampaignsAPI.getCampaignMessageCount(
        includeAudience,
        excludeAudience
      );

      // if the count is 0 then try calculating the count again
      if (data.count === 0) {
        const data = await CampaignsAPI.getCampaignMessageCount(
          includeAudience,
          excludeAudience
        );

        dispatch({
          type: GET_MESSAGE_COUNT,
          payload: data.count,
        });

        setCalculatingAudience(false);

        return data.count;
      }

      dispatch({
        type: GET_MESSAGE_COUNT,
        payload: data.count,
      });

      setCalculatingAudience(false);

      return data.count;
    } catch (err) {
      console.error(err);
    }
  };

  const duplicateOneCampaign = async (
    id: string,
    include?: boolean
  ): Promise<Campaign> => {
    try {
      const data = await CampaignsAPI.duplicateCampaign(id, include);

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: data,
      });

      toast.success(i18next.t('campaign_duplicate_success') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('campaign_duplicate_failure') as string);
      return Promise.reject(err);
    }
  };

  const pauseOneCampaign = async (id: string, campaign: Campaign) => {
    try {
      const data = await CampaignsAPI.updateCampaign(id, campaign);

      dispatch({
        type: UPDATE_CAMPAIGN,
        payload: data,
      });

      toast.success(i18next.t('campaign_pause_success') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('campaign_pause_failure') as string);
    }
  };

  const changeCampaignStatus = (id: string) => {
    dispatch({
      type: CHANGE_CAMPAIGN_STATUS,
      payload: id,
    });
  };

  const setLoading = (loading: boolean) => {
    dispatch({
      type: SET_LOADING,
      payload: loading,
    });
  };

  const clearAudience = () => {
    dispatch({
      type: CLEAR_AUDIENCE,
      payload: null,
    });
  };

  const setSelectedContacts = (contacts: Array<Contact>) => {
    dispatch({
      type: SET_SELECTED_CONTACTS,
      payload: contacts,
    });
  };

  const setAllSelected = (param: boolean) => {
    dispatch({
      type: SET_ALL_SELECTED,
      payload: param,
    });
  };

  const changeCurrentTabTotalContacts = (param: number | null) => {
    dispatch({
      type: CHANGE_TAB_TOTAL_CONTACTS,
      payload: param,
    });
  };

  const [showConfetti, setConfetti] = React.useState(false);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const setShowConfetti = async () => {
    setConfetti(true);

    await delay(4000);

    setConfetti(false);
  };

  const getCampaignTemplates = async (params: GetCampaignTemplateParams) => {
    try {
      const data = await CampaignsTemplatesAPI.getCampaignTemplates(params);

      dispatch({
        type: GET_CAMPAIGN_TEMPLATES,
        payload: { campaigns: data.campaigns, total: data.total, offset: params.offset },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCampaignTemplate = async (id: string) => {
    try {
      const data = await CampaignsTemplatesAPI.getCampaignTemplate(id);

      dispatch({
        type: GET_CAMPAIGN_TEMPLATE,
        payload: data,
      });

      setCurrentTemplate(data);

      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const createCampaignTemplate = async (template: Campaign): Promise<Campaign> => {
    try {
      const data = await CampaignsTemplatesAPI.createCampaignTemplate(template);

      dispatch({
        type: CREATE_CAMPAIGN_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('campaign_template_created_success') as string);

      return data as Campaign;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('campaign_template_created_failure') as string);
      return Promise.reject(err);
    }
  };

  const updateCampaignTemplate = async (id: string, template: Campaign) => {
    try {
      const data = await CampaignsTemplatesAPI.updateCampaignTemplate(id, template);

      dispatch({
        type: UPDATE_CAMPAIGN_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('campaign_template_updated_success') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('campaign_template_updated_failure') as string);
    }
  };

  const deleteCampaignTemplate = async (id: string) => {
    try {
      await CampaignsTemplatesAPI.deleteCampaignTemplate(id);

      dispatch({
        type: DELETE_CAMPAIGN_TEMPLATE,
        payload: id,
      });

      toast.success(i18next.t('campaign_template_deleted_success') as string);
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('campaign_template_deleted_failure') as string);
    }
  };

  const duplicateCampaignTemplate = async (id: string) => {
    try {
      const data = await CampaignsTemplatesAPI.duplicateCampaignTemplate(id);

      dispatch({
        type: CREATE_CAMPAIGN_TEMPLATE,
        payload: data,
      });

      toast.success(i18next.t('campaign_template_duplicate_success') as string);

      return data;
    } catch (err) {
      console.error(err);
      toast.error(i18next.t('campaign_template_duplicate_failure') as string);
    }
  };

  const setCurrentTemplate = (template: Campaign | null) => {
    dispatch({
      type: SET_CURRENT_TEMPLATE,
      payload: template,
    });
  };

  const setLoadingCampaignTemplates = (loading: boolean) => {
    dispatch({
      type: SET_LOADING_CAMPAIGN_TEMPLATES,
      payload: loading,
    });
  };

  const updateCampaignContactsParams = (filters: SearchFilters) => {
    dispatch({
      type: SET_CAMPAIGN_CONTACTS_PARAMS,
      payload: filters,
    });
  };

  return (
    <CampaignsContext.Provider
      value={{
        campaignsState,
        getCampaigns,
        updateCampaigns,
        getCampaign,
        getCampaignV2,
        createOneCampaign,
        createV2Campaign,
        updateOneCampaign,
        updateV2Campaign,
        deleteOneCampaign,
        setCurrentCampaign,
        changeCampaignStatus,
        sendPreview,
        getCampaignContacts,
        searchCampaignContacts,
        setLoading,
        clearAudience,
        getOneCampaignMessageCount,
        duplicateOneCampaign,
        setSelectedContacts,
        setAllSelected,
        showConfetti,
        setShowConfetti,
        setCurrentTab,
        currentTab,
        changeCurrentTabTotalContacts,
        pauseOneCampaign,
        isIdle,
        calculatingAudience,
        getCampaignTemplates,
        getCampaignTemplate,
        createCampaignTemplate,
        updateCampaignTemplate,
        setCurrentTemplate,
        deleteCampaignTemplate,
        duplicateCampaignTemplate,
        getCampaignContactsV2,
        setLoadingContacts,
        setLoadingCampaignTemplates,
        updateCampaignContactsParams,
        sendEmailPreview,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export default CampaignsState;
