import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Toaster } from 'sonner';

import {
  ForgotPassword,
  Join,
  Login,
  Organizations,
  Register,
  ResetPassword,
  SSO,
  SSORedirect,
} from '@/auth';
import { useAuth } from '@/auth/context/AuthProvider';
import {
  AutomationsContainer,
  AutomationTemplate,
  AutomationTemplates,
  EditKeyword,
  Keywords,
  Questions,
} from '@/automation';
import {
  AllCampaigns,
  ArchiveCampaign,
  Campaign,
  CampaignContainer,
  CampaignGlobalTemplatePreview,
  CampaignTemplate,
  CampaignTemplates,
  CompletedCampaigns,
  DraftCampaigns,
  ScheduledCampaigns,
  TemplateEditor,
} from '@/campaigns';
import {
  AllContacts,
  AllGroups,
  AllUploads,
  ContactContainer,
  Group,
  Upload,
} from '@/contacts';
import {
  ConversationAttachments,
  ConversationContainer,
  ConversationRedirect,
  InboxContainer,
  ScheduledMessages,
} from '@/inbox';
import {
  AgentAnalytics,
  AgentCall,
  AgentCalls,
  Agents,
  AgentsContainer,
  AgentVersion,
  AgentVersionCall,
  AgentVersionCalls,
  AgentVersions,
} from '@/pages/agents';
import { AgentContainer } from '@/pages/agents/editor/AgentContainer';
import { EditAgent } from '@/pages/agents/editor/EditAgent';
import { AITemplates } from '@/pages/agents/templates';
import { WebCallEnd } from '@/pages/agents/web/WebCallEnd';
import { WebCallInProgress } from '@/pages/agents/web/WebCallInProgress';
import { WebCallLogin } from '@/pages/agents/web/WebCallLogin';
import { WebCallStart } from '@/pages/agents/web/WebCallStart';
import { RedirectToMobileApp } from '@/pages/auth/RedirectToMobileApp';
import { CampaignAnalytics } from '@/pages/campaigns/analytics';
import {
  CreateCampaign,
  CreateEmailCampaign,
  CreateLinkCampaign,
} from '@/pages/campaigns/editor';
import { EmailCampaignsListPage } from '@/pages/campaigns/emails/ListEmailPage';
import {
  CampaignAnalytics as CampaignAnalyticsV2,
  CampaignContacts,
  CampaignOverview,
} from '@/pages/campaigns/v2/analytics';
import { Contacts } from '@/pages/data/contacts';
import { DataContainer } from '@/pages/data/layout/DataLayoutContainer';
import { Lists } from '@/pages/data/lists';
import { List } from '@/pages/data/lists/List';
import { Records } from '@/pages/data/objects/Records';
import { Segments } from '@/pages/data/segments';
import { Segment } from '@/pages/data/segments/Segment';
import { ApiKeys, DeveloperContainer, Webhooks } from '@/pages/developer';
import { Events } from '@/pages/developer/events';
import { Endpoint } from '@/pages/developer/webhooks/EditEndpoint';
import { Domain, DomainsList } from '@/pages/domains';
import { LinksContainer } from '@/pages/domains/layout/LinksLayoutContainer';
import { Embed } from '@/pages/embed';
import { CreateConversation } from '@/pages/inbox/create';
import { DraftMessages } from '@/pages/inbox/drafts';
import { Integration, Integrations } from '@/pages/integrations';
import { Configurations } from '@/pages/integrations/configurations';
import { CreateLink, Link, LinkAnalytics, LinksList } from '@/pages/links';
import { AgentsReport } from '@/pages/reports/agents';
import { UsageReport } from '@/pages/reports/usage';
import { AllReviews, LocationReviews } from '@/pages/reviews';
import ReviewState from '@/pages/reviews/context/ReviewContext';
import { ReviewLayoutContainer } from '@/pages/reviews/layout/ReviewLayoutContainer';
import { SequenceGlobalTemplateContainer } from '@/pages/sequences/templates/browse/SequenceGlobalTemplateContainer';
import { SequenceTemplates } from '@/pages/sequences/templates/list';
import { SequenceTemplate } from '@/pages/sequences/templates/SequenceTemplate';
import { SequenceTemplateContainer } from '@/pages/sequences/templates/SequenceTemplateContainer';
import { ProvidersAccounts } from '@/pages/settings/organization/accounts';
import { CreateNewChannel } from '@/pages/settings/organization/channels/create';
import { TrustCenter } from '@/pages/settings/organization/trust';
import { BrandVerificationForm } from '@/pages/settings/organization/trust/brands';
import { CreateCampaignForm } from '@/pages/settings/organization/trust/campaigns';
import { UserPermissions } from '@/pages/settings/organization/users/UserPermissions';
import { VoIP } from '@/pages/voip/VoIP';
import {
  AutomationsReport,
  CampaignsReport,
  ContactsReport,
  ConversationsReport,
  LinksReport,
  MessagingReport,
  ReportsContainer,
  ReviewsReport,
  SequencesReport,
  TagsReport,
  TemplatesReport,
} from '@/reports';
import {
  SequenceAnalytics,
  SequenceContacts,
  SequenceContainer,
  SequenceFlow,
  SequenceList,
  SequenceResponses,
  SequencesContainer,
  SequenceSteps,
} from '@/sequences';
import {
  Account,
  Billing,
  Channel,
  Channels,
  CustomData,
  CustomObject,
  General,
  Notifications,
  OnboardingSettings,
  Signatures,
  Tags,
  Team,
  TeamMember,
  Teams,
  Templates,
  UserPreferences,
  Users,
  Webchat,
  WhatsAppSignup,
} from '@/settings';
import { EventStreamDemo } from '@/shared/components/EventStreamDemo';
import { Loading } from '@/shared/components/Loading';
import { TopNavigation } from '@/shared/components/navigation/topNavigation';
import { NotificationsDrawer } from '@/shared/components/notifications/NotificationsDrawer';
import ProtectedRoute from '@/shared/components/ProtectedRoute';

import { GlobalProvider } from './GlobalProvider';

const App = () => {
  const auth = useAuth();
  const isDesktop = useMedia('(min-width: 912px)');
  const location = useLocation();
  const { campaignContactsV2, showUserPermissions } = useFlags();

  if (auth.loading) {
    return <Loading />;
  }

  if (!auth.isAuthenticated) {
    const redirectLink = window.location.href.includes('login?redirect')
      ? `${location.pathname}${location.search}`
      : '';
    const prevLink =
      location.pathname?.includes('login') || location.pathname === '/'
        ? ''
        : window.location.href;

    return (
      <>
        <Toaster position="top-right" closeButton richColors />
        <Switch>
          <Route path="/login/:id" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/sso/loading/:data" component={SSORedirect} />
          <Route path="/sso" component={SSO} />
          <Route path="/register/:id" component={Register} />
          <Route path="/register" component={Register} />
          <Route path="/join/:id" component={Join} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/forgot" component={ForgotPassword} />
          <Route path="/agent/:agent_id/call/:channel_id" component={WebCallLogin} />
          <Route path="/call/:id/start" component={WebCallStart} />
          <Route path="/call/:id/in-progress" component={WebCallInProgress} />
          <Route path="/call/end" component={WebCallEnd} />
          <Route
            exact
            path="/organizations"
            component={(props: any) => <Organizations {...props.location.state} />}
          />
          <Route exact path="/redirect-to-app" component={RedirectToMobileApp} />
          <Route
            path="*"
            render={() => (
              <Redirect
                to={redirectLink || (prevLink ? `/login?redirect=${prevLink}` : '/login')}
              />
            )}
          />
        </Switch>
      </>
    );
  }

  if (auth.isAuthenticated && auth.onboardingStatus === 'in_progress') {
    return (
      <>
        <Toaster position="top-right" closeButton richColors />
        <Switch>
          <Route path="*" render={() => <Redirect to="/onboarding" />} />
        </Switch>
      </>
    );
  }

  const redirectLink = window.location.href.includes('redirect')
    ? window.location.href.split('redirect=')[1]
    : '';
  const redirectPath = redirectLink?.includes(window.location.origin)
    ? redirectLink.replace(window.location.origin, '')
    : '';

  return (
    <GlobalProvider>
      <VoIP />
      {isDesktop && <TopNavigation />}
      {isDesktop && <NotificationsDrawer />}
      <Toaster position="top-right" closeButton richColors />
      <Switch>
        <Route path="/join/:id" component={Join} />
        <Route path="/embed" component={Embed} />
        <Route
          path="/organizations/:id/inbox/:conversation_id"
          component={ConversationRedirect}
        />
        <Route path="/data">
          <DataContainer>
            <Route path="/data/contacts" exact component={Contacts} />
            <Route path="/data/lists" exact component={Lists} />
            <Route path="/data/lists/:id([a-z0-9-]{36})" component={List} />
            <Route path="/data/segments" exact component={Segments} />
            <Route path="/data/segments/:id([a-z0-9-]{36})" exact component={Segment} />
            <Route path="/data/objects/:id" exact component={Records} />
          </DataContainer>
        </Route>
        <Route path="/contacts">
          <ContactContainer>
            <Route path="/contacts" exact component={AllContacts} />
            <Route path="/contacts/uploads" exact component={AllUploads} />
            <Route path="/contacts/uploads/:id([a-z0-9-]{36})" component={Upload} />
            <Route path="/contacts/groups" exact component={AllGroups} />
            <Route path="/contacts/groups/:id([a-z0-9-]{36})" component={Group} />
            <Route path="/contacts/objects/:id" exact component={Records} />
          </ContactContainer>
        </Route>
        <Route path="/inbox" exact render={() => <Redirect to="/inbox/all/open" />} />
        <Route path="/inbox/:filter?/:tab?/:id?">
          <InboxContainer>
            <Route path="/inbox/:filter/:tab/new" exact component={CreateConversation} />
            <Route
              path="/inbox/:filter/:tab/scheduled"
              exact
              component={ScheduledMessages}
            />
            <Route path="/inbox/:filter/:tab/drafts" exact component={DraftMessages} />
            <Route
              path="/inbox/:filter/:tab/attachments"
              exact
              component={ConversationAttachments}
            />
            <Route
              path="/inbox/:filter/spam/:id([a-z0-9-]{36})"
              exact
              component={ConversationContainer}
            />
            <Route
              path="/inbox/:filter/automated/:id([a-z0-9-]{36})"
              exact
              component={ConversationContainer}
            />
            <Route
              path="/inbox/:filter/closed/:id([a-z0-9-]{36})"
              exact
              component={ConversationContainer}
            />
            <Route
              path="/inbox/:filter/open/:id([a-z0-9-]{36})"
              exact
              component={ConversationContainer}
            />
          </InboxContainer>
        </Route>
        <Route path="/reviews/:id">
          <ReviewState>
            <ReviewLayoutContainer>
              <Route path="/reviews/all" exact component={AllReviews} />
              <Route path="/reviews/unresponded" exact component={AllReviews} />
              <Route path="/reviews/highest" exact component={AllReviews} />
              <Route path="/reviews/lowest" exact component={AllReviews} />
              <Route path="/reviews/:id" exact component={LocationReviews} />
            </ReviewLayoutContainer>
          </ReviewState>
        </Route>
        <Route path="/automations">
          <AutomationsContainer>
            <Route path="/automations/questions" exact component={Questions} />
            <Route path="/automations/keywords" exact component={Keywords} />
            <Route path="/automations/keywords/:id" exact component={EditKeyword} />
            <Route path="/automations/templates" exact component={AutomationTemplates} />
            <Route
              path="/automations/templates/:id"
              exact
              component={AutomationTemplate}
            />
          </AutomationsContainer>
        </Route>
        <Route path="/campaigns">
          <CampaignContainer>
            <Route
              path="/campaigns"
              exact
              render={() => <Redirect to="/campaigns/sms" />}
            />
            <Route
              path="/campaigns/sms/all"
              exact
              render={() => <Redirect to="/campaigns/sms" />}
            />
            <Route path="/campaigns/sms" exact component={AllCampaigns} />
            <Route path="/campaigns/sms/drafts" exact component={DraftCampaigns} />
            <Route path="/campaigns/sms/scheduled" exact component={ScheduledCampaigns} />
            <Route path="/campaigns/sms/complete" exact component={CompletedCampaigns} />
            <Route path="/campaigns/sms/archive" exact component={ArchiveCampaign} />
            <Route path="/campaigns/sms/create" exact component={CreateCampaign} />
            <Route
              path="/campaigns/sms/:id([a-z0-9-]{36})/edit"
              exact
              component={CreateCampaign}
            />
            <Route
              path="/campaigns/sms/:id([a-z0-9-]{36})"
              exact
              component={CreateCampaign}
            />
            <Route
              path="/campaigns/email/all"
              exact
              render={() => <Redirect to="/campaigns/email" />}
            />
            <Route path="/campaigns/email" exact component={EmailCampaignsListPage} />
            <Route
              path="/campaigns/email/drafts"
              exact
              render={() => <EmailCampaignsListPage status="draft" title="Drafts" />}
            />
            <Route
              path="/campaigns/email/complete"
              exact
              render={() => <EmailCampaignsListPage status="complete" title="Complete" />}
            />
            <Route
              path="/campaigns/email/scheduled"
              exact
              render={() => (
                <EmailCampaignsListPage status="scheduled" title="Scheduled" />
              )}
            />
            <Route
              path="/campaigns/email/archive"
              exact
              render={() => <EmailCampaignsListPage status="archive" title="Archived" />}
            />
            <Route path="/campaigns/email/create" exact component={CreateEmailCampaign} />
            <Route
              path="/campaigns/email/:id([a-z0-9-]{36})/edit"
              exact
              component={CreateEmailCampaign}
            />
            <Route
              path="/campaigns/email/:id([a-z0-9-]{36})"
              exact
              component={CreateEmailCampaign}
            />
            <Route path="/campaigns/link/create" exact component={CreateLinkCampaign} />
            <Route path="/campaigns/sms/templates" exact component={CampaignTemplates} />
            <Route
              path="/campaigns/sms/templates/create"
              exact
              component={TemplateEditor}
            />
            <Route
              path="/campaigns/sms/templates/:id([a-z0-9-]{36})"
              component={CampaignTemplate}
            />
            <Route
              path="/campaigns/sms/:id([a-z0-9-]{36})/overview"
              exact
              component={campaignContactsV2 ? CampaignOverview : CampaignAnalytics}
            />
            <ProtectedRoute
              path="/campaigns/sms/:id([a-z0-9-]{36})/contacts"
              component={CampaignContacts}
              flagKey="campaignContactsV2"
              fallbackRoute="/campaigns/sms/:id([a-z0-9-]{36})/overview"
            />
            <ProtectedRoute
              path="/campaigns/sms/:id([a-z0-9-]{36})/analytics"
              component={CampaignAnalyticsV2}
              flagKey="campaignContactsV2"
              fallbackRoute="/campaigns/sms/:id([a-z0-9-]{36})/overview"
            />
            <Route
              path="/campaigns/email/:id([a-z0-9-]{36})/overview"
              component={CampaignOverview}
            />
            <Route
              path="/campaigns/email/:id([a-z0-9-]{36})/contacts"
              component={CampaignContacts}
            />
            <Route
              path="/campaigns/email/:id([a-z0-9-]{36})/analytics"
              component={CampaignAnalyticsV2}
            />
            <Route
              path="/campaigns/sms/templates/browse"
              exact
              render={() => <CampaignTemplates isGlobal />}
            />
            <Route
              path="/campaigns/sms/templates/browse/:id([a-z0-9-]{36})"
              component={CampaignGlobalTemplatePreview}
            />
            <Route path="/campaigns/:id([a-z0-9-]{36})" component={Campaign} />
          </CampaignContainer>
          <LinksContainer>
            <Route path="/campaigns/links" exact component={LinksList} />
            <Route path="/campaigns/links/create" exact component={CreateLink} />
            <Route path="/campaigns/links/create/link" exact component={CreateLink} />
            <Route
              path="/campaigns/links/create/analytics"
              exact
              component={LinkAnalytics}
            />
            <Route path="/campaigns/links/:id([a-z0-9-]{36})" exact component={Link} />
            <Route
              path="/campaigns/links/:id([a-z0-9-]{36})/link"
              exact
              component={Link}
            />
            <Route
              path="/campaigns/links/:id([a-z0-9-]{36})/analytics"
              exact
              component={LinkAnalytics}
            />
          </LinksContainer>
        </Route>
        <Route path="/reports" exact render={() => <Redirect to="/reports/usage" />} />
        <Route path="/reports">
          <ReportsContainer>
            <Route path="/reports/messaging" exact component={MessagingReport} />
            <Route path="/reports/agents" exact component={AgentsReport} />
            <Route path="/reports/conversations" exact component={ConversationsReport} />
            <Route path="/reports/contacts" exact component={ContactsReport} />
            <Route path="/reports/campaigns" exact component={CampaignsReport} />
            <Route path="/reports/sequences" exact component={SequencesReport} />
            <Route path="/reports/automations" exact component={AutomationsReport} />
            <Route path="/reports/reviews" exact component={ReviewsReport} />
            <Route path="/reports/links" exact component={LinksReport} />
            <Route path="/reports/tags" exact component={TagsReport} />
            <Route path="/reports/templates" exact component={TemplatesReport} />
            <Route path="/reports/usage" exact component={UsageReport} />
          </ReportsContainer>
        </Route>
        <Route path="/sequences">
          <SequencesContainer>
            <Route path="/sequences" exact component={SequenceList} />
            <Route path="/sequences/me" exact component={SequenceList} />
            <Route path="/sequences/draft" exact component={SequenceList} />
            <Route path="/sequences/complete" exact component={SequenceList} />
            <Route path="/sequences/archive" exact component={SequenceList} />
            <Route path="/sequences/templates" exact component={SequenceTemplates} />
            <Route
              path="/sequences/templates/browse"
              exact
              component={() => <SequenceTemplates isGlobal />}
            />
            <Route path="/sequences/templates/:id([a-z0-9-]{36})">
              <SequenceTemplateContainer>
                <Route
                  path="/sequences/templates/:id([a-z0-9-]{36})/steps"
                  exact
                  component={SequenceTemplate}
                />
              </SequenceTemplateContainer>
            </Route>
            <Route path="/sequences/templates/browse/:id([a-z0-9-]{36})">
              <SequenceGlobalTemplateContainer>
                <Route
                  path="/sequences/templates/browse/:id([a-z0-9-]{36})/steps"
                  exact
                  component={() => <SequenceSteps disableEdit={true} />} // when in global template, disable edit
                />
              </SequenceGlobalTemplateContainer>
            </Route>
            <Route path="/sequences/:id([a-z0-9-]{36})">
              <SequenceContainer>
                <Route
                  path="/sequences/:id([a-z0-9-]{36})/steps"
                  exact
                  component={SequenceSteps}
                />
                <Route
                  path="/sequences/:id([a-z0-9-]{36})/flow"
                  exact
                  render={() => <SequenceFlow />}
                />
                <Route
                  path="/sequences/:id([a-z0-9-]{36})/contacts"
                  exact
                  render={() => <SequenceContacts />}
                />
                <Route
                  path="/sequences/:id([a-z0-9-]{36})/responses"
                  exact
                  render={() => <SequenceResponses />}
                />
                <Route
                  path="/sequences/:id([a-z0-9-]{36})/analytics"
                  exact
                  render={(props) => <SequenceAnalytics {...props} />}
                />
              </SequenceContainer>
            </Route>
          </SequencesContainer>
        </Route>
        <Route path="/agents">
          <AgentsContainer>
            <Route path="/agents" exact component={Agents} />
            <Route path="/agents/calls" exact component={AgentCalls} />
            <Route path="/agents/calls/:id([a-z0-9-]{36})" exact component={AgentCall} />
            <Route path="/agents/templates" exact component={AITemplates} />
            <Route path="/agents/:id([a-z0-9-]{36})">
              <AgentContainer>
                <Route
                  path="/agents/:id([a-z0-9-]{36})/editor"
                  exact
                  component={EditAgent}
                />
                <Route
                  path="/agents/:id([a-z0-9-]{36})/versions"
                  exact
                  component={AgentVersions}
                />
                <Route
                  path="/agents/:id([a-z0-9-]{36})/versions/:version_id([a-z0-9-]{36})"
                  exact
                  component={AgentVersion}
                />
                <Route
                  path="/agents/:id([a-z0-9-]{36})/calls"
                  exact
                  component={AgentVersionCalls}
                />
                <Route
                  path="/agents/:id([a-z0-9-]{36})/calls/:call_id([a-z0-9-]{36})"
                  exact
                  component={AgentVersionCall}
                />
                <Route
                  path="/agents/:id([a-z0-9-]{36})/analytics"
                  exact
                  component={AgentAnalytics}
                />
              </AgentContainer>
            </Route>
          </AgentsContainer>
        </Route>
        <Route path="/integrations" exact component={Integrations} />
        <Route path="/integrations/:id([a-z0-9-]{36})" exact component={Integration} />
        <Route
          path="/integrations/:id([a-z0-9-]{36})/configurations"
          exact
          component={Configurations}
        />
        <Route path="/settings/profile" component={Account} />
        <Route path="/settings/preferences" component={UserPreferences} />
        <Route path="/settings/general" component={General} />
        <Route path="/settings/billing" component={Billing} />
        <Route path="/settings/onboarding" exact component={OnboardingSettings} />
        <Route path="/settings/trust" exact component={TrustCenter} />
        <Route path="/settings/onboarding/meta" exact component={WhatsAppSignup} />
        <Route
          path="/settings/trust/10dlc/brands/create"
          exact
          component={BrandVerificationForm}
        />
        <Route path="/settings/trust/10dlc/brands/:id" component={OnboardingSettings} />
        <Route
          path="/settings/trust/10dlc/campaigns/create"
          exact
          component={CreateCampaignForm}
        />
        <Route
          path="/settings/trust/10dlc/campaigns/:id"
          component={OnboardingSettings}
        />
        <Route
          path="/settings/users/:id/permissions"
          render={() =>
            showUserPermissions ? <UserPermissions /> : <Redirect to="/settings/users" />
          }
        />
        <Route path="/settings/users" component={Users} />
        <Route path="/settings/webchat" component={Webchat} />
        <Route path="/settings/channels" exact component={Channels} />
        <Route path="/settings/channels/create" exact component={CreateNewChannel} />
        <Route path="/settings/channels/:id" component={Channel} />
        <Route path="/settings/accounts" exact component={ProvidersAccounts} />
        <Route path="/settings/notifications" component={Notifications} />
        <Route path="/settings/tags" component={Tags} />
        <Route path="/settings/templates" component={Templates} />
        <Route path="/settings/data" exact component={CustomData} />
        <Route path="/settings/data/:id([a-z0-9-]{36})" exact component={CustomObject} />
        <Route path="/settings/teams" exact component={Teams} />
        <Route path="/settings/teams/:id([a-z0-9-]{36})" exact component={Team} />
        <Route
          path="/settings/teams/:teamId([a-z0-9-]{36})/team_members/:id([a-z0-9-]{36})"
          component={TeamMember}
        />
        <Route path="/settings/signatures" component={Signatures} />
        <Route path="/settings/domains" exact component={DomainsList} />
        <Route path="/settings/domains/:id([a-z0-9-]{36})" exact component={Domain} />
        <Route path="/developer">
          <DeveloperContainer>
            <Route path="/developer/keys" component={ApiKeys} />
            <Route path="/developer/webhooks" exact component={Webhooks} />
            <Route
              path="/developer/webhooks/:id([a-z0-9-]{36})"
              exact
              component={Endpoint}
            />
            <Route path="/developer/events" component={Events} />
            <Route path="/developer/events/:id([a-z0-9-]{36})" component={Events} />
          </DeveloperContainer>
        </Route>
        <Route path="/demo/stream" component={EventStreamDemo} />
        <Route exact path="/redirect-to-app" component={RedirectToMobileApp} />
        <Route
          path="*"
          render={() => <Redirect to={redirectPath || '/inbox/all/open'} />}
        />
      </Switch>
    </GlobalProvider>
  );
};

export default App;
