/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { usePageView } from '@/shared/hooks';
import { CampaignStatus } from '@/shared/types/campaigns';
import { Box } from '@/shared/ui';

import { useCampaignsContext } from './context/CampaignsContext';

type CampaignParamType = {
  id: string;
};

export const Campaign = (): JSX.Element => {
  // product analytics tracking
  usePageView();
  const campaignParams = useParams<CampaignParamType>();

  const {
    getCampaign,
    campaignsState: { current },
  } = useCampaignsContext();

  useEffect(() => {
    async function fetchCampaign() {
      getCampaign(campaignParams.id);
    }
    // if no campaign is set, or the set campaign
    // is complete, there load the campaign and
    // the campaign results if they exist
    if (
      !current ||
      current.id !== campaignParams.id ||
      (current.status === CampaignStatus.COMPLETE && !current.analytics) ||
      (current.status === CampaignStatus.IN_PROGRESS && !current.analytics)
    ) {
      fetchCampaign();
    }
  }, []);

  return <Box css={{ p: 20, overflow: 'auto' }}></Box>;
};
