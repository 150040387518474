/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import EmailEditor, { Editor, EditorRef, EmailEditorProps } from 'react-email-editor';

import { Signature } from '@/shared/types';
import { JSONObject } from '@/shared/types/campaigns';
import { Flex, Skeleton } from '@/shared/ui';
import { styled } from '@/stitches.config';

export type CampaignEditorProps = {
  campaignId: string | null;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  emailCampaignJSONBody?: JSONObject;
  setEmailCampaignJSONBody: (JSONBody?: JSONObject | null) => void;
  isHTMLMessage: boolean;
  updateTemplate?: boolean;
  height?: string | number;
};

export const CampaignHTMLEmailEditor = (props: CampaignEditorProps): JSX.Element => {
  const {
    message,
    setMessage,
    campaignId,
    emailCampaignJSONBody,
    setEmailCampaignJSONBody,
    isHTMLMessage,
    updateTemplate,
    height,
  } = props;
  const emailEditorRef = useRef<EditorRef>(null);
  const [JSONBody, setJSONBody] = useState(emailCampaignJSONBody);
  const [HTMLMessage, setHTMLMessage] = useState<string | null>(
    emailCampaignJSONBody ? message : ''
  );
  const [isLoading, setLoading] = useState<boolean>(true);
  const [signature, setSignature] = useState<Signature | null>(null);

  useEffect(() => {
    if (isHTMLMessage) {
      if (!HTMLMessage) {
        setHTMLMessage(message);
        setJSONBody(emailCampaignJSONBody);
      } else {
        setMessage(HTMLMessage ?? '');
        setEmailCampaignJSONBody(JSONBody);
      }
    }
  }, [isHTMLMessage]);

  useEffect(() => {
    if (isHTMLMessage) {
      setMessage(HTMLMessage ?? '');
    }
  }, [HTMLMessage]);

  useEffect(() => {
    if (isHTMLMessage) {
      setEmailCampaignJSONBody(JSONBody);
    }
  }, [JSONBody]);

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    setLoading(false);
    const templateJson = getDraftFromLocalStorage(campaignId);
    if (emailCampaignJSONBody || templateJson) {
      loadDesign(
        unlayer,
        emailCampaignJSONBody ? emailCampaignJSONBody : JSON.parse(templateJson ?? '')
      );
    }
    // eslint-disable-next-line
    unlayer.addEventListener('design:updated', (_data: any) => {
      const unlayer = emailEditorRef.current?.editor;
      if (!unlayer) return;
      unlayer.exportHtml((data) => {
        const { design, html } = data;
        setHTMLMessage(html);
        setJSONBody(design as JSONObject);
        saveDraftToLocalStorage(campaignId, JSON.stringify(design, null, 4));
      });
    });
  };

  useEffect(() => {
    if (updateTemplate && isHTMLMessage) {
      const unlayer = emailEditorRef.current?.editor;
      if (unlayer && emailCampaignJSONBody) {
        loadDesign(unlayer, emailCampaignJSONBody ?? {});
      }
    }
  }, [updateTemplate]);

  const loadDesign = (unlayer: Editor, message: JSONObject) => {
    unlayer.loadDesign(message as any);
  };

  const getDraftFromLocalStorage = (campaignId: string | null) => {
    if (!campaignId) return null;
    const key = `email-campaign-${campaignId}-body`;
    return localStorage.getItem(key);
  };

  const saveDraftToLocalStorage = (
    campaignId: string | null,
    stringifiedHTMLDesign: string
  ) => {
    if (!campaignId) return null;
    const key = `email-campaign-${campaignId}-body`;
    localStorage.setItem(key, stringifiedHTMLDesign);
  };

  // Used to add signature to message when selected
  // As long as the signature has not already been added
  useEffect(() => {
    if (!signature) return;

    // if (emailMessage.plainText.includes(signature.body) || !emailMessage.htmlBody.includes(signature.body)) {
    if (message.includes(signature.body) && isHTMLMessage) {
      setMessage(`${message}\n${signature.body}`);
      setSignature(null);
    }
  }, [signature]);

  return (
    <>
      <Flex
        data-testid="email-html-editor"
        css={{ display: isLoading ? 'none' : 'block' }}
      >
        <EmailEditorContainer>
          <EmailEditor
            options={{
              id: 'email-html-editor',
              projectId: 255767,
              mergeTags: {
                organization_name: {
                  name: 'Organization Name',
                  value: '{{organization_name}}',
                  sample: 'Organization Name',
                },
                full_name: {
                  name: 'Full Name',
                  value: '{{full_name}}',
                  sample: 'John Doe',
                },
                first_name: {
                  name: 'First Name',
                  value: '{{first_name}}',
                  sample: 'John',
                },
                last_name: {
                  name: 'Last Name',
                  value: '{{last_name}}',
                  sample: 'Doe',
                },
                location_name: {
                  name: 'Channel Name',
                  value: '{{location_name}}',
                  sample: 'Channel Name',
                },
                location_address: {
                  name: 'Channel Address',
                  value: '{{location_address}}',
                  sample: '123 Main St, Town, USA',
                },
                review_link: {
                  name: 'Review Link',
                  value: '{{review_link}}',
                  sample: 'www.review.com/123456',
                },
                custom_a: {
                  name: 'Custom Field A',
                  value: '{{custom_a}}',
                  sample: 'Custom Field A',
                },
                custom_b: {
                  name: 'Custom Field B',
                  value: '{{custom_b}}',
                  sample: 'Custom Field B',
                },
                custom_c: {
                  name: 'Custom Field C',
                  value: '{{custom_c}}',
                  sample: 'Custom Field C',
                },
              },
            }}
            ref={emailEditorRef}
            onReady={onReady}
            style={{
              height: height ?? 'auto',
              minHeight: '700px',
            }}
          />
        </EmailEditorContainer>
      </Flex>
      {isLoading && (
        <Flex align="center" style={{ height: height ?? 400, borderRadius: '4px' }}>
          <Skeleton css={{ width: '100%', height: '100%', margin: '0' }} />
        </Flex>
      )}
    </>
  );
};

const EmailEditorContainer = styled('div', {
  borderStyle: 'solid',
  borderRadius: '4px',
  borderWidth: '0px',
  padding: '2px',
  boxShadow: 'inset 0 0 0 1px $colors$slate7',
  backgroundColor: 'white',
});
