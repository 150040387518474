import { FilterItem } from '../types/filter';

export function generateCampaignAnalyticsFilterItem(
  type:
    | 'all'
    | 'delivered'
    | 'notDelivered'
    | 'responded'
    | 'unresponded'
    | 'linkClicked'
    | 'linkNotClicked'
    | 'unsubscribed',
  campaignId: string
): FilterItem {
  switch (type) {
    case 'all': {
      return {
        column: 'id',
        comparison: '==',
        resource: 'campaign',
        value: campaignId,
      };
    }
    case 'delivered': {
      return {
        resource: 'campaign_message',
        column: 'delivery_status',
        comparison: 'in',
        value: ['delivery_unconfirmed', 'dlr_timeout', 'queued', 'delivered'],
        and: [
          {
            column: 'campaign_id',
            comparison: '==',
            resource: 'campaign_message',
            value: campaignId,
          },
        ],
      };
    }
    case 'notDelivered': {
      return {
        resource: 'campaign_message',
        column: 'delivery_status',
        comparison: 'in',
        value: ['delivery_failed', 'sending_failed', 'failed'],
        and: [
          {
            resource: 'campaign_message',
            column: 'campaign_id',
            comparison: '==',
            value: campaignId,
          },
        ],
      };
    }
    case 'responded': {
      return {
        column: 'campaign_id',
        comparison: '==',
        resource: 'campaign_contact',
        value: campaignId,
        and: [
          {
            column: 'id',
            comparison: '!=',
            resource: 'contact_message',
            value: '',
          },
        ],
      };
    }
    case 'unresponded': {
      return {
        column: 'campaign_id',
        comparison: '==',
        resource: 'campaign_message',
        value: campaignId,
        and: [
          {
            column: 'delivery_status',
            comparison: 'in',
            resource: 'campaign_message',
            value: ['delivery_unconfirmed', 'dlr_timeout', 'queued', 'delivered'],
            and: [
              {
                resource: 'contact_message',
                column: 'id',
                comparison: '==',
                value: null,
              },
            ],
          },
        ],
      };
    }
    case 'linkClicked': {
      return {
        resource: 'campaign_contact',
        column: 'campaign_id',
        comparison: '==',
        value: campaignId,
        and: [
          {
            resource: 'campaign_contact',
            column: 'clicked_link',
            comparison: '==',
            value: true,
          },
        ],
      };
    }
    case 'linkNotClicked': {
      return {
        resource: 'campaign_contact',
        column: 'campaign_id',
        comparison: '==',
        value: campaignId,
        and: [
          {
            resource: 'campaign_contact',
            column: 'clicked_link',
            comparison: '==',
            value: false,
          },
        ],
      };
    }
    case 'unsubscribed': {
      return {
        resource: 'campaign_contact',
        column: 'campaign_id',
        comparison: '==',
        value: campaignId,
        and: [
          {
            resource: 'campaign_contact',
            column: 'unsubscribed',
            comparison: '==',
            value: true,
          },
        ],
      };
    }
  }
}
