/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { usePageView } from '@/shared/hooks';
import { Contact } from '@/shared/types';

import { ContactsList } from '../ContactsList';
import { useContacts } from '../context/ContactContext';
import { mergeContacts } from '../context/ContactReducer';
import { ContactEditor } from '../editor';
import { ContactBulkActions } from '../editor/ContactBulkActions';
import { useGroups } from './context/GroupContext';

export const Group = (): JSX.Element => {
  usePageView();

  const contactContext = useContacts();
  const {
    filterPartOfContacts,
    contactState,
    setAllContactsChecked,
    setSearchResultChecked,
  } = contactContext;
  const {
    filteredContacts,
    isFiltered,
    isSearched,
    groupContacts,
    currentDynamicGroupId,
    dynamicGroupContactsTotal,
    allContactsChecked,
    searchResultChecked,
  } = contactState;
  const [loading, setLoading] = useState(true);

  const isDesktop = useMedia('(min-width: 912px)');

  // groups state
  const { groupsState, getGroup } = useGroups();
  const currentGroup = groupsState.current;

  const [offset, setOffset] = useState(0);
  const [sortedContacts, setSortedContacts] = useState<Contact[]>(filteredContacts);

  const [filters, setFilters] = useState({});

  const saveFilters = (filters: any) => {
    setFilters(filters);
  };

  const [checkedContacts, setCheckedContacts] = useState<Contact[]>([]);
  const [renderedContacts, setRenderedContacts] = useState<Contact[]>([]);
  const pathLocation = useLocation();

  const [currentGroupContacts, setCurrentGroupContacts] =
    useState<Contact[]>(groupContacts);

  const [search, setSearch] = useState('');

  useEffect(() => {
    async function getGroupContacts() {
      if (renderedContacts.length === 0 || currentDynamicGroupId !== currentGroup?.id) {
        setLoading(true);
      }

      if (currentGroup && (offset === 0 || offset > 20)) {
        if (currentGroup.filters_version !== 'v2' && 'search' in currentGroup.filters) {
          const result = await filterPartOfContacts(
            currentGroup?.filters.search,
            currentGroup?.id,
            offset
          );

          const data = result?.data || [];

          const updatedContactsList =
            result?.dynamicGroupId === currentGroup?.id
              ? mergeContacts(renderedContacts, data)
              : data;

          setRenderedContacts(updatedContactsList || []);
          setCurrentGroupContacts(updatedContactsList || []);
          setLoading(false);

          // if all contacts should be checked
          // set the newly rendered contact batch as checked
          if (allContactsChecked) {
            setCheckedContacts(updatedContactsList);
          }
        }
      }
    }

    getGroupContacts();
  }, [currentGroup?.id, offset]);

  useEffect(() => {
    setSortedContacts(
      renderedContacts.sort((a: Contact, b: Contact) =>
        ((a || {}).name || '').localeCompare((b || {}).name || '')
      )
    );
  }, [renderedContacts]);

  useEffect(() => {
    // reset offset every time new group is set as current
    setOffset(0);
    // set new filters every time new group is selected
    if (currentGroup && currentGroup.filters && 'search' in currentGroup.filters) {
      setFilters(currentGroup.filters);
    }
  }, [currentGroup?.id]);

  useEffect(() => {
    setCheckedContacts([]);
    if (!currentGroup) {
      const groupId =
        pathLocation.pathname.split('/')[pathLocation.pathname.split('/').length - 1];
      getGroup(groupId);
    }
  }, [currentGroup?.id]);

  useEffect(() => {
    if (isFiltered) {
      setRenderedContacts(filteredContacts || []);
      setCurrentGroupContacts(filteredContacts || []);
    }
  }, [filteredContacts]);

  return (
    <>
      {/* list of contacts for the given page */}
      <ContactsList
        title={currentGroup?.name || ''}
        contacts={currentGroupContacts}
        setOffset={setOffset}
        setCheckedContacts={setCheckedContacts}
        saveFilters={saveFilters}
        filters={filters}
        renderedContacts={renderedContacts}
        setRenderedContacts={setRenderedContacts}
        checkedContacts={checkedContacts}
        sortedContacts={sortedContacts}
        isGroup={true}
        isUpload={false}
        loading={loading}
        isFiltered={isFiltered}
        totalContacts={dynamicGroupContactsTotal}
        setSearchResultChecked={setSearchResultChecked}
        searchResultChecked={searchResultChecked}
        searchOrFiltersApplied={isSearched}
        setAllContactsChecked={setAllContactsChecked}
        allContactsChecked={allContactsChecked}
        search={search}
        setSearch={setSearch}
      />

      {/* desktop contact panel */}
      {isDesktop && (
        <SidebarNavigationContainer
          defaultWidth={360}
          minWidth={250}
          maxWidth={450}
          name="CONTACTS"
          dragDirection="right"
          disableCollapse
        >
          {checkedContacts.length > 0 ? (
            <ContactBulkActions
              totalContacts={dynamicGroupContactsTotal}
              checkedContacts={checkedContacts}
              checkContact={(contacts: string[] | Contact[] | Contact[]) => {
                if (typeof contacts[0] === 'string') {
                  // Handle the case where contacts is a string array
                  // You need to convert the strings to Contact objects
                  return;
                } else {
                  // Handle the case where contacts is a Contact array
                  setCheckedContacts(contacts as Contact[]);
                }
              }}
              allContactsChecked={allContactsChecked}
              setSearchResultChecked={setSearchResultChecked}
              setAllContactsChecked={setAllContactsChecked}
              bulkMessageAudience={{ group_id: currentGroup?.id }}
              isContactsPage={false}
              isGroupsPage={true}
              isUploadsPage={false}
            />
          ) : (
            <ContactEditor />
          )}
        </SidebarNavigationContainer>
      )}
    </>
  );
};
