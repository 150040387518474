import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteMatch } from 'react-router-dom';

import DomainsProvider from '@/pages/domains/context/DomainsContext';
import LinksProvider from '@/pages/links/context/LinksContext';
import { LayoutContent, MainLayout } from '@/shared/layouts';

export const LinksLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isLinks = useRouteMatch('/campaigns/links')?.isExact;
  const isLink = useRouteMatch('/campaigns/links/:id')?.isExact;
  const isLinkAnalytics = useRouteMatch('/campaigns/links/:id/:tab')?.isExact;
  const isLinkCreate = useRouteMatch('/campaigns/links/create')?.isExact;
  const isLinkCreateWithTab = useRouteMatch('/campaigns/links/create/:tab')?.isExact;
  return !isLinks &&
    !isLink &&
    !isLinkCreate &&
    !isLinkAnalytics &&
    !isLinkCreateWithTab ? null : (
    <MainLayout>
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};

export const LinksContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <DomainsProvider>
      <LinksProvider>
        <Helmet>
          <title>Whippy | Campaigns</title>
        </Helmet>
        <LinksLayoutContainer>{children}</LinksLayoutContainer>
      </LinksProvider>
    </DomainsProvider>
  );
};
