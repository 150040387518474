/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { usePageView } from '@/shared/hooks';
import { Contact } from '@/shared/types';

import { ContactsList } from '../ContactsList';
import { useContacts } from '../context/ContactContext';
import { mergeContacts } from '../context/ContactReducer';
import { ContactEditor } from '../editor';
import { ContactBulkActions } from '../editor/ContactBulkActions';
import { useUploads } from './context/UploadContext';

type ContactsData = {
  contacts: Array<Contact>;
  total: number;
};

export const Upload = (): JSX.Element => {
  usePageView();
  const contactContext = useContacts();
  const {
    contactState,
    setAllContactsChecked,
    setSearchResultChecked,
    getPartOfUploadsContacts,
  } = contactContext;
  const {
    contacts,
    isFiltered,
    isSearched,
    groupContacts,
    currentUploadId,
    groupContactsTotal,
    allContactsChecked,
    searchResultChecked,
  } = contactState;
  const [loading, setLoading] = useState(true);

  const isDesktop = useMedia('(min-width: 912px)');

  // uploads state
  const { uploadsState, getUpload } = useUploads();
  const currentUpload = uploadsState.current;

  const [offset, setOffset] = useState(0);
  const [sortedContacts, setSortedContacts] = useState(contacts);

  const [checkedContacts, setCheckedContacts] = useState<Array<Contact>>([]);
  const [renderedContacts, setRenderedContacts] = useState<Array<Contact>>([]);
  const { id } = useParams<{ id: string }>();

  const [currentUploadContacts, setCurrentUploadContacts] =
    useState<Array<Contact>>(groupContacts);

  const [search, setSearch] = useState('');

  useEffect(() => {
    async function getGroupContacts() {
      if (renderedContacts.length === 0 || currentUploadId !== currentUpload?.id) {
        setLoading(true);
      }

      if (currentUpload && (offset === 0 || offset > 20)) {
        const data: ContactsData | void = await getPartOfUploadsContacts(
          currentUpload.id,
          offset
        );

        if (!data) return;

        const updatedContactsList =
          currentUploadId === currentUpload?.id
            ? mergeContacts(renderedContacts, data.contacts)
            : data.contacts;

        setRenderedContacts(updatedContactsList || []);
        setCurrentUploadContacts(updatedContactsList || []);
        setLoading(false);

        // if all contacts should be checked
        // set the newly rendered contact batch as checked
        if (allContactsChecked) {
          setCheckedContacts(updatedContactsList);
        }
      }
    }

    getGroupContacts();
  }, [currentUpload?.id, offset]);

  useEffect(() => {
    if (!currentUpload) {
      getUpload(id);
    }
  }, [currentUpload?.id]);

  useEffect(() => {
    setSortedContacts(
      renderedContacts.sort((a: Contact, b: Contact) =>
        ((a || {}).name || '').localeCompare((b || {}).name || '')
      )
    );
  }, [renderedContacts]);

  useEffect(() => {
    // reset offset every time new upload is set as current
    setOffset(0);
  }, [currentUpload?.id]);

  useEffect(() => {
    setCheckedContacts([]);
  }, [currentUpload?.id]);

  useEffect(() => {
    // do not change rendered contacts
    // when search has been applied
    if (isSearched) {
      return;
    }
    setRenderedContacts(groupContacts);
  }, [groupContacts]);

  useEffect(() => {
    // setting all rendered contacts as checked when
    // allContactsChecked is true
    if (allContactsChecked) {
      setCheckedContacts(renderedContacts);
    }
  }, [allContactsChecked]);

  return (
    <>
      {/* list of contacts for the given page */}
      <ContactsList
        title={currentUpload?.name || ''}
        contacts={currentUploadContacts}
        setOffset={setOffset}
        setCheckedContacts={setCheckedContacts}
        renderedContacts={renderedContacts}
        setRenderedContacts={setRenderedContacts}
        checkedContacts={checkedContacts}
        sortedContacts={sortedContacts}
        isGroup={false}
        isUpload={true}
        loading={loading}
        isFiltered={isFiltered}
        totalContacts={groupContactsTotal}
        searchOrFiltersApplied={isSearched}
        setSearchResultChecked={setSearchResultChecked}
        searchResultChecked={searchResultChecked}
        setAllContactsChecked={setAllContactsChecked}
        allContactsChecked={allContactsChecked}
        search={search}
        setSearch={setSearch}
      />

      {/* desktop contact panel */}
      {isDesktop && (
        <SidebarNavigationContainer
          defaultWidth={360}
          minWidth={250}
          maxWidth={450}
          name="CONTACTS"
          dragDirection="right"
          disableCollapse
        >
          {checkedContacts.length > 0 ? (
            <ContactBulkActions
              totalContacts={groupContactsTotal}
              checkedContacts={checkedContacts}
              checkContact={(contacts: string[] | Contact[] | Contact[]) => {
                if (typeof contacts[0] === 'string') {
                  // Handle the case where contacts is a string array
                  // You need to convert the strings to Contact objects
                  return;
                } else {
                  // Handle the case where contacts is a Contact array
                  setCheckedContacts(contacts as Contact[]);
                }
              }}
              allContactsChecked={allContactsChecked}
              setSearchResultChecked={setSearchResultChecked}
              setAllContactsChecked={setAllContactsChecked}
              bulkMessageAudience={{ upload_id: currentUpload?.id }}
              isContactsPage={false}
              isGroupsPage={false}
              isUploadsPage={true}
            />
          ) : (
            <ContactEditor />
          )}
        </SidebarNavigationContainer>
      )}
    </>
  );
};
