import dayjs from 'dayjs';
import { useState } from 'react';
import { DatePicker } from 'react-dayjs-picker';

import { convertTime24to12 } from '@/campaigns/context/utils';
import { TimePicker } from '@/shared/components/timepicker/TimePicker';
import { convertTimeString } from '@/shared/components/timepicker/utils/getHours';
import { ScheduleOptions } from '@/shared/types/campaigns';
import {
  Box,
  Flex,
  HStack,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
} from '@/shared/ui';

/**
 * If we click on the date we want to pop up the date picker
 */
export type RadioGroupOptions = 'default' | 'scheduled';

type SetScheduleProps = {
  /** initial state oe the radio group */
  initialState: RadioGroupOptions;
  /** on radio click callback */
  onRadioClick: (value: string) => void;
  /** date select callback */
  onDateTimeUpdated: (updatedDateTime: ScheduleOptions) => void;
  /** schedule params object */
  scheduleOptions: ScheduleOptions;
};

export const ScheduleRadioGroup = (props: SetScheduleProps) => {
  const { onDateTimeUpdated, scheduleOptions, onRadioClick: onRemoteRadioClick } = props;

  const [radioValue, setRadioValue] = useState<RadioGroupOptions>(props.initialState);
  // this will disable or blur out the date AND time picker
  const [disableDateTimePicker, setDisableDateTimePicker] = useState<boolean>(
    props.initialState == 'default'
  );
  // showDatePicker controls the pop up that shows the calendar for you to select
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const onRadioClick = (item: RadioGroupOptions) => {
    if (item == 'default') {
      setShowDatePicker(false);
      setDisableDateTimePicker(true);
    } else {
      setDisableDateTimePicker(false);
    }
    setRadioValue(item);
    onRemoteRadioClick(item);
  };
  return (
    <>
      <Box css={{ mb: 10 }}>
        <RadioGroup value={radioValue} onValueChange={onRadioClick}>
          <HStack gap={2}>
            <StyledRadioIndicator align="center" css={{ width: '50%', height: '47px' }}>
              <Radio id="r1" value="default" css={{ mr: '$5' }}>
                <RadioGroupIndicator />
              </Radio>
              <Label htmlFor="r1" css={{ mb: 0, ml: 10 }}>
                Send immediately
              </Label>
            </StyledRadioIndicator>
            <StyledRadioIndicator
              align="center"
              justify="between"
              css={{ width: '100%', height: '47px' }}
            >
              <Flex align="center">
                <Radio id="r2" value="scheduled" css={{ mr: '$5' }}>
                  <RadioGroupIndicator />
                </Radio>
                <Label htmlFor="r2" css={{ mb: 0, mx: 10 }}>
                  Schedule for later
                </Label>
              </Flex>
              <HStack>
                <DatePicker
                  isOpen={showDatePicker}
                  setIsOpen={setShowDatePicker}
                  zIndex={9999999999}
                  value={formatDate(scheduleOptions)}
                  markToday={true}
                  format="MMM-dd-yyyy"
                  disableBeforeToday={true}
                  inputStyle={{
                    padding: '2px 3px',
                    border: '1px solid #D7DBDF',
                    borderRadius: '4px',
                    maxWidth: '92px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    textAlign: 'center',
                    pointerEvents: disableDateTimePicker ? 'none' : 'all',
                    backgroundColor: disableDateTimePicker ? '#F8F9FA' : 'transparent',
                    color: disableDateTimePicker ? '#C4CCD0' : '#000000',
                    outlineColor: '#3E54CF',
                  }}
                  colors={{
                    highlighted: 'hsl(0 0% 90.9%)',
                    disabled: 'hsl(0 0% 78.0%',
                    default: '',
                    active: '',
                  }}
                  onSelect={(date: dayjs.Dayjs) => {
                    const newDateTime = updateDate(scheduleOptions, date);
                    onDateTimeUpdated(newDateTime);
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                  onChange={(_e) => {}}
                  popoverPositions={['bottom']}
                  closeOnSelect={true}
                />
                <TimePicker
                  setTime={(time) => {
                    // only trigger if this was change was due to the time picker
                    // and not the date picker
                    if (time) {
                      const newDateTime = updateTime(scheduleOptions, time);
                      onDateTimeUpdated(newDateTime);
                    }
                  }}
                  time={
                    convertTime24to12(
                      `${scheduleOptions?.hour || ''}:${scheduleOptions?.minute || ''}`
                    ) || ''
                  }
                  currentDate={formatTime(scheduleOptions)}
                  timezone={scheduleOptions.timezone}
                  disabled={disableDateTimePicker}
                />
              </HStack>
            </StyledRadioIndicator>
          </HStack>
        </RadioGroup>
      </Box>
    </>
  );
};

function updateDate(
  currentSchedule: ScheduleOptions,
  newDate: dayjs.Dayjs
): ScheduleOptions {
  return {
    year: newDate.year().toString(),
    // we must add 1 to the date since it's zero indexed from dayjs
    month: (newDate.month() + 1).toString(),
    day: newDate.date().toString(),
    hour: currentSchedule.hour,
    minute: currentSchedule.minute,
    timezone: currentSchedule.timezone,
  };
}

// format of newTime comes as 6:15 PM
function updateTime(currentSchedule: ScheduleOptions, newTime: string): ScheduleOptions {
  const { hour, minute } = convertTimeString(newTime);
  return {
    hour: hour.toString(),
    minute: minute.toString(),
    year: currentSchedule.year,
    month: currentSchedule.month,
    day: currentSchedule.day,
    timezone: currentSchedule.timezone,
  };
}

/*
This returns the date in the format of MM/DD/YYYY
*/

function formatTime(scheduleOptions: ScheduleOptions): string {
  const date = new Date(
    parseInt(scheduleOptions.year),
    // schedule options is not zero indexed but Date is
    parseInt(scheduleOptions.month) - 1,
    parseInt(scheduleOptions.day)
  );
  const dateString = dayjs(date).format('MM/DD/YYYY');
  return dateString;
}

function formatDate(scheduleOptions: ScheduleOptions): string {
  return convertScheduleOptionToDayJS(scheduleOptions).format('ddd MMM D');
}

function convertScheduleOptionToDayJS(scheduleOptions: ScheduleOptions): dayjs.Dayjs {
  const date = new Date(
    parseInt(scheduleOptions.year),
    // note that the minus 1 is needed since Date is zero indexed
    parseInt(scheduleOptions.month) - 1,
    parseInt(scheduleOptions.day)
  );
  return dayjs(date);
}
