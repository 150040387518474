/* eslint-disable react-hooks/exhaustive-deps */
import { mauve } from '@radix-ui/colors';
import { Item } from '@radix-ui/react-dropdown-menu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { HiClipboard } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CopyToClipboard } from '@/shared/components/CopyToClipboard';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Channel } from '@/shared/types/channels';
import { ChannelsStates } from '@/shared/types/channels';
import { User, UserRoles, UserStates } from '@/shared/types/users';
import { Avatar, Box, Fieldset, Flex, HStack, Label, VStack } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { useChannels } from '../channels/context/ChannelContext';
import { AddUser } from './AddUser';
import { PAGE_SIZE, useUsers } from './context/UserContext';
import { UpdateUser } from './UpdateUser';

export const UsersTable = () => {
  const {
    userState,
    updateUser,
    disableUser,
    enableUser,
    archiveUser,
    updateFilterParams,
    setCurrent,
  } = useUsers();
  const { filteredUsers, loading, filterParams, totalCount, users } = userState;

  const location = useChannels();
  const { allChannels } = location.channelsState;
  const locations = allChannels;

  const auth = useAuth();
  const currentUserRole = auth?.tokens?.role;
  const email = auth?.tokens?.email;

  const { showUserPermissions } = useFlags();

  const history = useHistory();

  // returns true if the user.state === 'disabled'
  const isDisabled = (state?: UserStates) => {
    if (state === UserStates.DISABLED) {
      return true;
    } else {
      return false;
    }
  };

  // returns true if the user has bot role: user and state: enabled
  const isEnabledUser = (role?: UserRoles, state?: UserStates) => {
    if (role === UserRoles.USER && state === UserStates.ENABLED) {
      return true;
    } else {
      return false;
    }
  };

  // returns true whenever the locations of a user can be edited
  const isLocationColClickable = (
    rowRole?: UserRoles,
    rowEmail?: string,
    state?: UserStates
  ) => {
    if (
      ((rowRole === UserRoles.USER && currentUserRole === UserRoles.ADMIN) ||
        (rowRole === UserRoles.ADMIN && rowEmail === email)) &&
      !isDisabled(state)
    ) {
      return true;
    } else {
      return false;
    }
  };

  // returns true whenever the role of a user can be edited
  const isRoleColClickable = (rowRole?: UserRoles) => {
    if (rowRole !== UserRoles.ADMIN && currentUserRole === UserRoles.ADMIN) {
      return true;
    } else {
      return false;
    }
  };

  const handleUserPermissions = (user: User) => {
    setCurrent(user);
    history.push(`/settings/users/${user.id}/permissions`);
  };

  const enabledUsers = useMemo(() => {
    return users?.filter((user: User) => user?.state === UserStates.ENABLED);
  }, [users]);

  const enabledChannels = useMemo(() => {
    return locations.filter((location) => location?.state === ChannelsStates.ENABLED);
  }, [locations]);

  const columns: Array<TableColumn<User>> = useMemo(
    () => [
      {
        Header: 'User',
        colWidth: '50%',
        accessor: 'name',
        Cell: (props: { row: { original: User } }) => (
          <HStack gap={2} align="center">
            <Box>
              <Avatar
                variant="pink"
                size="newTable"
                src={props.row.original?.attachment?.url}
                fallback={initials(props.row.original.name || props.row.original.email)}
              />
            </Box>
            <VStack gap="1">
              <Box>{props.row.original.name}</Box>
              <Box css={{ color: '#718196', fontSize: 13 }}>
                <CopyToClipboard
                  copy={props.row.original.email}
                  description="Copy user email"
                  successMessage="Email copied to clipboard"
                >
                  <Flex align="center">
                    <Flex>{props.row.original.email}</Flex>
                    <Flex css={{ mx: 4 }}>
                      <HiClipboard />
                    </Flex>
                  </Flex>
                </CopyToClipboard>
              </Box>
            </VStack>
          </HStack>
        ),
      },
      {
        Header: 'Role',
        colWidth: '50%',
        accessor: 'role',
        Cell: (props: { row: { original: User } }) => {
          const user = props.row.original;
          const role = user.role;
          const state = user.state;
          const disabled = isDisabled(state);
          return (
            <Box data-testid="user-role" css={{ textTransform: 'capitalize' }}>
              {disabled && <Box>{state}</Box>}
              {!disabled && (
                <Box>{state === UserStates.INVITED ? state : role || state}</Box>
              )}
            </Box>
          );
        },
      },
      {
        Header: 'Channel Access',
        accessor: 'locations',
        Cell: (props: { row: { original: User } }) => {
          const user = props.row.original;
          const enabledChannelsIds = enabledChannels?.map((item: Channel) => item?.id);
          const initialValues = user.locations
            ?.filter(
              (item: Channel) =>
                item?.state === ChannelsStates.ENABLED &&
                enabledChannelsIds?.includes(item?.id)
            )
            ?.map((item: Channel) => item?.id);
          const role = user.role;
          const email = user.email;
          return (
            <UpdateUser
              isClickable={isLocationColClickable(role, email, user.state)}
              list={enabledChannels.map((item) => ({
                value: item.id,
                type: item?.name || item?.address,
              }))}
              initialValues={initialValues || []}
              user={user}
              disabled={!isLocationColClickable(role, email, user.state)}
            />
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props: { row: { original: User } }) => {
          const user = props.row.original;
          const role = user.role;
          const state = user.state;
          const enabled = isEnabledUser(role, state);
          const disabled = isDisabled(state);
          const clickable = isRoleColClickable(role);

          return state === UserStates.INVITED ? (
            <Box />
          ) : (
            <TableActionMenu disabled={!clickable}>
              <>
                {enabled && (
                  <>
                    {showUserPermissions && (
                      <CustomDropdownMenuItem
                        data-testid="user-permissions-option"
                        onClick={() => handleUserPermissions(user)}
                      >
                        Permissions
                      </CustomDropdownMenuItem>
                    )}
                    <ConfirmationDialog
                      width="432px"
                      title="Make this User an Admin?"
                      description={
                        <ConfirmationDialogDescription
                          value={user.email}
                          description="will have the ability to change User’s location access, invite Users to the account and give them Admin role."
                        />
                      }
                      onConfirm={() => updateUser({ id: user.id, role: UserRoles.ADMIN })}
                      confirmButtonTitle="Confirm"
                      cancelButtonTitle="Cancel"
                      confirmButtonVariant="primary"
                      cancelButtonVariant="grayBackground"
                    >
                      <CustomDropdownMenuItem
                        data-testid="make-admin-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Make Admin
                      </CustomDropdownMenuItem>
                    </ConfirmationDialog>
                    <DisableUserDialog
                      filteredUsers={enabledUsers}
                      disableUser={disableUser}
                      user={user}
                    />
                  </>
                )}
                {disabled && (
                  <>
                    <ConfirmationDialog
                      width="432px"
                      title="Enable User?"
                      description={
                        <ConfirmationDialogDescription
                          value={user.email}
                          description="will have access to the application again."
                        />
                      }
                      onConfirm={() => enableUser(user)}
                      confirmButtonTitle="Confirm"
                      cancelButtonTitle="Cancel"
                      confirmButtonVariant="primary"
                      cancelButtonVariant="grayBackground"
                    >
                      <CustomDropdownMenuItem
                        data-testid="enable-user-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Enable User
                      </CustomDropdownMenuItem>
                    </ConfirmationDialog>
                    <ConfirmationDialog
                      width="432px"
                      title="Archive User?"
                      description={
                        <ConfirmationDialogDescription
                          value={user.email}
                          description="will be archived."
                        />
                      }
                      onConfirm={() =>
                        archiveUser({ id: user.id, state: UserStates.ARCHIVED })
                      }
                      confirmButtonTitle="Confirm"
                      cancelButtonTitle="Cancel"
                      confirmButtonVariant="redBackground"
                      cancelButtonVariant="grayBackground"
                    >
                      <CustomDropdownMenuItemWarning
                        data-testid="archive-user-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Archive User
                      </CustomDropdownMenuItemWarning>
                    </ConfirmationDialog>
                  </>
                )}
              </>
            </TableActionMenu>
          );
        },
      },
    ],
    [filteredUsers, loading, enabledChannels]
  );

  return (
    <Table
      data={filteredUsers}
      columns={columns}
      caption="Users Table"
      totalCount={totalCount}
      setOffset={(offset) => {
        updateFilterParams({
          ...filterParams,
          offset,
        });
      }}
      pageSize={PAGE_SIZE}
      emptyTitle="No Users Match Search"
      isLoading={loading}
      colHeight={43}
      empty={
        <>
          <Box
            css={{
              width: 250,
              fontSize: 16,
              marginTop: 8,
              marginBottom: 16,
              fontWeight: 400,
            }}
          >
            Change your search parameters or invite a new user
          </Box>
          <Box>{currentUserRole === UserRoles.ADMIN ? <AddUser /> : null}</Box>
        </>
      }
    />
  );
};

export const CustomDropdownMenuItem = styled(Item, {
  all: 'unset',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  minHeight: 25,
  padding: '3px 12px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  fontSize: 14,
  color: '#1C2024',
  '&:hover': {
    backgroundColor: '#3A5CCC',
    color: '#FFF',
  },
  '&[data-disabled]': {
    color: mauve.mauve8,
    pointerEvents: 'none',
  },
});

export const CustomDropdownMenuItemWarning = styled(Item, {
  all: 'unset',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  minHeight: 25,
  padding: '3px 12px',
  position: 'relative',
  userSelect: 'none',
  cursor: 'pointer',
  borderRadius: '4px',
  fontSize: 14,
  color: '#D93D42',
  '&:hover': {
    backgroundColor: '#D93D42',
    color: '#FFF',
  },
});

export const ConfirmationDialogDescription = ({
  value,
  description,
}: {
  value: string;
  description: string;
}) => {
  return (
    <div>
      <span style={{ fontWeight: 700 }}>{value}</span> {description}
    </div>
  );
};

// unassignedUser must be added to list items in order to work with downshift
const unassignedUser = {
  name: 'Unassign',
  email: '',
  id: 0,
};

type UnassignedUserType = {
  name: string;
  email: string;
  id: number;
};

const DisableUserDialog = ({
  filteredUsers,
  disableUser,
  user,
}: {
  filteredUsers: Array<User>;
  disableUser: (params: User, assignee_user_id?: number | null) => Promise<void>;
  user: User;
}) => {
  const [reassignUser, setReassignUser] = useState<User | UnassignedUserType | null>(
    null
  );

  const usersList = [unassignedUser, ...filteredUsers].filter(
    (userItem: User | UnassignedUserType) => userItem.id !== user.id
  );

  return (
    <ConfirmationDialog
      width="432px"
      title="Disable this User?"
      description={
        <Box>
          <Fieldset>
            <Label>Reassign Conversations to</Label>
            <SingleSelect
              defaultPlaceholder={reassignUser?.name || 'Users'}
              isDropdown
              closeOnClick
              selectItem={reassignUser?.id.toString() || ''}
              setSelectItem={(item: string) => {
                const userToSet =
                  usersList.find((userItem) => userItem.id.toString() === item) || null;
                setReassignUser(userToSet);
              }}
              options={usersList.map((userItem: User | UnassignedUserType) => ({
                type: userItem.name || userItem.email || '',
                value: userItem.id.toString(),
              }))}
            />
          </Fieldset>
        </Box>
      }
      onConfirm={() => disableUser(user, reassignUser?.id || undefined)}
      confirmButtonTitle="Confirm"
      cancelButtonTitle="Cancel"
      confirmButtonVariant="redBackground"
      cancelButtonVariant="grayBackground"
    >
      <CustomDropdownMenuItemWarning
        data-testid="disable-user-option"
        onClick={(e) => e.preventDefault()}
      >
        Disable User
      </CustomDropdownMenuItemWarning>
    </ConfirmationDialog>
  );
};
