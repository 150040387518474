import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useMemo, useState } from 'react';
import {
  HiOutlineArrowDown,
  HiOutlineArrowUp,
  HiOutlinePaperClip,
  HiReply,
} from 'react-icons/hi';
import { Link, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { getCallStatus } from '@/pages/voip/ConversationCall';
import { Channel, ChannelsStates } from '@/shared/types/channels';
import {
  ConversationContactType,
  ConversationFilterTypes,
  ConversationItemModeTypes,
  ConversationItemSourceTypes,
  ConversationItemTypesType,
  ConversationMessageType,
  ConversationStatusTypes,
  ConversationType,
  SearchType,
  SearchTypes,
} from '@/shared/types/conversations';
import { User } from '@/shared/types/users';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  CustomScrollbar,
  Flex,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import {
  DATE_TIME_HOURLY,
  DATE_TIME_STAMP_YEAR,
  MONTH_DAY,
  TIME_STAMP,
} from '@/shared/utils/timestamps';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { keyframes, styled } from '@/stitches.config';

import { useConversation } from '../context/ConversationContext';
import {
  HighlightText,
  PREVIEW_MESSAGE_POST_LENGTH,
  PREVIEW_MESSAGE_PRE_LENGTH,
  truncateTextAroundHighlight,
} from './utils';

type ConversationPreviewProps = {
  conversation: ConversationType;
  /** the current conversation selected? */
  isActive: boolean;
  /** the current conversation tab */
  tab: string;
  /** the current conversation filter */
  filter: string;
  /** the current conversation checkbox hover id */
  checkboxHoverId?: string | null;
  /** mouse event handler */
  mouseEventHandler?: (id: string | null) => void;
  /** the current search type */
  searchType: SearchType;
  /** is the conversation in the contact panel */
  isContactPanel?: boolean;
  /** the current search value from the contact panel */
  search?: string;
};

// conversation preview card
// this is the box that contains a brief of the conversation
// including avatar, date, username, a brief of the last message
export function ConversationPreview(props: ConversationPreviewProps): JSX.Element {
  const {
    conversation,
    tab,
    filter,
    isActive,
    checkboxHoverId,
    mouseEventHandler,
    searchType = 'none',
    isContactPanel,
    search = '',
  } = props;
  const { id, unread_count, assigned_user_id, contact, location_id } = conversation;

  const {
    unselectConversation,
    toggleForwardState,
    getAndSetCurrent,
    selectConversation,
    conversationState,
    searchV2,
  } = useConversation();
  const { selectedConversations } = conversationState;

  const history = useHistory();

  const isDesktop = useMedia('(min-width: 912px)');

  // is this conversation selected?
  const isSelected = selectedConversations.includes(id);

  // are there any selected conversations? for a bulk action
  const isAnySelected = selectedConversations.length > 0;

  // only show unread count if the conversation is open and there are unread messages
  // if the conversation is closed or automated, don't show the unread count because it's not relevant
  const isUnreadCountShown =
    unread_count > 0 && conversation.status === ConversationStatusTypes.OPEN;

  // select or unselect the conversation
  const handleSelect = (id: string) => {
    if (isSelected) {
      unselectConversation(id);
    } else {
      selectConversation(id);
    }
  };

  // if the user is hovering over the checkbox, if the conversation is selected,
  // or if there are any selected conversations, show the checkbox
  const showCheckbox = checkboxHoverId === id || isSelected || isAnySelected;

  // gets the name or phone of the contact
  const getName = (contact: ConversationContactType) => {
    return contact.name || formatPhoneNumber(contact?.phone || '') || contact?.email;
  };

  const name = getName(contact);

  const usersContext = useUsers();
  const { users } = usersContext.userState;

  const {
    channelsState: { allChannels },
  } = useChannels();

  const filteredUsers = useMemo(() => {
    return users.filter((user: User) => {
      const userLocations = user?.locations || [];
      const userInLocation = userLocations.find(
        (location: Channel) => location?.id === location_id
      );
      return !!userInLocation; // Return true if userInLocation exists, false otherwise
    });
  }, [users, location_id]);

  const currentChannelName = useMemo(() => {
    const currentChannel = allChannels
      ?.filter((item: Channel) => item?.state === ChannelsStates.ENABLED)
      ?.find((item: Channel) => item?.id === conversation?.location_id);
    return currentChannel?.name || currentChannel?.address || '';
  }, [allChannels, conversation?.location_id]);

  // gets the assigned_user if such exists
  const getAssignedUser = (assignedUserID: number | null) => {
    return assignedUserID
      ? filteredUsers.find((user: User) => user.id === assignedUserID)
      : null;
  };

  const assigned_user = getAssignedUser(assigned_user_id);
  const date = renderDateWithYear(conversation);
  const message = renderMessage(conversation);

  const last_message = getLastMessage(conversation);

  const message_direction = last_message?.source_type;
  const most_recent_message_date =
    getFilteredAndSortedMessages(conversation)[0]?.inserted_at;

  // Memoizing search words to optimize performance. For example, from "quick brown fox"
  // it generates ["quick", "brown", "fox", "quick brown", "brown fox", "quick brown fox"].
  const searchWords = useMemo(() => {
    // Splitting search input into individual words and filtering out empty strings
    // E.g., "quick brown fox" becomes ["quick", "brown", "fox"].
    const individualWords = (isContactPanel ? search : searchV2.inputValue)
      .split(' ')
      .filter((word) => word.trim() !== '');

    // Combining individual words into phrases to enhance search matching
    // This step generates combinations like "quick brown", "brown fox", "quick brown fox".
    const combinedWords = individualWords.reduce<string[]>((acc, _, index) => {
      // Generating combinations of words starting from each word to the end
      for (let i = index + 1; i <= individualWords.length; i++) {
        const combined = individualWords.slice(index, i).join(' ');
        // Adding non-empty combinations to the accumulator
        if (combined !== '') acc.push(combined);
      }
      return acc;
    }, []);

    // Combine words and remove duplicates
    return [...new Set([...individualWords, ...combinedWords])];
  }, [searchV2.inputValue, search, isContactPanel]);

  return (
    <Box
      onMouseOut={() => !!mouseEventHandler && mouseEventHandler(null)}
      key={id}
      onClick={() => {
        // reset the forward state
        toggleForwardState(false, { message: '', attachment_urls: [], signature: null });

        if (searchType === SearchTypes.messages) {
          // If Search Type is messages, navigate to the conversation with selected message highlighted
          history.push(
            `/inbox/${filter}/${tab}/${id}?message_id=${conversation.conversationItemsPage?.conversationItems[0]?.id}`
          );
          getAndSetCurrent(
            id,
            conversation.conversationItemsPage?.conversationItems[0]?.id
          );
        } else if (searchType === SearchTypes.contacts) {
          // If Search Type is contacts, navigate to the conversation without message highlighting
          history.push(`/inbox/${filter}/${tab}/${id}`);
          getAndSetCurrent(id);
        } else {
          // If changing conversation not in search
          history.push(`/inbox/${filter}/${tab}/${id}`);
          getAndSetCurrent(id);
        }
      }}
      css={{
        px: 8,
        pb: 4,
      }}
    >
      <PreviewContainer align="center" active={isActive || isSelected}>
        <Flex
          css={{ mr: 9, pl: 0, width: 38, height: 37 }}
          onMouseEnter={(e) => {
            e.stopPropagation();
            !!mouseEventHandler && mouseEventHandler(id);
          }}
          onMouseLeave={() => !!mouseEventHandler && mouseEventHandler(null)}
        >
          {showCheckbox && isDesktop && (
            <Flex css={{ width: 35 }} justify="center" align="center">
              <Flex
                css={{
                  position: 'relative',
                }}
                align="center"
              >
                <Checkbox
                  size="1"
                  checked={isSelected}
                  onCheckedChange={() => handleSelect(id)}
                />
              </Flex>
            </Flex>
          )}
          {!showCheckbox && (
            <Flex>
              <Box
                css={{
                  position: 'relative',
                }}
              >
                <Avatar
                  size="36"
                  variant="lightGray"
                  src={
                    showContactIcon(name || '')
                      ? `${window.location.origin}/outline.svg`
                      : ''
                  }
                  alt={name || 'No name'}
                  fallback={initials(name || '')}
                />
                {assigned_user && (
                  <Box
                    as="span"
                    css={{
                      position: 'absolute',
                      bottom: '-3px',
                      right: '-3px',
                      boxShadow: '0 0 0 3px $colors$loContrast',
                      borderRadius: '$round',
                    }}
                  >
                    <Avatar
                      size="16"
                      variant="primary"
                      src={assigned_user.attachment?.url}
                      fallback={initials(assigned_user.name)}
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          )}
        </Flex>
        <Flex direction="column" justify="between" css={{ width: 'calc(100% - 50px)' }}>
          <NameContainer justify="between" align="center" css={{ width: '100%' }}>
            <StyledName>
              {(searchV2.inputValue.length || search?.length) > 1 ? (
                <HighlightText
                  highlightStyle={{ backgroundColor: '#FFC106' }}
                  sentence={name ?? contact?.email ?? ''}
                  wordsToHighlight={searchWords}
                />
              ) : (
                <>{name || contact?.email}</>
              )}
            </StyledName>
            <Flex align="center" justify="end">
              {message_direction === ConversationItemSourceTypes.INBOUND &&
              filter === ConversationFilterTypes.GHOSTED &&
              tab === ConversationStatusTypes.OPEN &&
              dayjs(most_recent_message_date).isBefore(dayjs().subtract(2, 'days')) ? (
                <Box css={{ fontSize: 11, color: '$red10' }}>
                  {renderWaitingSinceString(most_recent_message_date)}
                </Box>
              ) : (
                <StyledDate css={{ whiteSpace: 'nowrap' }}>{date}</StyledDate>
              )}
              {isContactPanel && (
                <Flex align="center" justify="end" css={{ ml: 4 }}>
                  <Text> • </Text>
                  <Badge
                    css={{ textTransform: 'capitalize', px: 8, ml: 4 }}
                    variant={
                      conversation?.status === ConversationStatusTypes.OPEN
                        ? 'green'
                        : 'gray'
                    }
                  >
                    {conversation?.status}
                  </Badge>
                </Flex>
              )}
            </Flex>
          </NameContainer>
          {isContactPanel && (
            <Flex css={{ mb: 2, mt: -1 }}>
              <Box css={{ fontSize: 12 }}>{`${currentChannelName} • `}</Box>
              <Box css={{ fontSize: 12, textTransform: 'capitalize', ml: 4 }}>
                {conversation?.channel_type}
              </Box>
            </Flex>
          )}
          <Flex justify="between" css={{ fontSize: 13 }}>
            <Flex align="center" css={{ width: 'calc(100% - 47px)' }}>
              {message_direction === ConversationItemSourceTypes.OUTBOUND &&
                last_message?.type !== ConversationItemTypesType.CALL && (
                  <Box>
                    <HiReply size={10} style={{ marginRight: 5 }} />
                  </Box>
                )}
              {last_message?.type === ConversationItemTypesType.CALL && (
                <Box>
                  {message_direction === ConversationItemSourceTypes.OUTBOUND ? (
                    <HiOutlineArrowUp size={10} style={{ transform: 'rotate(-45deg)' }} />
                  ) : (
                    <HiOutlineArrowDown
                      size={10}
                      style={{ transform: 'rotate(-45deg)' }}
                    />
                  )}
                </Box>
              )}
              <RenderMessagePreview
                message_direction={message_direction}
                message={message}
                isUnreadCountShown={isUnreadCountShown}
                users={users}
                searchType={searchType}
                searchWords={searchWords}
                messageType={last_message?.type}
                messageDeliveryStatus={last_message?.delivery_status}
                messageMode={last_message?.mode}
                searchValue={search}
              />
            </Flex>
            {/* show unread messages count */}
            {isUnreadCountShown && (
              <UnreadCount align="center" justify="center">
                {unread_count}
              </UnreadCount>
            )}
          </Flex>
        </Flex>
      </PreviewContainer>
    </Box>
  );
}

type RenderMessagePreviewProps = {
  message_direction: string | null | undefined;
  message: string | null | undefined;
  isUnreadCountShown: boolean;
  users: User[];
  searchType: SearchType;
  searchWords: string[];
  messageType: ConversationItemTypesType | undefined;
  messageDeliveryStatus: string | undefined;
  messageMode: ConversationItemModeTypes | null | undefined;
  searchValue?: string;
};

export const RenderMessagePreview = (props: RenderMessagePreviewProps) => {
  const {
    message_direction,
    message,
    isUnreadCountShown,
    users,
    searchWords,
    messageType,
    messageDeliveryStatus,
    messageMode,
    searchValue = '',
  } = props;
  const { searchV2 } = useConversation();

  const callStatus = useMemo(() => {
    return getCallStatus(messageDeliveryStatus, messageMode);
  }, [messageDeliveryStatus, messageMode]);

  // Handle NOTE messages by replacing user IDs with names or showing an attachment preview
  if (message_direction === ConversationItemSourceTypes.NOTE) {
    return (
      <MessagePreview isUnreadCountShown={isUnreadCountShown}>
        {replaceUserIdsWithNames(message || '', users) || <AttachmentPreview />}
      </MessagePreview>
    );
  }

  // Handle messages when search is active
  if (
    (searchV2.inputValue.length > 1 || searchValue?.length > 1) &&
    ((messageType === ConversationItemTypesType.CALL && message) ||
      messageType !== ConversationItemTypesType.CALL)
  ) {
    return (
      <MessagePreview
        css={{ maxWidth: '100%', whiteSpace: 'normal', maxHeight: '35px' }}
        isUnreadCountShown={isUnreadCountShown}
      >
        {(message ?? '').length < 1 && <AttachmentPreview />}
        <HighlightText
          sentence={truncateTextAroundHighlight(
            message || '',
            searchV2.inputValue || searchValue,
            PREVIEW_MESSAGE_PRE_LENGTH,
            PREVIEW_MESSAGE_POST_LENGTH
          )}
          wordsToHighlight={searchWords}
          highlightStyle={{ backgroundColor: '#FFC106' }}
        />
      </MessagePreview>
    );
  }

  if (
    messageType === ConversationItemTypesType.CALL &&
    messageMode != ConversationItemModeTypes.AI
  ) {
    return (
      <MessagePreview
        css={{ maxWidth: '100%', marginLeft: 5 }}
        isUnreadCountShown={isUnreadCountShown}
      >
        {callStatus}
      </MessagePreview>
    );
  }

  // Default case: Display the message or an attachment preview if the message is empty
  return (
    <MessagePreview css={{ maxWidth: '100%' }} isUnreadCountShown={isUnreadCountShown}>
      {message || <AttachmentPreview />}
    </MessagePreview>
  );
};

const AttachmentPreview = () => {
  return (
    <Flex align="center" css={{ alignItems: 'center' }}>
      <HiOutlinePaperClip
        size={12}
        style={{
          marginRight: '2px',
          transform: 'rotate(90deg) scaleY(-1)',
        }}
      />{' '}
      Attachment
    </Flex>
  );
};

const renderWaitingSinceString = (date: string) => {
  const now = dayjs();
  const diffInMinutes = now.diff(date, 'minute');
  const diffInHours = now.diff(date, 'hour');
  const diffInDays = now.diff(date, 'day');

  if (diffInMinutes < 60) {
    return `Received ${diffInMinutes} minutes ago. Follow up?`;
  } else if (diffInHours < 24) {
    return `Received ${diffInHours} hours ago. Follow up?`;
  } else {
    return `Received ${diffInDays} days ago. Follow up?`;
  }
};

export function getLastMessage(conversation: ConversationType) {
  const ordered_messages = getFilteredAndSortedMessages(conversation);
  return ordered_messages[0] ?? null;
}

type AssignedUserProps = {
  assigned_user?: User | null;
};

export const AssignedUser = (props: AssignedUserProps) => {
  const { assigned_user } = props;
  const [assignedUserPhoto, setAssignedUserPhoto] = useState<string | undefined>('');

  useEffect(() => {
    setAssignedUserPhoto(assigned_user?.attachment?.url || '');
  }, [assigned_user]);

  return (
    <Box css={{ mr: 6 }}>
      <Flex justify="start">
        <Avatar
          src={assignedUserPhoto}
          fallback={initials(assigned_user?.name || assigned_user?.email)}
          variant="pink"
          size="assign"
        />
      </Flex>
    </Box>
  );
};

// loading conversations list
export function LoadingConversationPreview(): JSX.Element {
  return (
    <CustomScrollbar>
      {Array.from({ length: 20 }, (_, k) => (
        <Box key={k} css={{ px: 8, pb: 4 }}>
          <PreviewContainer align="center">
            <Flex css={{ width: '90%', height: 40 }} align="center">
              <Skeleton
                css={{
                  width: '30px !important',
                  height: '30px !important',
                  m: 0,
                  borderRadius: '100%',
                }}
              />
              <Flex css={{ width: '90%', ml: 12 }} direction="column" justify="center">
                <Skeleton
                  variant="heading"
                  css={{ width: '75%', height: 10, m: 0, mb: 4 }}
                />
                <Skeleton
                  variant="heading"
                  css={{ width: '100%', height: 10, m: 0, mt: 4 }}
                />
              </Flex>
            </Flex>
          </PreviewContainer>
        </Box>
      ))}
    </CustomScrollbar>
  );
}

export const replaceUserIdsWithNames = (messageBody: string, users: User[]) => {
  const userIds = messageBody.match(/@{{\d+}}/g);
  if (!userIds) return messageBody;
  let newMessageBody = messageBody;
  userIds.forEach((userId) => {
    const user = users.find(
      (user) => user.id === parseInt(userId.replace(/@{{|}}/g, ''))
    );
    if (user) {
      newMessageBody = newMessageBody.replace(userId, `@${user?.name || user?.email}`);
    }
  });
  return newMessageBody;
};

export function renderDate(conversation: ConversationType) {
  dayjs.extend(isToday);
  // Remove the events from conversationItems
  const ordered_messages = getFilteredAndSortedMessages(conversation);

  // Render the correct date
  const message = ordered_messages.length > 0 ? ordered_messages.slice(-1)[0] : null;

  if (
    message &&
    message.updated_at &&
    ordered_messages.length > 0 &&
    dayjs(message.updated_at).isToday()
  ) {
    return dayjs(message.updated_at).format(TIME_STAMP);
  } else {
    return ordered_messages.length > 0
      ? dayjs(ordered_messages.slice(-1)[0].updated_at).format(MONTH_DAY)
      : dayjs(conversation.updated_at).format(MONTH_DAY);
  }
}

// Renders the date with year and time for a conversation
export function renderDateWithYear(conversation: ConversationType) {
  dayjs.extend(isToday);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Filter out event items from conversationItems
  const ordered_messages = getFilteredAndSortedMessages(conversation);

  // Get the last message in the conversation
  const message = ordered_messages.length > 0 ? ordered_messages.slice(0)[0] : null;

  if (
    message &&
    message.inserted_at &&
    ordered_messages.length > 0 &&
    dayjs(message.inserted_at).isToday()
  ) {
    // If the last message is from today, format as timestamp
    return dayjs.utc(message.inserted_at).tz().format(DATE_TIME_HOURLY);
  } else if (ordered_messages.length < 1 && dayjs(conversation.created_at).isToday()) {
    // If no messages and conversation created today, format as timestamp
    return dayjs.utc(conversation.created_at).tz().format(DATE_TIME_HOURLY);
  } else {
    // Otherwise, format the date with year and time
    return ordered_messages.length > 0
      ? dayjs
          .utc(ordered_messages.slice(0)[0].inserted_at)
          .tz()
          .format(DATE_TIME_STAMP_YEAR)
      : dayjs.utc(conversation.updated_at).tz().format(DATE_TIME_STAMP_YEAR);
  }
}

// return the body of the last message in conversation
export function renderMessage(conversation: ConversationType) {
  const ordered_messages = getFilteredAndSortedMessages(conversation);

  // Get the most recent message
  const message = ordered_messages[0] as ConversationMessageType | undefined;

  if (message) {
    switch (message.visibility_status) {
      case 'removed':
        return 'This message has been deleted...';
      case 'hidden':
        return 'This message has been hidden...';
      default:
        return message.body;
    }
  } else if (conversation?.scheduled_messages?.length) {
    return conversation.scheduled_messages[0].message.body;
  }

  return null;
}

// empty conversations list
export function EmptyConversationPreview(): JSX.Element {
  return (
    <Flex
      align="center"
      direction="column"
      justify="center"
      css={{ width: '100%', minHeight: '100%', flexGrow: 1 }}
    >
      <VStack gap={3} css={{ alignItems: 'center' }}>
        <Heading variant="bold">Inbox Zero</Heading>
        <Text>No Conversations</Text>
        <Link to="/inbox/all/open/new">
          <Button>New Conversation</Button>
        </Link>
      </VStack>
    </Flex>
  );
}

// if the contact name is not available, show the fallback image
export const showContactIcon = (name: string | null): boolean => {
  if (!name) return true;
  const numbers = name.match(/\d/g);
  if (numbers) {
    return numbers.length > 1;
  } else {
    return false;
  }
};

/**
 * Filters out event items and sorts messages by `inserted_at` in descending order.
 * @param conversation The conversation object containing conversation items.
 * @returns Sorted and filtered messages.
 */
export function getFilteredAndSortedMessages(
  conversation: ConversationType
): ConversationMessageType[] {
  return filterOutConversationItemEvents(conversation).sort((a, b) =>
    dayjs(b?.inserted_at).diff(dayjs(a?.inserted_at))
  ) as ConversationMessageType[];
}

/**
 * Filters out event items from conversationItems.
 * @param conversation The conversation object containing conversation items.
 * @returns Filtered conversation items.
 */
export function filterOutConversationItemEvents(conversation: ConversationType) {
  const filteredConversationItems = conversation.conversationItemsPage.conversationItems
    .map((item) => ({ ...item })) // Create a shallow copy of each item
    .filter((item) => !('event' in item)); // Filter out event items

  // if filteredConversationItems is empty return initialConversationItems
  if (filteredConversationItems.length) {
    return filteredConversationItems;
  } else {
    return conversation.initialConversationItems
      ? conversation.initialConversationItems
          .map((item) => ({ ...item }))
          .filter((item) => !('event' in item))
      : [];
  }
}

const PreviewContainer = styled(Flex, {
  w: '100%',
  p: 12,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#F2F2F5',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '#F2F2F5',
      },
    },
  },
});

export const pulseRed = keyframes({
  '0%': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(255, 82, 82, 0.7)',
  },
  '70%': {
    transform: 'scale(1)',
    boxShadow: '0 0 0 10px rgba(255, 82, 82, 0)',
  },
  '100%': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(255, 82, 82, 0)',
  },
});

const NameContainer = styled(Flex, {
  alignItems: 'center',
  pb: 3,
});

const StyledName = styled(Box, {
  overflow: 'hidden',
  fontWeight: 500,
  fontSize: 14,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 130,
});

const StyledDate = styled(Box, {
  fontWeight: 400,
  fontSize: 12,
});

const MessagePreview = styled(Box, {
  fontWeight: 400,
  fontSize: 12,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
  maxWidth: 210,
  whiteSpace: 'nowrap',
  color: '$slate11',
  // dynamically width change based on UnreadCount
  variants: {
    isUnreadCountShown: {
      // Adjust the width when the UnreadCount is shown
      true: {
        '@chrome': {
          maxWidth: 570,
        },
        '@lg': {
          maxWidth: 170,
        },
        '@xxl': {
          maxWidth: 220,
        },
      },
      // Default width when UnreadCount is not shown
      false: {
        '@chrome': {
          maxWidth: 600,
        },
        '@lg': {
          maxWidth: 200,
        },
        '@xxl': {
          maxWidth: 250,
        },
      },
    },
  },
});

const UnreadCount = styled(Flex, {
  backgroundColor: '#e53e3e',
  marginLeft: '10px',
  fontWeight: 500,
  width: 18,
  minWidth: 18,
  height: 18,
  minHeight: 18,
  fontSize: 11,
  borderRadius: 4,
  alignItems: 'center',
  alignContent: 'center',
  color: 'white',
  animation: `${pulseRed} 1.5s infinite`,
});
