/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { useCampaignsContext } from '@/pages/campaigns/context/CampaignsContext';
import { Description } from '@/pages/campaigns/create';
import { CampaignAccordion } from '@/pages/campaigns/create/CampaignAccordion';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { AccordionValue, Campaign } from '@/shared/types/campaigns';
import { DefaultObject } from '@/shared/types/data';
import { FilterItem } from '@/shared/types/filter';
import {
  Box,
  Button,
  ControlGroup,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Switch,
  SwitchThumb,
  VStack,
} from '@/shared/ui';

import {
  hasCampaignTimeSinceFilter,
  hasOpenConversationFilter,
} from '../editor/components/utils';
import {
  appendOrCondition,
  isValidQuickFilter,
  removeFirstFilterItem,
  removeOrCondition,
  replaceFirstFilterItemValue,
} from '../editor/utils';
import CampaignAudience from './CampaignAudience';

const customContactObject = structuredClone(default_contact_object);
customContactObject.custom_properties.unshift({
  default: '',
  id: 'contact-id',
  label: 'ID',
  type: 'text',
  key: 'id',
  required: true,
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_object_id: 'unique-id-for-contact',
});

export const CampaignAudienceAccordion = ({
  handleSave,
  accordion,
  setAccordion,
  exclude,
  setExcludedAudienceFilter,
  includedAudienceFilter,
  setIncludedAudienceFilter,
  isValidAudience,
  customDefaultObjects,
}: {
  handleSave: (accordion: AccordionValue) => void;
  accordion: AccordionValue;
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  exclude: {
    fullExcludeAudienceFilter: FilterItem[];
    removeFromFilter: (filterItems: FilterItem[]) => FilterItem[];
    appendToFilter: (filterItems: FilterItem[]) => FilterItem[];
  };
  setExcludedAudienceFilter: Dispatch<SetStateAction<FilterItem[]>>;
  includedAudienceFilter: FilterItem[] | null;
  setIncludedAudienceFilter: Dispatch<SetStateAction<FilterItem[] | null>>;
  isValidAudience: boolean;
  customDefaultObjects: Array<DefaultObject> | [];
}): JSX.Element => {
  const { fullExcludeAudienceFilter, removeFromFilter, appendToFilter } = exclude;
  const {
    campaignsState: { current },
  } = useCampaignsContext();
  const [excludeContactsWithConversations, setExcludeContactsWithConversations] =
    useState<boolean>(false);
  const [excludeOpenConversationFilter, setExcludeOpenConversationFilter] =
    useState<FilterItem | null>(() =>
      hasOpenConversationFilter(fullExcludeAudienceFilter)
    );
  // is the user excluding contacts that received other campaigns
  const [
    showExcludeContactsWithOtherCampaigns,
    setShowExcludeContactsWithOtherCampaigns,
  ] = useState<boolean>(false);

  // how many days if excludeContactsWithOtherCampaigns is true
  const [excludeCampaignSince, setExcludeCampaignSince] = useState<number | null>(() => {
    return initialTimeSinceCampaign(current);
  });
  const [excludeCampaignTimeSinceFilter, setExcludeCampaignTimeSinceFilter] =
    useState<FilterItem | null>(() =>
      hasCampaignTimeSinceFilter(fullExcludeAudienceFilter)
    );

  const [audienceTabValue, setAudienceTabValue] = useState<
    'advanced-filter' | 'quick-filter'
  >(() => {
    const includedFilter = current?.included_audience_filter?.filter ?? [];
    const excludedFilterWithoutSystem = removeUserExcludedFilter(
      current?.excluded_audience_filter?.filter ?? [],
      removeFromFilter,
      excludeOpenConversationFilter,
      excludeCampaignTimeSinceFilter
    );
    return !isValidQuickFilter(includedFilter, 'included') ||
      !isValidQuickFilter(excludedFilterWithoutSystem, 'excluded')
      ? 'advanced-filter'
      : 'quick-filter';
  });
  // should we exclude contacts with open conversation from the campaign?

  useEffect(() => {
    if (current) {
      // remove the system defaults from the excludedFilter
      setIncludedAudienceFilter(current.included_audience_filter?.filter ?? null);
      const maybeCampaignTimeSinceFilter = hasCampaignTimeSinceFilter(
        current.excluded_audience_filter?.filter ?? []
      );
      const maybeOpenConversationFilter = hasOpenConversationFilter(
        current.excluded_audience_filter?.filter ?? []
      );
      if (maybeOpenConversationFilter) {
        setExcludeContactsWithConversations(true);
        setExcludeOpenConversationFilter(maybeOpenConversationFilter);
      }
      if (maybeCampaignTimeSinceFilter) {
        setShowExcludeContactsWithOtherCampaigns(true);
        const today = moment();
        const daysDifference: number = today.diff(
          maybeCampaignTimeSinceFilter.value as string,
          'days'
        );
        setExcludeCampaignSince(daysDifference);
      }
      setExcludedAudienceFilter(current.excluded_audience_filter?.filter ?? []);
    }
  }, [current]);

  // props needed for advanced
  // current state

  const maybeUpdateLastCampaignSinceFilter = (
    isExcludeCampaignSinceChecked: boolean,
    excludeCampaignSince: number | null
  ) => {
    const daysAgo = moment().subtract(excludeCampaignSince, 'day').format('YYYY-MM-DD');
    const excludeLastCampaignSinceFilter = {
      column: 'last_campaign_date',
      comparison: '>',
      resource: 'communication_preference',
      value: daysAgo,
    };
    if (
      isExcludeCampaignSinceChecked &&
      hasCampaignTimeSinceFilter(fullExcludeAudienceFilter)
    ) {
      setExcludeCampaignTimeSinceFilter(excludeLastCampaignSinceFilter);
      setExcludedAudienceFilter((filterItems) => {
        return replaceFirstFilterItemValue(
          filterItems,
          (filterItem) => {
            return (
              filterItem.resource == 'communication_preference' &&
              filterItem.column == 'last_campaign_date' &&
              filterItem.comparison == '>'
            );
          },
          daysAgo
        );
      });
    } else if (isExcludeCampaignSinceChecked) {
      setExcludeCampaignTimeSinceFilter(excludeLastCampaignSinceFilter);
      setExcludedAudienceFilter((filterItems) =>
        appendOrCondition(filterItems, excludeLastCampaignSinceFilter)
      );
    } else {
      setExcludeCampaignTimeSinceFilter(null);
      setExcludedAudienceFilter((filterItems) => {
        return removeFirstFilterItem(filterItems, (filterItem) => {
          return (
            filterItem.resource == 'communication_preference' &&
            filterItem.column == 'last_campaign_date' &&
            filterItem.comparison == '>'
          );
        });
      });
    }
  };

  return (
    <CampaignAccordion
      index={2}
      title="Audience"
      description="Contacts who would receive this campaign"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.SELECT_AUDIENCE}
      isValid={isValidAudience}
      isError={false}
      setItemValue={setAccordion}
    >
      <VStack gap="2" css={{ gap: '24px' }}>
        <CampaignAudience
          channelId={current?.channel_id ?? null}
          includedAudienceFilter={includedAudienceFilter}
          exclude={{
            fullExcludeAudienceFilter: fullExcludeAudienceFilter,
            removeFromFilter: (filterItems) =>
              removeUserExcludedFilter(
                filterItems,
                removeFromFilter,
                excludeOpenConversationFilter,
                excludeCampaignTimeSinceFilter
              ),
            appendToFilter: (filterItems) =>
              appendUserExcludedFilter(
                filterItems,
                excludeOpenConversationFilter,
                excludeCampaignTimeSinceFilter,
                appendToFilter
              ),
          }}
          onIncludedAudienceFilterChanged={(filterItems) =>
            setIncludedAudienceFilter(filterItems)
          }
          onExcludedAudienceFilterChanged={(filterItems) =>
            setExcludedAudienceFilter(filterItems)
          }
          audienceTabValue={audienceTabValue}
          onAudienceTabValueChanged={(audienceTabValue) =>
            setAudienceTabValue(audienceTabValue)
          }
          customDefaultObjects={customDefaultObjects}
        />
        <Fieldset css={{ margin: '0px' }}>
          <Flex align="center" justify="between">
            <Flex direction="column">
              <Label>
                Exclude Contacts with Open Conversations in any Location (regardless of
                access)
              </Label>
              <Description>
                Exclude contacts that have an open conversation with any phone number in
                your organization regardless of your access.
              </Description>
            </Flex>
            <Box>
              <Switch
                data-testid="exclude-contacts-with-conversations-switch"
                checked={excludeContactsWithConversations}
                onCheckedChange={(checked) => {
                  setExcludeContactsWithConversations(checked);
                  const excludeOpenConversationFilter = {
                    column: 'status',
                    comparison: 'in',
                    resource: 'conversation',
                    value: ['open', 'automated'],
                  };
                  if (checked) {
                    setExcludeOpenConversationFilter(excludeOpenConversationFilter);
                    setExcludedAudienceFilter((filterItems) =>
                      appendOrCondition(filterItems, excludeOpenConversationFilter)
                    );
                  } else {
                    setExcludeOpenConversationFilter(null);
                    setExcludedAudienceFilter((filterItems) =>
                      removeFirstFilterItem(filterItems, (filterItem) => {
                        return (
                          filterItem.resource == 'conversation' &&
                          filterItem.column == 'status' &&
                          filterItem.comparison == 'in' &&
                          Array.isArray(filterItem.value) &&
                          filterItem.value.length == 2 &&
                          filterItem.value[0] == 'open' &&
                          filterItem.value[1] == 'automated'
                        );
                      })
                    );
                  }
                }}
              >
                <SwitchThumb />
              </Switch>
            </Box>
          </Flex>
        </Fieldset>
        <Fieldset css={{ margin: '0px' }}>
          <Flex align="center" justify="between">
            <Flex direction="column">
              <Label>Exclude Contacts who recently received another Campaign</Label>
              <Description>
                Excludes contacts from this campaign that have recently received another
                campaign.
              </Description>
            </Flex>
            <Box>
              <Switch
                data-testid="exclude-contacts-with-recent-campaigns"
                checked={showExcludeContactsWithOtherCampaigns}
                onCheckedChange={(checked) => {
                  setShowExcludeContactsWithOtherCampaigns(checked);
                  maybeUpdateLastCampaignSinceFilter(checked, excludeCampaignSince);
                  if (!checked) setExcludeCampaignSince(0);
                }}
              >
                <SwitchThumb />
              </Switch>
            </Box>
          </Flex>
          {showExcludeContactsWithOtherCampaigns && (
            <Box
              css={{
                mt: 10,
                p: 16,
                backgroundColor: '#F6F6F6',
                borderRadius: 4,
              }}
            >
              <HStack>
                <ControlGroup>
                  <IconButton
                    data-testid="subtract-from-campaign-time-since"
                    size={2}
                    css={{ backgroundColor: 'white' }}
                    onClick={() => {
                      excludeCampaignSince
                        ? setExcludeCampaignSince(excludeCampaignSince - 1)
                        : setExcludeCampaignSince(excludeCampaignSince);

                      maybeUpdateLastCampaignSinceFilter(
                        true,
                        excludeCampaignSince ? excludeCampaignSince - 1 : 0
                      );
                    }}
                  >
                    <HiMinus />
                  </IconButton>
                  <Input
                    type="number"
                    value={excludeCampaignSince ?? 0}
                    onChange={(e) => {
                      maybeUpdateLastCampaignSinceFilter(
                        showExcludeContactsWithOtherCampaigns,
                        parseInt(e.target.value)
                      );
                      setExcludeCampaignSince(Number(e.target.value));
                    }}
                    css={{ textAlign: 'center', width: 50 }}
                  />
                  <IconButton
                    data-testid="add-to-campaign-time-since"
                    size={2}
                    css={{ backgroundColor: 'white' }}
                    onClick={() => {
                      const nextValue = excludeCampaignSince
                        ? excludeCampaignSince + 1
                        : 1;
                      maybeUpdateLastCampaignSinceFilter(
                        showExcludeContactsWithOtherCampaigns,
                        nextValue
                      );
                      setExcludeCampaignSince(nextValue);
                    }}
                  >
                    <HiPlus />
                  </IconButton>
                </ControlGroup>
                <Box css={{ fontSize: 13 }}>days</Box>
              </HStack>
            </Box>
          )}
        </Fieldset>
        <HStack>
          <Button
            onClick={() => handleSave(AccordionValue.CREATE_MESSAGE)}
            disabled={!isValidAudience}
          >
            Save
          </Button>
          <Button
            variant="gray"
            ghost={true}
            onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </CampaignAccordion>
  );

  /**
   * This will ensure that we also remove the switch filters from the excluded audience filter
   * before we pass it to the audience builder
   */
  function removeUserExcludedFilter(
    filterItems: FilterItem[],
    removeFromFilter: (filterItems: FilterItem[]) => FilterItem[],
    excludeOpenConversationFilter: FilterItem | null,
    excludeCampaignTimeSinceFilter: FilterItem | null
  ) {
    let copyExcludedAudienceFilter = structuredClone(removeFromFilter(filterItems));
    if (
      excludeOpenConversationFilter &&
      hasOpenConversationFilter(copyExcludedAudienceFilter)
    ) {
      copyExcludedAudienceFilter = removeOrCondition(
        copyExcludedAudienceFilter,
        (filterItem: FilterItem) => {
          return (
            filterItem.resource == excludeOpenConversationFilter.resource &&
            filterItem.column == excludeOpenConversationFilter.column &&
            filterItem.comparison == excludeOpenConversationFilter.comparison &&
            filterItem.value == excludeOpenConversationFilter.value
          );
        }
      );
    }
    if (
      excludeCampaignTimeSinceFilter &&
      hasCampaignTimeSinceFilter(copyExcludedAudienceFilter)
    ) {
      copyExcludedAudienceFilter = removeOrCondition(
        copyExcludedAudienceFilter,
        (filterItem: FilterItem) => {
          return (
            filterItem.resource == excludeCampaignTimeSinceFilter.resource &&
            filterItem.column == excludeCampaignTimeSinceFilter.column &&
            filterItem.comparison == excludeCampaignTimeSinceFilter.comparison &&
            filterItem.value == excludeCampaignTimeSinceFilter.value
          );
        }
      );
    }
    return copyExcludedAudienceFilter;
  }

  function appendUserExcludedFilter(
    filterItems: FilterItem[],
    excludeOpenConversationFilter: FilterItem | null,
    excludeCampaignTimeSinceFilter: FilterItem | null,
    appendToFilter?: (filterItems: FilterItem[]) => FilterItem[]
  ) {
    let copyExcludedAudienceFilter = structuredClone(filterItems);
    if (appendToFilter) {
      copyExcludedAudienceFilter = appendToFilter(copyExcludedAudienceFilter);
    }
    if (
      excludeOpenConversationFilter &&
      !hasOpenConversationFilter(copyExcludedAudienceFilter)
    ) {
      copyExcludedAudienceFilter = appendOrCondition(
        copyExcludedAudienceFilter,
        excludeOpenConversationFilter
      );
    }
    if (
      excludeCampaignTimeSinceFilter &&
      !hasCampaignTimeSinceFilter(copyExcludedAudienceFilter)
    ) {
      copyExcludedAudienceFilter = appendOrCondition(
        copyExcludedAudienceFilter,
        excludeCampaignTimeSinceFilter
      );
    } else if (excludeCampaignTimeSinceFilter) {
      copyExcludedAudienceFilter = appendOrCondition(
        copyExcludedAudienceFilter,
        excludeCampaignTimeSinceFilter
      );
    }
    return copyExcludedAudienceFilter;
  }

  // if a filter item has last_campaign_date then this is the same as last time since in campaign
  function initialTimeSinceCampaign(campaign: Campaign | null): number | null {
    if (campaign && campaign?.included_audience_filter?.filter) {
      const timeSinceLastCampaignFilter = campaign.included_audience_filter.filter.find(
        (filterItem) => filterItem.column == 'last_campaign_date'
      );
      return (timeSinceLastCampaignFilter?.value as number) ?? null;
    }
    return null;
  }
};
