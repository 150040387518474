/* eslint-disable react-hooks/exhaustive-deps */
import { Call, Device } from '@twilio/voice-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import { api } from '@/shared/api/api';
import { startCallRecording, stopCallRecording } from '@/shared/api/calls';
import {
  CallStatusTypes,
  CallType,
  VoIPActionTypes,
  VoIPStateType,
} from '@/shared/types/voip';

import VoIPReducer from './VoIPReducer';

export const initialState: VoIPStateType = {
  device: null,
  incomingCalls: [],
  calls: [],
  current: null,
  callStatus: null,
};

export const VoIPContext = createContext<{
  voipState: VoIPStateType;
  initDevice: () => Promise<void>;
  makeOutgoingCall: (
    location_phone: string,
    contact_number: string,
    user_id: string
  ) => void;
  acceptIncomingCall: (callSid: string) => void;
  rejectCall: (callSid: string) => void;
  ignoreIncomingCall: (callSid: string) => void;
  hangUp: (callSid: string) => void;
  newCall: () => void;
  clearCallStatus: () => void;
  startCallRecord: (callSid: string, callType: Call.CallDirection) => Promise<void>;
  stopCallRecord: (
    callSid: string,
    callType: Call.CallDirection,
    recording_id: string
  ) => Promise<void>;
}>({
  voipState: initialState,
  initDevice: () => Promise.resolve(),
  makeOutgoingCall: () => Promise.resolve(),
  acceptIncomingCall: () => Promise.resolve(),
  rejectCall: () => Promise.resolve(),
  ignoreIncomingCall: () => Promise.resolve(),
  hangUp: () => Promise.resolve(),
  newCall: () => Promise.resolve(),
  clearCallStatus: () => Promise.resolve(),
  startCallRecord: () => Promise.resolve(),
  stopCallRecord: () => Promise.resolve(),
});

export const useVoIP = () => useContext(VoIPContext);

const VoIPState = ({ children }: { children: React.ReactNode }) => {
  const [voipState, dispatch] = useReducer(VoIPReducer, initialState);
  const { enableVoip } = useFlags();

  useEffect(() => {
    if (enableVoip) {
      initDevice();
    }
  }, [enableVoip]);

  useEffect(() => {
    return () => {
      if (voipState.device) {
        voipState.device.destroy();
      }
    };
  }, []);

  const getToken = async () => {
    const response = await api.get('/v2/calls/twilio/token');
    return response.data.data.token;
  };

  const initDevice = async () => {
    const token = await getToken();
    const device = new Device(token, { logLevel: 'info', allowIncomingWhileBusy: true });
    await device.register();
    device.on('tokenWillExpire', () => {
      return getToken().then((token) => device.updateToken(token));
    });
    device.on('error', (deviceError) => {
      console.log('deviceError ', deviceError);
      // the following table describes how deviceError will change with this feature flag
    });
    dispatch({
      type: VoIPActionTypes.ADD_DEVICE,
      payload: device,
    });
    device.on('incoming', handleIncomingCall(token));
  };

  const handleIncomingCall = (token: string) => (incomingCall: Call) => {
    console.log(incomingCall, 'incoming call');

    dispatch({
      type: VoIPActionTypes.ADD_INCOMING_CALL,
      payload: { call: incomingCall, callSid: incomingCall?.parameters?.CallSid },
    });

    incomingCall.on('accept', () => {
      dispatch({
        type: VoIPActionTypes.ADD_CALL_STATUS,
        payload: CallStatusTypes.INCOMING_ACCEPTED,
      });

      // Forward this call to a new Device instance using the call.connectToken string.
      incomingCall?.connectToken && forwardCall(incomingCall?.connectToken, token);

      console.log('The call was accepted. Media session is set up.');
    });

    incomingCall.on('cancel', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      dispatch({
        type: VoIPActionTypes.ADD_CALL_STATUS,
        payload: null,
      });
      console.log('The incoming call has been canceled.');
    });

    incomingCall.on('reject', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      console.log('The incoming call has been rejected.');
    });

    incomingCall.on('error', (callError: Error) => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      console.error('Call error:', callError);
    });

    incomingCall.on('disconnect', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      console.log('The incoming call was disconnected.');
    });
  };

  // The forwardCall function may look something like the following.
  async function forwardCall(connectToken: string, token: string) {
    // For each incoming call, create a new Device instance for interaction
    const device = new Device(token, { logLevel: 'info', allowIncomingWhileBusy: true });
    const call = await device.connect({ connectToken });

    // Destroy the Device after the call is completed
    handleOutgoingCall(call, () => device.destroy());
  }

  const makeOutgoingCall = async (
    location_phone: string,
    contact_number: string,
    user_id: string
  ) => {
    // return, If twilio hasn't registered user device.
    if (!voipState.device) {
      console.error('Device is not initialized.');
      return;
    }

    try {
      if (location_phone && contact_number && user_id) {
        const outgoingCall = await voipState.device.connect({
          params: { From: location_phone, To: contact_number, user_id },
        });

        dispatch({
          type: VoIPActionTypes.ADD_OUTGOING_CALL,
          payload: { call: outgoingCall, callSid: outgoingCall?.parameters?.CallSid },
        });

        handleOutgoingCall(outgoingCall);
      }
    } catch (error) {
      console.error('Error making the call:', error);
      error && toast.error(`${error}`);
    }
  };

  const handleOutgoingCall = (outgoingCall: Call, onDisconnect?: () => void) => {
    outgoingCall.on('accept', (outgoingCall) => {
      dispatch({
        type: VoIPActionTypes.ACCEPT_OUTGOING_CALL,
        payload: { call: outgoingCall, callSid: outgoingCall?.parameters?.CallSid },
      });
      console.log('The call was accepted. Media session is set up.');
    });

    outgoingCall.on('cancel', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.log('The outgoing call has been canceled.');
    });

    outgoingCall.on('error', (callError: Error) => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.error('Call error:', callError);
    });

    outgoingCall.on('disconnect', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.log('The outgoing call was disconnected.');
      onDisconnect?.();
    });

    outgoingCall.on('reject', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.log('The call was rejected.');
    });
    console.log(outgoingCall, 'outgoingCall');
  };

  const acceptIncomingCall = (callSid: string) => {
    const callData = voipState.incomingCalls?.find(
      (c: CallType) => c?.callSid === callSid
    );
    console.log('call accepted', callData);
    callData?.call?.accept();
  };

  const rejectCall = (callSid: string) => {
    const callData = voipState.incomingCalls?.find(
      (c: CallType) => c?.callSid === callSid
    );
    console.log('call reject', callData);
    console.log('call reject callSid', callSid);
    callData?.call?.reject();
  };

  const ignoreIncomingCall = (callSid: string) => {
    const callData = voipState.incomingCalls?.find(
      (c: CallType) => c?.call?.parameters?.CallSid === callSid
    );
    callData?.call?.ignore();
  };

  const hangUp = (callSid: string) => {
    const callData = voipState.calls?.find((c: CallType) => c?.callSid === callSid);
    callData?.call?.disconnect();
  };

  const newCall = () => {
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: CallStatusTypes.NEW_OUTGOING,
    });
  };

  const clearCallStatus = () => {
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: null,
    });
  };

  const startCallRecord = async (callSid: string, callType: Call.CallDirection) => {
    try {
      const data = await startCallRecording(callSid);

      if (data) {
        if (callType === Call.CallDirection.Incoming) {
          dispatch({
            type: VoIPActionTypes.START_INCOMING_CALL_RECORDING,
            payload: { ...data, callSid },
          });
        } else {
          dispatch({
            type: VoIPActionTypes.START_OUTGOING_CALL_RECORDING,
            payload: { ...data, callSid },
          });
        }

        toast.success('Call Recording is started');
      }
    } catch (error) {
      console.log('Call start recording error', error);
      toast.error('Start Call Recording failure');
    }
  };

  const stopCallRecord = async (
    callSid: string,
    callType: Call.CallDirection,
    recording_id: string
  ) => {
    try {
      await stopCallRecording(callSid, recording_id);

      if (callType === Call.CallDirection.Incoming) {
        dispatch({
          type: VoIPActionTypes.STOP_INCOMING_CALL_RECORDING,
          payload: callSid,
        });
      } else {
        dispatch({
          type: VoIPActionTypes.STOP_OUTGOING_CALL_RECORDING,
          payload: callSid,
        });
      }

      toast.success('Call Recording is stopped');
    } catch (error) {
      console.log('Call stop recording error', error);
      toast.error('Stop Call Recording failure');
    }
  };

  return (
    <VoIPContext.Provider
      value={{
        voipState,
        initDevice,
        makeOutgoingCall,
        acceptIncomingCall,
        rejectCall,
        ignoreIncomingCall,
        hangUp,
        newCall,
        clearCallStatus,
        startCallRecord,
        stopCallRecord,
      }}
    >
      {children}
    </VoIPContext.Provider>
  );
};

export default VoIPState;
