import 'react-dayjs-picker/dist/index.css';

import { Formik, FormikProps, FormikValues } from 'formik';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { debounce, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import { useData } from '@/pages/data/context/DataContext';
import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import {
  ContactParams,
  NewContactParams,
  searchContacts,
} from '@/shared/api/contacts/v2';
import { languages } from '@/shared/components/editor/v2/utils';
import {
  FormFieldWrapper,
  PhoneInputWithCountry,
  RadioInput,
  TextInput,
} from '@/shared/components/forms';
import { Contact, NewContact } from '@/shared/types';
import { Channel } from '@/shared/types/channels';
import { Contact as ContactData } from '@/shared/types/contacts';
import { Tag as TagType } from '@/shared/types/tags';
import { Box, Button, Flex, VStack } from '@/shared/ui';
import {
  isValidEmail,
  isValidPhoneNumber,
  toE164,
} from '@/shared/utils/validations/validations';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';
import { SelectComboboxField } from '@/shared/v2/components/forms/selectCombobox/SelectComboboxField';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';
import { useUploads } from '../../uploads/context/UploadContext';
import { formatContactName, formatQuickSearchFilter } from '../../utils';
import { ContactAddress } from './ContactAddress';
import { DatePickerInput } from './DatePicketInput';
import { TextInputWithValidation } from './TextInputWithValidation';

type ContactFormProps = {
  /* initial state of name field */
  name?: string;
  /* initial state of phone field */
  phone?: string;
  /* initial state of email field */
  email?: string;
  /* add contact functionality */
  handleCreateContact?: (params: NewContactParams) => void;
  /* close add contact panel */
  onClose?: () => void;
  /* is contact edit form */
  isEdit?: boolean;
};

const contactBlockOptions = [
  {
    type: 'False',
    value: 'false',
    backgroundColor: '#FF010110',
    color: '#BB0007D5',
  },
  {
    type: 'True',
    value: 'true',
    backgroundColor: '#02BA3C16',
    color: '#006B3BE7',
  },
];

export const transformDate = (date?: { month: number; day: number; year?: number }) => {
  return date
    ? new Date(date.year || new Date().getFullYear(), date.month - 1, date.day)
    : null;
};

export const ContactForm = (props: ContactFormProps) => {
  const { onClose, handleCreateContact, name, phone, email, isEdit } = props;

  const [isPhoneDuplicate, setIsPhoneDuplicate] = useState(false);
  const [isEmailDuplicate, setIsEmailDuplicate] = useState(false);
  const [isExternalIdDuplicate, setIsExternalIDuplicate] = useState(false);

  const {
    channelsState: { channels },
  } = useChannels();
  const {
    tagsState: { allTags },
  } = useTags();
  const {
    uploadsState: { allUploads },
  } = useUploads();
  const {
    contactState: { current },
    addContactHttp,
    updateContactHttp,
  } = useContacts();
  const { updateContact } = useData();
  const { updateConversationContact } = useConversation();

  const formikRef = useRef<FormikProps<FormikValues>>(null);

  const [countryCode, setCountryCode] = useState<CountryCode>('US');

  const contactTagsListArray = useMemo(
    () => allTags.filter((tag: TagType) => tag.type === 'standard'),
    [allTags]
  );

  const handleValidateOnChange = useCallback(
    debounce(async (name: string, value: string) => {
      const formattedValue = name === 'phone' ? toE164(value, countryCode) : value;
      const startSearch = isEdit
        ? formattedValue !== current?.[name as keyof Contact]
        : true;
      if (value && name && startSearch) {
        const { total } = await searchContacts(
          formatQuickSearchFilter(formattedValue, name),
          [],
          5
        );
        if (name === 'phone') {
          if (total) {
            setIsPhoneDuplicate(true);
          } else {
            setIsPhoneDuplicate(false);
          }
        }
        if (name === 'email') {
          if (total) {
            setIsEmailDuplicate(true);
          } else {
            setIsEmailDuplicate(false);
          }
        }
        if (name === 'external_id') {
          if (total) {
            setIsExternalIDuplicate(true);
          } else {
            setIsExternalIDuplicate(false);
          }
        }
      }
    }, 500),
    [countryCode, isEdit]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string(),
        phone: Yup.string()
          .test('phone', 'Required', function (value) {
            const { email } = this.parent;
            return value || email ? true : this.createError({ message: 'Required' });
          })
          .test('isValidPhoneNumber', 'Please provide a valid phone number', (value) =>
            value ? !!isValidPhoneNumber(value, countryCode) : true
          )
          .test('isDuplicate', 'Duplicate found', (value) =>
            value ? !isPhoneDuplicate : true
          ),
        email: Yup.string()
          .email('Email is invalid')
          .test('email', 'Required', function (value) {
            const { phone } = this.parent;
            return value || phone ? true : this.createError({ message: 'Required' });
          })
          .test('isValidEmail', 'Email is invalid', (value) =>
            value ? !!isValidEmail(value) : true
          )
          .test('isDuplicate', 'Duplicate found', (value) =>
            value ? !isEmailDuplicate : true
          ),
        communication_preferences: Yup.array().test(
          'len',
          'Required',
          (arr) => (Array.isArray(arr) && arr.length > 0) || isEdit
        ),
        external_id: Yup.string().test('isDuplicate', 'Duplicate found', (value) =>
          value ? !isExternalIdDuplicate : true
        ),
      }),
    [isEmailDuplicate, isExternalIdDuplicate, isPhoneDuplicate, isEdit, countryCode]
  );

  useEffect(() => {
    if (formikRef?.current?.dirty) {
      formikRef?.current?.setFieldTouched('email', true);
      formikRef?.current?.setFieldTouched('external_id', true);
      formikRef?.current?.setFieldTouched('phone', true);
    }
  }, [isEmailDuplicate, isExternalIdDuplicate, isPhoneDuplicate]);

  useEffect(() => {
    if (isEdit && current?.phone) {
      const parsedPhone = parsePhoneNumber(current?.phone || '');
      setCountryCode(parsedPhone?.country || 'US');
    }
  }, [current?.phone, isEdit]);

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return {
      year: dateObj.getFullYear(),
      day: dateObj.getDate(),
      month: dateObj.getMonth() + 1,
    };
  };

  const initialValues = useMemo(() => {
    const parsedPhone = current?.phone ? parsePhoneNumber(current?.phone) : '';
    const formattedPhone = parsedPhone ? parsedPhone.nationalNumber : '';

    return {
      name: (isEdit ? current?.name : name) || '',
      phone: (isEdit ? formattedPhone : phone) || '',
      email: (isEdit ? current?.email : email) || '',
      birth_date: isEdit && current?.birth_date ? transformDate(current?.birth_date) : '',
      communication_preferences: !isEdit
        ? channels.map((location) => location.id)
        : current?.communication_preferences?.map((item: any) => item.channel_id),
      address: isEdit
        ? current?.address
        : {
            address_line_one: '',
            address_line_two: '',
            city: '',
            state: '',
            country: '',
            post_code: '',
          },
      default_channel_id: isEdit ? current?.default_channel_id : '',
      external_id: isEdit ? current?.external_id || '' : '',
      language: isEdit ? current?.language : 'en',
      blocked: isEdit ? current?.blocked?.toString() : 'false',
      contact_tags: isEdit ? current?.contact_tags : [],
      contact_lists: isEdit ? current?.contact_lists : [],
    };
  }, [isEdit, current]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount={!isEdit}
      onSubmit={async (values) => {
        const params = {
          name: formatContactName(values.name) || null,
          phone: values.phone ? toE164(values.phone, countryCode) : null,
          email: values.email || null,
          birth_date: values.birth_date ? formatDate(values.birth_date) : null,
          communication_preferences: values.communication_preferences,
          address: values.address,
          default_channel_id: values.default_channel_id,
          external_id: values.external_id || null,
          language: values.language,
          blocked: JSON.parse(values.blocked),
          contact_tags: values.contact_tags,
          contact_lists: values.contact_lists,
        };
        const filteredParams =
          isEdit && current
            ? Object.entries(params).filter(
                ([key, value]) =>
                  !isEqual(current?.[key as keyof Contact], value) &&
                  key !== 'communication_preferences'
              )
            : [];
        const editParam = {
          id: current?.id,
          ...Object.fromEntries(filteredParams),
        };
        try {
          let data = null;
          const handleCreate = handleCreateContact ? handleCreateContact : addContactHttp;
          if (isEdit) {
            data = await updateContactHttp(editParam as ContactParams);
            if (data) {
              updateConversationContact(data);
              updateContact(data as ContactData);
            }
          } else {
            data = await handleCreate(params as NewContact);
          }
          if (data) {
            onClose?.();
          }
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {(formik) => (
        <form
          data-testid="create-contact-form"
          onSubmit={formik.handleSubmit}
          style={{ width: '100%', height: '100%' }}
        >
          <VStack
            gap={1}
            css={{
              overflowY: 'auto',
              height: 'calc(100% - 64px)',
              width: '100%',
              px: isEdit ? 0 : 24,
              pt: isEdit ? 0 : 24,
            }}
          >
            <FormFieldWrapper label="Name" name="name">
              <TextInput placeholder="Add contact's name" />
            </FormFieldWrapper>
            <FormFieldWrapper label="Email" name="email">
              <TextInputWithValidation
                placeholder="Add contact's email"
                onValidateOnChange={handleValidateOnChange}
              />
            </FormFieldWrapper>
            <FormFieldWrapper label="Phone" name="phone">
              <PhoneInputWithCountry
                placeholder="(000) 000-0000"
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                onValidateOnChange={handleValidateOnChange}
              />
            </FormFieldWrapper>
            <FormFieldWrapper label="External ID" name="external_id">
              <TextInputWithValidation
                placeholder="Add contact’s external ID"
                onValidateOnChange={handleValidateOnChange}
              />
            </FormFieldWrapper>
            <FormFieldWrapper label="Address" name="address">
              <ContactAddress name="address" />
            </FormFieldWrapper>
            <FormFieldWrapper label="Birth Date" name="birth_date">
              <DatePickerInput placeholder="Add contact’s birth date" />
            </FormFieldWrapper>
            {channels.length > 1 ? (
              <FormFieldWrapper label="Default Channel" name="default_channel_id">
                <SelectComboboxField
                  options={channels.map((channel: Channel) => ({
                    label: channel?.name || channel?.address || '',
                    value: channel.id,
                  }))}
                  name="default_channel_id"
                  searchLabel="Search"
                  selectLabel="Add contact’s default channel"
                  error={
                    !!(
                      formik.touched.default_channel_id &&
                      formik.errors.default_channel_id
                    )
                  }
                  valueStyles={{
                    backgroundColor: '#0144FF0F',
                    color: '#00259ECB',
                  }}
                />
              </FormFieldWrapper>
            ) : null}
            <FormFieldWrapper label="Language" name="language">
              <SelectComboboxField
                options={languages.map((language) => ({
                  label: language.label,
                  value: language.value,
                }))}
                name="language"
                searchLabel="Search"
                selectLabel="Add contact’s language"
                error={!!(formik.touched.language && formik.errors.language)}
                valueStyles={{
                  backgroundColor: '#01CCFF1D',
                  color: '#005681DA',
                }}
              />
            </FormFieldWrapper>
            {!isEdit && contactTagsListArray.length > 1 && (
              <FormFieldWrapper name="contact_tags" label="Tags">
                <ComboboxMultiselectInput
                  options={contactTagsListArray.map((tag: TagType) => ({
                    label: tag.name,
                    value: tag.id,
                    color: tag.color,
                  }))}
                  searchLabel="Search"
                  selectLabel="Add tags to the contact"
                  Trigger={ComboboxMultiselectTrigger}
                  Option={ComboboxMultiselectItem}
                  invalid={!!(formik.touched.contact_tags && formik.errors.contact_tags)}
                  selectAll
                />
              </FormFieldWrapper>
            )}
            {!isEdit && allUploads?.length > 1 && (
              <FormFieldWrapper name="contact_lists" label="Lists">
                <ComboboxMultiselectInput
                  options={allUploads.map((tag: TagType) => ({
                    label: tag.name,
                    value: tag.id,
                    color: tag.color,
                  }))}
                  searchLabel="Search"
                  selectLabel="Add lists to the contact"
                  Trigger={ComboboxMultiselectTrigger}
                  Option={ComboboxMultiselectItem}
                  invalid={
                    !!(formik.touched.contact_lists && formik.errors.contact_lists)
                  }
                  selectAll
                  visualized
                />
              </FormFieldWrapper>
            )}
            {channels.length > 1 && !isEdit && (
              <FormFieldWrapper
                name="communication_preferences"
                label="Communication Preferences"
              >
                <ComboboxMultiselectInput
                  options={channels.map((channel) => ({
                    label: channel.name || channel.address || '',
                    value: channel.id,
                  }))}
                  searchLabel="Search"
                  selectLabel="Set communication preferences"
                  Trigger={ComboboxMultiselectTrigger}
                  Option={ComboboxMultiselectItem}
                  invalid={
                    !!(
                      formik.touched.communication_preferences &&
                      formik.errors.communication_preferences
                    )
                  }
                  selectAll
                />
              </FormFieldWrapper>
            )}
            <FormFieldWrapper label="Blocked" name="blocked">
              <RadioInput
                options={contactBlockOptions}
                css={{
                  display: 'flex',
                  marginTop: -10,
                }}
              />
            </FormFieldWrapper>
          </VStack>
          <ButtonsWrapper
            css={{
              borderTop: isEdit ? 'none' : 'thin solid $gray4',
              px: isEdit ? 0 : 24,
              py: isEdit ? 0 : 11,
            }}
          >
            <CancelButton
              aria-label="Close"
              variant="gray"
              css={{
                height: 40,
                flex: 1,
                mr: 12,
                fontSize: 16,
                backgroundColor: '#00003B0D',
                boxShadow: 'none',
                color: '#60646C',
              }}
              type="button"
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <Button
              aria-label="Save Contact"
              type="submit"
              css={{
                height: 40,
                flex: 1,
                fontSize: 16,
                '&:disabled': {
                  backgroundColor: '#00003B0D',
                  color: '#00082F46',
                  boxShadow: 'none',
                },
              }}
              disabled={!(formik.isValid && formik.dirty)}
            >
              {`${isEdit ? 'Save' : 'Create'} Contact`}
            </Button>
          </ButtonsWrapper>
        </form>
      )}
    </Formik>
  );
};

export const ButtonsWrapper = styled(Flex, {
  width: '100%',
  alignItems: 'center',
});

export const CancelButton = styled(Button, {
  fontSize: 16,
  lineHeight: 24,
  fontWeight: 500,
});

export const RadioIndicator = styled(Box, {
  width: 16,
  height: 16,
  border: 'thin solid $gray4',
  borderRadius: 16,
});

export const SelectedRadioIndicator = styled(Box, {
  width: 16,
  height: 16,
  border: '4 solid $primary',
  borderRadius: 16,
});
