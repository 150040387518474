import dayjs from 'dayjs';
import { History, Location } from 'history';

import { Campaign, CampaignStatus } from '@/shared/types/campaigns';

import {
  UPDATE_CLICKED_CONTACTS,
  UPDATE_DELIVERED_CONTACTS,
  UPDATE_NOT_CLICKED_CONTACTS,
  UPDATE_NOT_DELIVERED_CONTACTS,
  UPDATE_RESPONDED_CONTACTS,
  UPDATE_UNFULFILLED_CONTACTS,
  UPDATE_UNRESPONDED_CONTACTS,
  UPDATE_UNSUBSCRIBED_CONTACTS,
} from './types';

export const convertTime12to24 = (time12h: string): string => {
  // if not undefined, convert to 24h format, else return a default value
  const [time, modifier] = time12h ? time12h.split(' ') : ['00:00', 'AM'];

  let hours = time.split(':')[0];
  const minutes = time.split(':')[1];

  if (hours === '12' && modifier === 'PM') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
};

export function convertTime24to12(time: any): string {
  let ampm;
  if (time.length > 1) {
    time = time.split(':');
    ampm = +time[0] < 12 ? 'AM' : 'PM';
    time[0] = +time[0] % 12 || 12;
  }

  return `${time[0]}:${time[1]} ${ampm}`;
}

export const generateCampaignRequestType = (type: string) => {
  switch (type) {
    case 'delivered':
      return UPDATE_DELIVERED_CONTACTS;
    case 'not_delivered':
      return UPDATE_NOT_DELIVERED_CONTACTS;
    case 'clicks':
      return UPDATE_CLICKED_CONTACTS;
    case 'not_clicked':
      return UPDATE_NOT_CLICKED_CONTACTS;
    case 'responded':
      return UPDATE_RESPONDED_CONTACTS;
    case 'unresponded':
      return UPDATE_UNRESPONDED_CONTACTS;
    case 'unsubscribed':
      return UPDATE_UNSUBSCRIBED_CONTACTS;
    case 'unfulfilled':
      return UPDATE_UNFULFILLED_CONTACTS;
    default:
      break;
  }
};

export const sortScheduledCampaignsBySendDate = (scheduledCampaigns: Array<Campaign>) => {
  // list of every campaign with it's formatted send date
  const listOfCampaignDates = scheduledCampaigns.map((scheduledCampaign: Campaign) => {
    const schedule = scheduledCampaign.schedule_options;
    if (schedule) {
      const date = new Date(
        Date.UTC(
          Number(schedule.year),
          Number(schedule.month) - 1,
          Number(schedule.day),
          Number(schedule.hour),
          Number(schedule.minute)
        )
      );

      return { scheduledCampaign, formattedDate: dayjs(date).format('M/D/YYYY HH:mm') };
    }
    return { scheduledCampaign, formattedDate: '' };
  });

  // sorted list of every campaign with it's formatted send date
  const sorted = listOfCampaignDates.sort((a: any, b: any) => {
    const dateA = new Date(a.formattedDate);
    const dateB = new Date(b.formattedDate);
    return dateA.valueOf() - dateB.valueOf();
  });

  const listOfSortedCampaigns = sorted.map(
    (sortedObject) => sortedObject.scheduledCampaign
  );
  return listOfSortedCampaigns;
};

export const checkCampaignURL = (
  history: History,
  location: Location,
  campaign: Campaign,
  campaignContactsV2: boolean
) => {
  if (
    campaign?.status === CampaignStatus.COMPLETE ||
    campaign?.status === CampaignStatus.IN_PROGRESS ||
    campaign?.status === CampaignStatus.ARCHIVED ||
    campaign?.status === CampaignStatus.PAUSED ||
    campaign?.status === CampaignStatus.QUEUED ||
    campaign?.status === CampaignStatus.FAILED
  ) {
    if (campaign.type === 'email') {
      !location.pathname.includes(`/${campaign.type}/${campaign.id}/contacts`) &&
        !location.pathname.includes(`/${campaign.type}/${campaign.id}/overview`) &&
        history.push(`/campaigns/${campaign.type}/${campaign.id}/contacts`);
    } else {
      !location.pathname.includes(`/${campaign.type}/${campaign.id}/contacts`) &&
        !location.pathname.includes(`/${campaign.type}/${campaign.id}/overview`) &&
        !location.pathname.includes(`/${campaign.type}/${campaign.id}/analytics`) &&
        history.push(
          `/campaigns/${campaign.type}/${campaign.id}/${campaignContactsV2 ? 'contacts' : 'overview'}`
        );
    }
  } else if (
    location.pathname.includes(`/${campaign.type}/${campaign.id}/contacts`) ||
    location.pathname.includes(`/${campaign.type}/${campaign.id}/overview`) ||
    location.pathname.includes(`/${campaign.type}/${campaign.id}/analytics`) ||
    location.pathname.includes(`/campaigns/${campaign.id}`)
  ) {
    history.push(`/campaigns/${campaign.type}/${campaign.id}`);
  }
};
