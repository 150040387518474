/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ZoomableImageComponent } from '@/shared/components/ZoomableImage';
import { LayoutContent, MainLayout } from '@/shared/layouts';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Box, EmptyStateContainer, Flex, HStack, Skeleton, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { RenderConfiguration } from './configurations';
import IntegrationConfigurationsState from './configurations/context/IntegrationConfigurationContext';
import { useIntegrations } from './context/IntegrationsContext';

export const Integration = () => {
  const { integrationsState, getIntegration, setCurrentIntegration } = useIntegrations();
  const { current, loading } = integrationsState;
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // if the current integration is not set in state or the id does not match, get it from the api
    if (!current || current.id !== id) {
      getIntegration(id);
    }

    // on unmount, set the current integration to null
    return () => {
      setCurrentIntegration(null);
    };
  }, [id]);

  if (loading) {
    return <PageContainer />;
  }

  if (!current) {
    return (
      <EmptyStateContainer
        align="center"
        justify="center"
        direction="column"
        css={{ width: '100vw', height: '100vh' }}
      >
        <Text css={{ fontWeight: 600 }}>This integration does not exist.</Text>
        <Text css={{ mt: 10 }}>
          Please navigate back to the list of integrations and select one of the existing
          ones
        </Text>
      </EmptyStateContainer>
    );
  }

  // get the current.integration_attachments and map over them to display the images
  // order by the order key and remove the objects if is_logo is true
  const images = current?.application_attachments?.sort((a, b) => a.order - b.order);
  const logo = images?.filter((image) => image.is_logo === true);
  const integration_images = images?.filter((image) => image.is_logo === false);

  return (
    <MainLayout>
      <LayoutContent>
        <PageLayout
          breadcrumbs={[
            { title: 'Integrations', path: '/integrations' },
            {
              title: `${current?.name}`,
              path: `/integrations/${current?.id}`,
            },
          ]}
          actions={
            <IntegrationConfigurationsState>
              <RenderConfiguration integration={current} />
            </IntegrationConfigurationsState>
          }
        >
          <IntegrationContainer css={{ px: 0, paddingTop: 0, backgroundColor: 'white' }}>
            <ImagesContainer>
              <HStack gap="3">
                {integration_images.length > 0 &&
                  integration_images?.map((integration_image, index) => (
                    <ZoomableImageComponent key={index} url={integration_image?.url} />
                  ))}
                {current.application_attachments &&
                  current.application_attachments.length == 0 &&
                  [...Array(5)].map((_, index) => (
                    <Box
                      key={index}
                      css={{
                        backgroundColor: 'white',
                        width: '480px',
                        height: '320px',
                        borderRadius: '6px',
                      }}
                    ></Box>
                  ))}
              </HStack>
            </ImagesContainer>
            <Flex
              css={{ width: '100%', paddingTop: '30px' }}
              direction="row"
              justify="center"
            >
              <Flex direction="column" css={{ maxWidth: '600px', mr: 30 }}>
                <StyledHTML
                  dangerouslySetInnerHTML={{ __html: current.rich_body_text }}
                />
              </Flex>
              <Flex direction="column" css={{ width: '400px', ml: 30 }}>
                <Box css={{ mb: 20 }}>
                  <img
                    src={logo[0]?.url}
                    alt="logo"
                    style={{ width: '60px', height: '60px' }}
                  />
                </Box>
                <Box css={{ fontSize: '1.5em', color: '#333', fontWeight: '600' }}>
                  Details
                </Box>
                <IntegrationDetail
                  text="Category"
                  value={current.categories[0]}
                  showBorder
                />
                <IntegrationDetail
                  text="Developer"
                  value={current.developer || 'Whippy AI'}
                  showBorder
                />
                <IntegrationDetail
                  text="Website"
                  value={current.website || 'www.whippy.ai'}
                  showBorder
                />
                {current.pricing && (
                  <IntegrationDetail
                    text="Pricing"
                    value={current.pricing || 'Free'}
                    showBorder
                  />
                )}
              </Flex>
            </Flex>
          </IntegrationContainer>
        </PageLayout>
      </LayoutContent>
    </MainLayout>
  );
};

type IntegrationDetailProps = {
  text: string;
  value: string;
  showBorder?: boolean;
};

const IntegrationDetail = (props: IntegrationDetailProps) => {
  return (
    <Flex
      justify="between"
      css={{
        width: '100%',
        py: 24,
        borderBottom: props.showBorder ? '1px solid #eaeaea' : '',
      }}
      direction="row"
    >
      <Text size="3" variant="bold">
        {props.text}
      </Text>
      <Box>{props.value}</Box>
    </Flex>
  );
};

const PageContainer = () => {
  return (
    <PageLayout breadcrumbs={[{ title: 'Integrations', path: '/integrations' }]}>
      <IntegrationContainer css={{ px: 0, paddingTop: 0, backgroundColor: 'white' }}>
        <ImagesContainer>
          <HStack gap="3">
            {[...Array(5)].map((_, index) => (
              <Skeleton
                key={index}
                css={{
                  backgroundColor: 'white',
                  width: '480px',
                  height: '320px',
                  borderRadius: '6px',
                }}
              />
            ))}
          </HStack>
        </ImagesContainer>
      </IntegrationContainer>
    </PageLayout>
  );
};

const IntegrationContainer = styled(Box, {
  p: 20,
  minHeight: '100vh',
  height: 'fit-content',
});

const ImagesContainer = styled(Flex, {
  width: '100%',
  py: 20,
  px: 30,
  overflowX: 'scroll',
  backgroundColor: '#fafafa',
});

const StyledHTML = styled('div', {
  '& h1': {
    fontSize: '2em',
    color: '#333',
    fontWeight: '600',
  },
  '& h2': {
    fontSize: '1.5em',
    color: '#333',
    fontWeight: '500',
  },
  '& h3': {
    fontSize: '1.2em',
    color: '#333',
    fontWeight: '400',
  },
  '& p': {
    fontSize: '1em',
    color: '#000',
    lineHeight: '1.5',
  },
  '& br': {
    content: '""',
    display: 'block',
    margin: '1em 0',
  },
  '& a': {
    color: '#000',
    textDecoration: 'none',
  },
  '& a:hover': {
    textDecoration: 'underline',
  },
  '& ul': {
    listStyle: 'disc',
    paddingLeft: '1em',
    fontSize: '1em',
    color: '#000',
    lineHeight: '1.5',
  },
});
