import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import { PageHeader } from '@/shared/components/navigation/PageHeader';
import {
  ConversationFilterTypes,
  ConversationStatusTypes,
} from '@/shared/types/conversations';
import { Box, Flex, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { MatchParams } from '../context/ConversationContext';
import { ConversationsList } from '../list';
import ScheduledMessageState from '../scheduled/context/ScheduledMessagesContext';
import { InboxLayoutContainer } from './InboxLayoutContainer';

export const InboxContainer = ({ children }: { children: React.ReactNode }) => {
  const isInbox = useRouteMatch('/inbox/:filter?/:tab?');
  const isConversation = useRouteMatch('/inbox/:filter/:tab/:id');
  const isDesktop = useMedia('(min-width: 912px)');

  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');
  const filter = match?.params.filter || ConversationFilterTypes.ALL;
  const tab = match?.params.tab || ConversationStatusTypes.OPEN;

  return (
    <ScheduledMessageState>
      <Helmet>
        <title>Whippy | Inbox</title>
      </Helmet>
      <InboxLayoutContainer>
        {!isDesktop && !isConversation && (
          <PageHeader>
            <Box>
              <Text variant="semibold">Inbox</Text>
            </Box>
            <Box />
          </PageHeader>
        )}
        {isInbox && !isDesktop && (
          <MobileConversationListContainer exact={isConversation?.isExact}>
            <Box>
              <ConversationsList filter={filter} tab={tab} match={match} />
            </Box>
          </MobileConversationListContainer>
        )}
        {isConversation?.isExact && !isDesktop && (
          <MobileConversationContainer>{children}</MobileConversationContainer>
        )}
        {isDesktop && (
          <DesktopConversationListContainer>
            <ConversationsList filter={filter} tab={tab} match={match}>
              {children}
            </ConversationsList>
          </DesktopConversationListContainer>
        )}
      </InboxLayoutContainer>
    </ScheduledMessageState>
  );
};

const MobileConversationListContainer = styled(Flex, {
  height: '100%',
  overflow: 'hidden',
  display: 'block',
  '@lg': {
    display: 'none',
  },
  variants: {
    exact: {
      true: {
        display: 'none',
      },
    },
  },
});

const MobileConversationContainer = styled(Flex, {
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  '@lg': {
    display: 'none',
  },
});

const DesktopConversationListContainer = styled(Flex, {
  height: '100%',
  overflow: 'hidden',
  display: 'none',
  '@lg': {
    display: 'flex',
  },
});
