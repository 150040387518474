import { useHistory, useRouteMatch } from 'react-router-dom';

import { PageLayout } from '@/shared/layouts/PageLayout';
import { Flex, HStack } from '@/shared/ui';

import { ContentContainer, LinkSubNavigationItem, TabsContainer } from '../analytics';
import { useLinks } from '../context/LinksContext';
import { LinkEditor } from './LinkEditor';

type CreateLinkProps = {
  children: React.ReactNode;
  actions: React.ReactNode;
  sidebar?: React.ReactNode;
};

export const CreateLinkContainer = ({
  children,
  actions,
  sidebar,
}: CreateLinkProps): JSX.Element => {
  // links state
  const {
    linksState: { current },
  } = useLinks();

  // get the link tab from the url
  const match = useRouteMatch<{ tab: string }>('/campaigns/links/create/:tab');
  const tab = match?.params?.tab as string;

  const history = useHistory();

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns/links' },
          { title: 'Links', path: '/campaigns/links' },
          {
            title: `${current?.shareable_link?.short_url || 'Create New Link'}`,
            path: `/campaigns/links/${current?.id || 'create'}`,
          },
        ]}
        actions={actions}
      >
        <TabsContainer align="center">
          <HStack>
            <LinkSubNavigationItem
              selected={tab?.includes('link')}
              onClick={() => history.push(`/campaigns/links/create/link`)}
            >
              Edit Link
            </LinkSubNavigationItem>
            <LinkSubNavigationItem
              selected={tab?.includes('analytics')}
              onClick={() => history.push(`/campaigns/links/create/analytics`)}
            >
              Analytics
            </LinkSubNavigationItem>
          </HStack>
        </TabsContainer>
        <ContentContainer direction="column">{children}</ContentContainer>
      </PageLayout>
      {sidebar}
    </Flex>
  );
};

export const CreateLink = () => {
  return <LinkEditor containerComponent={CreateLinkContainer} />;
};
