import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo } from 'react';
import {
  HiClock,
  HiInbox,
  HiPaperClip,
  HiUserCircle,
  HiUserRemove,
} from 'react-icons/hi';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useIdle, useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';
import { UnreadConversationCountType } from '@/shared/types/conversations';

import { MatchParams, useConversation } from '../context/ConversationContext';
import { InboxSideNavigation } from './InboxSideNavigation';

export function InboxNavigation(): JSX.Element {
  const location = useLocation();
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();
  const {
    conversationState: { unread_counts },
  } = useConversation();
  const { tokens } = useAuth();

  const isScheduledInbox = /\/scheduled(\/|$)/.test(location.pathname);
  const isDraftInbox = /\/drafts(\/|$)/.test(location.pathname);
  const isAttachmentsInbox = /\/attachments(\/|$)/.test(location.pathname);
  const isAllInbox =
    /\/all(\/|$)/.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  const isMyInbox =
    /\/me(\/|$)/.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  const isUnassignedInbox =
    /\/unassigned\//.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  const isGhostedInbox =
    /\/ghosted\//.test(location.pathname) &&
    !isScheduledInbox &&
    !isAttachmentsInbox &&
    !isDraftInbox;
  // const isUnresponsiveInbox =
  //   /\/unresponsive\//.test(location.pathname) &&
  //   !isScheduledInbox &&
  //   !isAttachmentsInbox &&
  //   !isDraftInbox;
  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');

  const sideNavigationTabs: Array<sideNavigationGroup> = useMemo(
    () => [
      {
        sideNavigationGroup: [
          {
            title: 'All Conversations',
            image: <HiInbox size={18} />,
            isActive: isAllInbox,
            link: `/inbox/all/${
              match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
            }`,
            count: unread_counts?.all,
          },
          {
            title: 'Assigned to me',
            image: <HiUserCircle size={18} />,
            isActive: isMyInbox,
            link: `/inbox/me/${
              match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
            }`,
            count:
              unread_counts?.assigned_users?.find(
                (item: UnreadConversationCountType) => item?.id === `${tokens?.user_id}`
              )?.total || 0,
          },
          {
            title: 'Unassigned',
            image: <HiUserRemove size={18} />,
            isActive: isUnassignedInbox,
            link: `/inbox/unassigned/${
              match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
            }`,
            count:
              unread_counts?.assigned_users?.find(
                (item: UnreadConversationCountType) => item?.id === null
              )?.total || 0,
          },
          {
            title: 'Ghosted',
            image: <span role="img" aria-label="ghost">👻</span>,
            isActive: isGhostedInbox,
            link: `/inbox/ghosted/${
              match?.params.tab !== 'spam' ? match?.params.tab : 'open' || 'open'
            }`,
          },

          {
            title: 'Scheduled',
            image: <HiClock size={18} />,
            isActive: isScheduledInbox,
            link: `/inbox/${match?.params.filter}/${
              match?.params.tab !== 'spam' ? match?.params.tab : 'open'
            }/scheduled`,
          },
          {
            title: 'Attachments',
            image: <HiPaperClip size={18} />,
            isActive: isAttachmentsInbox,
            link: `/inbox/${match?.params.filter}/${
              match?.params.tab !== 'spam' ? match?.params.tab : 'open'
            }/attachments`,
          },
        ].filter(Boolean), // Filter out false values
      },
    ],
    [unread_counts, tokens?.user_id, match?.params, location.pathname]
  );

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Inbox"
          sideNavigationTabs={sideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        >
          <InboxSideNavigation />
        </SideNavigation>
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const InboxLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');

  const { showInboxUnreadCounts } = useFlags();
  const { getUnreadCounts } = useConversation();

  // Set the value of isIdle to true after 1 minute of user inactivity
  const isIdle = useIdle(60e3);

  // Trigger unread counts when tab is visible
  const handleVisibilityChange = () => {
    if (!document.hidden) {
      getUnreadCounts();
    }
  };

  useEffect(() => {
    if (showInboxUnreadCounts) {
      // First call unread counts in the inbox page
      getUnreadCounts();

      // Add event listener for visibility change
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
  }, [showInboxUnreadCounts]);

  // Trigger unread counts when the browser tab comes back from idle mode
  useEffect(() => {
    if (showInboxUnreadCounts) {
      if (isIdle === false) {
        getUnreadCounts();
      }
    }
  }, [isIdle]);

  // Add cleanup
  useEffect(() => {
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <MainLayout>
      {isDesktop && <InboxNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};
