/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { Combobox, ComboboxItem, ComboboxList, ComboboxProvider } from '@ariakit/react';
import * as RadixPopover from '@radix-ui/react-popover';
import { matchSorter } from 'match-sorter';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HiSearch } from 'react-icons/hi';

import { styled } from '@/stitches.config';

import { ComboboxMultiselectItem } from './ComboboxMultiselectItem';
import { ComboboxProps, OptionType } from './types';

export const ComboboxMultiselect = <T extends unknown>({
  options,
  selected,
  onSelect,
  searchLabel,
  selectLabel,
  Trigger,
  Option,
  selectAll,
  disabled,
  width,
  invalid,
  visualized,
  totalOptions,
  loadMore,
  currentOffset,
  loading,
}: ComboboxProps<T>) => {
  const selectAllValue = 'handleSelectAll';
  const ref = useRef<HTMLDivElement>(null);
  const endOfListRef = useRef<HTMLDivElement | null>(null);
  const initialVisibleItemCount = 30;

  const [visibleItemCount, setVisibleItemCount] = useState(initialVisibleItemCount);
  const [searchValue, setSearchValue] = useState('');
  const [allSelected, setAllSelected] = useState(
    selectAll && selected.length === options.length
  );
  const [open, setOpen] = useState(false);
  const comboboxRef = useRef<HTMLDivElement>(null);

  const matches = useMemo(
    () => matchSorter(options, searchValue, { keys: ['label', 'value'] }),
    [searchValue, options]
  );

  useEffect(() => {
    setSelectedLabels(calculateResult(selected, options));
  }, [selected]);

  useEffect(() => {
    if (visualized) {
      setVisibleItemCount(initialVisibleItemCount);
      ref.current?.scrollTo({ top: 0 });
    }
  }, [matches, visualized]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (
              totalOptions &&
              loadMore &&
              currentOffset !== undefined &&
              currentOffset < totalOptions &&
              !loading
            ) {
              loadMore();
            } else if (matches.length > visibleItemCount) {
              // increase visible items count
              setVisibleItemCount((prev) => prev + initialVisibleItemCount);
              // focus on last item in previous list to fix jumping scroll
              // then remove 'data-active-item' attribute to not highlight element
              const nodes = ref.current?.querySelectorAll('[role="option"]');
              const last = nodes?.[
                nodes?.length - initialVisibleItemCount
              ] as HTMLElement;
              if (last) {
                last?.focus();
                last?.removeAttribute('data-active-item');
              }
            }
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 1 }
    );

    const timer = setTimeout(() => {
      if (endOfListRef?.current && visualized) {
        observer.observe(endOfListRef?.current);
      }
    }, 100);

    return () => {
      if (endOfListRef.current) {
        observer.unobserve(endOfListRef?.current);
      }
      clearTimeout(timer);
    };
  }, [endOfListRef, matches, visibleItemCount, open, visualized]);

  const calculateResult = (
    selectedItems: Array<string>,
    options: Array<OptionType<T>>
  ) => {
    return selectedItems.length
      ? options.filter((option) => selectedItems.includes(option.value))
      : [];
  };

  const [selectedLabels, setSelectedLabels] = useState<Array<OptionType<T>>>(
    calculateResult(selected, options)
  );

  const updateState = (value: Array<string>, isAllSelected: boolean) => {
    onSelect(value);
    setAllSelected(isAllSelected);
  };

  const handleSelect = (value: Array<string>) => {
    if (value.includes(selectAllValue)) {
      if (allSelected) {
        updateState([], false);
      } else {
        updateState(
          matches.map((v) => v.value),
          true
        );
      }
    } else {
      updateState(value, value.length === matches.length);
    }
  };

  return (
    <RadixPopover.Root open={open}>
      <RadixPopover.Anchor
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
        ref={comboboxRef}
        style={{
          width: '100%',
          cursor: 'pointer',
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <div
          data-testid="combobox-trigger"
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
        >
          <Trigger
            selectedLabels={selectedLabels}
            isOpen={open}
            placeholder={selectLabel}
            invalid={invalid}
          />
        </div>
      </RadixPopover.Anchor>
      <RadixPopoverContent
        align="start"
        css={{ width: width ?? 'var(--radix-popover-trigger-width)' }}
        onPointerDownOutside={(event) => {
          const target = event.target as Element | null;
          const isCombobox = target === comboboxRef.current;
          isCombobox ? event.preventDefault() : setOpen(false);
        }}
        sideOffset={10}
        avoidCollisions={true}
      >
        <ComboboxProvider
          open={open}
          includesBaseElement={false}
          selectedValue={selected}
          setSelectedValue={handleSelect}
          setValue={(value) => {
            setSearchValue(value);
          }}
        >
          <ComboboxContainer data-testid="combobox-container">
            <ComboboxWrapper>
              <StyledComboboxIcon>
                <HiSearch />
              </StyledComboboxIcon>
              <StyledCombobox
                placeholder={searchLabel || ''}
                onBlurCapture={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              />
            </ComboboxWrapper>
            <StyledComboboxList ref={ref} role="listbox">
              {selectAll && !!matches.length && (
                <StyledComboboxItem
                  data-testid="combobox-select-all"
                  value={selectAllValue}
                  focusOnHover
                >
                  <ComboboxMultiselectItem label="Select All" checked={allSelected} />
                </StyledComboboxItem>
              )}
              {(visualized ? matches.slice(0, visibleItemCount) : matches).map(
                (value) => (
                  <StyledComboboxItem
                    data-testid="combobox-option"
                    key={value.value}
                    value={value.value}
                    focusOnHover
                  >
                    <Option
                      checked={selected.includes(value.value)}
                      label={value.label}
                      color={value.color}
                    />
                  </StyledComboboxItem>
                )
              )}
              {visualized && (
                <div ref={endOfListRef} style={{ height: 1, marginBottom: 5 }} />
              )}
              {!matches.length && <div>No results found</div>}
            </StyledComboboxList>
          </ComboboxContainer>
        </ComboboxProvider>
      </RadixPopoverContent>
    </RadixPopover.Root>
  );
};

const RadixPopoverContent = styled(RadixPopover.Content, {
  zIndex: 999,
  background: 'white',
  borderRadius: 4,
  border: '1px solid #00003B0D',
  boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
});

const StyledComboboxItem = styled(ComboboxItem, {
  position: 'relative',
  fontSize: '14px',
  pointer: 'cursor',
  display: 'flex',
  height: '2rem',
  cursor: 'default',
  scrollMarginTop: '0.25rem',
  scrollMarginBottom: '0.25rem',
  alignItems: 'center',
  borderRadius: '0.25rem',
  paddingLeft: '0.425rem',
  paddingRight: '0.425rem',
  color: 'hsl(204 10% 10%)',
  outline: '2px solid transparent',
  outlineOffset: '2px',
  '&[data-active-item]': {
    backgroundColor: '#3A5CCC',
    color: '#fff',
  },
});

const StyledCombobox = styled(Combobox, {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 4,
  color: 'hsl(204 10% 10%)',
  padding: '$space$1 $space$5',
  width: '100%',
  height: '2rem',
  fontSize: '14px',
  minWidth: 'max-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  appearance: 'none',
  backgroundColor: 'hsl(204 10% 10% / 0.05)',
  paddingLeft: '1.75rem',
  outline: '2px solid transparent',
  outlineOffset: '2px',
});

const StyledComboboxList = styled(ComboboxList, {
  maxHeight: 'calc(var(--radix-popover-content-available-height) - 100px)',
  overflowY: 'auto',
  fontSize: '14px',
  pt: 8,
});

const StyledComboboxIcon = styled('div', {
  pointerEvents: 'none',
  position: 'absolute',
  left: '0.425rem',
  color: 'hsl(204 10% 10% / 0.6)',
});

const ComboboxWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '14px',
});

const ComboboxContainer = styled('div', {
  padding: '0.25rem',
});
