import React from 'react';

import {
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { CSS } from '@/stitches.config';

type TooltipIconButtonProps = {
  icon?: React.ReactElement;
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  side?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  variant?: 'ghost' | 'send' | 'review' | 'delete' | 'outline' | 'raised';
  sideOffset?: number;
  css?: CSS;
  size?: number;
  tooltipArrow?: boolean;
};

export const TooltipIconButton = React.forwardRef<
  HTMLButtonElement,
  TooltipIconButtonProps
>((props, ref) => {
  const { icon, text, onClick, side, sideOffset, css, variant, size, tooltipArrow } =
    props;

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton ref={ref} size={size} variant={variant} onClick={onClick}>
          {icon}
        </IconButton>
      </TooltipTrigger>
      <TooltipContent side={side} sideOffset={sideOffset} css={css}>
        {text}
        {tooltipArrow && <TooltipArrow />}
      </TooltipContent>
    </Tooltip>
  );
});

TooltipIconButton.displayName = 'TooltipIconButton';
