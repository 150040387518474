import { VisuallyHidden } from '@ariakit/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiChevronLeft, HiSearch, HiX } from 'react-icons/hi';
import { Virtuoso } from 'react-virtuoso';

import { DrawerHeaderContainer } from '@/pages/data/contacts/CreateContact';
import { ConversationPreview } from '@/pages/inbox/list/ConversationPreview';
import {
  ConversationFilterTypes,
  ConversationStatusTypes,
  ConversationType,
} from '@/shared/types/conversations';
import {
  Box,
  DialogTitle,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerPortal,
  DrawerTrigger,
  Flex,
  HStack,
  IconButton,
  Input,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useContacts } from '../../context/ContactContext';

const LIMIT = 20;

type ConversationsDrawerProps = {
  contactId: string;
  children: React.ReactNode;
  isInbox?: boolean;
  onClose?: () => void;
};

export const ConversationsDrawer = ({
  contactId,
  children,
}: ConversationsDrawerProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // contact context
  const {
    contactState: {
      currentContactConversations,
      searchedCurrentContactConversations,
      currentContactConversationsTotal,
      searchedCurrentContactConversationsTotal,
    },
    getContactConversations,
  } = useContacts();

  // local context
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');

  // handle search conversation
  const handleSearch = useCallback(
    debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      console.log('e.target.value', e.target.value);
      await getContactConversations(
        contactId,
        { offset: 0, limit: LIMIT },
        e.target.value,
        false
      );
      setSearch(e.target.value);
      setOffset(0);
    }, 500),
    [contactId]
  );

  // handle clear search
  const handleClearSearch = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    setSearch('');
    setOffset(0);
  }, []);

  // handle bottom state change to load more conversations
  const handleBottomStateChange = useCallback(
    async (atBottom: boolean) => {
      const total = search
        ? searchedCurrentContactConversationsTotal
        : currentContactConversationsTotal;
      const length = search
        ? searchedCurrentContactConversations?.length
        : currentContactConversations?.length;
      if (atBottom && total > length) {
        await getContactConversations(
          contactId,
          { offset: offset + LIMIT, limit: LIMIT },
          search,
          false
        );
        setOffset(offset + LIMIT);
      }
    },
    [
      offset,
      search,
      currentContactConversationsTotal,
      searchedCurrentContactConversationsTotal,
      currentContactConversations?.length,
      searchedCurrentContactConversations?.length,
      contactId,
    ]
  );

  // get contacts conversations on first mount
  useEffect(() => {
    contactId &&
      getContactConversations(contactId, { offset: 0, limit: LIMIT }, '', false);
  }, [contactId]);

  // reset local state when unmount
  useEffect(() => {
    return () => {
      setOffset(0);
      setSearch('');
    };
  }, []);

  return (
    <Drawer>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerPortal>
        <DrawerContent
          aria-describedby={`Contact Conversations`}
          css={{
            maxWidth: '420px',
            minWidth: '360px',
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <VisuallyHidden>
            <DialogTitle>Contact Conversations</DialogTitle>
          </VisuallyHidden>
          <Flex>
            <DrawerHeaderContainer css={{ px: 20 }}>
              <HStack>
                <DrawerClose>
                  <HiChevronLeft />
                </DrawerClose>
                <Heading css={{ textTransform: 'capitalize', ml: 20 }}>
                  Conversations
                </Heading>
              </HStack>
            </DrawerHeaderContainer>
          </Flex>
          <SearchContainer>
            <SearchIconContainer>
              <HiSearch />
            </SearchIconContainer>
            <SearchInput
              ref={inputRef}
              placeholder={`Search Conversations`}
              css={{ pl: 38 }}
              onChange={handleSearch}
            />
            {search.length > 0 && (
              <SearchControlsContainer css={{ right: 24 }}>
                <IconButton onClick={handleClearSearch}>
                  <HiX />
                </IconButton>
              </SearchControlsContainer>
            )}
          </SearchContainer>
          <DrawerContentContainer>
            <Virtuoso
              style={{ width: '100%' }}
              data={
                search ? searchedCurrentContactConversations : currentContactConversations
              }
              atBottomStateChange={handleBottomStateChange}
              itemContent={(i: number, item: ConversationType) => (
                <ConversationPreview
                  key={`${i}-${item?.id}-${item?.status}`}
                  conversation={item}
                  isActive={false}
                  tab={item.status || ConversationStatusTypes.OPEN}
                  filter={ConversationFilterTypes.ALL}
                  searchType={'none'}
                  search={search}
                  isContactPanel
                />
              )}
              components={{
                EmptyPlaceholder: () => {
                  if (search) {
                    return (
                      <Box css={{ textAlign: 'center', fontSize: 14 }}>
                        No search results
                      </Box>
                    );
                  }
                  return null;
                },
                Header: () => <Box css={{ pb: 18 }} />,
                Footer: () => <Box css={{ pb: 18 }} />,
              }}
            />
          </DrawerContentContainer>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

export const DrawerContentContainer = styled(Flex, {
  position: 'relative',
  width: '100%',
  height: '100%',
  flex: 1,
});

export const Heading = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 17,
  fontWeight: 800,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SearchContainer = styled(Box, {
  position: 'relative',
  width: '100%',
  px: 16,
  py: 12,
  borderBottom: 'thin solid var(--colors-gray4)',
});

const SearchInput = styled(Input, {
  boxShadow: 'none',
  height: 32,
  '&:focus': {
    boxShadow: 'none',
  },
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  left: 20,
});

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  right: 16,
});
