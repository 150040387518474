import React from 'react';

import { VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { CampaignOverviews } from '../usage/CampaignOverviews';
import { UserCampaign } from '../usage/UserCampaign';

export const CampaignsReport = () => {
  return (
    <ReportsContentContainer title="Campaigns">
      <VStack gap={5}>
        <CampaignOverviews />
        <UserCampaign />
      </VStack>
    </ReportsContentContainer>
  );
};
