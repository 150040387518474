// The reasoning behind why these functions are created is: in the FE we save the current filters in the following format {tags: [ArrayOfTagIds], sources: [ArrayOfSourceNames]} (this is the "simple format").

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { BirthDate } from '@/shared/types/contacts/uploads';
import { ConversationType } from '@/shared/types/conversations';

import { getFilteredAndSortedMessages } from '../inbox/list/ConversationPreview';

// The "complicated format" is the one we send to the BE {type: 'and', filters: [{attribute: 'tag.id', comparison: 'in', type: 'list', value: [ArrayOfTagIds]}, {attribute: 'source', comparison: 'in', type: 'list', value: [ArrayOfSourceNames]}]}.

// So what these functions do is convert one format to the other.

export const simplifyContactFilters = (filtersToBeSimplified: any) => {
  if (!filtersToBeSimplified) {
    return { tags: [], sources: [] };
  }
  if (filtersToBeSimplified.search[0]?.filters.length > 1) {
    if (filtersToBeSimplified.search[0]?.filters[0]?.attribute === 'tag.id') {
      return {
        tags: filtersToBeSimplified.search[0]?.filters[0]?.value,
        sources: filtersToBeSimplified.search[0]?.filters[1]?.value,
      };
    } else {
      return {
        tags: filtersToBeSimplified.search[0]?.filters[1]?.value,
        sources: filtersToBeSimplified.search[0]?.filters[0]?.value,
      };
    }
  } else {
    if (filtersToBeSimplified.search[0]?.filters[0]?.attribute === 'tag.id') {
      return { tags: filtersToBeSimplified.search[0]?.filters[0]?.value, sources: [] };
    } else {
      return { sources: filtersToBeSimplified.search[0]?.filters[0]?.value, tags: [] };
    }
  }
};

export const complicateContactFilters = (filtersToBeComplicated: any) => {
  if (
    filtersToBeComplicated.tags?.length === 0 &&
    filtersToBeComplicated.sources?.length === 0
  ) {
    return { search: [] };
  } else if (
    filtersToBeComplicated.tags?.length > 0 &&
    filtersToBeComplicated.sources?.length === 0
  ) {
    const params = {
      type: 'and',
      filters: [
        {
          attribute: 'tag.id',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.tags,
        },
      ],
    };

    return { search: [params] };
  } else if (
    filtersToBeComplicated.tags?.length === 0 &&
    filtersToBeComplicated.sources?.length > 0
  ) {
    const params = {
      type: 'and',
      filters: [
        {
          attribute: 'source',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.sources,
        },
      ],
    };

    return { search: [params] };
  } else {
    const params = {
      type: 'and',
      filters: [
        {
          attribute: 'tag.id',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.tags,
        },
        {
          attribute: 'source',
          comparison: 'in',
          type: 'list',
          value: filtersToBeComplicated.sources,
        },
      ],
    };

    return { search: [params] };
  }
};

// removes leading and trailing and prevents full whitespace names
export const formatContactName = (contactName: string) => {
  if (!contactName || typeof contactName !== 'string') return '';
  if (contactName.trim().length === 0 && contactName !== '') {
    return '';
  }
  return contactName.trim();
};

export const formatBirthDate = (date: string): BirthDate => {
  const dateObject = new Date(date);

  return {
    day: dateObject.getUTCDate(), // Day of the month (1-31)
    month: dateObject.getUTCMonth() + 1, // Months are zero-based, so add 1
    year: dateObject.getUTCFullYear(), // Full year
  };
};

export const formatQuickSearchFilter = (value: string, name: string) => {
  return [
    {
      column: name,
      comparison: 'ilike',
      resource: 'contact',
      value,
    },
  ];
};

export const renderCampaignDate = (date?: string) => {
  dayjs.extend(relativeTime);

  if (!date) return 'Today';

  if (dayjs(new Date()).diff(new Date(date), 'day') < 1) {
    return dayjs(new Date(date)).fromNow(true);
  }

  return dayjs(new Date(date)).format('ddd DD MMM YY');
};

export const renderConversationDate = (date: string) => {
  dayjs.extend(relativeTime);
  return dayjs(new Date(date)).fromNow();
};

export const renderMessageDate = (conversation: ConversationType) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  // Filter out event items from conversationItems
  const ordered_messages = getFilteredAndSortedMessages(conversation);

  // Get the last message in the conversation
  const message = ordered_messages.length > 0 ? ordered_messages.slice(0)[0] : null;

  // Get date
  const date = message
    ? message.inserted_at
    : conversation?.updated_at || conversation.created_at || conversation?.inserted_at;
  // get days
  const days = dayjs().diff(dayjs.utc(date).tz(), 'days');
  // get hours
  const hours = dayjs().diff(dayjs.utc(date).tz(), 'hours');
  // Get minutes
  const minutes = dayjs().diff(dayjs.utc(date).tz(), 'minutes');

  if (days >= 1) {
    // Return days
    return `${days}d`;
  } else if (hours >= 1) {
    // Return hours
    return `${hours}h`;
  } else if (minutes >= 1) {
    // Return minutes
    return `${minutes}m`;
  } else {
    return 'a few seconds';
  }
};
