import moment from 'moment';
import React from 'react';
import { ResponsiveContainer } from 'recharts';

import { Heatmap } from '@/pages/agents/analytics/Heatmap';
import { Card } from '@/pages/links/analytics/Card';
import { Chart } from '@/pages/links/analytics/Chart';
import { ReportData } from '@/shared/api/reports';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { Grid } from '@/shared/ui';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

const new_chart_config = [
  {
    dataKey: 'count',
    fill: '#3E54CF',
    name: 'Conversations',
  },
];
const opened_chart_config = [
  {
    dataKey: 'count',
    fill: '#8E4EC6',
    name: 'Conversations',
  },
];

const closed_chart_config = [
  {
    dataKey: 'count',
    fill: '#D6409F',
    name: 'Conversations',
  },
];

const reportCards = [
  {
    label: 'New Conversations',
    type: 'total',
    description:
      'The total number of new conversations created during the selected time period.',
  },
  {
    label: 'Opened Conversations',
    type: 'total',
    description:
      'The total number of opened conversations created during the selected time period.',
  },
  {
    label: 'Closed Conversations',
    type: 'total',
    description:
      'The total number of closed conversations created during the selected time period.',
  },
];

export type BarData = {
  key: string;
  count: number;
}[];

export const ConversationsAnalytics = ({
  data,
  newConversationsBar,
  openedConversationsBar,
  closedConversationsBar,
  isLoading,
}: {
  data: Array<ReportData>;
  newConversationsBar: BarData;
  openedConversationsBar: BarData;
  closedConversationsBar: BarData;
  isLoading: boolean;
}) => {
  return (
    <>
      <Grid columns="3" gap="5" data-testid="conversations-analytics">
        {reportCards.map((card) => (
          <Card
            key={card.label}
            title={card.label}
            value={
              data.find((d) => d.chart === card.type && d.label == card.label)?.values[0]
                ?.x as number
            }
            tooltipContent={card.description}
            loading={isLoading}
            hidePercent
          />
        ))}
      </Grid>
      <ReportsCollapsible title="Conversations Heatmap" description="">
        {data.length > 0 && (
          <ResponsiveContainer>
            <Heatmap data={data} loading={isLoading} />
          </ResponsiveContainer>
        )}
      </ReportsCollapsible>
      <Chart
        title="New Conversations by Day"
        chartConfig={new_chart_config}
        data={newConversationsBar || []}
        loading={isLoading}
        barSize={56}
      />
      <Chart
        title="Opened Conversations by Day"
        chartConfig={opened_chart_config}
        data={openedConversationsBar || []}
        loading={isLoading}
        barSize={56}
      />
      <Chart
        title="Closed Conversations by Day"
        chartConfig={closed_chart_config}
        data={closedConversationsBar || []}
        loading={isLoading}
        barSize={56}
      />
    </>
  );
};
